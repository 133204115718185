<template>
  <ProSpaceRightPanel
    class="case-panel"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @delete="deletePanel"
    @clear="clearPanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerCases">
        <template #header>
          <ProSpaceAction :text="$t('editItemTxt')" @click="editPanel" />
        </template>
        <ProSpacePanelBlockList :list="content" />
        <ProSpacePanelBlockItem
          :title="$t('activityPeriod')"
          v-if="useCase"
          class="padding-top-10"
          :topline="true"
        >
          <template #content>
            <ProSpaceDatesColumn
              :start-date="useCase.startDate"
              :end-date="useCase.endDate"
              :format="userinfo.dateFormat"
            />
          </template>
        </ProSpacePanelBlockItem>
      </ProSpacePanelBlock>
      <ProSpacePanelBlock :header="headerClaim" class="margin-top-20">
        <template #header>
          <ProSpaceAction :text="$t('addItemTxt')" @click="onClickAdd" />
        </template>
        <ProSpaceSimpleGrid
          ref="simple-grid"
          :headers="listHeaders"
          :service="secondService"
          prop-id="securityUserId"
          getMethod="grid"
          deleteMethod="deleteItem"
          deletable
          :id="selectedData.id"
          :key="selectedData.id"
          :btnText="$t('viewMore')"
          :confirmDelete="true"
          :confirmTitle="$t('deletingClaim')"
          :confirmText="$t('questionDeleting')"
          :confirmObject="$t('claim')"
          :modalTitle="`${$t('add')} ${$t('useCaseLower')}`"
          :cancelTxt="$t('cancelInf')"
          :applyTxt="$t('applyTxt')"
        />
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockHeader,
  ProSpacePanelBlockItem,
  ProSpaceSimpleGrid,
  ProSpaceDatesColumn
} from "@prospace/prospace-components-library";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "CasePanel",
  components: {
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockHeader,
    ProSpacePanelBlockItem,
    ProSpaceSimpleGrid,
    ProSpaceDatesColumn,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    service: {
      type: Object,
      required: true,
    },
    secondService: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      useCase: null,
      header: {},
      loading: false,
      hasError: false,
      headerCases: {
        title: this.$t("useCase"),
        isExpand: false,
      },
      headerClaim: {
        title: this.$t("claim"),
        isExpand: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    listHeaders() {
      return [
        { name: this.$t("taskType"), value: "securityClaimServiceName" },
        { name: this.$t("value"), value: "securityClaimValue" },
      ];
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      }
    },
    header() {
      if (this.useCase) {
        return {
          title: this.useCase.title,
          id: this.useCase.id,
          showCounter: false,
          options: this.options
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
      };
    },
    content() {
      if (this.useCase) {
        return [
          [
            {
              title: this.$t("description"),
              text: this.useCase.description,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.useCase) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.useCase.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.useCase.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.useCase.createdAt),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.useCase.lastUpdated),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData() {
      this.update();
    },
  },
  methods: {
    async update() {
      this.hasError = false;
      try {
        this.useCase = await this.getCase();
      } catch (e) {
        this.hasError = true;
      }
    },
    async refresh() {
      await this.$refs["simple-grid"].getServerData();
      await this.update();
    },
    async getCase() {
      if (this.selectedData) {
        this.loading = true;
        const res = await this.service.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    onClickAdd() {
      this.$emit("add");
    },
    editPanel() {
      this.$emit("edit-item", this.useCase);
    },
    clearPanel() {
      this.$emit("clear");
    },
    deletePanel() {
      this.$emit("delete-item", this.useCase);
    },
    showHistoryPanel() {
      this.$emit("history-item", this.useCase);
    },
  },
};
</script>
