<template>
    <ProSpaceFlashMessage
        v-model="model"
        :applyTxt="$t('confirmTxt')"
        :cancelTxt="$t('cancelTxt')"
        :title="$t('questClearFilters')"
        @apply="onApply"
        @cancel="onCancel"
        :needHideApply="false"
    >
        {{ $t('bodyClearFilters') }}
    </ProSpaceFlashMessage>
</template>

<script>
import {
    ProSpaceFlashMessage 
} from "@prospace/prospace-components-library";
import {
    useMultiSelectModalBlockFilters
} from "@composes/multiselect"
export default {
    components: {
        ProSpaceFlashMessage
    },
    name: "ModalBlockFilters",
    setup() {
        const {
            model,
            onApply,
            onCancel
        } = useMultiSelectModalBlockFilters()
        return {
            model,
            onApply,
            onCancel
        }
    }
}
</script>

<style>

</style>