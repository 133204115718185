<template>
  <ProSpaceGeoLayout isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('dashboardsName')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="this.$t('newDashboard')"
            @click="showModalForm"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="basetype-tree__left-panel"
        searchable
        :panelName="$t('topics')"
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            searchName="name"
            getNodeMethod="treeWithAll"
            propKey="id"
            propLabel="name"
            defaultSortField="name"
            :searchPlaceholder="$t('searchTxt')"
            :service="baseTypeService"
            :prop-parent-id="null"
            :defaultFilter="{
              disabled: { and: [false] },
              systemDirectoryCode: { and: ['DashboardSection'] },
            }"
            @select="nodeSelect"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="dashboardService.constructor.name + 'grid'"
            :noMountedRequest="true"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
            ref="filterPanel"
            :filter-model="['name', 'description']"
            :ignore-clear-keys="['section']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('nameL')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.description"
                :placeholder-value="$t('description')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="dashboardService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="dashboardService"
            :noMountedRequest="true"
            :default-filter="defaultFilters.disabled"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="name"
                :header="this.$t('nameL')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :icon="field.data.icon"
                    :noHover="!checkFormAccess(field.data)"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn v-if="field.data">
                    {{ field.data.description }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    v-if="field.data"
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="restrictions" style="min-width: 40px; max-width: 40px;">
                <template #body="field">
                  <ProSpaceIcon
                    v-if="isRestrictions(field.data) && checkAccess('restrictions-tab')"
                    icon="boxes" style="top: 6px;"
                    v-hint="$t('dashboardRestrictionsHint', { arg: this.getRestrictionsNames })"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="wrapperCheckRoles(dotsOptions, field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <DashboardModalForm
        v-model="showModal"
        :id="editId"
        :dashboardName="editDashboardName"
        :afterSaveHandler="actionUpdate"
      />
    </template>
    <template #right>
      <DashboardPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="dashboardService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkEditAccess="checkFormAccess"
        :checkRestrictionsAccess="checkRestrictionsAccess"
        :checkAccess="checkAccess"
        @edit="editItem"
        @delete="deleteItem"
        @open="toItem"
        @edit:restrictions="editRestrictionsItem"
        @show:modal="showDRPModals($event)"
        @openModalViewMore="openModalViewMore($event)"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteDashboard', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
  <DashboardModalRestrictions
    v-if="showModalRestrictions"
    v-model="showModalRestrictions"
    :dashboard="editedRestrictionsDashboard"
    @update="actionUpdate"
  />
  <DistributorSelectModal
    v-model="showDistributorModal"
    selectionMode="single"
    readOnly
    :modalTitle="$t('listDistributors')"
    :service="distributorGridService"
    :getMethod="distributorGetMethod"
    :defaultFilter="distributorGridFilter"
    :filterModel="distributorFilterModel"
    :filterPrefix="distributorFilterPrefix"
  />
  <DistributorHierarchySelectModal
    class="dashboard-restrictions-selections__hierarchy"
    v-model="showPositionsModal"
    expandAll
    hideRightPanel
    hideTags
    needFilterValues
    readOnly
    modalWidth="525"
    modalHeight="575"
    :titleModal="$t('positions')"
    :service="positionsService"
    getMethod="allTree"
    selectionMode="checkbox"
    :propParentId="null"
    :checkedRows="positionsCheckedRows"
    @close="clearPositions"
  />
  <RolesSelectModal
    v-model="showRolesModal"
    readOnly
    :service="rolesService"
    :default-filter="defaultFilters.disabled"
  />
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceHLayout,
  Filters,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage,
  ProSpaceDefaultTextColumn,
  Hint,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";

import { DashboardService } from "../../services/DashboardService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { RoleService } from "../../../../../Services/Notification/ProSpace.Notification.Frontend/services/RoleService";


import DashboardPanel from "./components/DashboardPanel";
import DashboardModalForm from "./components/DashboardModalForm";
import DashboardModalRestrictions from "./components/DashboardRestrictions/DashboardModalRestrictions.vue";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal";
import RolesSelectModal from "@modals/RolesSelectModal.vue";

import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useMultiSelectGrid } from "@composes/multiselect";
import { ref } from "vue";
const _ = require("lodash");
import { useStatistic } from "@composes";
export default {
  mixins: [accessMixin],
  directives: {
    hint: Hint
  },
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    DashboardPanel,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceInputText,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceHLayout,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage,
    DashboardModalForm,
    DashboardModalRestrictions,
    ProSpaceDefaultTextColumn,
    DistributorSelectModal,
    DistributorHierarchySelectModal,
    RolesSelectModal,
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      v$: useVuelidate(),
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    }
  },
  data() {
    return {
      showModal: false,
      showModalRestrictions: false,

      // Service
      dashboardService: new DashboardService(),
      baseTypeService: new BaseTypeService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      roleService: new RoleService(),

      //InlinePanel
      selectedNode: null,
      editId: null,
      editDashboardName: null,
      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },
      defaultFilters: Filters.getDefaultsFilters(),

      deletingModel: null,
      showDeleteModal: false,

      editedRestrictionsDashboard: null,

      // distributors modal
      showDistributorModal: false,
      distributorGridFilter: null,
      distributorGridService: null,
      distributorFilterModel: null,
      distributorFilterPrefix: "",
      distributorGetMethod: "grid",

      // positions modal
      showPositionsModal: false,
      positionsService: null,
      positionsCheckedRows: null,

      // roles modal
      showRolesModal: false,
      rolesService: null,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      let res = [
        {
          name: "open",
          text: this.$t("open"),
          icon: "file-lines",
          method: this.toItem,
          liteRef: "open-option",
        },
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "restrictions",
          text: this.$t("setPermissions"),
          icon: "list-check",
          method: this.editRestrictionsItem,
          liteRef: "restrictions-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        }
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        res = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    fastFilters() {
      let filters = [{ label: this.$t("allTxt"), field: "disabled", value: false }];

      if (this.checkDeleteAccess())
        filters.push({
          label: this.$t("deleted"),
          field: "disabled",
          value: true,
        });

      return filters;
    },
    getRestrictionsNames() {
      return `${this.$t('distributorsRestrictionType', 3)}, ${this.$t('rolesRestrictionType', 3)} ${this.$t('simpleAND')} ${this.$t('positionsRestrictionType', 3)}`;
    },
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.filterPanel.filter["section"] = filter;
      this.filterPanel.submitFilter();
      this.selectedNode = _.cloneDeep(value);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    showModalForm() {
      this.editId = null;
      this.editDashboardName = null;
      this.showModal = true;
    },
    editItem(model) {
      if (!model) return;
      this.showModal = true;
      this.editId = model.id;
      this.editDashboardName = model.name;
    },
    toItem(model) {
      if (!model) return;
      const url = `/dashboard/dashboard/${model.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push({
        name: 'dashboard',
        params: { id: model.id }
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.dashboardService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear()
        this.deletingModel = null;
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    editRestrictionsItem(e) {
      this.editedRestrictionsDashboard = e;
      this.showModalRestrictions = true;
    },
    distrListShow() {
      this.distributorGridFilter = { disabled: { and: [false] } };
      this.distributorGridService = this.distributorService;
      this.distributorGetMethod = "grid";
      this.distributorFilterModel = ['code', 'name'];
      this.distributorFilterPrefix = "";
      this.$nextTick(() => {
        this.showDistributorModal = true;
      });
    },
    positionsListShow() {
      this.positionsService = this.distributorOrgService;
      this.$nextTick(() => {
        this.showPositionsModal = true;
      });
    },
    rolesListShow() {
      this.rolesService = this.roleService;
      this.$nextTick(() => {
        this.showRolesModal = true;
      });
    },
    showDRPModals(name) {
      if(name === 'distributors') {
        this.distrListShow();
      } else if (name === 'positions') {
        this.positionsListShow();
      } else if (name === 'roles') {
        this.rolesListShow()
      }
    },
    isRestrictions(data) {
      const { restrictions } = data;
      if (restrictions) {
        return restrictions.distributors.length > 0 ||
          restrictions.isAllDistributors ||
          restrictions.roles.length > 0 ||
          restrictions.isAllRoles ||
          restrictions.positions.length > 0 ||
          restrictions.isAllPositions;
      } else {
        return false;
      }
    },
    collectChildren(data) {
      const result = [];

      function recurse(node) {
        result.push(node);

        if (node.children && node.children.length > 0) {
          node.children.forEach(child => recurse(child));
        }
      }

      data.forEach(item => recurse(item));

      return result;
    },
    async openModalViewMore({ name, filter }) {
      if (name === 'positions') {
        this.positionsService = this.distributorOrgService;
        let positions = null;
        try {
          const res = await this.positionsService.allTree();
          positions = res.data;
        } catch {
          console.error('Can not get positions');
        }
        if (positions) {
          const checkedIds = Object.values(Object.values(filter)[0].or);
          const collectPositions = this.collectChildren(positions);
          const checkedPositions = collectPositions.filter(p => checkedIds.includes(p.id));

          this.positionsCheckedRows = checkedPositions;
        }
      }
      this.$nextTick(() => {
        this.showDRPModals(name);
      });
    },
    clearPositions() {
      this.positionsService = null;
      this.positionsCheckedRows = null;
    }
  },
  mounted() {
    this.loadRoleAccess();
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.dashboard-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: var(--prospace-ui-border);
  }
}
</style>
