<template>
  <div class="dashboard-colorpicker">
    <ProSpaceVLayout :gap="5">
      <ProSpaceHLayout v-if="label || hint"  :gap="5">
        <ProSpaceLabel class="dashboard-colorpicker__label" :line="20" v-if="label" :text="label" color=""/>
        <ProSpaceIcon v-if="hint" icon="question" v-hint="hint" />
      </ProSpaceHLayout>

      <ProSpaceDropdown
        v-if="localColor"
        v-model="localColor"
        :placeholder="$t('graphColor')"
        :getMethod="getMethod"
        :service="dashboardColorService"
        panelClass="dashboard-colorpicker__dropdown-panel ui-shadow"
      >
        <template #value="slotProps">
          <ProSpaceHLayout :gap="5">
            <ColorPicker
              v-if="localColor"
              v-model="colorTemp"
              :class="['dashboard-colorpicker-preview', `dashboard-colorpicker-preview--${colorTemp.slice(1)}`]"
              format="hex"
              disabled
              :autoZIndex="false"
              :baseZIndex="5001"
            />
            {{ getOptionText(slotProps) }}
          </ProSpaceHLayout>
        </template>
        <template #option="slotProps">
          <ProSpaceHLayout :gap="5">
            <ColorPicker
              v-if="localColor"
              v-model="slotProps.option.label"
              class="dashboard-colorpicker-preview"
              format="hex"
              disabled
              :autoZIndex="false"
              :baseZIndex="5001"
            />
            <span class="text-size-13">
              {{ getOptionText(slotProps.option) }}
            </span>
          </ProSpaceHLayout>
        </template>
      </ProSpaceDropdown>
      <div class="dashboard-colorpicker__panel"></div>
    </ProSpaceVLayout>
  </div>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceIcon,
  ProSpaceDropdown,
  Hint,
} from "@prospace/prospace-components-library";
import ColorPicker from 'primevue/colorpicker';
import { DashboardColorService } from "@dashboard/services/DashboardColorService.js"

export default {
  name: "DashboardColorPicker",
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
    ColorPicker,
    ProSpaceLabel,
    ProSpaceIcon,
    ProSpaceDropdown
  },
  directives: {
    hint: Hint,
  },
  emits: ['update:color'],
  props: {
    color: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ["text", "background", "graph"].includes(value);
      },
    },
    hint: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      localColor: null,
      panel: null,
      dashboardColorService: new DashboardColorService()
    };
  },
  watch: {
    localColor(val) {
      this.$emit('update:color', val);
    }
  },
  mounted() {
    this.localColor = this.color;
    // this.panel = this.$el.querySelector('.dashboard-colorpicker__panel');
  },
  computed: {
    getMethod() {
      let type = this.type.charAt(0).toUpperCase() + this.type.slice(1);
      return `get${type}Colors`;
    },
    documentStyle() {
      return getComputedStyle(document.documentElement);
    },
    colorTemp() {
      return this.documentStyle.getPropertyValue(this.localColor);
    }
  },
  methods: {
    getOptionText(option) {
      let prop = 'graphColor'
      if (option && option.value && option.value.startsWith("--prospace-")) {
        let theme = localStorage.getItem("theme") ?? "light";
        prop = theme + "-" + option.value.replace("--prospace-", "");
      }
      return this.$t(prop)
    }
  },
}
</script>

<style lang="scss">
$colors: (
  --prospace-ui-border-color: 'FFFFFF',
  --prospace-dashboard-text-black: '37474F',
  --prospace-text-gray: '687284',
  --prospace-dashboard-text-green: '27AE60',
  --prospace-dashboard-text-orange: 'DD7903',
  --prospace-ui-red: 'EE6D62',
  --prospace-block-green-secondary: 'E8F5E9',
  --prospace-block-realy-yellow: 'FFF8E1',
  --prospace-dashboard-background-red-secondary: 'FBE9E7',
  --prospace-dashboard-background-grey: 'F4F6F8',
  --prospace-dashboard-background-light-blue: 'E7F2FA'
);

.dashboard-colorpicker {
  position: relative;
  width: 100%;
  &__label {
    font-size: 12px !important;
    color: var(--prospace-text-gray);
  }
  .p-dropdown-label {
    padding-left: 8px !important;
  }
  &__dropdown-panel {
    .p-dropdown-items-wrapper {
      max-height: 185px !important;
    }
    .p-dropdown-item {
      padding-left: 8px !important;
      border: 1px solid transparent;
      @each $name, $color in $colors {
        &[aria-label="##{$color}"] {
          .dashboard-colorpicker-preview {
            border-color: var($name);
          }
        }
      }
      .dashboard-colorpicker-preview {
        & + span {
          padding-left: 3px;
        }
      }
    }
  }
}
.dashboard-colorpicker-preview {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid transparent;
  line-height: 14px;

  @each $name, $color in $colors {
    &--#{$color} {
      border-color: var($name);
    }
  }

  .p-colorpicker-preview {
    width: 100%;
    height: 100%;
    min-height: 14px;
    padding: 0;
    border: none;
    border-radius: 0px;
  }
}
.p-colorpicker-panel {
  z-index: 4001;
}
</style>
