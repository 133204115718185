import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "../../Common/utils/auth";
//import routes
import reportRoutes from "./routes/ReportRoutes";
import competenceRoutes from "./routes/CompetenceRoutes";
import reserveServiceRoutes from "./routes/ReserveServiceRoutes";
import statisticsReportRoutes from "./routes/StatisticsReportRoutes";
import newsServiceRoutes from "./routes/NewsServiceRoutes";
import documentServiceRoutes from "./routes/DocumentServiceRoutes";
import taskServiceRoutes from "./routes/TaskServiceRoutes";
import informationRoutes from "./routes/InformationRoutes";
import integrationRoutes from "./routes/IntegrationRoutes";
import masterdataRoutes from "./routes/MasterDataRoutes";
import basetypeRoutes from "./routes/BaseTypeRoutes";
import adminRoutes from "./routes/SecurityAdminRoutes";
import schedulerRoutes from "./routes/SchedulerRoutes";
import securityRoutes from "./routes/SecurityRoutes";
import notificationRoutes from "./routes/NotificationRoutes";
import systemSettingRoutes from "./routes/SystemSettingRoutes";
import workflowRoutes from "./routes/WorkflowRoutes";
import dashboardRoutes from "./routes/DashboardRoutes";

const routes = [
  reportRoutes,
  competenceRoutes,
  reserveServiceRoutes,
  statisticsReportRoutes,
  newsServiceRoutes,
  documentServiceRoutes,
  taskServiceRoutes,
  informationRoutes,
  integrationRoutes,
  masterdataRoutes,
  basetypeRoutes,
  adminRoutes,
  schedulerRoutes,
  securityRoutes,
  notificationRoutes,
  systemSettingRoutes,
  workflowRoutes,
  dashboardRoutes
].flat();

const comingSoonRoutes = [];

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken();
  if (to.path !== "/security/login" && !hasToken) {
    if (["locallogin", "login", "auth"].indexOf(to.name) == -1) {
      window.location.href = `/security/login?redirect=${to.path}`;
    } else {
      next();
    }
  } else if (comingSoonRoutes.includes(to.path)) {
    next(`/information/coming-soon`);
  } else if (to.meta.menuitem && !to.query.menuitem) {
    next({
      path: to.path,
      query: { ...to.query, menuitem: btoa(to.meta.menuitem) },
    });
  } else {
    next();
  }
});

export default router;
