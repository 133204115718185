<template>
  <ProSpaceGeoLayout class="usefullink" v-if="model">
    <template #top>
      <ProSpaceTopPanel
        :header="model.name ? model.name : $t('newTxtF') + ' ' + $t('link')"
        back
        @back="backHandler"
      >
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout :is-templated="true" :template-center="true">
        <template #center>
          <ProSpaceTabs
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="mixinLoadingAccess"
            :withoutMarginBottom="true"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              :disabled="v$.model.$invalid"
              icon="check"
              :text="$t('save')"
              ref="action-save"
              @click="save()"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('usefulLinkMainInfo')"
                      :bottom="$t('usefulLinkMainInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceDropdown
                        v-model="model.usefulLinkTypeCode"
                        ref="typeCode-ProSpaceDropdown"
                        :label="$t('usefulLinkType')"
                        :service="baseTypeService"
                        :placeholder="$t('usefulLinkType')"
                        propValue="code"
                        propLabel="name"
                        getMethod="grid"
                        argMethod="$filter=(systemDirectoryCode eq 'Usful link types' and disabled eq false)&$orderby=name asc"
                      />
                      <ProSpaceInputText
                        v-model="model.name"
                        ref="name-ProSpaceInputText"
                        :label="$t('usefulLinkName')"
                        :placeholder="$t('usefulLinkName')"
                        :invalid="v$.model.name.$error"
                        :invalid-messages="v$.model.name.$errors"
                        @blur="v$.model.name.$touch"
                      />
                      <ProSpaceInputText
                        v-model="model.link"
                        ref="link-ProSpaceInputText"
                        :label="$t('usefulLinkLink')"
                        :placeholder="$t('usefulLinkLink')"
                        :invalid="v$.model.link.$error"
                        :invalid-messages="v$.model.link.$errors"
                        :link="model.link"
                        @blur="v$.model.link.$touch"
                      />
                      <ProSpaceInputText
                        v-model="model.materialDescription"
                        ref="description-ProSpaceInputText"
                        :label="$t('usefulLinkMaterialDescription')"
                        :placeholder="$t('usefulLinkMaterialDescription')"
                        :invalid="v$.model.materialDescription.$error"
                        :invalid-messages="v$.model.materialDescription.$errors"
                        :isTextarea="true"
                        :autoResize="false"
                        @blur="v$.model.materialDescription.$touch"
                      />
                      <ProSpaceInputText
                        v-model="model.userManualLink"
                        ref="userManualLink-ProSpaceInputText"
                        :label="$t('usefulLinkUserManualLink')"
                        :placeholder="$t('usefulLinkUserManualLink')"
                        :invalid="v$.model.userManualLink.$error"
                        :invalid-messages="v$.model.userManualLink.$errors"
                        :isTextarea="true"
                        :autoResize="false"
                        @blur="v$.model.userManualLink.$touch"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.$error ||
                      v$.model.name.$error ||
                      v$.model.link.$error ||
                      v$.model.materialDescription.$error ||
                      v$.model.userManualLink.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('usefulLinkPicInfo')"
                      :bottom="$t('usefulLinkPicInfoAdditional')"
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceFile
                        ref="picFileName-ProSpaceFile"
                        v-model="picFile"
                        :maxFileSize="102400"
                        accept=".jpeg, .jpg, .png, .gif"
                        :invalid="v$.model.picFileName.$error"
                        @blur="v$.model.picFileName.$touch"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { UsefulLinkService } from "../../services/UsefulLinkService.js";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview" }];
    return {
      // Tabs
      modelTab: this.tabs[0], // Первое предустановленное значение

      // Common
      layoutName: "",
      model: null,
      loading: false,

      // Service
      usefulLinkService: new UsefulLinkService(),
      baseTypeService: new BaseTypeService(),

      getMethod: "get",
      masterId: null,
      picFile: null,
    };
  },
  watch: {
    picFile: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.picChanged(newValue);
      },
    },
  },
  validations() {
    return {
      model: {
        picFileName: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '500'}),
            maxLength(500)
          ),
        },
        name: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        link: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '500'}),
            maxLength(500)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        materialDescription: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '1300'}),
            maxLength(1300)
          ),
        },
        userManualLink: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '500'}),
            maxLength(500)
          ),
        },
      },
    };
  },
  mounted() {
    this.mountAccess();
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async getData() {
      if (this.masterId === undefined) {
        this.model = {
          usefulLinkTypeCode:
            this.$route.query && this.$route.query.code
              ? this.$route.query.code
              : null,
          picFileName: "",
          picFileContent: "",
          picFileSize: "",
          name: "",
          link: "",
          materialDescription: "",
          userManualLink: "",
        };
        return;
      }
      const result = await this.usefulLinkService[this.getMethod](
        this.masterId
      );
      this.model = result.data;

      this.layoutName = `${this.model.name}`;
      this.picFile = [
        this.dataURLtoFile(result.data.picFileContent, result.data.picFileName),
      ];
    },
    save() {
      this.loading = true;
      let me = this;
      if (this.masterId) {
        this.usefulLinkService.set().then(() => {
          this.getData();
          this.loading = false;
        });
      } else {
        me.usefulLinkService["create"](this.model).then((result) => {
          this.masterId = result.data[0].id;
          me.getData();
          this.loading = false;
        });
      }
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
    dataURLtoFile(dataurl, filename) {
      if (!dataurl || !filename) return null;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    picChanged(files) {
      if (Array.isArray(files) && files[0]) {
        this.model.picFileName = files[0].name;
        this.setPicFileString(files[0]);
      } else if (this.model) {
        this.model.picFileName = null;
        this.model.picFileSize = null;
        this.model.picFileContent = null;
      }
    },
    setPicFileString(picFile) {
      const me = this;
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        me.model.picFileContent = base64String;
      };
      reader.readAsDataURL(picFile);
    },
  },
  computed: {
    refsForCheckInMixin() {
      let refs = [
        "typeCode-ProSpaceDropdown",
        "name-ProSpaceInputText",
        "link-ProSpaceInputText",
        "description-ProSpaceInputText",
        "userManualLink-ProSpaceInputText",
        "picFileName-ProSpaceFile",
        "action-save",
      ];

      return refs;
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
  },
};
</script>
<style lang="scss"></style>
