import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DashboardService/DashboardIntegrationMatchingApi.js";
import store from "../../../../Frontends/Common/store/index.js";
export class DashboardIntegrationMatchingService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editDashboardIntegrationMatching", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editDashboardIntegrationMatching");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDashboardIntegrationMatchings", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(this.Api, "getDashboardIntegrationMatching", null, id);
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createDashboardIntegrationMatching", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteDashboardIntegrationMatching", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editDashboardIntegrationMatching", model);
  }
  async resetMatching() {
    return await this.transformer.editItem(this.Api, "resetMatching");
  }
  async export(filter, format) {
    let role = JSON.parse(localStorage.getItem("userRole")).systemName;
    const res = this.Api.export(filter, role, format);
    await store.dispatch('notifications/handleResponse', {
      promiseResponse: res,
    })
    return res;
  }
}
