<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-if="dashboardIntegrationMatching" v-slot:center>
      <ProSpacePanelBlock :header="headerDashboardIntegrationMatching">
        <ProSpacePanelBlockList :list="content" :itemsPerPage="7" />
      </ProSpacePanelBlock>
    </template>
    <template v-if="dashboardIntegrationMatching" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import { DashboardIntegrationMatchingService } from "../../../services/DashboardIntegrationMatchingService";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "DashboardIntegrationMatchingPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  data() {
    return {
      dashboardIntegrationMatching: null,
      dashboardIntegrationMatchingService: new DashboardIntegrationMatchingService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    headerDashboardIntegrationMatching() {
      return {
        title: this.$t("commonInformation"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [{ name: this.$t("overview"), isActive: true }];
    },
    header() {
      if (this.dashboardIntegrationMatching) {
        return {
          title: this.dashboardIntegrationMatching.integrationValue,
          id: this.selectedData.id,
          options: this.options,
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        showCounter: false,
        cleareble: true,
      };
    },
    content() {
      if (this.dashboardIntegrationMatching) {
        return [
          [
            {
              "title": this.$t("dashboardIntegrationMatchingIntegrationValue"),
              "text": this.dashboardIntegrationMatching.integrationValue
            }
          ],
          [
            {
              "title": this.$t("dashboardIntegrationMatchingIntegrationSource"),
              "text": this.dashboardIntegrationMatching.integrationSource
            }
          ],
          [
            {
              "title": this.$t("dashboardIntegrationMatchingIntegrationField"),
              "text": this.$t(this.dashboardIntegrationMatching.integrationField)
            }
          ],
          [
            {
              "title": this.$t("dashboardIntegrationMatchingSystemValue"),
              "text": this.dashboardIntegrationMatching.systemValue
            }
          ],
          [
            {
              "title": this.$t("dashboardIntegrationMatchingDisplayValue"),
              "text": this.dashboardIntegrationMatching.displayValue
            }
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.dashboardIntegrationMatching) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.dashboardIntegrationMatching.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.dashboardIntegrationMatching.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.dashboardIntegrationMatching.createdAt) + ' ' + getAMorPM(this.dashboardIntegrationMatching.createdAt, this.userinfo.timeFormat),
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.dashboardIntegrationMatching.lastUpdated) + ' ' + getAMorPM(this.dashboardIntegrationMatching.lastUpdated, this.userinfo.timeFormat),
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();  
      }
    },
  },
  methods: {
    update() {
      this.hasError = false;
      try {
        this.getDashboardIntegrationMatching();
      } catch (e) {
        this.hasError = true;
      }
    },
    getDashboardIntegrationMatching() {
      if (this.selectedData) {
        this.loading = true;
        this.dashboardIntegrationMatchingService.get(this.selectedData.id).then((res) => {
          this.dashboardIntegrationMatching = res.data;
          this.loading = false;
        });
      }
    },
    editPanel() {
      this.$emit("edit", this.dashboardIntegrationMatching);
    },
    deletePanel() {
      this.$emit("delete", this.dashboardIntegrationMatching);
    },
    showHistoryPanel() {
      this.$emit("history", this.dashboardIntegrationMatching);
    },
  },
};
</script>

<style scoped></style>
