<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :modelFastFilter="modelFastFilter"
        :fastFilters="fastFilters"
        :unicKeyStorage="userService.constructor.name + 'grid'"
        @apply="submitFilter"
        ref="filterPanel"
        :filter-model="[
          'firstName',
          'secondName',
          'lastName',
          'login',
          'email',
        ]"
        @update:modelFastFilter="
          (val) => {
            modelFastFilter = val;
            selectedMultipleClear()
          }
        "
        :blocked-filters="needBlock"
        @block="selectedMultipleBlockFilterHandler"
      >
        <template #hint> {{ $t("filterByTxt") }}</template>
        <template #filters="{ filter }">
          <ProSpaceMultiSelect
            :localization="$t"
            v-model="filter.status"
            :service="statusService"
            getMethod="get"
            optionLabel="text"
            optionValue="status"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :placeholderValue="$t('status')"
            :applyTxt="$t('applyTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :searchPlaceholder="$t('searchTxt')"
            @apply="emitFilter"
          />
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="customServices"
            :unicKeyStorage="userService.constructor.name + 'grid'"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="userService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="multiple"
        style="height: calc(100vh - 50px)"
      >
        <template #columns>
          <Column field="firstName" header="Holder Name" sortables>
            <template #body="field">
              <ProSpaceTableHeaderCell
                style="cursor: pointer"
                v-if="field.data"
                :id="field.data.id"
                :status="getStatus(field.data.isActive)"
                :title="`${field.data.firstName} ${field.data.lastName}`"
                :description="`${field.data.description || ''}`"
              />
            </template>
          </Column>
          <Column field="email" header="Holder e-mail" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.email }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
        </template>
        <template #empty>
          <ProSpaceEmptyGrid
            icon="error-message"
            :title="$t('noDataTitle')"
            :text="$t('noDataTxt')"
          />
        </template>
      </ProSpaceMainGrid>
    </template>
    <template #right>
      <NotificationUsersPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="userService"
        :second-service="securityUserRole"
        @addRole="showRoleModal = true"
        @clear="selectedMultipleReset"
      />
    </template>
    <ProSpaceGridModalForm
      v-model="showRoleModal"
      ref="add role"
      width="410"
      @apply="(mappingModel) => sendGridModal(mappingModel)"
      :service="roleService"
      :preService="securityUserRole"
      :id="masterId"
      :key="masterId"
      preGetMethod="get"
      mappingField="securityRoleId"
      getMethod="grid"
      colName="systemName"
      colDesc="Name"
      header="Role"
      :applyTxt="$t('applyTxt')"
      :cancelTxt="$t('cancelInf')"
      :searchTxt="$t('searchTxt')"
    />
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceFilterPanel,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceGridModalForm,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ModalForm,
  event_bus,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { UserService } from "../../services/UserService.js";
import { RoleService } from "../../services/RoleService.js";
import { StatusService } from "../../services/StatusService.js";
import { SecurityUserRole } from "../../services/SecurityUserRole.js";
import NotificationUsersPanel from "./components/NotificationUsersPanel.vue";
import { mapGetters } from "vuex";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import ModalBlockFilters from "@multiselect/ModalBlockFilters"
import { ref } from "vue"
export default {
  components: {
    ProSpaceTableHeaderCell,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceInputText,
    ProSpaceGeoLayout,
    ProSpaceGridModalForm,
    ProSpaceFilterPanel,
    ProSpaceMainGrid,
    ModalForm,
    ProSpaceEmptyGrid,
    Column,
    NotificationUsersPanel,
    ProSpaceDefaultTextColumn,
    ModalBlockFilters
  },
  data() {
    return {
      // Grid Panel
      selectedValues: [],
      emitter: event_bus,
      panelModel: {},
      panelModels: [],
      tabCountField: "roles",

      // Service
      userService: new UserService(),
      roleService: new RoleService(),
      statusService: new StatusService(),
      securityUserRole: new SecurityUserRole(),

      // Common
      layoutName: "Users",

      // Modal
      showRoleModal: false,
      modalModel: {
        firstName: "",
        lastName: "",
      },
      isEdit: false,
      isCreate: false,

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      selectedMultipleBlockFilterHandler,
      selectedData,
      needBlock, selectedMultipleReset
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      selectedData, needBlock,
      selectedMultipleReset
    }
  },
  methods: {
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    afterLoadHanlder(model) {
      if (typeof model == "object") {
        this.panelModel = model;
      } else {
        this.panelModels = model;
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
      this.selectedValues = this.grid.getSelectedModel();
    },
    sendGridModal(mappingModel) {
      if (!Array.isArray(mappingModel)) {
        mappingModel = [mappingModel];
      }
      const rightPanel = this.$refs["right-panel"];
      const user = rightPanel.user;
      this.securityUserRole.create(user, mappingModel).then(() => {
        rightPanel.update();
      });
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    spToggle() {
      this.spHide = !this.spHide;
    },
    showModalForm(name) {
      this.$refs[name].show();
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new RoleService(),
          getMethod: "grid",
          model: "securityRoleId",
          label: this.$t("role"),
          optionValue: "id",
          optionLabel: "systemName",
          defaultFilter: { disabled: false },
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    disabledModalRule() {
      return (
        this.modalModel.firstName.length == 0 ||
        this.modalModel.lastName.length == 0
      );
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        { label: this.$t("active"), field: "isActive", value: true },
      ];
    },
  },
};
</script>
<style lang="scss" scoped></style>
