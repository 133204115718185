import _ from "lodash";

export const getStyleColor = (color) => {
    if (!color) {
        return '';
    }
    return `background-color: var(${color.background}); color: var(${color.text});`;
}
export const getStyleTextColor = (color) => {
    if (!color) {
        return '';
    }
    return `color: var(${color.text});`;
}

export const checkValue = (value) => {
    let valid = !_.isNull(value) && !_.isUndefined(value);
    if (valid && Array.isArray(value))
        valid = value.length > 0
    return valid;
}

export const getCustomizableProperties = (searchValue) => {
  const simpleResponseStylesheet = getSimpleResponseStyleSheet(searchValue);

  if (!simpleResponseStylesheet) {
    console.debug('No properties found.')
    return [];
  }

  const properties = getSimpleResponseStyleProperties(simpleResponseStylesheet, searchValue);
  return properties;
}

export const getSimpleResponseStyleSheet = (searchValue) => {
  const styleSheets = Array.from(document.styleSheets);

  const simpleResponseStylesheet = styleSheets.find(styleSheet => {
    const cssRules = Array.from(styleSheet.cssRules);
    return cssRules.find(rule => rule.cssText.includes(searchValue))
  });

  return simpleResponseStylesheet;
}

export const getSimpleResponseStyleProperties = (styleSheet, searchValue) => {
  const cssRules =  Array.from(styleSheet.cssRules);
  const rootStyleRule = cssRules.find((cssRule) => {
    const rule = cssRule;
    return rule.selectorText === ':root';
  })

  const rootStyleProperties = Array.from(rootStyleRule.style);

  return rootStyleProperties.filter(prop => prop.includes(searchValue));
}
