<template>
  <ProSpaceModal
    v-model="modelModal"
    icon="history"
    class="prospace-searchfield--tree"
    :title="$t('distributorOrg')"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :content-style="modalContentStyle"
    :small-padding="true"
    @apply="handlerApply"
    @cancel="handlerApply"
  >
    <ProSpaceGeoLayout>
      <template #center>
        <ProSpaceGeoLayout>
          <template #center>
            <ProSpaceTree
              ref="tree"
              :searchPlaceholder="$t('searchTxt')"
              :searchable="false"
              :hideIcon="hideIconTree"
              :service="service"
              :getMethod="getMethod"
              :defaultFilter="{ disabled: { and: [false] } }"
              :needAdd="true"
              :needDelete="true"
              @select="selectedHandler"
              @addEvent="openCreate"
              @deleteEvent="showDeleteFlashMessage"
            >
              <template #empty>
                <ProSpaceEmptyGrid
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                  :need-btn="true"
                  @button-event="openCreate"
                />
              </template>
            </ProSpaceTree>
          </template>
          <template #right>
            <ProSpaceRightPanel v-if="selectedItem">
              <template #center>
                <ProSpacePanelBlock
                  :header="{ title: $t('parameters'), isExpand: false }"
                >
                  <template v-slot:header>
                    <ProSpaceAction
                      :text="$t('editItemTxt')"
                      @click="editItem"
                    />
                  </template>
                  <ProSpacePanelBlockList
                    :list="getDistrOrgPanelInfo(selectedItem)"
                  />
                </ProSpacePanelBlock>
              </template>
            </ProSpaceRightPanel>
          </template>
        </ProSpaceGeoLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
  <ProSpaceModal
    :key="`validate-modal-${showModal}`"
    v-model="showModal"
    width="410"
    :disabledApply="false"
    :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
    :icon="isEdit ? 'edit' : 'plus'"
    @apply="changeItem"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :validate-local="$t"
    :validate-model="modalModel"
    :validate-config="{
      name: { required },
    }"
  >
    <template #default="{ v }">
      <ProSpaceInputText
        :label="$t('name')"
        v-model="modalModel.name"
        :placeholder="$t('name')"
        :invalid="v.name.$error"
        :invalid-messages="v.name.$errors"
        @blur="v.name.$touch"
      />
    </template>
  </ProSpaceModal>
  <OrgDeleteFlashMessage 
    v-model="flashMessageDelete"
    v-model:node="deletingNode"
    @apply="deleteNode"
  />
</template>

<script>
import {
  ProSpaceInputText,
  ProSpaceModal,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceFilterPanel,
  ProSpaceRightPanel,
  ProSpaceTree,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceEmptyGrid,
} from "@prospace/prospace-components-library";
import OrgDeleteFlashMessage from './OrgDeleteFlashMessage'
export default {
  components: {
    ProSpaceInputText,
    ProSpaceModal,
    ProSpaceMainGrid,
    ProSpaceGeoLayout,
    ProSpaceFilterPanel,
    ProSpaceRightPanel,
    ProSpaceTree,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceEmptyGrid,
    OrgDeleteFlashMessage
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    getMethod: {
      type: String,
      default: "getNode",
    },
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      modalModel: {},
      parentNode: {},

      modelModal: false,
      selectedItem: null,
      modalContentStyle: null,
      changed: false,

      flashMessageDelete: false,
      deletingNode: null
    };
  },
  created() {
    this.getContentStyle();
  },
  methods: {
    showDeleteFlashMessage(node) {
      this.flashMessageDelete = true
      this.deletingNode = node
    },
    changeItem() {
      if (this.parentNode && this.parentNode.id)
        this.modalModel.parentId = this.parentNode.id;

      const method = this.isEdit ? "update" : "create";
      this.service[method](this.modalModel).then((res) => {
        this.$refs.tree.getData(this.parentNode);
        this.changed = true;
        this.modalModel = {};
        this.parentNode = {};
        this.showModal = false;
      });
    },
    openCreate(node) {
      this.isEdit = false;
      this.parentNode = node;
      this.modalModel = {};
      this.showModal = true;
    },
    editItem() {
      this.modalModel = { ...this.selectedItem };
      this.isEdit = true;
      this.parentNode = this.$refs.tree.findNode(this.$refs.tree.nodes, this.selectedItem.parentId);
      this.showModal = true;
    },
    deleteNode() {
      const node = this.deletingNode
      const me = this;
      this.service.delete(node).then((res) => {
        if (res.success) {
          if (this.selectedItem.id === node.id)
            this.selectedItem = null;
          this.changed = true;
          let isChild = false;
          let key = node.key;
          if (node.parentId) {
            isChild = true;
            key = node.parentId;
          }
          let parent = me.$refs.tree.findNode(me.$refs.tree.nodes, key);
          if (isChild) {
            let i = parent.children.findIndex((n) => n.key === node.key);
            if (i > -1) parent.children.splice(i, 1);
            parent.leaf = parent.children.length === 0;
          } else {
            let i = me.$refs.tree.nodes.findIndex((n) => n.key === key);
            if (i > -1) me.$refs.tree.nodes.splice(i, 1);
          }
        }
      });
    },
    getDistrOrgPanelInfo(record) {
      const pos = [
        {
          title: this.$t("position"),
          text: record.name,
        },
      ];
      const parentPos = [
        {
          title: this.$t("parentPosition"),
          text: record.parentName,
        },
      ];
      return record.parentName ? [parentPos, pos] : [pos];
    },
    show() {
      this.modelModal = true;
    },
    selectedHandler(item) {
      this.selectedItem = item;
    },
    handlerApply() {
      this.selectedItem = null;
      if(this.$refs.tree) {
        const k = btoa(
          window.location.href +
          this.$refs.tree.service.constructor.name +
          this.$refs.tree.getNodeMethod +
          "component_tree"
        );
        localStorage.removeItem(k);
      }
      if (this.changed) this.$emit("apply");
    },
    getContentStyle() {
      this.modalContentStyle = {
        height: "50vh",
        width: "60vw",
      };
    },
  },
};
</script>
