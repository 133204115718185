<template>
  <ProSpaceVLayout :gap="10">
    <label class="trigger-checkbox-day-group-label">
      {{ label }}
    </label>
    <ProSpaceHLayout :gap="15" class="trigger-days-h-wrapper">
      <div v-for="(checkbox, i) in options" :key="i">
        <span class="trigger-checkbox-day-group">
          <Checkbox
            :id="checkbox['label']"
            v-model="days"
            :value="checkbox['value']"
          />
          <label :for="checkbox['label']" class="trigger-checkbox-day-label">
            {{ checkbox['label'] }}
          </label>
        </span>
      </div>
    </ProSpaceHLayout>
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceVLayout,
} from "@prospace/prospace-components-library";
import Checkbox from 'primevue/checkbox'

export default {
  emits: ["update:days"],
  components: {
    Checkbox,
    ProSpaceHLayout,
    ProSpaceVLayout,
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    triggerOptions: {
      type: Object
    }
  },
  data() {
    return {
      days: this.triggerOptions.DaysOfWeek,
      options: [
        { label: this.$t('sun'), value: 0x40},
        { label: this.$t('mon'), value: 0x01},
        { label: this.$t('tue'), value: 0x02},
        { label: this.$t('wed'), value: 0x04},
        { label: this.$t('thu'), value: 0x08},
        { label: this.$t('fri'), value: 0x10},
        { label: this.$t('sat'), value: 0x20},
      ]
    }
  },
  watch: {
    days: {
      handler(val) {
        this.triggerOptions.DaysOfWeek = val;
      },
    },
  },
  methods: {

  }
};
</script>

<style>
.trigger-checkbox-day-group-label {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: var(--prospace-text-gray);
}
.trigger-checkbox-day-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: var(--prospace-text-main);
}
.trigger-checkbox-day-group {
  display: flex;
  flex-direction: row;
}
.trigger-checkbox-day-group > label {
  margin-left: 5px;
}
.trigger-days-h-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
