<template>
  <div
    class="prospace-geo-layout"
    :class="{
      'is-templated': templateCenter,
      'is-inline-h': isInlineH,
      'is-inline-w': isInlineW,
    }"
  >
    <div class="prospace-geo-layout__top" v-if="!isHideTop">
      <slot name="top" />
    </div>
    <div class="prospace-geo-layout__left prospace-scrollbar" v-if="!isHideLeft">
      <slot name="left" />
    </div>
    <div
      class="prospace-geo-layout__center prospace-scrollbar"
      :class="{ 'prospace-geo-layout__center--template': templateCenter }"
      v-if="!isHideCenter"
    >
      <slot name="center" />
    </div>
    <div
      class="prospace-geo-layout__right prospace-scrollbar"
      :class="{ 'prospace-geo-layout__right--template': templateRight }"
      v-if="!isHideRight"
    >
      <slot name="right" />
    </div>
    <div class="prospace-geo-layout__bottom" v-if="!isHideBottom">
      <slot name="bottom" />
    </div>

    <ProgressSpinner v-if="loading" class="prospace-geo-layout__loading" />
  </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
export default {
  functional: true,
  name: "ProSpaceGeoLayoutOld",
  components: { ProgressSpinner },
  props: {
    isTemplated: {
      type: Boolean,
      default: false,
    },
    isHideTop: {
      type: Boolean,
      default: false,
    },
    isHideLeft: {
      type: Boolean,
      default: false,
    },
    isHideCenter: {
      type: Boolean,
      default: false,
    },
    isHideRight: {
      type: Boolean,
      default: false,
    },
    isHideBottom: {
      type: Boolean,
      default: false,
    },
    isInlineH: {
      type: Boolean,
      default: false,
    },
    isInlineW: {
      type: Boolean,
      default: false,
    },
    templateCenter: {
      type: Boolean,
      default: false,
    },
    templateRight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.prospace-geo-layout {
  .p-progress-spinner {
    z-index: 100;
    &:before {
      padding: 0px;
    }
  }
  &__loading {
    overflow: hidden;
    opacity: 0.5;
    .p-progress-spinner-svg {
      width: 40px;
      stroke: var(--libSpinnerColor);
    }
  }
}
</style>
<style lang="scss" scoped>
.prospace-geo-layout {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content 1fr max-content;
  width: 100%;
  height: 100%;
  gap: 0 0;
  grid-template-areas:
    "top top top"
    "left center right"
    "bottom bottom bottom";

  &__left {
    grid-area: left;
    overflow: auto;
  }

  &__top {
    grid-area: top;
  }

  &__bottom {
    grid-area: bottom;
    overflow: auto;
  }

  &__right {
    grid-area: right;
    overflow: auto;
    &--template {
      // box-shadow: 0px 2px 10px rgb(51 51 51 / 7%);
      margin: 10px 10px 10px 0;
      border-radius: 5px;
    }
  }

  &__center {
    grid-area: center;
    overflow: auto;
    &--template {
      // box-shadow: 0px 2px 10px rgb(51 51 51 / 7%);
      margin: 10px;
      border-radius: 5px;
    }
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.is-inline-h {
    height: auto;
  }
  &.is-inline-w {
    width: auto;
  }
}
</style>
