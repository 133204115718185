<template>
  <div class="dashboard-filter-panel" ref="panel">
    <div ref="left" class="dashboard-filter-panel__left">
      <div
        class="dashboard-filter-panel__title"
        :style="getStyleTextColor(titleColor)"
        v-if="checkValue(title)"
      >
        {{ title }}
      </div>
      <div
        class="dashboard-filter-panel__text"
        :style="getStyleTextColor(textColor)"
        v-if="checkValue(text)"
      >
        {{ text }}
      </div>
      <div
        class="dashboard-filter-panel__rate"
        :style="getStyleColor(rateColor)"
        v-if="checkValue(rate)"
      >
        {{ rate }}
      </div>
    </div>
    <div
      class="dashboard-filter-panel__filters"
      :style="`width: ${widthFilters};`"
      v-if="checkValue(filters)"
    >
      <ProSpaceHLayout :gap="10" justify-content="flex-end">
        <div class="text-color-gray text-size-13">
          {{ $t("filterBy") }}
        </div>
        <ProSpaceScroll
          class="dashboard-filter-panel__box"
          ref="tabs-scroll"
          @overflow="isVisibleScrollBtns = true"
          @underflow="isVisibleScrollBtns = false"
          @btn-left="(isDisable) => (isDisableBtnLeft = isDisable)"
          @btn-right="(isDisable) => (isDisableBtnRight = isDisable)"
        >
          <Component
            :is="item.component"
            v-for="(item, index) in filters"
            v-bind="getItem(item)"
            v-model="model[item.name]"
            isAddSearch
            arrayReturn
            :key="`${locale}-${index}`"
            :placeholderValue="getLocale(item)"
            :txtTitleModal="getLocale(item)"
            :selectAllTxt="$t('selectAllTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :cancelTxt="$t('cancelInf')"
            :applyTxt="$t('applyTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :emptyTxt="$t('emptyFilter')"
          />
        </ProSpaceScroll>
        <ProSpaceHLayout style="width: 40px" v-if="isVisibleScrollBtns">
          <ProSpaceIconButton
            :disabled="isDisableBtnLeft"
            icon="chevron-left"
            :typeIcon="getTypeIcon(isDisableBtnLeft)"
            @click="moveTabsLeft"
          />
          <ProSpaceIconButton
            :disabled="isDisableBtnRight"
            icon="chevron-right"
            :typeIcon="getTypeIcon(isDisableBtnRight)"
            @click="moveTabsRight"
          />
        </ProSpaceHLayout>
      </ProSpaceHLayout>
    </div>
  </div>
</template>

<script>
import {
  getStyleColor,
  getStyleTextColor,
  checkValue,
} from "@dashboard/utils/dashboardUtils.js";
import {
  ProSpaceHLayout,
  ProSpaceIconButton,
  ProSpaceScroll,
} from "@prospace/prospace-components-library";
import { useI18n } from "vue-i18n";
import _ from "lodash";
export default {
  name: "DashboardFilterPanel",
  components: {
    ProSpaceHLayout,
    ProSpaceIconButton,
    ProSpaceScroll,
  },
  props: {
    model: {
      type: Object,
      default: {},
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    rate: {
      type: Number,
      default: null,
    },
    titleColor: {
      type: Object,
      default: null,
    },
    textColor: {
      type: Object,
      default: null,
    },
    rateColor: {
      type: Object,
      default: null,
    },
    filters: {
      type: Array,
      default: null,
    },
  },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data() {
    return {
      isDisableBtnLeft: true,
      isDisableBtnRight: false,
      isVisibleScrollBtns: false,
      widthFilters: 0,
      ro: null,
    };
  },
  mounted() {
    this.setWidthFilters();
    this.bindResizeObserver();
  },
  methods: {
    getStyleColor,
    getStyleTextColor,
    checkValue,
    getItem(item) {
      return _.omit(item, ["component", "localization", "name"]);
    },
    getLocale(item) {
      return item.localization[this.locale];
    },
    getTypeIcon(isDisabled) {
      return isDisabled ? "default" : "secondary";
    },
    moveTabsLeft() {
      this.$refs["tabs-scroll"].moveLeft();
    },
    moveTabsRight() {
      this.$refs["tabs-scroll"].moveRight();
    },
    setWidthFilters() {
      if (!this.$refs.left) {
        return "100%";
      }
      const { offsetWidth } = this.$refs.left;
      this.widthFilters = `calc(100% - 10px - ${offsetWidth}px)`;
    },
    bindResizeObserver() {
      if (!this.$refs.panel) {
        return;
      }
      this.ro = new ResizeObserver(this.callbackResizeObserver);
      this.ro.observe(this.$refs.panel);
    },
    unbindResizeObserver() {
      if (!this.$refs.panel || !this.ro) {
        return;
      }
      this.ro.unobserve(this.$refs.panel);
    },
    callbackResizeObserver() {
      this.setWidthFilters();
    },
  },
};
</script>

<style lang="scss">
.dashboard-filter-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  &__title,
  &__text,
  &__rate {
    display: inline-block;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    // color: var(--prospace-text-main);
    margin-right: 15px;
  }
  &__text {
    font-size: 13px;
    line-height: 16.25px;
    // color: var(--prospace-text-gray);
    margin-right: 5px;
  }
  &__rate {
    font-size: 13px;
    line-height: 16.25px;
    font-weight: 500;
    padding: 3px 6px 1px;
    border-radius: 5px;
  }
  &__left {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__filters {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
  }
  &__box {
    width: auto;
    padding: 3px;
    .prospace-scroll__inner {
      gap: 5px;
      .prospace-filter-block {
        min-width: auto;
      }
    }
    @media screen and (max-width: 1366px) {
      .prospace-filter-block {
        min-width: 82px !important;
        .p-multiselect {
          .p-multiselect-label {
            min-width: 100%;
          }
        }
      }
    }
  }
}
</style>
