<template>
    <div class="dashboard-plate-value" :class="{ 'dashboard-plate-value__box': fullWidth }" :style="getStyleColor(color)" v-if="checkValue(title || text)">
        <div class="dashboard-plate-value__title" v-if="checkValue(title)">
            {{ title }}
        </div>
        <div class="dashboard-plate-value__text" v-if="checkValue(text)" :title="text">
            {{ text }}
        </div>
    </div>
</template>

<script>
import {
    getStyleColor,
    checkValue
} from "@dashboard/utils/dashboardUtils.js";
export default {
    name: "DashboardPlateValue",
    props: {
        title: {
            type: Object,
            required: true
        },
        text: {
            type: Object,
            required: true
        },
        color: {
            type: Object,
            default: null
        },
        fullWidth: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        getStyleColor,
        checkValue
    },
}
</script>

<style lang="scss">
.dashboard-plate-value {
    padding: 6.5px 10px;
    border-radius: 5px;
    text-align: center;
    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        // color: var(--prospace-text-main);
        margin-bottom: 2px;
    }
    &__text {
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__box {
        width: 100%;
    }
}
</style>
