<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('systemDirectories')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            style="margin-left: 10px"
            ref="add-button"
            type="primary"
            icon="plus"
            :label="this.$t('newTxt')"
            @click="create"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="systemDirectoryService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="name"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('name')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  systemDirectoryService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="systemDirectoryService"
            :noMountedRequest="true"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="name"
                :header="this.$t('name')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :noHover="!checkFormAccess(field.data)"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="wrapperCheckRoles(dotsOptions, field.data)"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :disabledApply="false"
        :title="isEdit ? $t('editItemTxt') : $t('createItemTxt')"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          name: { required },
        }"
      >
        <template #default="{ v }">
          <ProSpaceInputText
            :label="$t('name')"
            v-model="modalModel.name"
            :placeholder="$t('name')"
            :invalid="v.name.$error"
            :invalid-messages="v.name.$errors"
            @blur="v.name.$touch"
          />
        </template>
      </ProSpaceModal>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="SystemDirectoryApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <SystemDirectoryPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="systemDirectoryService"
        :options="dotsOptions"
        :filterAccessDots="wrapperCheckRoles"
        :checkEditAccess="checkFormAccess"
        :checkAccess="checkAccess"
        @clear="selectedMultipleClear"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteSystemDirectory', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceInputText,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { SystemDirectoryService } from "../../services/SystemDirectoryService.js";

import Column from "primevue/column";
import SystemDirectoryPanel from "./components/SystemDirectoryPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    SystemDirectoryPanel,
    ProSpaceHistoryModal,
    ProSpaceInputText,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      v$: useVuelidate(),
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    };
  },
  validations() {
    return {
      modalModel: {
        name: { required },
      },
    };
  },
  data() {
    return {
      showModal: false,
      showHistoryModal: false,

      // Service
      systemDirectoryService: new SystemDirectoryService(),

      //InlinePanel
      isEdit: false,
      isCreate: false,
      modalModel: { name: null },

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
          liteRef: "history-option",
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        res = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
  },
  mounted() {
    this.loadRoleAccess();
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      if (!data || !this.checkFormAccess(data)) return;
      const url = `/basetype/systemdirectory/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.filterPanel.submitFilter();
      this.selectedMultipleClear();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    create() {
      this.isCreate = true;
      this.isEdit = false;
      this.showChangeModal();
    },
    showChangeModal() {
      this.modalModel = { name: null };
      this.showModal = true;
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.systemDirectoryService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.systemDirectoryService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
  },
};
</script>
