<template>
  <ProSpaceModal
    v-model="visible"
    width="820"
    :title="
      (isCreate ? $t('taskCreate') : $t('taskEdit'))
    "
    @apply="save"
    :icon="isCreate ? 'plus' : 'edit'"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :contentStyle="modalContentStyle"
  >
    <ProSpaceTabs
      v-if="visible"
      ref="tabs"
      :tabs="tabs"
      class="task-editor__tabs-header"
      is-full-width
      @select-tab="tabSelected"
    >
      <ProSpaceHLayout :is-inline-w="true" :gap="5">
        <ProSpaceAction
          v-if="isTriggersTabActive"
          icon="plus"
          :disabled="loading"
          :text="$t('addItemTxt')"
          @click="showTriggerEditor"
        />
      </ProSpaceHLayout>
      <template v-slot:tab-1>
        <TaskEditorMainInfo :task="task" :isCreate="isCreate" />
      </template>
      <template v-slot:tab-2>
        <TaskEditorTriggers
          ref="triggerTab"
          :task="task"
          :isCreate="isCreate"
          @selectTrigger="triggerSelected"
        />
      </template>
    </ProSpaceTabs>
    <template #footer>
      <ProSpaceHLayout justify-content="flex-end">
        <TaskEditorFooterText
          v-if="!isCreate"
          :task="task"
          :trigger="trigger"
        />
        <ProSpaceHLayout :gap="5" justify-content="flex-end" is-inline-w>
          <ProSpaceButton
            type="secondary"
            :label="$t('cancelInf')"
            :disabled="loading"
            @click="cancel"
          />
          <ProSpaceButton
            type="primary"
            :label="primaryBtnText"
            :icon="primaryBtnIcon"
            :disabled="loading"
            @click="primaryBtnHandler"
          />
        </ProSpaceHLayout>
      </ProSpaceHLayout>
    </template>
    <SystemLogModal ref="systemLog" />
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceHLayout,
  ProSpaceModal,
  ProSpaceTabs,
  ProSpaceAction,
  ProSpaceTag,
  ProSpaceButton
} from "@prospace/prospace-components-library";
import TaskEditorMainInfo from "./components/TaskEditorMainInfo";
import TaskEditorTriggers from "./components/TaskEditorTriggers";
import TaskEditorFooterText from "./components/TaskEditorFooterText";
import SystemLogModal from "./SystemLogModal";
import { JobTriggerService } from "../../../services/JobTriggerService.js";
import _ from "lodash";

export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceHLayout,
    ProSpaceModal,
    ProSpaceTabs,
    ProSpaceAction,
    ProSpaceTag,
    ProSpaceButton,
    TaskEditorMainInfo,
    TaskEditorTriggers,
    TaskEditorFooterText,
    SystemLogModal,
  },
  data() {
    return {
      task: null,
      selectedTrigger: null,
      isCreation: false,
      visible: false,
      loading: false,
      needGridRefresh: false,

      jobTriggerService: new JobTriggerService(),

      modelTab: { name: this.$t("task") },
      modelTabIndex: 0,
      modalContentStyle: {
        overflow: 'hidden',
        height: '520px',
        paddingBottom: '0'
      },
    };
  },
  computed: {
    tabs() {
      return [
        { name: this.$t("task"), isActive: true },
        { name: this.$t("triggers") },
      ];
    },
    isTaskTabActive() {
      return this.modelTab.name == this.$t("task");
    },
    isTriggersTabActive() {
      return this.modelTab.name == this.$t("triggers");
    },
    isCreate() {
      return this.isCreation;
    },
    icon() {
      return { faPlus, faPen };
    },
    trigger() {
      return this.selectedTrigger;
    },
    primaryBtnText() {
      return this.modelTab.name == this.$t("task")
        ? this.$t("next")
        : this.$t("save");
    },
    primaryBtnIcon() {
      return this.modelTab.name == this.$t("task") ? null : "check";
    },
    primaryBtnHandler() {
      return this.modelTab.name == this.$t("task") ? this.next : this.save;
    },
  },
  watch: {
    visible: {
      handler(visible) {
        if (!visible) {
          this.closed();
        }
      },
    },
  },
  methods: {
    show(task) {
      this.isCreation = !task.id;

      if (!this.isCreation) {
        this.task = _.cloneDeep(task);
        this.task.context = JSON.parse(this.task.context);
      } else {
        this.task = {
          id: 0,
          name: "Task",
          active: true,
          itemType: "Job",
          description: null,
          context: { JobType: null },
        };
      }

      this.visible = true;
    },
    closed() {
      this.task = null;
      this.isCreation = false;
      this.selectedTrigger = null;
      this.loading = false;
      this.$refs.tabs.setTabs(0);

      this.modelTab = { name: this.$t("task") };
      this.modelTabIndex = 0;

      if(this.needGridRefresh)
        this.$emit("closedAfterCreate");

      this.needGridRefresh = false;
    },
    showModalForm(name, item) {
      this.$refs[name].show(item);
    },
    showTriggerEditor() {
      this.$refs.triggerTab.showTriggerEditor(null);
    },
    showSystemLog() {
      this.showModalForm("systemLog", this.task);
    },
    tabSelected(obj) {
      this.modelTab = obj.selectedTab;
      this.modelTabIndex = obj.index;
      this.selectedTrigger = null;
    },
    triggerSelected(trigger) {
      this.selectedTrigger = _.cloneDeep(trigger);
    },
    // save the entire form in one request. Sending task and all triggers
    save() {
      let me = this;
      me.loading = true;
      // TODO: updated flag to update only changed triggers
      let taskCopy = _.cloneDeep(me.task);
      taskCopy.context = JSON.stringify(taskCopy.context);

      let saveModel = [...me.$refs.triggerTab.$refs.grid.records, taskCopy]
      let saveMethod = me.isCreate ? "create" : "update";


      me.jobTriggerService[saveMethod](saveModel).then((response) => {
        me.$emit("saved", response.data[0]);
        me.visible = false;
        me.loading = false;
      }).catch(() => {
        me.loading = false;
      });
    },
    deleteTask() {
      let me = this;
      let deleteModel = _.cloneDeep(me.task);

      deleteModel.context = JSON.stringify(deleteModel.context);

      me.jobTriggerService.delete(deleteModel).then((response) => {
        me.visible = false;
        me.$emit("deleted", response);
      });
    },
    next() {
      this.$refs.tabs.select(
        { name: this.$t("triggers") },
        this.modelTabIndex + 1
      );
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.task-editor__tabs-header {
  .prospace-tabs__header {
    width: auto !important;
    // margin: 10px 10px 20px !important;
  }

  .prospace-tabs__content {
    padding-right: 10px;
  }

  .form-field {
    padding: 10px;
  }
}
</style>
