<template>
  <ProSpaceModal
    v-model="showModal"
    :title="$t('parameter')"
    icon="parameters"
    :applyTxt="false"
    :cancelTxt="$t('cancelInf')"
  >
    <template v-slot:default>
      <ProSpaceGeoLayout>
        <template #center>
          <pre class="parameters-view">{{ params }}</pre>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceModal,
  ProSpaceVLayout,
  ProSpaceHLayout,
} from "@prospace/prospace-components-library";
export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceModal,
    ProSpaceVLayout,
    ProSpaceHLayout,
  },
  data() {
    return {
      showModal: false,
      params: null,
    };
  },
  methods: {
    show(task) {
      this.params = JSON.stringify(JSON.parse(task.context), null, 2);
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.parameters-view {
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 10px;
  padding-right: 20px;
  display: inline-block;
}
.prospace-scrollbar {
  &:has(.parameters-view) {
    max-height: 73vh;
  }
}
</style>
