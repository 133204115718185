<template>
  <DashboardModalLayout
    :class="['dashboard-layout-settings', `dashboard-layout-settings--${selectedLeftTreeNode?.element}`]"
    :panels="panels"
    isHideCenter
    scrollableRight
    @clickAction="handleAddDataset"
  >
    <template #left>
      <ProSpaceTree
        ref="leftTree"
        propKey="systemName"
        propLabel="element"
        getNodeMethod="getTree"
        noMountedSelectNode
        :service="leftTreeService"
        :needAdd="handleLeftTreeNeedAdd"
        :needDelete="handleLeftTreeNeedDelete"
        :localization="$t"
        @select="handleNodeSelect"
        @loaded="handleTreeLoaded"
      >
        <template #action="{ node }">
          <ProSpaceHLayout :gap="6" justify-content="flex-end">
            <div v-if="node.needWarning" class="tree-item-action-container">
              <Fa
                :icon="icons.faExclamationTriangle"
                :style="{ color: '#EE6D62' }"
                size="10px"
              />
            </div>
            <div v-if="node.needAdd" class="tree-item-action-container">
              <Fa
                :icon="icons.faPlus"
                :style="{ color: 'var(--prospace-ui-main)' }"
                size="10px"
                @click="handlerShowModalContainer(node)"
              />
            </div>
            <div v-if="node.needDelete" class="tree-item-action-container">
              <Fa
                :icon="icons.faTrashAlt"
                :style="{ color: 'var(--prospace-ui-main)' }"
                size="12px"
                @click="showDeleteAlertModal(node)"
              />
            </div>
          </ProSpaceHLayout>
        </template>
      </ProSpaceTree>
    </template>
    <template #right v-if="selectedLeftTreeNode?.systemName === 'filterPanel'">
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('source')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="filterPanelSourceDropdown"
              v-model="selectedLeftTreeNode.source"
              :options="filterPanelSourceDropdownOptions"
              :placeholder="$t('source')"
              :invalid="v$.selectedLeftTreeNode.source.$error"
              :invalid-messages="v$.selectedLeftTreeNode.source.$errors"
              propValue="systemName"
              propLabel="name"
              @change="handleFilterPanelSourceDropdownChange"
              @blur="v$.selectedLeftTreeNode.source.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <DashboardLayoutElementSettings
        isColorTextOnly
        :elementName="$t('title')"
        :elementType="model.filterPanel.header.type"
        :elementValue="model.filterPanel.header.value"
        :color="model.filterPanel.header.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="filterPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.header"
        @update:elementType="(elementType) => updateElementProp('filterPanel', 'header', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('filterPanel', 'header', 'value', elementValue)"
        @update:color="(color) => updateElementProp('filterPanel', 'header', 'color', color)"
      />
      <DashboardLayoutElementSettings
        isColorTextOnly
        :elementName="$t('label')"
        :elementType="model.filterPanel.label.type"
        :elementValue="model.filterPanel.label.value"
        :color="model.filterPanel.label.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="filterPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.label"
        @update:elementType="(elementType) => updateElementProp('filterPanel', 'label', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('filterPanel', 'label', 'value', elementValue)"
        @update:color="(color) => updateElementProp('filterPanel', 'label', 'color', color)"
      />
      <DashboardLayoutElementSettings
        :elementName="$t('value')"
        :elementType="model.filterPanel.value.type"
        :elementValue="model.filterPanel.value.value"
        :color="model.filterPanel.value.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="filterPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.value"
        @update:elementType="(elementType) => updateElementProp('filterPanel', 'value', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('filterPanel', 'value', 'value', elementValue)"
        @update:color="(color) => updateElementProp('filterPanel', 'value', 'color', color)"
      />
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterPanelFilterHintText"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <ProSpaceInputChips
              v-model="model.filterPanel.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
    <template #right v-else-if="selectedLeftTreeNode?.root === 'rootContainer'">
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('containerType')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="rootContainerTypeDropdown"
              v-model="model.rootContainer.type"
              :options="rootContainerTypeDropdownOptions"
              :placeholder="$t('containerType')"
              propValue="systemName"
              propLabel="name"
              @change="changeContainerChildrenValues"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
    <!--Контейнер-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'container'">
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('containerType')"
        >
          <template #content>
            <ProSpaceDropdown
              v-model="selectedLeftTreeNode.type"
              :options="rootContainerTypeDropdownOptions"
              :placeholder="$t('containerType')"
              propValue="systemName"
              propLabel="name"
              @change="changeContainerChildrenValues"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="
            isParentContainerVertical
              ? $t('heightContainerPx')
              : $t('widthContainerPercent')
          "
        >
          <template #content>
            <ProSpaceInputNumber
              v-model="selectedLeftTreeNode.value"
              likeText
              :placeholder="
                isParentContainerVertical
                  ? $t('heightContainerPx')
                  : $t('widthContainerPercent')
              "
              :invalid="v$.selectedLeftTreeNode.value.$error"
              :invalid-messages="v$.selectedLeftTreeNode.value.$errors"
              @blur="v$.selectedLeftTreeNode.value.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
    <!--График правый таб-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'graph'">
      <ProSpaceTabs
        v-model="modelTabChart"
        :tabs="chartTabs"
        class="dashboard-layout-settings__tabs"
      >
        <template
          v-for="(tab, index) in chartTabs"
          v-slot:[getTabSlotName(index)]
        >
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
              :title="$t('chartType')"
            >
              <template #content>
                <ProSpaceDropdown
                  ref="childChartTypeDropdown"
                  v-model="selectedLeftTreeNode.datasets[index].graphType"
                  :options="chartTypeDropdownOptions"
                  :placeholder="$t('chartType')"
                  propValue="systemName"
                  propLabel="name"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
              :title="$t('value')"
            >
              <template #content>
                <ProSpaceDropdown
                  ref="childChartValueDropdown"
                  v-model="selectedLeftTreeNode.datasets[index].valueField"
                  propValue="systemName"
                  propLabel="name"
                  :placeholder="$t('value')"
                  :options="childGraphValuesOptions"
                  :invalid="v$.selectedLeftTreeNode.datasets[index].valueField.$error"
                  :invalid-messages="v$.selectedLeftTreeNode.datasets[index].valueField.$errors"
                  @blur="v$.selectedLeftTreeNode.datasets[index].valueField.$touch"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <div v-if="index === 0" class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
              :title="$t('xAxis')"
            >
              <template #content>
                <ProSpaceDropdown
                  ref="childChartXAxisDropdown"
                  v-model="selectedLeftTreeNode.xAxis"
                  propValue="systemName"
                  propLabel="name"
                  :placeholder="$t('xAxis')"
                  :options="childGraphParametersOptions"
                  :invalid="v$.selectedLeftTreeNode.xAxis.$error"
                  :invalid-messages="v$.selectedLeftTreeNode.xAxis.$errors"
                  @blur="v$.selectedLeftTreeNode.xAxis.$touch"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <div v-if="index === 0" class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
            >
              <template #header>
                <ProSpaceHLayout :gap="5">
                  <div>
                    {{ $t('filter') }}
                  </div>
                  <ProSpaceIcon
                    v-hint="filterHintText"
                    icon="question"
                  />
                </ProSpaceHLayout>
              </template>
              <template #content>
                <ProSpaceInputChips
                  ref="childIteratorPanelFilterChip"
                  v-model="selectedLeftTreeNode.filter"
                  showHintItem
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
            >
              <template #header>
                <ProSpaceHLayout :gap="5">
                  <div>
                    {{ $t('color') }}
                  </div>
                  <ProSpaceIcon
                    v-hint="$t('graphColor')"
                    icon="question"
                  />
                </ProSpaceHLayout>
              </template>
              <template #content>
                <DashboardColorPicker
                  type="graph"
                  :color="selectedLeftTreeNode.datasets[index].color.background"
                  @update:color="(val) => setDatasetColor(val, index)"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <div v-if="index === 0" class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
            >
              <template #content>
                <ProSpaceCheckbox
                  style="margin-bottom: 20px;"
                  v-if="index === 0"
                  v-model="chartShowLegendCheckbox"
                  :changeObj="selectedLeftTreeNode"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <ProSpaceAction
            v-if="index > 0"
            :text="$t('deleteDataset')"
            @click="handleDeleteGraphDatasetClick(index)"
          />
        </template>
      </ProSpaceTabs>
    </template>
    <!--Текстовая панель правый таб-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'text'">
      <DashboardLayoutElementSettings
        isColorTextOnly
        :elementName="$t('title')"
        :elementType="selectedLeftTreeNode.header.type"
        :elementValue="selectedLeftTreeNode.header.value"
        :color="selectedLeftTreeNode.header.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="childPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.header"
        @update:elementType="(elementType) => updateElementProp('', 'header', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('', 'header', 'value', elementValue)"
        @update:color="(color) => updateElementProp('', 'header', 'color', color)"
      />
      <DashboardLayoutElementSettings
        isColorTextOnly
        showElementLink
        :elementName="$t('label')"
        :elementType="selectedLeftTreeNode.label.type"
        :elementLink="selectedLeftTreeNode.label.link"
        :elementValue="selectedLeftTreeNode.label.value"
        :color="selectedLeftTreeNode.label.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="childPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.label"
        @update:elementType="(elementType) => updateElementProp('', 'label', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('', 'label', 'value', elementValue)"
        @update:elementLink="(link) => updateElementProp('', 'label', 'link', link)"
        @update:color="(color) => updateElementProp('', 'label', 'color', color)"
      />
      <DashboardLayoutElementSettings
        :elementName="$t('value')"
        :elementType="selectedLeftTreeNode.value.type"
        :elementValue="selectedLeftTreeNode.value.value"
        :color="selectedLeftTreeNode.value.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="childPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.value"
        @update:elementType="(elementType) => updateElementProp('', 'value', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('', 'value', 'value', elementValue)"
        @update:color="(color) => updateElementProp('', 'value', 'color', color)"
      />
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterHintText"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <ProSpaceInputChips
              v-model="selectedLeftTreeNode.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
    <!--Плашка со значением правый таб-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'panel'">
      <DashboardLayoutElementSettings
        :needColor="false"
        :elementName="$t('label')"
        :elementType="selectedLeftTreeNode.label.type"
        :elementValue="selectedLeftTreeNode.label.value"
        :color="selectedLeftTreeNode.label.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="childPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.label"
        @update:elementType="(elementType) => updateElementProp('', 'label', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('', 'label', 'value', elementValue)"
        @update:color="(color) => updateElementProp('', 'label', 'color', color)"
      />
      <DashboardLayoutElementSettings
        :needColor="false"
        :elementName="$t('value')"
        :elementType="selectedLeftTreeNode.value.type"
        :elementValue="selectedLeftTreeNode.value.value"
        :color="selectedLeftTreeNode.value.color"
        :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
        :valueOptions="childPanelValuesOptions"
        :validation="v$.selectedLeftTreeNode.value"
        @update:elementType="(elementType) => updateElementProp('', 'value', 'type', elementType)"
        @update:elementValue="(elementValue) => updateElementProp('', 'value', 'value', elementValue)"
        @update:color="(color) => updateElementProp('', 'value', 'color', color)"
      />
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterHintText"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <ProSpaceInputChips
              v-model="selectedLeftTreeNode.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row prospace-panel-block-list__row--withoutMarginAction">
        <ProSpaceAction
          :text="$t('addColorSelection')"
          @click="handleAddColorSelection"
        />
      </div>
    </template>
    <!--Плашка-итератор правый таб-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'iterpanel'">
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('value')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="childIteratorPanelValueDropdown"
              v-model="selectedLeftTreeNode.value"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('value')"
              :invalid="v$.selectedLeftTreeNode.value.$error"
              :invalid-messages="v$.selectedLeftTreeNode.value.$errors"
              @blur="v$.selectedLeftTreeNode.value.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('label')"
        >
          <template #content>
            <ProSpaceDropdown
              ref="childIteratorPanelLabelDropdown"
              v-model="selectedLeftTreeNode.label"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('label')"
              :invalid="v$.selectedLeftTreeNode.label.$error"
              :invalid-messages="v$.selectedLeftTreeNode.label.$errors"
              @blur="v$.selectedLeftTreeNode.label.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterHintText"
                icon="question"
              />
            </ProSpaceHLayout>

          </template>
          <template #content>
            <ProSpaceInputChips
              ref="childIteratorPanelFilterChip"
              v-model="selectedLeftTreeNode.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <ProSpaceAction
        :text="$t('addColorSelection')"
        @click="handleAddColorSelection"
      />
    </template>
    <!--Сводная таблица правый таб-->
    <template #right v-else-if="selectedLeftTreeNode?.element === 'pivot'">
      <PivotTableSettings
        :pivotTableModel="selectedLeftTreeNode"
        :createPivotValueField="createPivotValueField"
        :fieldOptions="childPanelValuesOptions"
        :filterHintText="filterHintText"
        :validation="v$.selectedLeftTreeNode"
        @update:tagsRow="setTagsRow"
      />
    </template>
    <template #right v-else-if="selectedLeftTreeNode?.element === 'table'">
    </template>
  </DashboardModalLayout>
  <DashboardModalColorSelection
    v-if="showModal"
    v-model="showModal"
    fieldType="field"
    :color="editingColor"
    @update:color="updateColor"
  />
  <DashboardModalNewContainer
    v-model="showModalContainer"
    @create:container="setNewContainer"
  />
  <ProSpaceFlashMessage
    v-if="deleteAlertModal"
    v-model="deleteAlertModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingElement')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="removeNode"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('alertTextDeletingElement', { name:  deletableNode.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpacePanelBlock,
  ProSpaceTag,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceInputSwitch,
  ProSpaceLeftPanel,
  ProSpaceAction,
  ProSpaceInputNumber,
  ProSpaceInputChips,
  ProSpaceTabs,
  ProSpaceCheckbox,
  ProSpaceFlashMessage,
  Hint,
} from "@prospace/prospace-components-library";
import DashboardModalLayout from "./DashboardModalLayout.vue";
import DashboardModalColorSelection from "./DashboardModalColorSelection.vue";
import DashboardModalNewContainer from "./DashboardModalNewContainer.vue";
import DashboardColorPicker from "./DashboardColorPicker.vue";
import DashboardLayoutElementSettings from "./DashboardLayoutElementSettings.vue";
import { faTrashAlt, faPlus, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength, minValue } from "@vuelidate/validators";
import PivotTableSettings from "./DashboardModalForm/PivotTableSettings.vue";
export default {
  name: "DashboardLayoutSettings",
  components: {
    ProSpaceGeoLayout,
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpacePanelBlockItem,
    ProSpaceDropdown,
    ProSpaceInputSwitch,
    ProSpaceLeftPanel,
    ProSpacePanelBlock,
    ProSpaceAction,
    DashboardModalLayout,
    DashboardModalColorSelection,
    DashboardModalNewContainer,
    ProSpaceFlashMessage,
    ProSpaceInputNumber,
    Fa: FontAwesomeIcon,
    ProSpaceInputChips,
    ProSpaceTabs,
    ProSpaceCheckbox,
    DashboardColorPicker,
    DashboardLayoutElementSettings,
    PivotTableSettings
  },
  props: {
    layoutSettings: {
      type: Array,
      required: true,
    },
    validateWhenSelect: {
      type: Array,
      default: false
    },
    selectedSourcesService: {
      type: Object,
      required: true,
    },
    createDefaultLayoutColorSettings: {
      type: Function,
      required: true,
    },
    createLayoutSettingsRootContainerChild: {
      type: Function,
      required: true,
    },
    createGraphLayout: {
      type: Function,
      required: true,
    },
    createPivotValueField: {
      type: Function,
      required: true,
    }
  },
  emits: ["update:model"],
  directives: {
    hint: Hint
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editingColor: null,
      selectedLeftTreeNode: null,
      leftTreeService: {
        getTree: this.getLeftTreeData,
      },
      filterPanelSourceDropdownOptions: [],
      showModal: false,
      showModalContainer: false,
      activeContainer: null,
      deleteAlertModal: false,
      deletableNode: null,
      modelTabChart: {
        name: this.$t("dataset") + " 1",
        isActive: true,
        systemName: "dataset1",
      },
      model: {},
      filterPanelValuesOptions: [],
      childPanelValuesOptions: [],
      childGraphParametersOptions: [],
      childGraphValuesOptions: [],
      firstLoad: true,
      totalLevels: 0,
    };
  },
  validations() {
    const elementValidation = (elementPropName) => {
      return {
        type: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        value: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
            () => this.elementValueValidation(elementPropName)),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 255 }),
            maxLength(255)
          ),
        }
      }
    }

    const containerValidation = (container) => ({
      value: {
        minValue: helpers.withMessage(
          this.$t("minValueErrorMessage") + 1,
          minValue(1))
      },
    })

    const graphValidation = (graph) => ({
      xAxis: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      datasets: graph.datasets.map(dataset => ({
        valueField: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        }
      }))
    })

    const textPanelValidation = (textPanel) => ({
      header: elementValidation("header"),
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const valuePanelValidation = (valuePanel) => ({
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const iterPanelValidation = () => ({
      value: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      label: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
    })

    const filterPanelSourceValidation = (panel) => helpers.withParams(
      { type: 'filterPanelSourceValidation', value: panel },
      (value) => {
        if (panel.header.type === 'field' || panel.label.type === 'field' || panel.value.type === 'field')
          return !!value;
        return true;
      }
    )

    const filterPanelValidation = (filterPanel) => ({
      source: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), filterPanelSourceValidation(filterPanel))
      },
      header: elementValidation("header"),
      label: elementValidation("label"),
      value: elementValidation("value")
    })

    const pivotValidation = (pivot) => ({
      columnField: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
      },
      rows: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
          () => pivot.rows?.length > 0 && pivot.rows.every(row => row))
      },
      values: {
        required: helpers.withMessage(this.$t("requiredFieldErrorMessage"),
          () => pivot.values?.length > 0 && pivot.values.every(val => val?.field))
      },
      sort: {
        field: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        direction: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          checkValues: () => pivot.sort.direction === 'asc' || pivot.sort.direction === 'desc'
        }
      }
    })

    const selectedLeftTreeNodeValidation = () => {
      if (this.selectedLeftTreeNode.systemName === 'filterPanel')
        return filterPanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'container')
        return containerValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'graph')
        return graphValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'text')
        return textPanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'panel')
        return valuePanelValidation(this.selectedLeftTreeNode)
      if (this.selectedLeftTreeNode.element === 'iterpanel')
        return iterPanelValidation()
      if (this.selectedLeftTreeNode.element === 'pivot')
        return pivotValidation(this.selectedLeftTreeNode)
      console.error("Couldn't build validation for element:", this.selectedLeftTreeNode)
    }

    const result = {};

    if (this.selectedLeftTreeNode && this.selectedLeftTreeNode.systemName !== 'mainContainer')
      result.selectedLeftTreeNode = selectedLeftTreeNodeValidation()

    return result
  },
  watch: {
    "selectedLeftTreeNode.type": {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          const root = this.model.rootContainer;
          const path = this.findPathByKey(
            root,
            this.selectedLeftTreeNode.systemName
          );
          const obj = path ? this.getObjectByPath(root, path) : null;
          if (obj && obj.element === "container") {
            obj.type = val;
            obj.icon =
              val === "vertical" ? "node-container" : "node-hcontainer";
          } else if (obj) {
            obj.icon = this.getNodeIcon(obj.element);
          }
        }
      },
    },
    "selectedLeftTreeNode.element": {
      handler: function(val) {
        if (val === 'pivot' && !this.selectedLeftTreeNode.tagRows) {
          this.selectedLeftTreeNode.tagRows = {};
        }
      }
    },
    layoutSettings: {
      handler: function (val) {
        this.model = val;
      },
      immediate: true,
      deep: true,
    },
    validateWhenSelect: {
      handler: function (val) {
        if (val && this.v$.selectedLeftTreeNode)
          this.v$.selectedLeftTreeNode.$touch();
      },
    }
  },
  async created() {
    this.filterPanelSourceDropdownOptions = (
      await this.selectedSourcesService.getSelectedSources()
    ).data;
    this.childPanelValuesOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children;
    this.childGraphParametersOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children.filter(x => x.isParameter);
    this.childGraphValuesOptions = (
      await this.selectedSourcesService.getMainSource()
    ).data[0].children.filter(x => x.isValue);
  },
  mounted() {
    this.$refs["leftTree"].handlerDoubleClick = function () {
      return false;
    };
    this.totalLevels = this.calcTotalLevels(this.model.rootContainer);
    // this.$nextTick(() => {
    //   this.setWidthTree(this.totalLevels);
    // });
  },
  methods: {
    async getLeftTreeData() {
      if (this.model) {
        const { filterPanel, rootContainer } = this.model;
        const existingKeys = new Set();
        this.initializeUniqueKeys(rootContainer, existingKeys);
        return {
          data: [
            {
              ...filterPanel,
              systemName: "filterPanel",
              element: this.$t("filterPanel"),
              name: this.$t("filterPanel"),
              icon: "folder",
            },
            {
              ...rootContainer,
              index: 0,
              systemName: "mainContainer",
              element: this.$t("mainContainer"),
              name: this.$t("mainContainer"),
              icon: "folder",
              needAdd: true,
              level: 0,
              root: "rootContainer",
            },
          ],
        };
      } else {
        return {
          data: [
            {
              systemName: "filterPanel",
              name: this.$t("filterPanel"),
              icon: "folder",
              needWarning: false,
            },
            {
              index: 0,
              systemName: "mainContainer",
              name: this.$t("mainContainer"),
              icon: "folder",
              needAdd: true,
              needWarning: false,
              level: 0,
              children: [],
              root: "rootContainer",
            },
          ],
        };
      }
    },
    handleLeftTreeNeedAdd: (node) => node.needAdd,
    handleLeftTreeNeedDelete: (node) => node.needDelete,
    handleNodeSelect(node) {
      this.selectedLeftTreeNode = null;
      this.$nextTick(async () => {
        this.selectedLeftTreeNode = node;

        const selectedSources = (
          await this.selectedSourcesService.getSelectedSources()
        ).data;
        const selectedFilterPanelSourceOption = this.model.filterPanel.source
          ? selectedSources.filter(
              (o) => o.systemName === this.model.filterPanel.source
            )[0]
          : null;

        if (node.systemName === "filterPanel") {
          if (this.$refs.filterPanelSourceDropdown)
            this.$refs.filterPanelSourceDropdown.localOptions = selectedSources;
          if (selectedFilterPanelSourceOption) {
            this.handleFilterPanelSourceDropdownChange(selectedFilterPanelSourceOption, false);
          }
        }
        if (this.childPanelValuesOptions) {
          if (node.element === "graph") {
            this.modelTabChart = {
              name: this.$t("dataset") + " 1",
              isActive: true,
              systemName: "dataset1",
            },
            this.$refs.childChartValueDropdown.localOptions =
              this.childGraphValuesOptions;
            this.$refs.childChartXAxisDropdown.localOptions =
              this.childGraphParametersOptions;
          } else if (node.element === "iterpanel") {
            this.$refs.childIteratorPanelValueDropdown.localOptions =
              this.childPanelValuesOptions;
            this.$refs.childIteratorPanelLabelDropdown.localOptions =
              this.childPanelValuesOptions;
          }
        }
        this.$emit("update:model", this.model);
        if (this.v$ && this.v$.selectedLeftTreeNode && this.validateWhenSelect)
          this.v$.selectedLeftTreeNode.$touch();
        else
          this.v$.$reset();
      })
    },
    handleFilterPanelSourceDropdownChange(item, noClearElements = true) {
      if (!item) return;
      this.filterPanelValuesOptions = item.children;
      this.model.filterPanel.source = item.systemName;
      if(noClearElements) {
        if (this.model.filterPanel.header.type === "field")
          this.model.filterPanel.header.value = null
        if (this.model.filterPanel.label.type === "field")
          this.model.filterPanel.label.value = null
        if (this.model.filterPanel.value.type === "field")
          this.model.filterPanel.value.value = null
      }
    },
    handleAddColorSelection() {
      this.editingColor = this.selectedLeftTreeNode["color"];
      this.showModal = true;
    },
    updateElementProp(district, element, prop, newVal) {
      if (district) {
        this.model[district][element][prop] = newVal
      }
      else {
        this.selectedLeftTreeNode[element][prop] = newVal;
      }
    },
    handlerShowModalContainer(e) {
      this.showModalContainer = true;
      this.activeContainer = e;
    },
    calcTotalLevels(rootObject) {
      if (Array.isArray(rootObject.children) && rootObject.children.length > 0) {
        for (let i = 0; i < rootObject.children.length; i++) {
          const children = this.calcTotalLevels(
            rootObject.children[i],
          );
          if (children) {
            return children;
          }
        }
      } else if (rootObject.level) {
        return rootObject.level;
      }
    },
    findPathByKey(rootObject, targetKey, path = "") {
      if (rootObject.systemName === targetKey) {
        return path;
      }

      if (Array.isArray(rootObject.children)) {
        for (let i = 0; i < rootObject.children.length; i++) {
          const childPath = this.findPathByKey(
            rootObject.children[i],
            targetKey,
            `${path}.children[${i}]`
          );
          if (childPath) {
            return childPath;
          }
        }
      }

      return null;
    },
    generateUniqueKey(existingKeys, level, index) {
      let uniqueKey;

      do {
        uniqueKey = `key_${level}:${index}`;
        if (!existingKeys.has(uniqueKey)) {
          return uniqueKey;
        }
        index++;
      } while (true);
    },
    getObjectByPath(root, path) {
      if (path.startsWith(".")) {
        path = path.slice(1);
      }

      const keys = path.split(/[\.\[\]']+/).filter(Boolean);

      return keys.reduce((acc, key) => {
        return acc[key];
      }, root);
    },
    addNewObject(parentNode, root, existingKeys, obj) {
      const path =
        parentNode && typeof parentNode === "string"
          ? this.getObjectByPath(root, parentNode)
          : root;
      const index = path.children.length + 1;
      const key = this.generateUniqueKey(existingKeys, path.level + 1, index);

      const newObject = {
        ...obj,
        systemName: key,
        key: key,
        children: [],
        level: path.level + 1,
        parent: "",
      };
      const parent = { ...this.activeContainer };
      delete parent["parent"];
      delete parent["children"];
      newObject.parent = parent;
      path.children.push(newObject);
      existingKeys.add(newObject.systemName);
      return newObject;
    },
    initializeUniqueKeys(node, existingKeys) {
      let index = 1;
      if (!node.name && node.element) {
        node.name = node.element;
      }
      if (!node.level) {
        node.level = 0;
      }
      node.systemName = this.generateUniqueKey(existingKeys, node.level, index);
      node.key = node.systemName;
      existingKeys.add(node.systemName);

      if (Array.isArray(node.children)) {
        for (const child of node.children) {
          child.level = node.level + 1;
          index++;
          child.systemName = this.generateUniqueKey(
            existingKeys,
            child.level,
            index
          );
          child.key = child.systemName;
          existingKeys.add(child.systemName);
          this.initializeUniqueKeys(child, existingKeys);
        }
      }
    },
    getNodeIcon(type) {
      const types = {
        container: "container",
        hcontainer: "hcontainer",
        graph: "graph",
        text: "text",
        panel: "iterpanel",
        iterpanel: "iterpanel",
        pivot: "table",
        table: "table",
      };
      return `node-${types[type]}`;
    },
    setNewContainer(type) {
      const { label, value } = type;
      const { children, level, systemName } = this.activeContainer;
      const existingKeys = new Set();
      const root = this.model.rootContainer;
      const rootObject = root;
      const rootPath = "";
      const childPath = this.findPathByKey(rootObject, systemName, rootPath);
      const icon = this.getNodeIcon(value);
      const model = this.createLayoutSettingsRootContainerChild(value);

      const newElement = {
        ...model,
        systemName: "",
        key: "",
        name: label,
        icon: icon || "folder",
        needAdd: value === "container",
        needDelete: true,
        needWarning: false,
        level: level,
        children: [],
      };
      this.initializeUniqueKeys(rootObject, existingKeys);
      const newObject = this.addNewObject(childPath, rootObject, existingKeys, newElement);

      this.showModalContainer = false;
      this.$refs.leftTree.expandNode(this.activeContainer, false);
      this.$refs.leftTree.selectNode(newObject);
      // this.setWidthTree(level);
    },
    setWidthTree(level) {
      const tree = this.$refs.leftTree;
      if (!tree) return;
      const treeWrapper = tree.$el.querySelector('.p-tree-wrapper');
      const currentWidthTree = treeWrapper.offsetWidth || 377;
      const stepLevelWidth = 20;
      const threshold = 5;
      const widthTree = level && level >= threshold
        ? currentWidthTree + (stepLevelWidth * (level % threshold))
        : currentWidthTree;
      treeWrapper.style.width = `${widthTree}px`;
    },
    removeNode(childNode, lastStep = true) {
      const node = childNode || this.$refs.leftTree.nodes[1];
      const { key } = this.deletableNode;
      if (Array.isArray(node.children)) {
        const index = node.children.findIndex((child) => child.key === key);
        if (index != -1)
          node.children.splice(index, 1);
        else
          node.children.forEach((child) => {
            if (child.element === "container")
              this.removeNode(child, false)
          });
      }

      if (lastStep) {
        this.deleteAlertModal = false;
        this.$refs.leftTree.getData();
      }
    },
    changeContainerChildrenValues(val) {
      let children = this.selectedLeftTreeNode?.children
      if (!children || children.length == 0)
        return;

      children.forEach(elem => {
        elem.parent.type = val.systemName
      });
    },
    getTabSlotName(index) {
      return "tab-" + (index + 1);
    },
    checkSelectedElementType(type) {
      return this.selectedLeftTreeNode?.element === type;
    },
    showDeleteAlertModal(node) {
      this.deletableNode = node;
      this.deleteAlertModal = true;
    },
    handleAddDataset() {
      const newDataset = this.createGraphLayout();
      this.selectedLeftTreeNode.datasets.push(newDataset);
    },
    updateColor(color) {
      this.selectedLeftTreeNode["color"] = color;
    },
    handleTreeLoaded() {
      this.$refs.leftTree.selectNode(0)
      this.expandAllNodes();
    },
    expandAllNodes(force = false) {
      if (force || this.firstLoad) {
        const tree = this.$refs.leftTree;
        tree.expandAll();
        this.firstLoad = false;
      }
    },
    handleDeleteGraphDatasetClick(index) {
      this.selectedLeftTreeNode.datasets =
        this.selectedLeftTreeNode.datasets.filter((d, i) => i !== index)
    },
    setDatasetColor(color, index) {
      this.selectedLeftTreeNode.datasets[index].color.background = color
    },
    setTagsRow(tags) {
      this.selectedLeftTreeNode.tagRows = tags;
    },
    elementValueValidation(elementPropName) {
      const elementModel = this.selectedLeftTreeNode[elementPropName]
      if (elementModel.type !== 'none')
        return !!elementModel.value;
      return true;
    }
  },
  computed: {
    panels() {
      let rightPanelTitle = "";
      let rightPanelAction = "";

      if (this.selectedLeftTreeNode?.systemName === "filterPanel")
        rightPanelTitle = this.$t("filterPanelSettings");
      else if (this.selectedLeftTreeNode?.root === "rootContainer")
        rightPanelTitle = this.$t("rootContainerSettings");
      else if (this.checkSelectedElementType("container"))
        rightPanelTitle = this.$t("containerSettings");
      else if (this.checkSelectedElementType("graph")) {
        rightPanelTitle = this.$t("chartSettings");
        rightPanelAction = this.$t('addDataset');
      }
      else if (this.checkSelectedElementType("text"))
        rightPanelTitle = this.$t("textPanelSettings");
      else if (this.checkSelectedElementType("panel"))
        rightPanelTitle = this.$t("digitPanelSettings");
      else if (this.checkSelectedElementType("iterpanel"))
        rightPanelTitle = this.$t("iteratorSettings");
      else if (this.checkSelectedElementType("pivot"))
        rightPanelTitle = "";
      else if (this.checkSelectedElementType("table"))
        rightPanelTitle = this.$t("simpleTableSettings");

      return [
        {
          title: "",
          search: false,
        },
        {},
        {
          title: rightPanelTitle,
          action: rightPanelAction
        },
      ];
    },
    icons() {
      return { faTrashAlt, faPlus, faExclamationTriangle };
    },
    rootContainerTypeDropdownOptions() {
      return [
        {
          systemName: "vertical",
          name: this.$t("vertical"),
        },
        {
          systemName: "horizontal",
          name: this.$t("horizontal"),
        },
      ];
    },
    chartTypeDropdownOptions() {
      return [
        {
          systemName: "bar",
          name: this.$t("barChart"),
        },
        {
          systemName: "line",
          name: this.$t("lineChart"),
        },
      ];
    },
    chartTabs() {
      const chartDatasets = this.selectedLeftTreeNode?.datasets;
      if (!chartDatasets) return [];

      return chartDatasets.map((dataset, index) => {
        const datasetNumber = index + 1;
        const datasetName = "dataset" + datasetNumber;

        return {
          name: this.$t("dataset") + " " + datasetNumber,
          systemName: datasetName,
          isActive: this.modelTabChart.systemName === datasetName,
        };
      });
    },
    chartShowLegendCheckbox() {
      return [{ label: this.$t("addLegend"), field: "showLegend" }];
    },
    isParentContainerVertical() {
      return this.selectedLeftTreeNode?.parent?.type === "vertical";
    },
    filterPanelFilterHintText() {
      let useNextNamesToFillFilterField = this.$t("exampleToFillFilterField") + "\n\n" + this.$t("useNextNamesToFillFilterField");
      for (const option of this.filterPanelValuesOptions) {
        useNextNamesToFillFilterField+= `\n${option.systemName} - ${option.name}`
      }
      return useNextNamesToFillFilterField;
    },
    filterHintText() {
      let useNextNamesToFillFilterField = this.$t("exampleToFillFilterField") + "\n\n" + this.$t("useNextNamesToFillFilterField");
      for (const option of this.childPanelValuesOptions) {
        useNextNamesToFillFilterField+= `\n${option.systemName} - ${option.name}`
      }
      return useNextNamesToFillFilterField;
    }
  },
};
</script>

<style lang="scss" >
.dashboard-layout-settings {
  .prospace-left-panel {
    & > .prospace-geo-layout__center {
      padding-right: 3px !important;
      padding-bottom: 3px !important;
      .p-tree-wrapper {
        padding-right: 3px !important;
      }
    }
  }
  &.dashboard-grid-layout {
    &.prospace-geo-layout {
      &--ishideCenter {
        grid-template-rows: 1fr !important;
      }
    }
  }
  .tree-item-action-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
    border-radius: 5px;
    background: var(--prospace-ui-select-light);
  }
  .prospace-tree-item .prospace-icon {
    min-width: 16px;
  }
  .dashboard-grid-layout__right {
    padding-left: 0;
  }
  .p-tree .p-tree-wrapper .p-tree-container .p-treenode .p-treenode-content {
    margin: 0;
    padding-right: 10px;
    padding-left: 5px;
  }
  .p-tree {
    overflow: auto !important;
    .p-tree-wrapper {
      width: 100%;
      .p-tree-container {
        .p-treenode {
          .p-treenode-children {
            margin-right: 0px;
            padding-left: 26px;
            & > .p-treenode {
              margin-right: 0px;
            }
            .p-treenode-content {
              padding-left: 0px;
              padding-right: 10px;
            }
            .p-treenode-children {
              padding-left: 22px;
            }
          }
          .p-treenode-content {
            &.p-treenode-selectable {
              &.p-highlight {
                position: relative;
                background-color: transparent;
                &:before {
                  content: '';
                  position: absolute;
                  left: -300%;
                  top: 0;
                  width: 600%;
                  height: 30px;
                  background-color: var(--prospace-ui-left-panel-selected);
                  z-index: 0;
                }
              }
            }
            .p-treenode-label {
              position: relative;
            }
          }
        }
      }
    }
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    .prospace-icon {
      background-color: var(--prospace-ui-main);
    }
  }
  .prospace-panel-block-list__row {
    .prospace-action {
      margin-top: 20px;
    }
    &--withoutMarginAction {
      .prospace-action {
        margin-top: 0;
      }
    }
  }
  .dashboard-grid-layout {
    &__panel {
      .prospace-geo-layout__center {
        padding: 10px;
      }
    }
  }
  &__tabs {
    padding-right: 3px;
    .prospace-tabs__content {
      padding: 20px 3px 20px 0 !important;
    }
    .prospace-tabs__header {
      &.prospace-h-layout {
        margin: 0;
      }
    }
  }
  &--graph {
    .dashboard-grid-layout__right {
      &.prospace-panel-block {
        .prospace-panel-block__body {
          margin-right: -5px;
          padding-right: 0 !important;
        }
      }
    }
  }
  &--pivot {
    .dashboard-grid-layout__right {
      &.prospace-panel-block {
        .prospace-panel-block__body {
          height: 100%;
          margin-right: 0px;
          padding-right: 0px !important;
          padding-left: 0 !important;
        }
        .prospace-panel-block {
          &__body {
            padding-left: 10px !important;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        .prospace-panel-block-item {
          &__header {
            font-size: 12px;
            font-weight: 400;
            color: var(--prospace-text-gray);
          }
        }
      }
      .prospace-list-items-table {
        .p-datatable-tbody {
          & > tr {
            display: inline-block;
            &:not(.p-datatable-row-expansion) {
              padding-top: 9px;
              padding-bottom: 9px;
              & > td {
                &:last-child {
                  padding: 0;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .prospace-list-items__additional-value {
                    font-size: 13px;
                    color: var(--prospace-ui-main);
                    cursor: pointer;
                    .prospace-input-switch__wrapper {
                      width: 140px;
                      label {
                        font-size: 11px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
