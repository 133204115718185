<template>
    <LoginLayout class="security-local-login" v-model:modelMessageError="showMessageError" :error="error">
        <div class="security-local-login">
            <div class="form">
                <label class="label">{{ $t('login') }}</label>
                <ProSpaceInputText class="input"
                    v-model="login"
                    :placeholder="$t('enterLogin')"
                    :dark="true"
                />
                <label class="label">{{ $t('password') }}</label>
                <ProSpaceInputText class="input"
                    v-model="password"
                    :placeholder="$t('enterPassword')"
                    :dark="true"
                    :isPassword="true"
                />
                <ProSpaceButton class="btn btn--sign-in"
                    @click="signIn" :loading="loading"
                    :label="$t('signIn')"
                />
                <ProSpaceAction class="btn btn--back-to-login"
                    :text="$t('backToLogin')"
                    :gray="true"
                    @click="back"
                />
            </div>
        </div>
    </LoginLayout>
</template>

<script>
import {
  ProSpaceButton,
  ProSpaceAction,
  ProSpaceInputText,
} from "@prospace/prospace-components-library"
import LoginLayout from "./components/LoginLayout.vue";
import UserService from "../../services/UserService";
import { hashPassword } from "../../../../../Frontends/Common/utils/passwordHelper";
export default {
  name: "LocalLogin",
  components: {
    ProSpaceButton,
    ProSpaceAction,
    ProSpaceInputText,
    LoginLayout
  },
  data() {
    return {
      userService: new UserService(),
      login: null,
      password: null,
      loading: false,
      showMessageError: false,
      error: null
    };
  },
  beforeCreate() {
    document.querySelector("html").dataset.theme = `theme-light`;
  },
  mounted() {

  },
  methods: {
    async signIn(loginType) {
      var provider = new URLSearchParams(window.location.search).get(
        "provider"
      );
      var returnUrl = new URLSearchParams(window.location.search).get(
        "returnUrl"
      );

      this.showMessageError = false;
      this.error = null
      this.loading = true
      const response = await this.userService.signIn(this.login, hashPassword(this.password), provider);
      if (response && response.data) {
        if (response.data.data && response.data.data.valid === true) {
            this.userService.authorize(provider, returnUrl.substring(5));
        } else if (response.data.errors) {
          this.showMessageError = true;
          this.error = response.data.errors.errors[0]
        }
      }
      this.loading = false
    },
    back() {
        this.userService.moveToLogin()
    },
  },
};
</script>
<style lang="scss" scope>
.security-local-login {
    .form {
        width: 320px;
        margin: 0 auto;
    }
    .label {
        display: block;
        font-size: 12px;
        line-height: 125%;
        color: #99B5CE;
        margin: 10px auto 5px;
    }
    .prospace-input-text input.p-inputtext.p-component {
        background-color: #314354;;
        box-shadow: inset 0 0 0 1px #405265;
        border-color: #405265;
        color: #99B5CE !important;
        // &:focus {
        //     box-shadow: 0px 0px 3px 2px rgba(33, 150, 243, 0.8);
        // }
        &::placeholder {
            color: #99B5CE !important;
        }
    }
    .btn {
        display: block;
        font-size: 14px;
        line-height: 16px;
        margin: 0 auto;
        &.btn--sign-in {
            margin-top: 25px;
            width: 320px;
            height: 30px;
            background: var(--prospace-ui-main);
            color: var(--prospace-text-white);
            border-radius: 5px;
        }
        &.btn--back-to-login {
            color: #99B5CE;
            margin-top: 20px;
            width: 81px;
        }
    }
}
</style>
