<template>
  <ProSpaceEmptyGrid
    :title="$t('noDataTitle')"
    :text="$t('noDataTxt')"
    icon="error-message"
  />
</template>

<script>
import { ProSpaceEmptyGrid } from "@prospace/prospace-components-library";
export default {
  name: "ComingSoon",
  components: { ProSpaceEmptyGrid },
};
</script>

<style scoped></style>
