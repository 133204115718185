<template>
  <ProSpaceGeoLayout class="system-setting">
    <template #top>
      <ProSpaceTopPanel :header="$t('systemSettings')" />
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="system-setting__left-panel"
        :searchPlaceholder="$t('searchTxt')"
        searchable
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :searchPlaceholder="$t('searchTxt')"
            :service="systemSettingService"
            searchName="name"
            getMethod="get"
            prop-label="name"
            prop-key="id"
            hide-icon
            @select="nodeSelect"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="modelTab"
        :tabs="tabs"
        v-model="modelTab"
        @select="handlerSelectTab"
      >
        <ProSpaceAction
          icon="double-check"
          :text="$t('saveAll')"
          @click="saveAll"
        />
        <template #Settings>
          <PanelSettings
            ref="panelSettings"
            :key="masterId"
            :id="masterId"
            v-loading="loading"
            @save="saveSettings"
          />
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  Loading,
} from "@prospace/prospace-components-library";
import { SystemSettingService } from "../../services/SystemSettingService";
import PanelSettings from "./PanelSettings.vue";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceLeftPanel,
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceTree,
    PanelSettings,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
  },
  directives: {
    loading: Loading,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { setStatistic, distActions };
  },
  data() {
    return {
      layoutName: "System settings",
      masterId: null,
      systemSettingService: new SystemSettingService(),
      modelTab: null,
      loading: false,
    };
  },
  mounted() {
    this.modelTab = this.tabs[0];
  },
  computed: {
    tabs() {
      return [{ label: this.$t("settingsTxt"), value: "Settings", systemName: "settings" }];
    },
  },
  methods: {
    handlerSelectTab(tab) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    nodeSelect(node) {
      this.masterId = node.fullPath;
    },
    saveAll() {
      this.saveSettings();
    },
    saveSettings() {
      const models = this.$refs["panelSettings"].model;
      this.checkValid(models);
      this.loading = true;
      this.systemSettingService.editItems(models).then((response) => {
        this.loading = false;
      });
    },
    checkValid(models) {
      models = models.map((m) => {
        if (typeof m.value !== "string") {
          m.value = String(m.value);
        }
        return m;
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
</style>
