import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/dashboardservice/dashboard/";

export default class DashboardApi {
  constructor() {
  }
  async getDashboards(params) {
    return await request.post(`${rootRoute}getDashboards`, {
      filter: params,
      parameters: {},
    });
  }
  async getDashboard(id) {
    return await request.post(`${rootRoute}getDashboard`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createDashboard(model) {
    const data = [model];
    return await request.post(`${rootRoute}createDashboard`, { data });
  }
  async deleteDashboard(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDashboard`, { data });
  }
  async editDashboard(model) {
    const data = [model];
    return await request.post(`${rootRoute}updateDashboard`, { data });
  }
}
