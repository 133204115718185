<template>
    <ProSpaceFilterSearchfield
        v-model="model"
        :closeTxt="$t('closeTxt')"
        :cancelTxt="$t('cancelInf')"
        :placeholderValue="$t('distributor')"
        :applyTxt="$t('applyTxt')"
        :clearFilterTxt="$t('clearFilterTxt')"
        :service="distributorService"
        :txtTitleModal="$t('selectDistributors')"
        :txtNameColumn="$t('distributor')"
        get-method="grid"
        optionLabel="name"
        optionValue="id"
    >
        <template #modal="{model, setModel, applyFn, selectedArray, isWatchMode}">
            <DistributorSelectModal ref="modal"
                :modelValue="model"
                @update:modelValue="setModel"
                :defaultFilter="{}"
                :selectedIds="selectedArray.map(i => i.id)"
                :modalTitle="$t('selectDistributors')"
                @click.stop
                @applyDistributors="applyDistributorSelectModal(applyFn)"
            />
        </template>
    </ProSpaceFilterSearchfield>
</template>

<script>
import DistributorSelectModal from "@modals/DistributorSelectModal";
import { DistributorService } from "@masterdata/services/DistributorService.js"
import {
    ProSpaceFilterSearchfield
} from "@prospace/prospace-components-library";
export default {
    name: "ProSpaceFilterDistributor",
    components: {
        DistributorSelectModal,
        ProSpaceFilterSearchfield
    },
    props: {
        modelValue: {
            type: Number,
        },
    },
    data() {
        return {
            distributorService: new DistributorService(),
        }
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        applyDistributorSelectModal(applyFn) {
            const modal = this.$refs.modal;
            applyFn(modal.grid.getSelectedModel())
        }
    },
}
</script>