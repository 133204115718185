<template>
  <ProSpaceRightPanel
    class="documents-right-panel"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
    @edit="editPanel"
    @delete="deletePanel"
    @show-history="showHistoryPanel"
  >
    <template v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="document" :header="headerDocuments">
            <template v-slot>
              <div class="prospace-panel-block-list">
                <div
                  v-if="checkAccess('publisher-column')"
                  class="prospace-panel-block-list__row"
                >
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentPublisher')"
                    :text="document.publisher"
                  />
                </div>
                <div
                  class="prospace-panel-block-list__row"
                >
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentMessage')"
                    :text="document.message"
                    is-html
                  />
                </div>
                <div
                  class="prospace-panel-block-list__row"
                >
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentAttachment')"
                  >
                    <template #content>
                      <ProSpaceVLayout :gap="5">
                        <ProSpaceAction
                          v-for="(attachment, i) in successedAttachments"
                          icon-width="14px"
                          icon-height="14px"
                          icon="paperclip"
                          :key="i"
                          :text="attachment.fileName"
                          @click="downloadAttachment(attachment)"
                        />
                      </ProSpaceVLayout>
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
                <div
                  class="prospace-panel-block-list__row"
                >
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentTags')"
                  >
                    <template #content>
                      <ProSpaceTagsColumn :tags="tags" :limit="2" />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
              </div>
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock v-if="isFilial || checkAccess('tab-distributors-ProSpaceTabs', document)" :header="headerDistributors">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="!isFilial && !document.disabled && checkAccess('edit-recipients', document)"
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditDistributorsModal"
                />
              </template>
              <template v-slot>
                <ProSpaceAction
                  v-if="!document.isDistributorStructureRestricted && document.isAllDistributors"
                  :text="`${$t('allTxt')} ${$t('distributors')}`"
                  style="margin-bottom: 20px;"
                  @click="showEditDistributorsModalReadOnly"
                />
                <ProSpaceSimpleGrid
                  v-if="
                    !document.isDistributorStructureRestricted 
                    && !document.isAllDistributors 
                    && document.distributorIds 
                    && document.distributorIds.length > 0
                  "
                  ref="distributorGrid"
                  class="documents-right-panel__simple-grid"
                  getMethod="grid"
                  :headers="distributorGridHeaders"
                  :service="distributorService"
                  :limit="5"
                  :btnText="$t('viewMore')"
                  :defaultModal="false"
                  :defaultFilter="distributorGridFilter"
                  :deletable="!document.disabled && checkAccess('edit-recipients', document)"
                  :deleteMethod="null"
                  :confirmDelete="true"
                  confirmIcon="flash-success"
                  :confirmTitle="$t('deletingContactUser')"
                  :confirmText="$t('questionDeleting')"
                  :afterConfirmText="$t('questionDeletingList')"
                  deletingFieldName="name"
                  @delete="deleteDistributor"
                  @openModal="showEditDistributorsModalReadOnly"
                />
                <ProSpaceSimpleGrid
                  v-if="document.isDistributorStructureRestricted"
                  ref="distributorGrid"
                  :items="document.distributorStructures"
                  :headers="distributorStructureHeaders"
                  :bordered="true"
                  :defaultModal="false"
                  :deletable="checkAccess('edit-recipients', document)"
                  :btnText="$t('viewMore')"
                  showViewAll
                  @delete="deleteDocDistributorStructure"
                  @openModal="showDistrStructureModalReadOnly"
                />
                <DistributorSelectModal
                  v-model="editDistributorModal"
                  :modalTitle="distributorSelectTitle"
                  :selectedIds="document.distributorIds"
                  :needAllQuestion="!readOnlyDistributorsModal"
                  :readOnly="readOnlyDistributorsModal"
                  :selectionMode="readOnlyDistributorsModal ? 'single' : 'multiple'"
                  :isAll="document.isAllDistributors"
                  :defaultFilter="distributorGridFilter"
                  :allSelectionText="$t('isAllDistributorsSelectionDocs')"
                  @applyDistributors="applyEditDistributor"
                />
                <DistributorHierarchySelectModal
                  v-if="document.isDistributorStructureRestricted"
                  v-model="visibleDistrStructureModal"
                  expandAll
                  :getMethod="getMethodDistrStructureModal"
                  :readOnly="readOnlyDistrStructureModal"
                  :titleModal="$t('distributorStructure')"
                  :service="distributorStructureService"
                  :checkedRows="document.distributorStructures"
                  @apply="applyDistributorStructure"
                />
              </template>
            </ProSpacePanelBlock>
            <ProSpacePanelBlock v-if="document.isPersonal" :header="headerPositions">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="!document.disabled && checkAccess('position-PositionSelectTree', this.document)"
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditPositionsModal"
                />
              </template>
              <template v-slot>
                <PositionSelectTree
                  ref="positionTree"
                  positionGetMethod="getPositions"
                  positionSelectionMode="single"
                  propLabel="distributorOrgName"
                  expandAll
                  selectLastNodes
                  :positionService="documentViewService"
                  :positionFilter="{ disabled: { and: [false] }, documentId: document.id }"
                />
                <ProSpaceModal
                  v-model="positionsModal"
                  icon="parameters"
                  ref="positionsModal"
                  width="720"
                  height="420"
                  :title="`${$t('recipients')}`"
                  :cancelTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  @apply="applyEditPositions"
                  @cancel="cancelEditPositions"
                  smallPadding
                >
                  <ProSpaceGeoLayout>
                    <template #center>
                      <PositionSelectTree
                        positionGetMethod="allTree"
                        positionSelectionMode="checkbox"
                        propLabel="name"
                        expandAll
                        :positionService="distributorOrgService"
                        :checkedPositions="positions"
                        @changed="(newPositions) => { this.positions = [...newPositions] }"
                        @selectedItem="selectedHandlerTree"
                      />
                    </template>
                    <template #right>
                      <ProSpaceRightPanel
                        style="border: var(--prospace-ui-border)"
                      >
                        <template #center>
                          <ProSpacePanelBlock
                            :header="{ title: $t('parameters'), isExpand: false }"
                          >
                            <ProSpacePanelBlockList :list="getPositionRightPanel" />
                          </ProSpacePanelBlock>
                        </template>
                      </ProSpaceRightPanel>
                    </template>
                  </ProSpaceGeoLayout>
                </ProSpaceModal>
              </template>
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <ProSpacePanelBlock :header="headerRecipients">
            <template v-slot:header>
              <ProSpaceAction
                v-if="!document.disabled && checkAccess('edit-recipients', this.document)"
                :text="`${$t('editItemTxt')}`"
                @click="showEditRecipientsModal"
              />
            </template>
            <template v-slot>
              <ProSpaceSimpleGrid
                ref="recipientGrid"
                getMethod="simple"
                :headers="recipientGridHeaders"
                :service="recipientService"
                :limit="7"
                :btnText="$t('viewMore')"
                :deletable="!document.disabled && checkAccess('edit-recipients', this.document)"
                :defaultModal="false"
                :confirmDelete="true"
                confirmIcon="flash-success"
                :confirmTitle="$t('deletingContactUser')"
                :confirmText="$t('questionDeleting')"
                :afterConfirmText="$t('questionDeletingList')"
                deletingFieldName="email"
                @delete="deleteFromFilter"
                @openModal="showRecipientsModal"
              />
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="editRecipientModal"
                node-method="onlyMars"
                @close="applyEditRecipient"
              />
              <ProSpaceModal
                v-model="recipientModal"
                icon="parameters"
                ref="recipientModal"
                :title="`${$t('notifications')}`"
                :cancelTxt="$t('closeTxt')"
                :applyTxt="null"
                :contentStyle="modalContentStyle"
              >
                <ProSpaceGeoLayout>
                  <template #center>
                    <ProSpaceSimpleGrid
                      ref="recipientGrid"
                      getMethod="simple"
                      :headers="recipientGridModalHeaders"
                      :service="recipientService"
                      :limit="50"
                      :btnText="$t('viewMore')"
                      :deletable="false"
                      :deleteMethod="null"
                    />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceModal>
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
  ProSpaceSimpleGrid,
  ProSpaceModal,
  ProSpaceVLayout
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import PositionSelectTree from "@trees/PositionSelectTree";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal";
import { DocumentService } from "../../../services/DocumentService";
import { DocumentViewService } from "../../../services/DocumentViewService";
import { DocumentRecipientService } from "../../../services/DocumentRecipientService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "DocumentPanel",
  components: {
    ProSpaceGeoLayout,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
    ProSpaceSimpleGrid,
    ProSpaceModal,
    OrgStructureSelectModal,
    DistributorSelectModal,
    PositionSelectTree,
    ProSpaceVLayout,
    DistributorHierarchySelectModal
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    }
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData
    }
  },
  data() {
    return {
      document: {},
      modelTab: { name: this.$t("parameters"), isActive: true, systemName: "parameters" },
      documentService: new DocumentService(),
      documentViewService: new DocumentViewService(),
      recipientService: new DocumentRecipientService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      distributorStructureService: new DistributorStructureService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
      recipientModal: false,
      positionsModal: false,
      editRecipientModal: false,
      editDistributorModal: false,
      readOnlyDistributorsModal: false,
      visibleDistrStructureModal: false,
      readOnlyDistrStructureModal: true,
      distributorSelectTitle: "",
      getMethodDistrStructureModal: "getDocViewTree",
      persistRecipients: true,
      positions: [],
      selectedOrgPosition: null,
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          width: 90,
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      distributorStructureHeaders: [
        { name: this.$t("distributor"), value: "distributorStructureViewDistributorName" },
        { name: this.$t("branch"), value: "distributorStructureViewName" },
      ],
      recipientGridModalHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridModalHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      modalContentStyle: {
        overflow: "hidden",
        height: "calc(95vh - 180px)",
        width: "95vw",
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    isFilial() {
      return this.document.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.document);
    },
    successedAttachments() {
      return this.attachments.filter(x => x.statusFlag === 0);
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=documentId eq ${Number(
          this.document.id
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorGridFilter() {
      return !this.document.isAllDistributors ? { id: { or: this.document.distributorIds } } : { disabled: { and: [false] } };
    },
    headerDocuments() {
      return {
        title: this.$t("document"),
        isExpand: false,
      };
    },
    headerDistributors() {
      return {
        title: this.document.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
        isExpand: true,
      };
    },
    headerPositions() {
      return {
        title: this.$t("recipients"),
        isExpand: true,
      };
    },
    headerRecipients() {
      return {
        title: this.$t("recipients"),
        isExpand: true,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      if(!this.document)
        return [];

      const tabs = [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("recipients"),
          systemName: "recipients",
          isHidden: !this.isFilial && !(this.document.isPersonal || this.checkAccess("tab-distributors-ProSpaceTabs", this.document)),
          isActive: this.modelTab.systemName === "recipients",
        },
        {
          name: this.$t("notifications"),
          systemName: "notifications",
          isHidden: !this.checkAccess("tab-notifications-ProSpaceTabs", this.document),
          isActive: this.modelTab.systemName === "notifications",
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
    header() {
      if (this.document) {
        return {
          title: this.document.title,
          id: this.document.typeName,
          status: this.getStatus(this.document.status, this.document.statusCode),
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
      };
    },
    contentBottom() {
      let metaInfo = [];
      if (this.document) {
        if(this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.document.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.document.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.document.createdAt) + ' ' + getAMorPM(this.document.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.document.lastUpdated) + ' ' + getAMorPM(this.document.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
    getPositionRightPanel() {
      if (this.selectedOrgPosition) {
        const pos = [
          {
            title: this.$t("position"),
            text: this.selectedOrgPosition.name,
          },
        ];
        const parentPos = [
          {
            title: this.$t("parentPosition"),
            text: this.selectedOrgPosition.parentName,
          },
        ];
        return this.selectedOrgPosition.parentName ? [parentPos, pos] : [pos];
      }
      return [];
    },
    tags() {
      return this.document.tags ? this.document.tags.split('#') : [];
    },
    attachments() {
      return this.document.attachments ?? [];
    },
  },
  mounted() {
    this.update();
    this.modelTab = { name: this.$t("parameters"), isActive: true, systemName: "parameters" };
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();
      }
    },
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    selectedHandlerTree(item) {
      this.selectedOrgPosition = item;
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.document)
        : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getDocument();
      } catch (e) {
        this.hasError = true;
      }
    },
    getDocument() {
      if (this.selectedData) {
        this.loading = true;
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "Documents",
          id: Number(this.selectedData.id),
        });
        this.documentViewService.get(this.selectedData.id).then((res) => {
          this.document = res.data;
          this.documentViewService.documentId = this.document.id;
          this.positions = [...this.document.positions || []];
          this.distributorStructureService.setDocId(this.document.id);
          if (!this.document) return
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "Documents",
            id: "documentPublishStaticId",
            realId: Number(this.document.id),
            type: this.document.type,
            groupUpdate: true
          });
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "Documents",
            id: "documentNewStaticId",
            realId: Number(this.document.id),
            type: this.document.type,
            groupUpdate: true
          });
          let filter = JSON.parse(this.document.filter);
          if (filter) {
            this.nodeFilter = filter.nodeFilter;
            this.gridFilter = filter.gridFilter;
          }
          this.feelNeedFilter();

          this.$nextTick(() => {
            if(this.$refs.recipientGrid) this.$refs.recipientGrid.getData();
            if(this.$refs.distributorGrid) this.$refs.distributorGrid.getData();
            if(this.$refs.positionTree) this.$refs.positionTree.refresh();
          })

          this.persistRecipients = true;
          this.recipientService.filter = this.recipientGridFilter;

          this.loading = false;
        });
      }
    },
    showRecipientsModal() {
      this.recipientModal = true;
    },
    showEditPositionsModal() {
      this.positionsModal = true;
    },
    showEditRecipientsModal() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = { ...this.nodeFilter };
      this.$refs.filterModal.gridFilter = { ...this.gridFilter };
      this.$refs.filterModal.selectedTreeNodes = { ...this.selectedTreeNodes };
      this.$nextTick(() => {
        this.editRecipientModal = true;
      });
    },
    updateSelectedTreeNodes() {
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (this.selectedTreeNodes) {
          delete this.selectedTreeNodes["all"];
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      // баг если дочерние check, parent uncheck, см свой чат
      // listTreeNodes добавлять из через this.nodeFilter.add (нет некоторых узлов)
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      this.selectedTreeNodes = listTreeNodes;
    },
    showEditDistributorsModal() {
      if(this.document.isDistributorStructureRestricted) {
        this.readOnlyDistrStructureModal = false;
        this.getMethodDistrStructureModal = "allTree";
        this.$nextTick(() => {
          this.visibleDistrStructureModal = true;
        })
      } else {
        this.distributorSelectTitle = this.$t('selectDistributors');
        this.readOnlyDistributorsModal = false;
        this.$nextTick(() => {
          this.editDistributorModal = true;
        })
      }
    },
    showEditDistributorsModalReadOnly() {
      this.distributorSelectTitle = this.$t('listDistributors');
      this.readOnlyDistributorsModal = true;
      this.$nextTick(() => {
        this.editDistributorModal = true;
      })
    },
    showDistrStructureModalReadOnly() {
      this.readOnlyDistrStructureModal = true;
      this.getMethodDistrStructureModal = "getDocViewTree";
      this.$nextTick(() => {
        this.visibleDistrStructureModal = true;
      })
    },
    applyEditRecipient() {
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.document.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.document.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.document.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.document.needFilter) this.$refs.recipientGrid.getData();
      this.editRecipientModal = false;
      this.saveModel();
    },
    applyDistributorStructure(units) {
      this.document.distributorStructures = units.map(x => { 
        return {
          distributorStructureViewId: x.distributorStructureViewId,
          distributorStructureViewName: x.distributorStructureViewName || "",
          distributorStructureViewCode: x.distributorStructureViewCode,
          distributorStructureViewType: x.distributorStructureViewType,
          distributorStructureViewDistributorId: x.distributorStructureViewDistributorId,
          distributorStructureViewDistributorName: x.distributorStructureViewDistributorName
        }
      });
      this.saveModel();
    },
    async applyEditDistributor({items, requestModel}, isAll) {
      this.document.isAllDistributors = isAll;
      if (!isAll) {
        this.document.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.document.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.document.distributorIds = Array.isArray(items) ? items : [];
        }
      }
      this.saveModel();
    },
    deleteDocDistributorStructure(docDistrStructure) {
      this.document.distributorStructures = this.document.distributorStructures.filter(x => x.id !== docDistrStructure.id);
      this.saveModel();
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.document.oDataFilter = this.oDataStringFilter;
      this.document.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.persistRecipients = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
      this.saveModel();
    },
    feelNeedFilter() {
      this.document.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    applyEditPositions() {
      this.positions.forEach(x => {
        x["documentId"] = this.document.id;
        x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
      })
      this.document.positions = [...this.positions];
      this.saveModel();
    },
    cancelEditPositions() {
      this.positions = [...this.document.positions];
    },
    saveModel() {
      this.documentService.update(this.document).then((result) => {
        this.$emit('refresh');
      });
    },
    deleteDistributor(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.document.distributorIds = this.document.distributorIds.filter((r) => r !== item.id);

      this.saveModel();
    },
    getStatus(status, statusCode) {
      if (!status || !statusCode) return;

      let type = "";
      if (statusCode == "Publication") type = "blue";
      else type = "default";

      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    clearPanel() {
      this.$emit("clear");
    },
    editPanel() {
      this.$emit("edit", this.document);
    },
    deletePanel() {
      this.$emit("delete", this.document);
    },
    showHistoryPanel() {
      this.$emit("history", this.document);
    },
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.prospace-panel-block-list {
  &__row {
    display: flex;
    column-gap: 10px;
    //border-bottom: 1px dashed var(--libBorder);

    &:last-child {
      border-bottom: none;
    }
    &:not(:first-child) {
      .prospace-panel-block-item {
        padding: 10px 0;
      }
    }
    &:first-child {
      .prospace-panel-block-item {
        padding: 0 0 10px;
      }
    }
  }
}

.documents-right-panel :deep(.prospace-default-text-column > div) {
  overflow: hidden;
  max-width: 105px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
