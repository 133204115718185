<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @result="actionHandler('result')"
    @taskResult="actionHandler('taskResult')"
    @taskLog="actionHandler('taskLog')"
  >
    <template v-slot:center>
      <!-- <ProSpacePanelBlock :header="headerOverview">
        <template #header>
        </template>
        <ProSpacePanelBlockList :list="overviewContent" />
      </ProSpacePanelBlock>
      <ProSpacePanelBlock :header="headerParameters">
        <template #header>
        </template>
        <ProSpacePanelBlockList :list="parametersContent" />
      </ProSpacePanelBlock> -->
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpacePanelBlockItem,
  ProSpaceAction,
} from "@prospace/prospace-components-library";

import { SystemLogService } from "../../../services/SystemLogService.js";

export default {
  name: "SystemTaskPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpacePanelBlockItem,
    ProSpaceAction,
  },
  props: {
    id: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      task: null,
      systemLogService: new SystemLogService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    headerOverview() {
      return {
        title: this.$t("overview"),
        isExpand: false,
      };
    },
    headerMetaInfo() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.task) {
        return {
          title: this.task.name,
          id: this.id,
          status: this.getStatus(this.task.status),
          options: this.options,
          showCounter: false,
        };
      }
      return {};
    },
    overviewContent() {
      if (this.task) {
        return [
          [
            {
              title: this.$t("description"),
              text: this.task.description,
            },
          ],
        ];
      }
      return [];
    },
    parametersContent() {
      if (this.task) {
        let params = this.task.parameters;
        let content = [];

        params.forEach((prop) => {
          content.push([
            {
              title: prop.key,
              text: prop.value,
            },
          ]);
        });

        return content;
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.hasError = false;
      try {
        this.getTask();
      } catch (e) {
        this.hasError = true;
      }
    },
    getTask() {
      if (this.id) {
        this.loading = true;
        this.systemLogService.get(this.id).then((res) => {
          this.task = res.data[0];
          this.loading = false;
        });
      } else {
        this.task = null;
      }
    },
    actionHandler(action) {
      this.$emit(action, this.task);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "completed":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
  },
  watch: {
    id() {
      this.update();
    },
  },
};
</script>

<style scoped></style>
