<template>
  <ProSpaceGeoLayout class="geo-row">
    <template v-slot:left>
      <ProSpaceTextBlock
        :top="mainTxt"
        :bottom="infoTxt"
        :gap="10"
        type="third"
        break-word
      />
    </template>
    <template v-slot:center>
      <ProSpaceGeoLayout>
        <template v-slot:top>
          <ProSpaceButton
            v-if="checkAccess(`action-add-${cpAbbreviation}`, model)"
            type="secondary"
            icon="plus"
            class="margin-bottom-30"
            :label="this.$t('add')"
            @click="showCreate"
          />
        </template>
        <template v-slot:center>
          <ProSpaceSimpleGrid
            ref="grid"
            getMethod="grid"
            :headers="fields"
            :id="model.id"
            :propId="masterField"
            :limit="null"
            :confirmDelete="false"
            :service="service"
            :defaultFilter="{ disabled: { and: [false] } }"
            :dateFormat="userinfo.dateFormat"
            :deletable="
              checkAccess(
                `action-delete-${cpAbbreviation}-ProSpaceSimpleGrid`,
                model
              )
            "
            :deleteMethod="deleteMethodGrid"
            breakpoint="600px"
            @delete="showFlashMessageDelete"
          />
          <ProSpaceModal
            v-model="showModal"
            width="410"
            icon="plus"
            :cancelTxt="$t('cancelInf')"
            :applyTxt="$t('addItemTxt')"
            :disabledApply="v$.modalModel.$invalid"
            @apply="createItem"
            @cancel="resetValidation"
          >
            <template #title>
              {{ modalHeader }}
            </template>
            <ProSpaceVLayout :gap="20">
              <ProSpaceHLayout
                v-for="(fields, index) in modalFields"
                :key="index"
                align-items="flex-start"
                :gap="10"
                :class="{
                  'hidden-row': fields.every(
                    (field) => field.isHide && field.isHide(modalModel)
                  ),
                }"
              >
                <component
                  v-for="field in fields"
                  :ref="field.name"
                  :is="getComponent(field.type)"
                  v-model="modalModel[field.name]"
                  v-show="field.isHide ? !field.isHide(modalModel) : true"
                  :key="field.name"
                  :prefix="
                    field.name === 'phone' || field.name === 'phoneAdditional'
                      ? '+7'
                      : ''
                  "
                  :label="field.displayName"
                  :placeholder="
                    field.placeholder ? field.placeholder : field.displayName
                  "
                  :dateFormat="userinfo.dateFormat"
                  :service="field.service"
                  :getMethod="field.serviceGetMethod || 'get'"
                  :propValue="field.propValue"
                  :propLabel="field.propLabel"
                  :argMethod="field.argMethod"
                  :optionLabel="field.propLabel"
                  :optionValue="field.propValue"
                  :filterModel="field.filterModel"
                  :icon="field.icon"
                  :searchPlaceholder="$t('searchTxt')"
                  :cancelTxt="$t('cancelInf')"
                  :applyTxt="$t('choose')"
                  :txtFilterHint="$t('filterBy')"
                  :fastFilters="[]"
                  :txtTitleModal="field.modalTitle"
                  :displayValue="field.displayValue"
                  :defaultFilter="field.defaultFilter"
                  :disabled="
                    field.isDisabled ? field.isDisabled(modalModel) : false
                  "
                  :invalid="v$.modalModel[field.name].$error"
                  :invalid-messages="v$.modalModel[field.name].$errors"
                  @blur="v$.modalModel[field.name].$touch"
                  @update:modelValue="updateField($event, field)"
                >
                  <template
                    v-if="
                      field.type === 'search' &&
                      field.customServices &&
                      field.customServices.length > 0
                    "
                    #filters="{ filter }"
                  >
                    <ProSpaceCustomFilterPanel
                      ref="custom-filter"
                      :customTxt="$t('custom')"
                      :customFilterTxt="$t('customFilterTxt')"
                      :clearFilterTxt="$t('clearFilterTxt')"
                      :selectAllTxt="$t('selectAllTxt')"
                      :closeTxt="$t('closeTxt')"
                      :applyTxt="$t('applyTxt')"
                      :fromTxt="$t('fromTxt')"
                      :toTxt="$t('toTxt')"
                      :services="field.customServices"
                      @apply="
                        (model) => customFilterApply(filter, model, field.name)
                      "
                    />
                  </template>
                  <template v-if="field.type === 'search'" #columns>
                    <Column
                      v-for="column in field.columns"
                      :key="column.name"
                      :field="column.name"
                      :header="column.header"
                      sortable
                    >
                      <template #body="field">
                        <ProSpaceDefaultTextColumn :style="column.name === 'fullName' ? 'font-weight: 500;' : ''">
                          {{ field.data[column.name] }}
                        </ProSpaceDefaultTextColumn>
                      </template>
                    </Column>
                  </template>
                  <template v-if="field.type === 'search'" #panel="{ item }">
                    <ProSpaceRightPanel :header="headerRightPanel(item, field)">
                      <template #center>
                        <ProSpacePanelBlock :header="{}" withPaddingTop>
                          <template v-slot:header> </template>
                          <template v-slot>
                            <ProSpacePanelBlockList
                              :itemsPerPage="5"
                              :list="contentRightPanel(item, field)"
                            />
                          </template>
                        </ProSpacePanelBlock>
                      </template>
                    </ProSpaceRightPanel>
                  </template>
                </component>
              </ProSpaceHLayout>
            </ProSpaceVLayout>
          </ProSpaceModal>
          <ProSpaceFlashMessage
            v-model="flashMessageDelete"
            type="default"
            icon="flash-success"
            :title="$t('deletingContactUser')"
          >
            <template #default>
              <span>
                {{ $t("questionDeletingContactUser") }}
                <span class="text-color-accent">{{ deletingItem.fio }}</span
                >?
              </span>
            </template>
            <template #footer>
              <ProSpaceHLayout :gap="5" justify-content="center">
                <ProSpaceButton
                  type="tertiary"
                  :label="$t('reject')"
                  @click="handlerCancelFlashMessage"
                />
                <ProSpaceButton
                  type="primary"
                  icon="check"
                  :label="$t('delete')"
                  @click="handlerApplyFlashMessage"
                />
              </ProSpaceHLayout>
            </template>
          </ProSpaceFlashMessage>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceGeoLayout,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceAction,
  ProSpaceTextBlock,
  ProSpaceSimpleGrid,
  ProSpaceModal,
  ProSpaceInputText,
  ProSpaceButton,
  ProSpaceInputCalendar,
  ProSpaceDropdown,
  ProSpaceSearchfield,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceDefaultTextColumn,
  ProSpaceCustomFilterPanel,
  ProSpaceFlashMessage,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
const _ = require("lodash");

export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceAction,
    ProSpaceTextBlock,
    ProSpaceSimpleGrid,
    ProSpaceModal,
    ProSpaceInputText,
    ProSpaceButton,
    ProSpaceInputCalendar,
    ProSpaceDropdown,
    ProSpaceSearchfield,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceDefaultTextColumn,
    ProSpaceCustomFilterPanel,
    ProSpaceFlashMessage,
    Column
  },
  props: {
    cpAbbreviation: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    masterField: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
    createMethod: {
      type: String,
      default: "create",
    },
    deleteMethod: {
      type: String,
      default: "delete",
    },
    validationProps: {
      type: Object,
      default: {},
    },
    fields: {
      type: Array,
      default: [],
    },
    modalFields: {
      type: Array,
      default: [],
    },
    modalHeader: {
      type: String,
      default: [],
    },
    mainTxt: {
      type: String,
    },
    infoTxt: {
      type: String,
    },
    buildModel: {
      type: Function,
    },
    checkAccess: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      showModal: false,

      modalModel: {},

      deleteMethodGrid: "",
      flashMessageDelete: false,
      deletingItem: null,
    };
  },
  validations() {
    return {
      modalModel: this.validationProps,
    };
  },
  methods: {
    handlerCancelFlashMessage() {
      (this.flashMessageDelete = false), (this.deletingItem = null);
      this.deleteMethodGrid = "";
    },
    handlerApplyFlashMessage() {
      this.deleteMethodGrid = "delete";
      this.$nextTick(() => {
        this.$refs.grid.deleteHandler(this.deletingItem);
        this.handlerCancelFlashMessage();
      });
    },
    showFlashMessageDelete(item) {
      this.flashMessageDelete = true;
      this.deletingItem = item;
    },
    getComponent(type) {
      switch (type) {
        case "search":
          return "ProSpaceSearchfield";
        case "dropdown":
          return "ProSpaceDropdown";
        case "date":
          return "ProSpaceInputCalendar";

        default:
          return "ProSpaceInputText";
      }
    },
    showCreate() {
      this.modalModel = {
        [this.masterField]: this.model.id,
      };
      this.showModal = true;
    },
    createItem() {
      const me = this;
      this.service[this.createMethod](this.getModelToAction()).then(
        (response) => {
          me.$refs.grid.getData();
          this.resetValidation();
        }
      );
    },
    getModelToAction() {
      let model = _.cloneDeep(this.modalModel);
      model.distributorName = this.model.name;
      model.distributorMailAddress = this.model.mailAddress;
      model.distributorLogoFileContent = this.model.logoFileContent;

      if (model.phone) model.phone = `+7${model.phone}`;
      if (model.phoneAdditional)
        model.phoneAdditional = `+7${model.phoneAdditional}`;

      if (this.buildModel) model = this.buildModel(model);

      return model;
    },
    resetValidation() {
      this.v$.modalModel.$reset();
    },
    customFilterApply(filter, model, ref) {
      filter = Object.assign(filter, model);
      this.$refs[ref][0].$refs["filter-panel"].submitFilter();
    },
    updateField(data, field) {
      if(field.update && typeof field.update === 'function')
        field.update(data, field.name)
    },
    headerRightPanel(item, field) {
      if (item && field.panelHeader && typeof field.panelHeader === 'function') {
        return field.panelHeader(item)
      }
    },
    contentRightPanel(item, field) {
      if (item && field.panelContent && typeof field.panelContent === 'function') {
        return field.panelContent(item)
      }
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
  },
};
</script>
<style lang="scss">
  .hidden-row {
    display: none!important;
  }
</style>
