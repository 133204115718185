<template>
    <ProSpaceHLayout class="dashboard-chart-item" isInlineH justify-content="space-between" :gap="10" v-if="checkValue(title || text || rate)">
        <div class="dashboard-chart-item__left">
            <div class="dashboard-chart-item__left__title" :style="getStyleTextColor(titleColor)"
                v-if="checkValue(title)">
                {{ title }}
            </div>
            <div class="dashboard-chart-item__left__text" :style="getStyleTextColor(textColor)"
                v-if="checkValue(text)">
                <a v-if="link?.href" :href="link.href" target="_blank" :title="`${link.href}`">{{ link.text }}</a>
                <span v-else>{{ text }}</span>
            </div>
        </div>
        <div class="dashboard-chart-item__right" v-if="checkValue(rate)">
            <div class="dashboard-chart-item-rate" :style="getStyleColor(rateColor)">
                {{ rate }}
            </div>
        </div>
    </ProSpaceHLayout>
</template>

<script>
import {
    ProSpaceHLayout
} from "@prospace/prospace-components-library";
import {
    getStyleColor,
    getStyleTextColor,
    checkValue
} from "@dashboard/utils/dashboardUtils.js";
export default {
    name: 'DashboardChartItem',
    components: {
        ProSpaceHLayout
    },
    props: {
        title: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        link: {
            type: Object,
            default: null
        },
        rate: {
            type: Number,
            default: null
        },
        titleColor: {
            type: Object,
            default: null
        },
        textColor: {
            type: Object,
            default: null
        },
        rateColor: {
            type: Object,
            default: null
        },
    },
    methods: {
        getStyleColor,
        getStyleTextColor,
        checkValue
    },
}
</script>

<style lang="scss">
.dashboard-chart-item {
    &__left {
        &__title {
            font-size: 15px;
            font-weight: 500;
            color: var(--prospace-text-main);
            line-height: 17.58px;
            margin-bottom: 2px;
        }
        &__text {
            font-size: 10px;
            line-height: 11.72px;
            color: var(--prospace-ui-icon-gray);
        }
    }
    &__right {

    }
    &-rate {
        font-size: 18px;
        font-weight: 700;
        color: var(--prospace-text-white);
        padding: 5px 10px;
        border-radius: 5px;
    }
}
</style>
