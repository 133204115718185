<template>
  <div v-if="clearData" class="prospace-scrollbar" :style="`width: 100%; height: fit-content; overflow: auto;`">
    <table ref="dashboardPivot" class="dashboard-pivot" :style="getTableStyle()">
      <colgroup :span="rowspan"></colgroup>
      <colgroup v-for="col in columns.length" :key="col" :span="colspan"></colgroup>
      <thead>
        <tr>
          <th
            v-for="(row, index) in Object.keys(rowData[0].rows).length"
            :key="row"
            scope="colgroup"
            class="no-border sticky-left dashboard-pivot__start"
            :class="`sticky-left-shift-${index}`"
          ></th>
          <th
            v-for="col in columns"
            :key="col"
            :colspan="colspan"
            scope="colgroup"
          >
            {{ col }}
          </th>
        </tr>
        <tr>
          <th
            v-for="(row, index) in Object.keys(rowData[0].rows)"
            :key="row"
            scope="col"
            class="sticky-left dashboard-pivot-indicator__text"
            :class="`sticky-left-shift-${index}`"
            v-hint="getLocalizedRow(row)"
          >
            {{ getLocalizedRow(row) }}
          </th>
          <template v-for="col in columns.length">
            <th
              class="dashboard-pivot-value__text"
              v-for="valueKey in headerValues"
              :key="valueKey"
              scope="col"
              v-hint="valueKey"
            >
              {{ valueKey }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
      <tr v-for="row in rowData" :key="row.key">
        <th
          v-for="(rowKey, index) in Object.keys(row.rows)"
          :key="rowKey"
          scope="row"
          class="sticky-left dashboard-pivot__start"
          :class="`sticky-left-shift-${index}`"
          v-hint="getLocalizedRow(row.rows[rowKey])"
        >
          <template v-if="tagRows && tagRows[rowKey]">
            <ProSpaceTag :text="escapeValue(row.rows[rowKey])" bordered />
          </template>
          <template v-else>
            {{ escapeValue(row.rows[rowKey]) }}
          </template>
        </th>
        <template v-for="col in columns">
          <template
            v-if="data.find((x) => x.row === row.key && x.column === col)"
          >
            <td
              v-for="val in data.find((x) => x.row === row.key && x.column === col).values"
              :style="getStyleColor(val.color)"
              :key="val.value"
            >
              {{ val.value }}
            </td>
          </template>
          <template v-else>
            <td
              :style="getStyleColor({ background: '--prospace-ui-bg', text: '--prospace-dashboard-text-black' })"
              v-for="val in colspan"
              :key="val"
            ></td>
          </template>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceIcon,
  ProSpaceDropdown,
  Hint,
  ProSpaceTag,
} from "@prospace/prospace-components-library";
import Chart from "primevue/chart";
import {
  getStyleColor,
  getCustomizableProperties,
} from "@dashboard/utils/dashboardUtils.js";
import { ref, onMounted } from "vue";
import _ from "lodash";
export default {
  name: "DashboardPivot",
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceIcon,
    ProSpaceDropdown,
    Hint,
    ProSpaceTag,
    Chart,
  },
  directives: { Hint },
  props: {
    rowData: {
      type: Array,
      default: null,
    },
    tagRows: {
      type: Object,
      default: null,
    },
    columns: {
      type: Array,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    datasetFields: {
      type: Array,
      default: null,
    },
    broker: {
      type: Object,
      required: true
    }
  },
  setup(_, { emit }) {
    const dashboardPivot = ref(null);

    onMounted(() => {
      emit('ref-sent', dashboardPivot.value);
    });

    return {
      dashboardPivot
    };
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.setLeftShiftForStickyRows();
    })
  },
  methods: {
    getStyleColor,
    getCustomizableProperties,
    getLocalizedRow(name) {
      let fieldName = name;
      if (this.datasetFields && this.datasetFields.length > 0) {
        let field = this.datasetFields.find((f) => f.systemName === name);
        fieldName = field?.name ?? fieldName;
      }
      return fieldName;
    },
    setLeftShiftForStickyRows() {
      let currentLeft = 0;
      for (let index = 0; index < this.rowspan; index++) {
        let shiftCols = [...this.$el.getElementsByClassName(`sticky-left-shift-${index}`)];
        let maxWidth = Math.max(...shiftCols.map(c => c.getBoundingClientRect().width));
        if (maxWidth < 60)
          maxWidth = 60;
        else if (maxWidth > 140)
          maxWidth = 140;

        shiftCols.forEach(el => {
          el.style.left = currentLeft + "px";
          el.style.width = maxWidth + "px";
          el.style.minWidth = maxWidth + "px";
          el.style.maxWidth = maxWidth + "px";
        });

        currentLeft += maxWidth;
      }
    },
    getTableStyle() {
      return `max-width: ${(this.rowspan + this.columns.length * this.colspan) * 60}px;`
    },
    escapeValue(value) {
      return this.broker.escapeValue(value);
    }
  },
  computed: {
    clearData() {
      return this.rowData && this.rowData.length > 0
        && this.columns && this.columns.length > 0
        && this.data && this.data.length > 0
    },
    colspan() {
      let span = 0;
      if (this.data && this.data.length > 0)
        span = this.data[0].values.length;
      return span;
    },
    rowspan() {
      let span = 0;
      if (this.rowData && this.rowData.length > 0)
        span = Object.keys(this.rowData[0].rows).length;
      return span;
    },
    headerValues() {
      let values = [];
      if (this.data && this.data.length > 0)
        values = this.data[0].values.map((x) => x.key);
      return values;
    },
  },
};
</script>

<style lang="scss">
.dashboard-pivot {
  width: 100%;
  border-collapse: separate;
  background: var(--prospace-ui-bg);
  .no-padding {
    padding: 0 !important;
  }
  .no-border {
    border: 0 !important;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
  &-value {
    &__text {
      font-size: 10px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      color: var(--prospace-text-main) !important;
      min-width: 80px;
      max-width: 140px;
    }
  }
  &-indicator {
    tr {
      td {
        padding-top: 9px;
        padding-bottom: 9px;
      }
      th {
        border: 0.5px solid var(--prospace-ui-border-color);
      }
      &:last-child {
        td {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            border-bottom: 0.5px solid var(--prospace-ui-border-color);
            bottom: -1px;
            left: 0;
          }
        }
      }
    }
    &__text {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      padding-left: 10px !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      background-color: var(--prospace-ui-gray-card-bg);
      text-align: left !important;
      color: var(--prospace-text-main) !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  thead {
    position: sticky;
    top: 0px;
    background-color: var(--prospace-ui-bg);
    z-index: 11;
    tr {
      &:first-child {
        th {
          &:before {
            border-top: 0;
          }
          &:after {
            border-bottom: 0;
          }
        }
      }
    }
  }
  tbody {
    th {
      border-top: 0.5px solid var(--prospace-ui-border-color);
      border-bottom: 0.5px solid var(--prospace-ui-border-color);
    }
  }
  th,
  td {
    line-height: 11.72px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    padding: 10px 5px;
    font-size: 10px;
  }
  th {
    position: relative;
    color: var(--prospace-text-main);
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 100%;
    border: 0.5px solid var(--prospace-ui-border-color);
    &[scope="colgroup"] {
      border-top: 0;
      font-size: 12px;
      font-weight: 600;
      &:last-child {
        border-right: 0;
      }
    }
    &[scope="col"] {
      padding-top: 7px;
      padding-bottom: 7px;
      background: var(--prospace-ui-gray-card-bg);
      &:last-child {
        border-right: 0;
      }
    }
    &[colspan="3"] {
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
  td {
    font-size: 10px;
    font-weight: 400;
    border-top: 0.5px solid var(--prospace-ui-border-color);
    border-bottom: 0.5px solid var(--prospace-ui-border-color);
    border-left: 0.5px solid rgba(0, 0, 0, 0);
    border-right: 0.5px solid rgba(0, 0, 0, 0);
  }
  &__no-border {
    border: none !important;
  }
  &__start {
    text-align: start !important;
    padding-left: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    .prospace-tag {
      max-width: 100%;
    }
  }
  .sticky-left {
    height: 100%;
    width: 100%;
    min-width: 60px;
    max-width: 140px;
    position: sticky;
    background-color: var(--prospace-ui-bg);
    z-index: 10;
  }
}
</style>
