<template>
  <ProSpaceModal
    v-model="localModelValue"
    :title="$t('reqCreateUserHeader')"
    icon="user-plus"
    width="570"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="false"
    class="prospace-req-create-user-modal"
  >
    <ProSpacePanelBlockHeader
      :title="$t('reqCreateUserTopText')"
      :bottomTitle="$t('reqCreateUserBottomText')"
      :isNeedControls="false"
    />
    <ProSpaceTodoList :list="todoList" />
  </ProSpaceModal>
</template>
<script>
import {
  ProSpacePanelBlockHeader,
  ProSpaceTodoList,
} from "@prospace/prospace-components-library";
import { ProSpaceModal } from "@prospace/prospace-components-library";
export default {
  components: {
    ProSpaceModal,
    ProSpaceTodoList,
    ProSpacePanelBlockHeader,
  },
  computed: {
    todoList() {
      return [
        {
          header: this.$t("regOnPortal"),
          desc: this.$t("ifNotRegOnPortal"),
          linkText: this.$t("linkOnRegPortal"),
          link: "https://drive.google.com/file/d/1AmKIwvE18ebQl3mt6SlzWhvApCnGEBVl/view",
        },
        {
          header: this.$t("createReportOfProblem"),
          list: [
            this.$t("reqCreateSelectType"),
            this.$t("reqCreateSelectNextD"),
            this.$t("reqCreateSelectFill"),
            this.$t("reqCreateSelectComment"),
          ],
          linkText: this.$t("linkOnPortal"),
          link: "https://mars.intradesk.ru",
        },
        {
          header: this.$t("expAnswer"),
          desc: this.$t("reqCreateSendLogin"),
        },
      ];
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
  },
};
</script>
<style lang="scss">
.prospace-req-create-user-modal {
  .prospace-panel-block-header {
    padding: 15px 20px;
    &__title {
      white-space: normal;
    }
    &__bottom-title {
      white-space: normal;
    }
  }
}
</style>
