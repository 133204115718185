<template>
  <ProSpaceTree
    :searchPlaceholder="$t('searchTxt')"
    ref="tree"
    :service="recipientTreeService"
    searchName="label"
    getNodeMethod="search"
    :searchable="needSearch"
    :noOdata="true"
  >
    <template #counter="{ node }">
      <ProSpaceTag
        v-if="node.children ? node.children.length : false"
        :text="node.children ? node.children.length : false"
      />
    </template>
    <template #action="{ node }">
      <div class="recipient-tree-button">
        <ProSpaceIconButton
          v-if="node.children"
          icon="plus"
          type="primary"
          class="recipient-tree__add-btn"
          @click="openAddRecipientModal(node)"
        />
        <ProSpaceIconButton
          v-else
          icon="circle-close"
          @click="removeNode(node)"
        />
      </div>
    </template>
  </ProSpaceTree>
  <ProSpaceModal
    v-if="recipientNode"
    v-model="showModal"
    :title="`Admin ${recipientNode.label.toLowerCase()}`"
    :style="{
      width:
        recipientNode && recipientNode.label !== 'Custom' ? '90%' : '641px',
    }"
    :contentStyle="{ overflow: 'hidden', height: '750px' }"
    @apply="changeItem"
    class="recipient-modal"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
  >
    <NotificationUsers
      v-if="recipientNode.label === 'Users'"
      ref="notificationUsers"
    />
    <NotificationRoles
      v-else-if="recipientNode.label === 'Roles'"
      ref="notificationRoles"
    />
    <div v-else style="margin: 10px; padding-bottom: 80px">
      <ProSpaceVLayout :gap="30">
        <ProSpaceInputText
          label="Custom contact"
          v-model="customValue"
          placeholder=""
        />
        <ProSpaceSelectButton
          label="Notification Type"
          v-model="selectedNotifyTarget"
          :items="notificationTargets"
          multiple
        />
      </ProSpaceVLayout>
    </div>
  </ProSpaceModal>
</template>
<script>
import {
  ProSpaceInputText,
  ProSpaceModal,
  ProSpaceTree,
  ProSpaceTag,
  ProSpaceVLayout,
  ProSpaceSelectButton,
  ProSpaceIconButton,
} from "@prospace/prospace-components-library";
import NotificationUsers from "./NotificationUsers.vue";
import NotificationRoles from "./NotificationRoles.vue";
export default {
  components: {
    NotificationUsers,
    NotificationRoles,
    ProSpaceInputText,
    ProSpaceModal,
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceVLayout,
    ProSpaceSelectButton,
    ProSpaceIconButton,
  },
  data() {
    return {
      showModal: false,
      recipientTabPanel: { name: "To" },

      recipientNode: null,
      customValue: null,

      //help data
      notificationTargets: [
        { value: "Mail", label: "E-mail", icon: "mail" },
        // { value: "SMS", label: "SMS", icon: "sms"},
        // { value: "Telegram", label: "Telegram", icon: "telegram"}
      ],
      selectedNotifyTarget: ["Mail"],
    };
  },
  props: {
    recipientFilter: {
      type: Object,
      required: true,
    },
    recipientTreeService: {
      type: Object,
      required: true,
    },
    needSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  created() {
    if (this.recipientTreeService.data === null)
      this.recipientTreeService.set(this.recipientFilter);
  },
  methods: {
    changeTarget(target) {
      if (
        Object.values({ ...this.selectedNotifyTarget }).includes(target.value)
      )
        this.selectedNotifyTarget = this.selectedNotifyTarget.filter(
          (e) => e !== target.value
        );
      else this.selectedNotifyTarget.push(target.value);
    },
    openAddRecipientModal(node) {
      this.recipientNode = node;
      this.showModal = true;
    },
    removeNode(node) {
      let parsedKey = node.key.split("-");
      let parentNode = this.recipientTreeService.tree[parsedKey[0]].find(
        (e) => e.key === `${parsedKey[0]}-${parsedKey[1]}`
      );
      parentNode.children = parentNode.children.filter(
        (e) => e.key != node.key
      );
      this.$refs.tree.searchingNodes();
      // parentNode.counter = parentNode.children.length;
      this.$emit("updated", this.recipientTreeService.parseTree());
    },
    changeItem() {
      if (["Roles", "Users"].includes(this.recipientNode.label)) {
        let selected =
          this.$refs[`notification${this.recipientNode.label}`].selectedValues;
        let labelField =
          this.recipientNode.label === "Roles"
            ? (e) => (!!e.displayName ? e.displayName : e.systemName)
            : (e) => e.login;

        if (!Array.isArray(selected)) selected = [selected];
        let maxI = this.recipientNode.children.length;
        let childNodes = selected.map((e, i) => {
          return {
            key: `${this.recipientNode.key}-${maxI + i}`,
            label: labelField(e),
            data: e.id,
          };
        });
        this.recipientNode.children =
          this.recipientNode.children.concat(childNodes);
        // this.recipientNode.counter = this.recipientNode.children.length;
      } else {
        let maxI = this.recipientNode.children.length;
        let child = {
          key: `${this.recipientNode.key}-${maxI}`,
          label: this.customValue,
          data: this.customValue,
          targets: this.selectedNotifyTarget,
        };
        this.recipientNode.children.push(child);
        // this.recipientNode.counter = this.recipientNode.children.length;
        this.selectedNotifyTarget = [];
      }

      this.$emit("updated", this.recipientTreeService.parseTree());
      this.recipientNode = null;
      this.customValue = null;
    },
    getLength(arr) {
      if (!arr) return 0;

      return arr.length;
    },
  },
  computed: {
    recipientTabs() {
      return [
        {
          name: "To",
          count: this.recipientFilter.To
            ? this.getLength(this.recipientFilter.To.Custom) +
              this.getLength(this.recipientFilter.To.Roles) +
              this.getLength(this.recipientFilter.To.Users)
            : null,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
.recipient-tree-button {
  width: 20px;
  height: 20px;
  cursor: pointer;

  button {
    position: absolute;
    right: 0;
    margin-right: 5px;
  }
}

.recipient-modal {
  .p-dialog-content {
    padding: 0;
  }
}
</style>
