<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :txtResetFilters="$t('txtResetFilters')"
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        @apply="submitFilter"
        ref="filter-panel"
        filter-model="ActualModel/CompetenceDirectoryName"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="allCustomFilters"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="historyService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="single"
        defaultSortField="editDate"
        defaultSortOrder="-1"
        :defaultFilter="defaultFilter"
      >
        <template #columns>
          <Column
            field="ActualModel/CompetenceDirectoryName"
            :header="this.$t('nameL')"
            sortable
            headerClass="move-sort-head"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                isHtml
                :title="field.data.actualModel.CompetenceDirectoryName || `<span style='color: transparent;'>-</span>`"
              />
            </template>
          </Column>
          <Column field="operationType" :header="$t('operation')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.operationType }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="userFullName" :header="$t('user')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.userFullName }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="editDate" :header="$t('changeDateTime')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.editDate"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>


        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { CompetenceService } from "../../../services/CompetenceService";
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  components: {
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
  },
  props: {
    historyService: {
      type: Object,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: true,
    },
    historyCustomServices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      competenceService: new CompetenceService(),
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [];
    },
    allCustomFilters() {
      const historyCustomServices = _.cloneDeep(this.historyCustomServices);
      historyCustomServices[0].service.dataOperations = historyCustomServices[0].service.dataOperations.filter(i => i.operationType !== 'Deleted');
      return historyCustomServices.concat(this.customServices);
    },
  },
  methods: {
    getActualName(record) {
      return record.CompetenceDirectoryName;
    },
    getActualId(record) {
      return record.Id;
    },
    getActualStatus(record) {
      return this.getStatus(record.Status);
    },
    openFromReference(record, fieldName) {
      this.$emit("openFromReference", record, fieldName);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    selectedHandler(val) {
      this.$emit("selectedHandler", val);
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "draft":
          type = "default";
          break;
        case "new":
          type = "light-blue";
          break;
        case "inprogress":
          type = "warning";
          break;
        case "completed":
          type = "success";
          break;
        case "canceled":
          type = "default";
          break;
        default:
          type = "default";
      }
      return {
        text: this.$t(('competenceStatus' + status).toLowerCase()),
        type,
      };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model, this.defaultFilter);
      this.$refs["filter-panel"].submitFilter();
    },
  },
};
</script>
<style lang="scss">

</style>
