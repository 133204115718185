export class DashboardColorService {
  constructor() {
    const styles = getComputedStyle(document.documentElement);
    this.textColors = [
      { label: styles.getPropertyValue("--prospace-dashboard-text-black"), value: "--prospace-dashboard-text-black" },
      { label: styles.getPropertyValue("--prospace-dashboard-text-grey"), value: "--prospace-dashboard-text-grey" },
      { label: styles.getPropertyValue("--prospace-dashboard-text-white"), value: "--prospace-dashboard-text-white" },
      { label: styles.getPropertyValue("--prospace-dashboard-text-green"), value: "--prospace-dashboard-text-green" },
      { label: styles.getPropertyValue("--prospace-dashboard-text-orange"), value: "--prospace-dashboard-text-orange" },
      { label: styles.getPropertyValue("--prospace-dashboard-text-red"), value: "--prospace-dashboard-text-red" },
    ];
    this.backgroundColors = [
      { label: styles.getPropertyValue("--prospace-dashboard-background-green"), value: "--prospace-dashboard-background-green" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-orange"), value: "--prospace-dashboard-background-orange" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-light-red"), value: "--prospace-dashboard-background-light-red" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-green-secondary"), value: "--prospace-dashboard-background-green-secondary" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-yellow"), value: "--prospace-dashboard-background-yellow" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-red-secondary"), value: "--prospace-dashboard-background-red-secondary" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-grey"), value: "--prospace-dashboard-background-grey" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-light-blue"), value: "--prospace-dashboard-background-light-blue" },
      { label: styles.getPropertyValue("--prospace-dashboard-background-light-black"), value: "--prospace-dashboard-background-light-black" },
    ];
    this.graphColors = [
      { label: styles.getPropertyValue("--prospace-dashboard-graph-black"), value: "--prospace-dashboard-graph-black" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-light-blue"), value: "--prospace-dashboard-graph-light-blue" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-green"), value: "--prospace-dashboard-graph-green" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-turquoise"), value: "--prospace-dashboard-graph-turquoise" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-blue"), value: "--prospace-dashboard-graph-blue" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-orange"), value: "--prospace-dashboard-graph-orange" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-red"), value: "--prospace-dashboard-graph-red" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-pink"), value: "--prospace-dashboard-graph-pink" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-violet"), value: "--prospace-dashboard-graph-violet" },
      { label: styles.getPropertyValue("--prospace-dashboard-graph-grey"), value: "--prospace-dashboard-graph-grey" },
    ];
  }
  async getTextColors() {
    const data = {
      records: this.textColors,
    };
    return Promise.resolve({ data });
  }
  async getBackgroundColors() {
    const data = {
      records: this.backgroundColors,
    };
    return Promise.resolve({ data });
  }
  async getGraphColors() {
    const data = {
      records: this.graphColors,
    };
    return Promise.resolve({ data });
  }
}
