<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
  >
    <template v-if="selectedCount === 1" v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerOverview">
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList :list="content" />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="headerRoles">
            <template v-slot:header>
              <ProSpaceAction
                :text="`${$t('add')}`"
                @click="$emit('addRole')"
              />
            </template>
            <template v-slot>
              <ProSpaceSimpleGrid
                :headers="listHeadersRoles"
                prop-id="securityUserId"
                :service="securityUserRole"
                get-method="grid"
                :id="selectedData.id"
                :deletable="true"
                :bordered="true"
                :btnText="$t('viewMore')"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-if="selectedCount === 1" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-if="isMultipleView()" v-slot:center>
      <PanelBlockSelectedItems :items="[
          { name: $t('id'), value: 'id', f: (v) => v },
          { name: $t('name'), value: 'fullName', f: (v) => v },
        ]"
      />
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceSimpleGrid,
} from "@prospace/prospace-components-library";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import PanelBlockSelectedItems from "@multiselect/PanelBlockSelectedItems"
export default {
  name: "NotificationUserPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    PanelBlockSelectedItems
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    secondService: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData, selectedClude, selectedCount, isMultipleView } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData, selectedClude, selectedCount, isMultipleView
    }
  },
  data() {
    return {
      user: null,
      userRoles: null,
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerOverview() {
      return {
        title: this.$t("detailTxt"),
        isExpand: true,
      };
    },
    headerRoles() {
      return {
        title: this.$t("roles"),
        isExpand: false,
      };
    },
    listHeadersRoles() {
      return [
        { name: this.$t("name"), value: "securityRoleSystemName" },
        { name: this.$t("group"), value: "securityRoleDisplayName" },
      ];
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
        { name: this.$t("overview"), isActive: true, systemName: "overview" },
        { name: this.$t("roles"), systemName: "roles" },
      ];
    },
    header() {
      if (this.user) {
        return {
          title: this.user.firstName + " " + this.user.lastName,
          id: this.user.id,
          status: this.getStatus(this.user.status),
          options: [
            { name: "edit", text: this.$t("editItemTxt"), icon: "edit" },
            { name: "delete", text: this.$t("delete"), icon: "delete" },
          ],
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        counter: this.selectedCount,
        showCounter: true,
      };
    },
    content() {
      if (this.user) {
        return [
          [
            {
              title: this.$t("firstName"),
              text: this.user.firstName,
            },
            {
              title: this.$t("lastName"),
              text: this.user.lastName,
            },
          ],
          [
            {
              title: this.$t("email"),
              text: this.user.email,
              link: "mailto:" + this.user.email,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      if (this.user) {
        return [
          [
            {
              title: this.$t("createdByTxt"),
              text: this.user.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.user.updatedBy,
            },
          ],
          [
            {
              title: this.$t("createdAtTxt"),
              text: this.getFormatDateTime(this.user.createdAt)
            },
            {
              title: this.$t("lastUpdatedTxt"),
              text: this.getFormatDateTime(this.user.lastUpdated)
            },
          ],
        ];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async update() {
      this.hasError = false;
      try {
        this.user = await this.getUser();
        this.userRoles = await this.getUserRoles();
      } catch (e) {
        this.hasError = true;
      }
    },
    async getUser() {
      if (this.selectedCount === 1) {
        this.loading = true;
        const res = await this.service.get(this.selectedData.id);
        this.loading = false;
        return res.data;
      }
      return null;
    },
    async getUserRoles() {
      if (this.selectedCount === 1) {
        const res = await this.secondService.get(this.selectedData.id);
        return res.data;
      }
      return null;
    },
    getStatus(status) {
      if (!status) return;
      return {
        type: status === "ACTIVE" ? "success" : "danger",
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
      };
    },
    async deleteRole(role) {
      await this.secondService.delete(role);
      this.update();
    },
    clearPanel() {
      this.$emit('clear')
    }
  },
  watch: {
    selectedData() {
      this.update();
    },
    selectedCount() {
      this.update();
    }
  },
};
</script>

<style scoped></style>
