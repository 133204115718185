import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DashboardService/DashboardApi";

export class DashboardService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
  }
  async setPart(fields) {
    return await this.transformer.setFormPart(this.Api, "editDashboard", fields);
  }
  async set() {
    return await this.transformer.setForm(this.Api, "editDashboard");
  }
  async grid(args) {
    return await this.transformer.getGrid(this.Api, "getDashboards", args);
  }
  async get(id) {
    return await this.transformer.getGridPanel(
      this.Api,
      "getDashboard",
      null,
      id
    );
  }
  async tree(args) {
    let result = await this.transformer.getTree(this.Api, "getDashboards", args);
    const data = result.data.map((i) => {
      i.leaf = true;
      return i;
    });
    return Promise.resolve({ data });
  }
  async treeWithAll(args) {
    let result = await this.tree(args);
    if (result.data) {
      let all = [
        {
          id: 0,
          leaf: true,
          name: "Все",
          code: "all",
          unreadCounter: 0,
          icon: "note-sticky",
        },
      ];
      result.data = all.concat(result.data);
    }

    return result;
  }
  async treeWithAllWithoutIcon(args) {
    let result = await this.tree(args);
    if (result.data) {
      let all = [
        {
          id: 0,
          leaf: true,
          name: "Все",
          code: "all",
          unreadCounter: 0
        },
      ];
      result.data = all.concat(result.data);
    }

    return result;
  }
  async create(model) {
    return await this.transformer.createItem(this.Api, "createDashboard", model);
  }
  async delete(model) {
    return await this.transformer.deleteItem(this.Api, "deleteDashboard", model);
  }
  async update(model) {
    return await this.transformer.editItem(this.Api, "editDashboard", model);
  }
  async getIcons() {
    let icons = icon_arr.map((icon) => {
      return { label: icon, value: icon };
    });
    icons.splice(0, 0, { label: "default", value: "note-sticky" });
    const data = {
      records: icons,
    };
    return Promise.resolve({ data });
  }
}
