<template>
  <ProSpaceGeoLayout class="notifications" :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="layoutName">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxtT')"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            @apply="submitFilter"
            :unicKeyStorage="notificationService.constructor.name + 'grid'"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear();
              }
            "
            ref="filterPanel"
            filter-model="name"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.isActive"
                :service="statusService"
                getMethod="get"
                optionLabel="text"
                optionValue="isActive"
                :selectAllTxt="$t('selectAllTxt')"
                :applyTxt="$t('applyTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('status')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="$refs['filter-panel'].submitFilter()"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="notificationService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="notificationService"
            :noMountedRequest="true"
            selectionMode="single"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    style="cursor: pointer"
                    @click-on-title="toItem(field.data)"
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.isActive)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                  />
                </template>
              </Column>
              <Column field="startDate" :header="$t('activityPeriod')" sortable>
                <template #body="field">
                  <ProSpaceDatesColumn
                    :start-date="field.data.startDate"
                    :end-date="field.data.endDate"
                    :format="userinfo.dateFormat"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('created')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="createdBy" :header="$t('createdByTxt')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.createdBy }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="empty-grid"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <NotificationPanel
        ref="right-panel"
        v-if="hasSelect"
        :service="notificationService"
        :secondService="recipientTreeService"
        :options="dotsOptions"
        @overview-edit="editItem"
        @edit="editItem"
        @delete-item="deleteItem"
        @history-item="showHistory"
        @clear="selectedMultipleClear"
      />
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          name: { required },
          startDate: { required },
          endDate: { required },
        }"
      >
        <template #title>
          {{ isEdit ? $t("editItemTxt") : "" }}
          {{ isCreate ? $t("createItemTxt") : "" }}
          {{ $t("notificationLower") }}
          {{ detailName }}
        </template>
        <template #default="{ v }">
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              :label="$t('name')"
              v-model="modalModel.name"
              :placeholder="$t('notificationLower')"
              :invalid="v.name.$error"
              :invalid-messages="v.name.$errors"
              @blur="v.name.$touch"
            />
            <ProSpaceInputText
              :label="$t('description')"
              v-model="modalModel.description"
              :placeholder="$t('description')"
              :is-textarea="true"
            />
            <ProSpaceDatePeriod
              :label="$t('activityPeriodF')"
              :model="modalModel"
              startDate="startDate"
              endDate="endDate"
              :dateFormat="userinfo.dateFormat"
              :validStart="v.startDate"
              :validEnd="v.endDate"
            />
          </ProSpaceVLayout>
        </template>
      </ProSpaceModal>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="NotificationApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteNotification', { name:  deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceInputText,
  ProSpaceDatePeriod,
  ProSpaceDatesColumn,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ProSpaceModal,
  event_bus,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceRightPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceFilterPanel,
  ProSpaceDots,
  ProSpaceEmptyGrid,
  ProSpaceVLayout,
  ProSpaceDefaultTextColumn,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import GridModalForm from "./gw.vue";
import Column from "primevue/column";
import NotificationPanel from "./components/NotificationPanel.vue";
import { NotificationService } from "../../services/NotificationService.js";
import { StatusService } from "../../services/StatusService.js";
import { RecipientTreeService } from "../../services/RecipientTreeService.js";
import RecipientTree from "./RecipientTree.vue";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceInputText,
    GridModalForm,
    ProSpaceDatePeriod,
    RecipientTree,
    ProSpaceDatesColumn,
    ProSpaceMainGrid,
    ProSpaceModal,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceRightPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    NotificationPanel,
    ProSpaceFilterPanel,
    ProSpaceDots,
    ProSpaceVLayout,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      v$: useVuelidate(), grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
    };
  },
  validations() {
    return {
      modalModel: {
        name: { required },
        startDate: { required },
        endDate: { required },
      },
    };
  },
  data() {
    return {
      showHistoryModal: false,
      showModal: false,
      // Grid Panel
      emitter: event_bus,
      panelModel: {},
      panelModels: [],
      tabCountField: "roles",

      // Service
      notificationService: new NotificationService(),
      recipientTreeService: new RecipientTreeService(),
      statusService: new StatusService(),

      // Modal
      modalModel: {
        name: "",
        description: "",
        startDate: null,
        endDate: null,
      },
      isEdit: false,
      isCreate: false,

      //InlinePanel
      masterId: null,

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    getStatus(status) {
      if (status) {
        return {
          text: "Active",
          type: "success",
        };
      } else {
        return {
          text: "Not active",
          type: "default",
        };
      }
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    updateRecipintFilter(data, model) {
      model.recipientFilter = JSON.stringify(data);
      this.notificationService.setModel(model);
    },
    toItem(data) {
      const url = `/notifications/notification/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    afterLoadHanlder(model) {
      if (typeof model == "object") {
        this.panelModel = model;
      } else {
        this.panelModels = model;
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    spToggle() {
      this.spHide = !this.spHide;
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        name: "",
        description: "",
        startDate: null,
        endDate: null,
      };
      this.showModal = true;
    },

    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "edit";
      }
      this.notificationService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.notificationService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.grid.selectedModel = this.grid.selectedModel.filter(i => i.id !== this.deletingModel.id)
        this.deletingModel = null;
      });
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    sendGridModal(model, mappingModel) {
      this.securityUserRole.create(model, mappingModel).then(() => {
        this.actionUpdate();
      });
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    isDeletedTab() {
      return this.modelFastFilter.label === this.$t("deleted");
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          field: "disabled",
          value: false,
        },
        { label: this.$t("active"), field: "isActive", value: true },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    layoutName() {
      return this.$t("notifications");
    },
  },
};
</script>
