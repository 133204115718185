<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="$t('reports')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            v-if="checkAccess('add-button')"
            type="primary"
            icon="plus"
            :label="this.$t('reportNew')"
            @click="
              $router.push({
                path: `/report/report`,
                query: {
                  id: this.selectedNode ? this.selectedNode.id : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            ref="filterPanel"
            :fastFilters="fastFilters"
            :modelFastFilter="modelFastFilter"
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :filter-model="['code', 'name']"
            :unicKeyStorage="reportService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear();
              }
            "
          >
            <template #hint>
              {{ $t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="reportService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>

          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :service="reportService"
            :noMountedRequest="true"
            :defaultSortOrder="-1"
            @selected-change="selectedHandler"
            @db-click="toItem"
            @loaded="setLoadedReports"
          >
            <template #columns>
              <Column
                field="powerBIHref"
                :header="this.$t('reportPowerBIHref')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :img-src="field.data.picFileContent"
                    width-image="232px"
                    height-image="138px"
                    align-items="center"
                    show-image
                    @click-on-img="openLink(field.data)"
                  />
                </template>
              </Column>
              <Column field="name" :header="$t('reportName')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    :title="field.data.name"
                    :description="field.data.description"
                    :id="field.data.id"
                    :descriptionOverflow="false"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="instructionGFID" :header="$t('instruction')" sortable>
                <template #body="field">
                  <ProSpaceVLayout justify-content="center" v-if="field.data.instructionGFID">
                    <ProSpaceAction
                      :text="$t('instruction')"
                      @click.stop="handleInstructionClick(field.data)"
                    />
                  </ProSpaceVLayout>
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-90">
                <template #body="field">
                  <ProSpaceHLayout justify-content="flex-end" :gap="10">
                    <ProSpaceIconsGrid
                      isBookmarkExist
                      :bookmarkHint="field.data.isImportant ? $t('bookmarkDeleteHint') : $t('bookmarkHint')"
                      :isBookmarkActive="
                        isImportantInMount
                          ? field.data.isImportant
                          : checkImportantInMount(field.data.id)
                      "
                      @bookmarkEvt="setImportant(field.data)"
                    />
                    <ProSpaceDots
                      :options="localWrapperCheckRoles(dotsOptions, field.data)"
                      @action="(action) => handleDots(action, field.data)"
                    ></ProSpaceDots>
                  </ProSpaceHLayout>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                v-if="isEmptyGrid"
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <ReportPanel
        v-if="hasSelect"
        ref="right-panel"
        :reserve="selectedData"
        :options="dotsOptions"
        :filterAccessDots="localWrapperCheckRoles"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      type="default"
      icon="flash-warning"
      :title="$t('deletingContactUser')"
      :applyTxt="$t('confirmTxt')"
      :cancelTxt="$t('reject')"
      @apply="applyDelete"
    >
      <ProSpaceVLayout :gap="10">
        <div
          class="text-color-gray text-bold"
          style="padding: 0 5px"
          v-html="$t('deleteReport', { name: deletingItem.name })"
        ></div>
      </ProSpaceVLayout>
    </ProSpaceFlashMessage>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceHLayout,
  ProSpaceTopPanel,
  ProSpaceButton,
  ProSpaceIconButton,
  ProSpaceFilterPanel,
  ProSpaceFilterSearchfield,
  ProSpaceMainGrid,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceDateTimeColumn,
  ProSpaceFilterDateTimeRange,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceDots,
  ProSpaceEmptyGrid,
  ProSpaceIconsGrid,
  ProSpaceFlashMessage,
  ProSpaceVLayout,
  ProSpaceAction
} from "@prospace/prospace-components-library";
import { mapGetters } from "vuex";
import ReportPanel from "./components/ReportPanel.vue";
import { ReportService } from "../../services/ReportService.js";
import { ReportImportantService } from "../../services/ReportImportantService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js"
import Column from "primevue/column";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  name: 'Reports',
  mixins: [accessMixin],
  components: {
    ProSpaceGeoLayout,
    ProSpaceHLayout,
    ProSpaceTopPanel,
    ProSpaceButton,
    ProSpaceIconButton,
    ProSpaceFilterPanel,
    ProSpaceFilterSearchfield,
    ReportPanel,
    ProSpaceMainGrid,
    Column,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFilterDateTimeRange,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceDots,
    ProSpaceEmptyGrid,
    ProSpaceIconsGrid,
    ProSpaceFlashMessage,
    ProSpaceVLayout,
    ProSpaceAction,
  },
  data() {
    return {
      reportService: new ReportService(),
      reportImportantService: new ReportImportantService(),
      fileService: new FileService(),
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: [
          { field: "disabled", value: false }
        ]
      },

      activeReserve: null,
      loaded: false,

      importantSaver: null,
      isImportantInMount: false,
      isImportantLocalState: [],
      isImportantSaveRun: false,

      deletingItem: null,
      showDeleteModal: false,

      selectedModel: null,
    }
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect, selectedData
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      return [
        {
          type: "chips",
          model: "name",
          label: this.$t("reportNameContains"),
          selected: {},
        },
        {
          type: "chips",
          model: "description",
          label: this.$t("reportDescriptionContains"),
          selected: {},
        },
        {
          service: this.reportService,
          getMethod: "instructionGFIDOptions",
          optionValue: "value",
          optionLabel: "text",
          type: "checkboxes",
          model: "hasInstruction",
          label: this.$t("instruction"),
          selected: {},
          needSelectAll: true,
        },
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      ]
    },
    dotsOptions() {
      const options = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.openItem,
          liteRef: "edit-option",
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
          liteRef: "delete-option",
        },
      ]

      if (this.modelFastFilter.label === this.$t("deleted"))
        return options.filter(o => o.name !== 'delete' && o.name !== "edit");
      else return options;
    },
    isEmptyGrid() {
      return !this.isLoaded;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: [
            { field: "disabled", value: false },
          ]
        },
        {
          label: this.$t("checkeds"),
          value: [
            { field: "disabled", value: false },
            { field: "isImportant", value: true }
          ]
        },
        {
          label: this.$t("deleted"),
          value: [
            { field: "disabled", value: true }
          ],
          isHidden: !this.checkDeleteAccess()
        },
      ];
    }
  },
  methods: {
    setLoadedReports(e) {
      if (e?.length === 0) {
        this.isLoaded = false;
      }
      this.isLoaded = true;
      this.isImportantInMount = true;
      this.isImportantLocalState = [...this.grid.records];
    },
    checkImportantInMount(id) {
      const test = this.isImportantLocalState.find((elem) => elem.id === id);
      return test.isImportant;
    },
    setImportant(report) {
      this.isImportantInMount = false;

      this.isImportantLocalState.forEach((el) => {
        if (el.id === report.id) {
          el.isImportant = !el.isImportant;
        }
      });
      let importantUpdates = this.getUpdatedImportantReports();

      if (this.checkNewImportantSet(report, importantUpdates)) {
        importantUpdates.push({
          reportId: report.id,
          securityUserCode: this.userinfo.code,
          isImportantSet: report.isImportant,
        });
      } else {
        importantUpdates = importantUpdates.filter(
          (x) => x.reportId != report.id
        );
      }

      localStorage["important reports"] = JSON.stringify(importantUpdates);
    },
    checkNewImportantSet(report, importantUpdates) {
      return !importantUpdates.some((x) => x.reportId == report.id);
    },
    saveIsImportant(isBackground) {
      if (this.isImportantSaveRun)
        return new Promise((resolve, reject) => {
          resolve();
        });

      if (!isBackground) this.loadingHandler(true);

      let importantUpdates = this.getUpdatedImportantReports();
      if (importantUpdates.length === 0)
        return new Promise((resolve, reject) => {
          resolve();
        });

      this.isImportantSaveRun = true;

      const toCreate = importantUpdates.filter((x) => x.isImportantSet);
      const toDelete = importantUpdates.filter((x) => !x.isImportantSet);

      let promises = [];

      if (toCreate.length > 0)
        promises.push(this.reportImportantService.create(toCreate));
      if (toDelete.length > 0)
        promises.push(this.reportImportantService.delete(toDelete));

      return Promise.all(promises)
        .then(() => {
          let importantUpdatesAfter = this.getUpdatedImportantReports();
          importantUpdatesAfter = importantUpdatesAfter.filter(
            (x) =>
              !importantUpdates.some(
                (u) =>
                  u.reportId == x.reportId &&
                  u.securityUserCode == x.securityUserCode
              )
          );
          localStorage["important reports"] = JSON.stringify(
            importantUpdatesAfter
          );

          if (!isBackground) this.loadingHandler(false);

          this.isImportantSaveRun = false;
        })
        .catch(() => {
          if (!isBackground) this.loadingHandler(false);

          this.isImportantSaveRun = false;
        });
    },
    getUpdatedImportantReports() {
      let importantReports = localStorage["important reports"];
      return importantReports ? JSON.parse(localStorage["important reports"]) : [];
    },
    openItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    toItem(data) {
      if (!data || !this.checkFormAccess(data)) return;
      const url = `/report/report/${data.id}`
      this.$router.push(url);
    },
    deleteItem(model) {
      this.deletingItem = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.reportService.delete(this.deletingItem).then(() => {
        this.selectedModel = null;
        this.deletingItem = null;
        this.actionUpdate();
      });
    },
    handleDots(action, item) {
      const option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    clear() {
      this.selectedModel = {};
      this.$refs.grid.selectedModel = [];
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.$refs["filterPanel"].submitFilter();
    },
    submitFilter({ filter, search }) {
      this.saveIsImportant().then(() => this.$refs.grid.submitFilter(filter, search));
    },
    actionUpdate() {
      this.saveIsImportant().then(() => {
        this.$refs.grid.getRecords(true);
      })
    },
    openLink(data) {
      window.open(data.powerBIHref, '_blank');
    },
    handleInstructionClick(report) {
      this.fileService.getFileToken(report.instructionGFID).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    localWrapperCheckRoles(dotsOptions, data) {
       return this.wrapperCheckRoles(dotsOptions, data)
    },
  },
  mounted() {
    this.loadRoleAccess();
    window.addEventListener("beforeunload", this.beforeUnload);
    this.saveIsImportant(true);
    this.importantSaver = setInterval(() => this.saveIsImportant(true), 10000);
  },
  beforeUnload(event) {
    this.saveIsImportant();
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    clearInterval(this.importantSaver);
  },
  beforeRouteLeave() {
    this.saveIsImportant();
  },
}
</script>

<style>

</style>
