<template>
  <ProSpaceRightPanel
    v-if="ids.length > 0"
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="$emit('edit', taskassignment)"
    @delete="$emit('delete', taskassignment)"
    @show-history="$emit('history', taskassignment)"
    @cancel-task="$emit('cancel', taskassignment)"
  >
    <template v-if="ids.length === 1" v-slot:center>
      <ProSpaceTabs v-model="modelTab" :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock v-if="taskassignment" :header="headerOverview">
            <template v-slot>
              <div class="prospace-panel-block-list">
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('type')"
                    :text="taskassignment.typeName"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('description')"
                    :text="taskassignment.description"
                    is-html
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('finishedAt')"
                    :text="new Date(taskassignment.endAt)"
                    :date-format="`${userinfo.dateFormat}`"
                  />
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('documentAttachment')"
                  >
                    <template #content>
                      <ProSpaceAction
                        v-for="(attachment, i) in successedAttachments"
                        icon-width="14px"
                        icon-height="14px"
                        icon="paperclip"
                        :key="i"
                        :text="attachment.fileName"
                        @click="downloadAttachment(attachment)"
                      />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
                <div class="prospace-panel-block-list__row">
                  <ProSpacePanelBlockItem
                    class="prospace-panel-block-list__item"
                    :title="$t('tags')"
                  >
                    <template #content>
                      <ProSpaceTagsColumn :tags="tags" :limit="2" />
                    </template>
                  </ProSpacePanelBlockItem>
                </div>
              </div>
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpacePanelBlock 
              v-if="isFilial || checkAccess('tab-distributors-ProSpaceTabs', taskassignment)" 
              :header="headerDistributors">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="
                    !isFilial &&
                    !taskassignment.lock &&
                    !taskassignment.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      taskassignment
                    )
                  "
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditDistributorsModal"
                />
              </template>
              <template v-slot>
                <ProSpaceAction
                  v-if="!taskassignment.isDistributorStructureRestricted && taskassignment.isAllDistributors"
                  :text="`${$t('allTxt')} ${$t('distributors')}`"
                  @click="showEditDistributorsModalReadOnly"
                />
                <ProSpaceSimpleGrid
                  v-if="
                    !taskassignment.isDistributorStructureRestricted &&
                    !taskassignment.isAllDistributors &&
                    taskassignment.distributorIds &&
                    taskassignment.distributorIds.length > 0
                  "
                  ref="distributorGrid"
                  getMethod="grid"
                  :headers="distributorGridHeaders"
                  :service="distributorService"
                  :limit="5"
                  :btnText="$t('viewMore')"
                  :defaultModal="false"
                  :defaultFilter="distributorGridFilter"
                  :deletable="
                    !taskassignment.lock &&
                    !taskassignment.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      taskassignment
                    )
                  "
                  deletingFieldName="name"
                  :deleteMethod="null"
                  :confirmDelete="true"
                  confirmIcon="flash-success"
                  :confirmTitle="$t('deletingContactUser')"
                  :confirmText="$t('questionDeleting')"
                  :afterConfirmText="$t('questionDeletingList')"
                  @delete="deleteDistributor"
                  @openModal="showEditDistributorsModalReadOnly"
                />
                <ProSpaceEmptyGrid style="min-height: 283px;"
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                  v-if="!isFilial &&  taskassignment.distributorIds && taskassignment.distributorIds.length === 0"/>
                <ProSpaceSimpleGrid
                  v-if="taskassignment.isDistributorStructureRestricted"
                  ref="distributorGrid"
                  :items="taskassignment.distributorStructures"
                  :headers="distributorStructureHeaders"
                  :bordered="true"
                  :defaultModal="false"
                  :deletable="
                    !taskassignment.disabled &&
                    checkStatusAccess(
                      'action-delete-ProSpaceSimpleGrid',
                      taskassignment
                    )
                  "
                  :btnText="$t('viewMore')"
                  showViewAll
                  @delete="deleteTaskAssignmentDistributorStructure"
                  @openModal="showDistrStructureModalReadOnly"
                />
                <DistributorSelectModal
                  v-model="editDistributorModal"
                  :modalTitle="distributorSelectTitle"
                  :selectedIds="taskassignment.distributorIds"
                  :needAllQuestion="!readOnlyDistributorsModal"
                  :readOnly="readOnlyDistributorsModal"
                  :selectionMode="readOnlyDistributorsModal ? 'single' : 'multiple'"
                  :isAll="taskassignment.isAllDistributors"
                  :defaultFilter="distributorGridFilter"
                  :allSelectionText="$t('isAllDistributorsSelectionTask')"
                  @applyDistributors="applyEditDistributor"
                />
                <DistributorHierarchySelectModal
                  v-if="taskassignment.isDistributorStructureRestricted"
                  v-model="visibleDistrStructureModal"
                  expandAll
                  :getMethod="getMethodDistrStructureModal"
                  :readOnly="readOnlyDistrStructureModal"
                  :titleModal="$t('distributorStructure')"
                  :service="distributorStructureService"
                  :checkedRows="taskassignment.distributorStructures"
                  @apply="applyDistributorStructure"
                />
            </template>
            </ProSpacePanelBlock>
            <ProSpacePanelBlock v-if="taskassignment.isPositionsRestricted" :header="headerPositions">
              <template v-slot:header>
                <ProSpaceAction
                  v-if="!taskassignment.disabled
                  && checkAccess('position-PositionSelectTree', taskassignment)
                  && checkStatusAccess('position-PositionSelectTree', taskassignment)
                  && checkEditFieldAccess('positions')
                "
                  :text="`${$t('editItemTxt')}`"
                  @click="showEditPositionsModal"
                />
              </template>
              <template v-slot>
                <PositionSelectTree
                  ref="positionTree"
                  positionGetMethod="getPositions"
                  positionSelectionMode="single"
                  propLabel="distributorOrgName"
                  expandAll
                  selectLastNodes
                  :positionService="taskAssignmentService"
                  :positionFilter="{ disabled: { and: [false] }, taskAssignmentId: taskassignment.id }"
                />
                <ProSpaceModal
                  v-model="positionsModal"
                  icon="parameters"
                  ref="positionsModal"
                  width="720"
                  height="420"
                  :title="`${$t('performers')}`"
                  :cancelTxt="$t('closeTxt')"
                  :applyTxt="$t('applyTxt')"
                  @apply="applyEditPositions"
                  @cancel="cancelEditPositions"
                  smallPadding
                >
                  <ProSpaceGeoLayout>
                    <template #center>
                      <PositionSelectTree class="bg-color-gray-card-bg padding-10"
                        positionGetMethod="allTree"
                        positionSelectionMode="checkbox"
                        propLabel="name"
                        expandAll
                        :positionService="distributorOrgService"
                        :checkedPositions="positions"
                        @changed="(newPositions) => { this.positions = [...newPositions] }"
                        @selectedItem="selectedHandlerTree"
                      />
                    </template>
                    <template #right>
                      <ProSpaceRightPanel class="padding-top-10" style="border: var(--prospace-ui-border)">
                        <template #center>
                          <ProSpacePanelBlock
                            :header="{ title: $t('parameters'), isExpand: false }"
                          >
                            <ProSpacePanelBlockList :list="getPositionRightPanel" />
                          </ProSpacePanelBlock>
                        </template>
                      </ProSpaceRightPanel>
                    </template>
                  </ProSpaceGeoLayout>
                </ProSpaceModal>
              </template>
            </ProSpacePanelBlock>
          </ProSpaceVLayout>
        </template>
        <template v-slot:tab-3>
          <ProSpacePanelBlock :header="headerRecipients">
            <template v-slot:header>
              <ProSpaceAction
                v-if="
                  !taskassignment.lock &&
                  !taskassignment.disabled &&
                  checkStatusAccess(
                    'action-delete-ProSpaceSimpleGrid',
                    taskassignment
                  )
                "
                :text="`${$t('editItemTxt')}`"
                @click="showEditRecipientsModal"
              />
            </template>
            <template v-slot>
              <ProSpaceSimpleGrid
                ref="recipientGrid"
                getMethod="simple"
                :headers="recipientGridHeaders"
                :service="recipientService"
                :limit="7"
                :btnText="$t('viewMore')"
                :deletable="
                  !taskassignment.lock &&
                  !taskassignment.disabled &&
                  checkStatusAccess(
                    'action-delete-ProSpaceSimpleGrid',
                    taskassignment
                  )
                "
                :defaultModal="false"
                :confirmDelete="true"
                confirmIcon="flash-success"
                :confirmTitle="$t('deletingContactUser')"
                :confirmText="$t('questionDeleting')"
                :afterConfirmText="$t('questionDeletingList')"
                deletingFieldName="email"
                @delete="deleteFromFilter"
                @openModal="showRecipientsModal"
              />
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="editRecipientModal"
                node-method="onlyMars"
                @close="applyEditRecipient"
              />
              <ProSpaceModal
                v-model="recipientModal"
                icon="message"
                ref="recipientModal"
                :title="`${$t('notifications')}`"
                :cancelTxt="$t('closeTxt')"
                :applyTxt="null"
                :contentStyle="modalContentStyle"
              >
                <ProSpaceGeoLayout>
                  <template #center>
                    <ProSpaceSimpleGrid
                      ref="recipientGrid"
                      getMethod="simple"
                      :headers="recipientGridModalHeaders"
                      :service="recipientService"
                      :limit="50"
                      :btnText="$t('viewMore')"
                      :deletable="false"
                      :deleteMethod="null"
                    />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceModal>
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-if="ids.length === 1" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpacePanelBlockItem,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceTagsColumn,
  ProSpaceVLayout,
  ProSpaceEmptyGrid
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import PositionSelectTree from "@trees/PositionSelectTree";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal";
import statusAccessMixin from "../../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import editFieldMixin from "../../../../../../Frontends/Common/utils/elementAccess/editFieldMixin";
import { TaskAssignmentService } from "../../../services/TaskAssignmentService";
import { TaskAssignmentRecipientService } from "../../../services/TaskAssignmentRecipientService";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { FileService } from "../../../../../../Frontends/ProSpace/services/FileService.js";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useStatistic
} from "@composes"
export default {
  mixins: [statusAccessMixin, editFieldMixin],
  name: "TaskAssignmentPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceModal,
    ProSpaceGeoLayout,
    ProSpaceTabs,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpacePanelBlockItem,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceTagsColumn,
    OrgStructureSelectModal,
    DistributorSelectModal,
    DistributorHierarchySelectModal,
    PositionSelectTree,
    ProSpaceVLayout,
    ProSpaceEmptyGrid
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    ids: {
      type: Array,
      required: true,
    },
    filterAccessDots: {
      type: Function,
    },
    checkAccess: {
      type: Function,
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions
    }
  },
  data() {
    return {
      taskassignment: {},
      editFieldModel: {},
      selectedOrgPosition: null,
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      taskAssignmentService: new TaskAssignmentService(),
      recipientService: new TaskAssignmentRecipientService(),
      distributorService: new DistributorService(),
      distributorOrgService: new DistributorOrgService(),
      distributorStructureService: new DistributorStructureService(),
      fileService: new FileService(),
      loading: false,
      hasError: false,
      recipientModal: false,
      positionsModal: false,
      persistRecipients: true,
      editRecipientModal: false,
      editDistributorModal: false,
      readOnlyDistributorsModal: false,
      visibleDistrStructureModal: false,
      readOnlyDistrStructureModal: true,
      distributorSelectTitle: "",
      getMethodDistrStructureModal: "getTaskAssignmentViewTree",
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      recipientGridHeaders: [
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          width: 90,
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      recipientGridModalHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridModalHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],
      distributorStructureHeaders: [
        { name: this.$t("distributor"), value: "distributorStructureViewDistributorName" },
        { name: this.$t("branch"), value: "distributorStructureViewName" },
      ],
      modalContentStyle: {
        overflow: "hidden",
        height: "calc(95vh - 180px)",
        width: "95vw",
      },
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    isFilial() {
      return this.taskassignment.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.taskassignment);
    },
    statusAccessConfig() {
      return {
        "action-delete-ProSpaceSimpleGrid": this.recipientGridStatusConfig,
        "addRecipient-button": this.recipientGridStatusConfig,
        "position-PositionSelectTree": this.recipientGridStatusConfig,
      }
    },
    recipientGridStatusConfig() {
      let config = {
        "Distributor user": "NONE",
        "Distributor manager": "NONE",
        "Mars user": "NONE",
        "Mars manager": "NONE",
        "Content manager": {
          creation: "CREATOR_OR_NONE",
          taskDraft: "CREATOR_OR_NONE",
          taskModer: "CREATOR_OR_NONE",
          other: "NONE",
        },
        "Support administrator": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "NONE",
        },
        "Technical Support": {
          other: "ALIVE",
        },
        "Content manager plus": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "NONE",
        },
      };

      // disable distr edit for task series after publication
      let afterPublish = !["taskModer", "taskDraft"].includes(
        this.taskassignment.status
      );
      if (afterPublish === true && this.taskassignment.needRepeat === true) {
        delete config["Support administrator"].taskPublished;
        delete config["Content manager plus"].taskPublished;
        config["Technical Support"].taskPublished = "NONE";
      }
      return config;
    },
    successedAttachments() {
      return this.attachments.filter(x => x.statusFlag === 0);
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=taskAssignmentId eq ${Number(
          this.taskassignment.id
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorGridFilter() {
      return { id: { or: this.taskassignment.distributorIds } };
    },
    headerOverview() {
      return {
        title: this.$t("taskAssignment"),
        isExpand: true,
      };
    },
    headerRecipients() {
      return {
        title: this.$t("recipients"),
        isExpand: true,
      };
    },
    headerDistributors() {
      return {
        title: this.taskassignment.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
        isExpand: true,
      };
    },
    headerPositions() {
      return {
        title: this.$t("performers"),
        isExpand: true,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      if (!this.taskassignment) return [];

      let isHidden = [
        "taskWaitPublish"
      ].includes(this.taskassignment.status);

      const tabs = [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("recipients"),
          systemName: "distributors",
          isHidden: !this.isFilial && !(this.taskassignment.isPositionsRestricted || this.checkAccess("tab-distributors-ProSpaceTabs", this.taskassignment)),
          isActive: this.modelTab.systemName === "distributors",
        },
        {
          name: this.$t("notifications"),
          systemName: "notifications",
          isActive: this.modelTab.systemName === "notifications",
          isHidden: !this.checkAccess("tab-notifications-ProSpaceTabs", this.model),
        },
      ];
      if (!tabs.some(i => i.isActive)) {
        tabs[0].isActive = true
        this.modelTab = tabs[0]
      }
      return tabs
    },
    tags() {
      return this.taskassignment.tags
        ? this.taskassignment.tags.split("#")
        : [];
    },
    attachments() {
      return this.taskassignment.attachments ?? [];
    },
    header() {
      if (this.taskassignment) {
        return {
          title: this.taskassignment.name,
          id: this.taskassignment.typeName,
          status: this.getStatus(this.taskassignment.status),
          options: this.getAccessDots(this.options),
          icon: this.getNeedRepeatIcon(this.taskassignment.needRepeat),
          iconColored: true,
          iconHint: this.$t("repeatHint"),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        counter: this.ids.length,
        showCounter: true,
      };
    },
    contentBottom() {
      let metaInfo = [];
      if (this.taskassignment) {
        if (this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.taskassignment.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.taskassignment.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.taskassignment.createdAt) + ' ' + getAMorPM(this.taskassignment.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.taskassignment.lastUpdated) + ' ' + getAMorPM(this.taskassignment.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
    getPositionRightPanel() {
      if (this.selectedOrgPosition) {
        const pos = [
          {
            title: this.$t("position"),
            text: this.selectedOrgPosition.name,
          },
        ];
        const parentPos = [
          {
            title: this.$t("parentPosition"),
            text: this.selectedOrgPosition.parentName,
          },
        ];
        return this.selectedOrgPosition.parentName ? [parentPos, pos] : [pos];
      }
      return [];
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    getNeedRepeatIcon(repeat) {
      if (!repeat) return;
      return "repeat";
    },
    getAccessDots(options) {
      return this.filterAccessDots
        ? this.filterAccessDots(options, this.taskassignment)
        : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getTaskAssignment();
      } catch (e) {
        this.hasError = true;
      }
    },
    showRecipientsModal() {
      this.recipientModal = true;
    },
    showEditRecipientsModal() {
      this.editRecipientModal = true;
    },
    showEditDistributorsModal() {
      if(this.taskassignment.isDistributorStructureRestricted) {
        this.readOnlyDistrStructureModal = false;
        this.getMethodDistrStructureModal = "allTree";
        this.$nextTick(() => {
          this.visibleDistrStructureModal = true;
        })
      } else {
        this.distributorSelectTitle = this.$t('selectDistributors');
        this.readOnlyDistributorsModal = false;
        this.$nextTick(() => {
          this.editDistributorModal = true;
        })
      }
    },
    showEditDistributorsModalReadOnly() {
      this.distributorSelectTitle = this.$t('listDistributors');
      this.readOnlyDistributorsModal = true;
      this.$nextTick(() => {
        this.editDistributorModal = true;
      })
    },
    applyEditRecipient() {
      this.nodeFilter = { ...this.$refs.filterModal.nodeFilter };
      this.gridFilter = { ...this.$refs.filterModal.gridFilter };
      this.selectedTreeNodes = {
        ...this.$refs.filterModal.$refs.tree.checkedNodes,
      };
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.taskassignment.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.taskassignment.nodeSelection = JSON.stringify(
        this.selectedTreeNodes
      );
      this.taskassignment.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.taskassignment.needFilter) this.$refs.recipientGrid.getData();
      this.editRecipientModal = false;
      this.saveModel();
    },
    async applyEditDistributor({items, requestModel}, isAll) {
      this.taskassignment.isAllDistributors = isAll;
      if (!isAll) {
        this.taskassignment.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.taskassignment.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.taskassignment.distributorIds = Array.isArray(items) ? items : [];
        }
      }
      this.saveModel();
    },
    downloadAttachment(attachment) {
      const gfid = attachment.gfid;

      this.fileService.getFileToken(gfid).then((response) => {
        if (response != null) {
          this.fileService
            .downloadFile(response.data)
            .then((fileResponse) => {
              if(!fileResponse)
                return;

              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(fileResponse.data);
              let name = fileResponse.fileName;
              link.download = decodeURI(name).replace("%2b", "+");
              link.click();
            });
        }
      });
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.taskassignment.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
      this.saveModel();
    },
    feelNeedFilter() {
      this.taskassignment.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    saveModel() {
      this.taskAssignmentService.update(this.taskassignment).then((result) => {
        this.$emit("refresh");
      });
    },
    deleteDistributor(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.taskassignment.distributorIds =
        this.taskassignment.distributorIds.filter((r) => r !== item.id);

      this.saveModel();
    },
    getTaskAssignment() {
      if (this.ids.length === 1) {
        this.loading = true;
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "Tasks",
          id: Number(this.ids[0]),
        });
        this.taskAssignmentService.getFull(this.ids[0]).then((res) => {
          this.taskassignment = res.data;
          this.taskAssignmentService.taskAssignmentId = this.taskassignment.id;
          this.positions = [...this.taskassignment.positions || []];
          this.distributorStructureService.setTaskAssignmentId(this.taskassignment.id);
          try {
            if (this.taskassignment.editFieldsSetting) {
              this.editFieldModel = JSON.parse(this.taskassignment.editFieldsSetting);
            }
          } catch {}
          if (!this.taskassignment) return;
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "Tasks",
            id: "taskPublishStaticId",
            realId: Number(this.ids[0]),
            type: this.taskassignment.type,
            groupUpdate: true,
          });
          this.$store.dispatch("notifications/updateReadedRecord", {
            title: "Tasks",
            id: "taskNewStaticId",
            realId: Number(this.ids[0]),
            type: this.taskassignment.type,
            groupUpdate: true,
          });
          let filter = JSON.parse(this.taskassignment.filter);
          if (filter) {
            this.nodeFilter = filter.nodeFilter;
            this.gridFilter = filter.gridFilter;
          }
          this.feelNeedFilter();

          this.$nextTick(() => {
            if (this.$refs.recipientGrid) this.$refs.recipientGrid.getData();
            if (this.$refs.distributorGrid) this.$refs.distributorGrid.getData();
            if (this.$refs.positionTree) this.$refs.positionTree.refresh();
          });

          this.persistRecipients = true;
          this.recipientService.fromView = false;
          this.recipientService.filter = this.recipientGridFilter;

          this.loading = false;
        });
      }
    },
    getStatus(status) {
      if (!status) return;
      return this.taskAssignmentService.getStatusByValue(status);
    },
    deleteTaskAssignmentDistributorStructure(taskAssignmentDistrStructure) {
      this.taskassignment.distributorStructures = 
        this.taskassignment.distributorStructures.filter(x => x.id !== taskAssignmentDistrStructure.id);
        this.saveModel();
    },
    showDistrStructureModalReadOnly() {
      this.readOnlyDistrStructureModal = true;
      this.getMethodDistrStructureModal = "getTaskAssignmentViewTree";
      this.$nextTick(() => {
        this.visibleDistrStructureModal = true;
      })
    },
    applyDistributorStructure(units) {
      this.taskassignment.distributorStructures = units.map(x => {
        return {
          distributorStructureViewId: x.distributorStructureViewId,
          distributorStructureViewName: x.distributorStructureViewName || "",
          distributorStructureViewCode: x.distributorStructureViewCode,
          distributorStructureViewType: x.distributorStructureViewType,
          distributorStructureViewDistributorId: x.distributorStructureViewDistributorId,
          distributorStructureViewDistributorName: x.distributorStructureViewDistributorName
        }
      });
      this.saveModel();
    },
    showEditPositionsModal() {
      this.positionsModal = true;
    },
    applyEditPositions() {
      this.positions.forEach(x => {
        x["taskAssignmentId"] = this.taskassignment.id;
        x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
      })
      this.taskassignment.positions = [...this.positions];
      this.saveModel();
    },
    cancelEditPositions() {
      this.positions = [...this.taskassignment.positions] || [];
    },
    selectedHandlerTree(item) {
      this.selectedOrgPosition = item;
    },
  },
  watch: {
    ids() {
      this.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.prospace-panel-block-list {
  &__row {
    display: flex;
    column-gap: 10px;
    //border-bottom: 1px dashed var(--libBorder);

    &:last-child {
      border-bottom: none;
    }
    &:not(:first-child) {
      .prospace-panel-block-item {
        padding: 10px 0;
      }
    }
    &:first-child {
      .prospace-panel-block-item {
        padding: 0 0 10px;
      }
    }
  }
}
</style>
