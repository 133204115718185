import dashboards from "../../../../Services/DashboardService/DashboardService.Frontend/views/Dashboards/Dashboards";
import dashboard from "../../../../Services/DashboardService/DashboardService.Frontend/views/Dashboards/Dashboard";

import dashboardintegrationmatchings from "../../../../Services/DashboardService/DashboardService.Frontend/views/DashboardIntegrationMatchings/DashboardIntegrationMatchings";
import dashboardintegrationmatching from "../../../../Services/DashboardService/DashboardService.Frontend/views/DashboardIntegrationMatchings/DashboardIntegrationMatching";

export const dashboardRoutes = [
  {
    path: "/dashboardservice/dashboards",
    name: "dashboards",
    component: dashboards,
  },
  {
    path: "/dashboardservice/dashboard/:id",
    name: "dashboard",
    component: dashboard,
    meta: {
      menuitem: "/dashboardservice/dashboards",
    },
  },
  { 
    path: "/dashboardservice/dashboardintegrationmatchings", 
    name: "dashboardintegrationmatchings", 
    component: dashboardintegrationmatchings 
  },
  { 
    path: "/dashboardservice/dashboardintegrationmatching", 
    name: "dashboardintegrationmatching-create", 
    component: dashboardintegrationmatching 
  },
  { 
    path: "/dashboardservice/dashboardintegrationmatching/:id", 
    name: "dashboardintegrationmatching", 
    component: dashboardintegrationmatching 
  },
];

export default dashboardRoutes;
