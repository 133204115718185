<template>
  <ProSpaceGeoLayout :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('authorizedContactPersons')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceDots
            style="margin-left: 10px"
            :options="wrapperCheckRoles(dotsHeaderOptions)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="
              authorizedContactPersonService.constructor.name + 'grid'
            "
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="fio"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear();
              }
            "
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceFilterDistributor
                v-model="filter.distributorId"
                :hint="$t('hintFilterDistrNews')"
                @apply="emitFilter"
              />
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.isRegistered"
                :service="authorizedContactPersonService"
                getMethod="getIsRegisteredValues"
                optionLabel="label"
                optionValue="value"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('isRegistered')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  authorizedContactPersonService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            selectionMode="single"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="authorizedContactPersonService"
            :noMountedRequest="true"
            @selected-change="selectedHandler"
          >
            <template #columns>
              <Column field="fio" :header="$t('fioFull')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :title="field.data.fio"
                    :no-hover="true"
                  />
                </template>
              </Column>
              <Column
                field="distributorName"
                :header="this.$t('distributor')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.distributorName }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="isRegistered" :header="$t('isRegistered')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.isRegistered ? $t('yes') : $t('no') }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="position" :header="$t('positionFull')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.position }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="systemPosition" :header="$t('systemPosition')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.systemPosition }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="email" :header="$t('email')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.email }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column
                field="actions"
                class="column-end column-end-30"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="AuthorizedContactPersonApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <AuthorizedContactPersonPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="authorizedContactPersonService"
        :options="dotsOptions"
        :checkAccess="checkAccess"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceDateTimeColumn
} from "@prospace/prospace-components-library";
import { AuthorizedContactPersonService } from "../../services/AuthorizedContactPersonService.js";
import { DistributorService } from "../../services/DistributorService.js";
import { DistributorOrgService } from "../../services/DistributorOrgService.js";

import Column from "primevue/column";
import AuthorizedContactPersonPanel from "./components/AuthorizedContactPersonPanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import ProSpaceFilterDistributor from "@filters/ProSpaceFilterDistributor.vue";
import { mapGetters } from "vuex";
const _ = require("lodash");
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceEmptyGrid,
    AuthorizedContactPersonPanel,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceDateTimeColumn,
    ProSpaceFilterDistributor
  },
  data() {
    return {
      showHistoryModal: false,

      // Service
      authorizedContactPersonService: new AuthorizedContactPersonService(),
      distributorService: new DistributorService(),

      //InlinePanel
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "FIO",
          label: this.$t("fioFull"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new DistributorOrgService(),
          getMethod: "grid",
          model: "systemPositionId",
          label: this.$t("systemPosition"),
          optionValue: "id",
          optionLabel: "name",
          selected: {},
        },
        {
          type: "chips",
          model: "position",
          label: this.$t("positionFull"),
          selected: {},
        },
        {
          type: "chips",
          model: "email",
          label: this.$t("email"),
          selected: {},
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    dotsOptions() {
      let res = [
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      return res;
    },
    dotsHeaderOptions() {
      return [
        {
          name: "export_csv",
          text: this.$t("export_csv"),
          icon: "download",
          liteRef: "export-csv-option",
        },
        {
          name: "export_xlsx",
          text: this.$t("export_xlsx"),
          icon: "download",
          liteRef: "export-xlsx-option",
        },
      ];
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
  },
  mounted() {
    this.loadRoleAccess();
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.fio;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.authorizedContactPersonService.export(
        this.grid.getRecords(false, true),
        format
      );
      this.loadingHandler(false);
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "export_csv") {
        this.actionExport("CSV");
      } else if (action === "export_xlsx") {
        this.actionExport("XLSX");
      }
    },
  },
};
</script>
<style lang="scss"></style>
