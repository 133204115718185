<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="this.$t('taskAssignmentTemplates')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="this.$t('newTxt')"
            @click="
              $router.push({
                path: `/taskservice/taskassignmenttemplate`,
                query: {
                  code: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #left>
      <ProSpaceLeftPanel
        class="basetype-tree__left-panel"
        :panelName="$t('topics')"
        searchable
        :searchPlaceholder="$t('searchTxt')"
        @search="handlerSearch"
      >
        <template #center>
          <ProSpaceTree
            ref="tree"
            :searchPlaceholder="$t('searchTxt')"
            :service="baseTypeService"
            searchName="name"
            getNodeMethod="treeWithAll"
            propKey="id"
            propLabel="name"
            :default-filter="getDefaultFilter()"
            :prop-parent-id="null"
            defaultSortField="name"
            @select="nodeSelect"
          />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="
              taskAssignmentTemplateService.constructor.name + 'grid'
            "
            @apply="submitFilter"
            ref="filterPanel"
            :filter-model="['templateName', 'name']"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
            :ignore-clear-keys="['type']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.type"
                :service="baseTypeService"
                getMethod="grid"
                optionLabel="name"
                optionValue="code"
                :default-filter="{
                  disabled: false,
                  systemDirectoryCode: 'Task type',
                }"
                :placeholderValue="$t('taskAssignmentTemplateType')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.templateName"
                :placeholder-value="$t('taskAssignmentTemplateTemplateName')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceFilterContainsChips
                v-model="filter.tags"
                :placeholder-value="$t('taskAssignmentTemplateTags')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  taskAssignmentTemplateService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            :service="taskAssignmentTemplateService"
            @selected-change="selectedHandler"
            @db-click="toItem"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :noMountedRequest="true"
            getMethod="grid"
            :default-filter="defaultFilters"
          >
            <template #columns>
              <Column
                field="templateName"
                :header="this.$t('taskAssignmentTemplateTemplateName')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.templateName"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>

              <Column
                field="name"
                :header="$t('taskAssignmentTemplateName')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.name }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="actions"
                style="display: flex; justify-content: flex-end"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="TaskAssignmentTemplateApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <TaskAssignmentTemplatePanel
        v-if="hasSelect"
        ref="right-panel"
        :service="taskAssignmentTemplateService"
        :ids="selectedModel"
        :options="dotsOptions"
        :checkAccess="checkAccess"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
        @clear="selectedMultipleClear"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteTaskTemplate', { name: modalModel.templateName })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputText,
  Filters,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { TaskAssignmentTemplateService } from "../../services/TaskAssignmentTemplateService.js";

import Column from "primevue/column";
import TaskAssignmentTemplatePanel from "./components/TaskAssignmentTemplatePanel";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { BaseTypeService } from "../../../../../Services/BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
const _ = require("lodash");
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    TaskAssignmentTemplatePanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputText,
    Filters,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    }
  },
  data() {
    return {
      showHistoryModal: false,
      // Grid Panel
      selectedNode: {},

      gridDefaultFilter: {},

      // Service
      taskAssignmentTemplateService: new TaskAssignmentTemplateService(),
      baseTypeService: new BaseTypeService(),

      //InlinePanel
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      defaultFilters: Filters.getDefaultsFilters().disabled,
      showDeleteModal: false
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    customServices() {
      let filters = [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "name",
          label: this.$t("taskAssignmentTemplateName"),
          selected: {},
        },
        {
          type: "chips",
          model: "description",
          label: this.$t("taskAssignmentTemplateDescription"),
          selected: {},
        },
        {
          type: "switch",
          model: "isPositionsRestricted",
          label: this.$t("taskAssignmentSendByPositions"),
          switchLabel: this.$t("taskAssignmentSendByPositions"),
          selected: {},
        },
        {
          type: "switch",
          model: "isDistributorStructureRestricted",
          label: this.$t("taskAssignmentSendByDistributorStructures"),
          switchLabel: this.$t("taskAssignmentSendByDistributorStructures"),
          selected: {},
        },
        {
          switchLabel: this.$t("taskAssignmentTemplateMarsValidation"),
          type: "switch",
          model: "marsValidation",
          label: this.$t("taskAssignmentTemplateValidation"),
          selected: {},
        },
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        const wrapperRes = res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
        return wrapperRes;
      }
      return res;
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
  },
  mounted() {
    this.loadRoleAccess();
  },
  methods: {
    handlerSearch(value) {
      this.$refs.tree.handlerSearch(value);
    },
    getDefaultFilter() {
      return {
        disabled: { and: [false] },
        systemDirectoryCode: { and: ["Task type"] },
      };
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      const url = `/taskservice/taskassignmenttemplate/${data.id}`;
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.taskAssignmentTemplateService
        .export(this.grid.getRecords(false, true), format)
      this.loadingHandler(false)
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    editItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    deleteItem(model) {
      if (!model) return;
      this.modalModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.taskAssignmentTemplateService.delete(this.modalModel).then(() => {
        this.actionUpdate();
        this.selectedModel = {};
      });
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.filterPanel.filter["type"] = filter;
      this.filterPanel.submitFilter();
      this.selectedNode = _.cloneDeep(value);
    },
  },
};
</script>
<style lang="scss">
.basetype-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--libBorder);
  }
}
</style>
