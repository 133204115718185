<template>
  <DashboardModalLayout
    class="dashboard-source-select"
    :panels="panels"
    @searchLeft="dataSourceListTreeHandlerSearch"
    @searchCenter="selectedDataSourcesTreeHandlerSearch"
  >
    <template #left >
      <ProSpaceTree
        ref="dataSourceListTree"
        searchName="name"
        getNodeMethod="getDatasetModel"
        propKey="systemName"
        propLabel="name"
        selectionMode="checkbox"
        needLocalSearch
        :errorMessage="$t('noData')"
        :searchFilterModel="['name', 'systemName']"
        :service="datasetService"
        :fnCheckHandler="() => {}"
        @check="handlerCheck"
        @uncheck="handlerUncheck"
        @loaded="handleDataSourceListTreeLoaded"
      >
        <template #prefix="{ node }">
          <ProSpaceTag v-bind="getTagInfo(node)" />
        </template>
      </ProSpaceTree>
    </template>
    <template #center>
      <ProSpaceTree
        ref="selectedDataSourcesTree"
        searchName="name"
        getNodeMethod="getSelectedSources"
        propKey="systemName"
        propLabel="name"
        needLocalSearch
        :errorMessage="$t('noData')"
        :errorMessageText="$t('noSourcesText')"
        :needExpandAfterSearch="false"
        :searchFilterModel="['name', 'systemName']"
        :service="selectedSourcesService"
        :noMountedSelectNode="true"
        @select="nodeSelect"
      >
        <template #prefix="{ node }">
          <ProSpaceTag v-bind="getTagInfo(node, true)" />
        </template>
      </ProSpaceTree>
    </template>
    <template #right>
      <div v-if="selectedSource" class="dashboard-grid-layout__right prospace-panel-block-list">
        <div class="prospace-panel-block-list__row">
          <ProSpacePanelBlockItem
            class="prospace-panel-block-list__item"
            :title="$t('dataSourceName')"
            :text="selectedSource.name"
          />
        </div>
        <div class="prospace-panel-block-list__row">
          <ProSpacePanelBlockItem
            class="prospace-panel-block-list__item"
            :title="$t('fields')"
          >
            <template #content>
              <ProSpaceTagsColumn
                :tags="selectedSource.fieldNames"
                :limit="2"
              />
            </template>
          </ProSpacePanelBlockItem>
        </div>
        <div class="prospace-panel-block-list__row">
          <ProSpacePanelBlockItem
            class="prospace-panel-block-list__item"
          >
            <template #content>
              <ProSpaceHLayout :gap="5">
                <ProSpaceInputSwitch style="width: calc(100% - 22px);"
                  v-model="selectedSource.isMain"
                  :label="$t('markAsBasicSource')"
                  v-hint="$t('markAsBasicSource')"
                  @update:modelValue="handleMainSourceChange"
                />
                <ProSpaceIcon
                  v-hint="$t('changeBaseSourceNotAvailable')"
                  style="min-width: 20px;"
                  icon="question"
                />
              </ProSpaceHLayout>
            </template>
          </ProSpacePanelBlockItem>
        </div>
      </div>
    </template>
  </DashboardModalLayout>
  <ProSpaceFlashMessage
    v-if="showClearMessage"
    v-model="showClearMessage"
    type="default"
    icon="flash-warning"
    :title="clearMessageTitle"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="methodAfterClear"
    @cancel="cancelClear"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="clearMessageDescription"
      ></div>
      {{ clearMessageQuestion }}
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>

<script>
import {
  ProSpacePanelBlock,
  ProSpaceTag,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceInputSwitch,
  ProSpaceTagsColumn,
  ProSpaceIcon,
  ProSpaceHLayout,
  ProSpaceFlashMessage,
  Hint,
} from "@prospace/prospace-components-library";
import { DashboardDatasetService } from "../../../services/DashboardDatasetService.js";
import DashboardModalLayout from "./DashboardModalLayout.vue";
import _ from "lodash";
export default {
  name: "DashboardSourceSelect",
  components: {
    DashboardModalLayout,
    ProSpacePanelBlock,
    ProSpaceTag,
    ProSpaceTree,
    ProSpacePanelBlockItem,
    ProSpaceInputSwitch,
    ProSpaceTagsColumn,
    ProSpaceIcon,
    ProSpaceHLayout,
    ProSpaceFlashMessage
  },
  directives: {
    hint: Hint
  },
  props: {
    getTagInfo: {
      type: Function,
      required: true
    },
    selectedSourcesService: {
      type: Object,
      required: true,
    },
    datasets: {
      type: Array,
      default: () => []
    },
    counter: {
      type: Number,
      default: 0
    },
    nextStepsEdited: {
      type: Boolean
    }
  },
  data() {
    return {
      datasetService: new DashboardDatasetService(),
      selectedSource: null,

      oldDatasets: _.cloneDeep(this.datasets),
      showClearMessage: false,
      clearMessageTitle: null,
      clearMessageDescription: null,
      clearMessageQuestion: null,
      methodAfterClear: null,
      cancelClear: null,

      selectedSourcesSearchValue: ""
    };
  },
  computed: {
    panels() {
      return [
        {
          title: this.$t('dataSourceList'),
          search: true
        },
        {
          title: this.$t('selectedDataSources'),
          search: true,
          clearButtonHandler: this.handleClearSelectedSourcesButtonClick,
          clearButtonVisible: true,
          counter: this.counter
        },
        {
          title: this.$t("mainInformation"),
          isExpand: false,
          show: this.selectedSource
        },
      ]
    },
  },
  methods: {
    dataSourceListTreeHandlerSearch(value) {
      this.$refs.dataSourceListTree.handlerSearch(value);
    },
    selectedDataSourcesTreeHandlerSearch(value) {
      this.selectedSourcesSearchValue = value;
      this.$refs.selectedDataSourcesTree.handlerSearch(value);
    },
    nodeSelect(value) {
      if (!value.children)
        return;
      this.selectedSource = value;
    },
    handlerCheck(data) {
      this.handlerCheckUncheck(data)
    },
    handlerUncheck(data) {
      if (this.nextStepsEdited && this.oldDatasets.some(x => x.datasetName == data.node.systemName)) {
        this.clearMessageTitle = this.$t("dashboardSourceChangeTitle");
        this.clearMessageDescription = this.$t("dashboardSourceChangeDescription");
        this.clearMessageQuestion = this.$t("dashboardSourceChangeQuestion");
        this.methodAfterClear = () => {
          this.$emit("clearNextData");
          this.$refs.selectedDataSourcesTree.expandedKeys[data.node.key] = false;
          this.handlerCheckUncheck(data);
        }
        this.cancelClear = () => {
          this.$refs.dataSourceListTree.checkedNodes[data.node.systemName] = {
            checked: true,
            partialChecked: false,
          };
        }

        this.$nextTick(() => {
          this.showClearMessage = true;
        })
      } else {
        this.$refs.selectedDataSourcesTree.expandedKeys[data.node.key] = false;
        this.handlerCheckUncheck(data);
      }
    },
    handlerCheckUncheck({ checkedNodes, node }) {
      let selectedSourceTree = this.$refs.selectedDataSourcesTree;
      if (node?.children.length < node.fieldNames.length) {
        const sources = this.$refs.dataSourceListTree.fullNodes.find(n => n.systemName === node.systemName);
        if (sources) {
          node = sources;
        }
      }
      this.$emit("handlerCheckUncheck", {checkedNodes, node});
      selectedSourceTree.getData();
      selectedSourceTree.fullNodes = null;
      if (this.selectedSource && node.systemName === this.selectedSource.systemName)
        this.selectedSource = null;
      this.$nextTick(() => {
        this.markDefaultMainSourceIfNotMarked();
        this.selectedDataSourcesTreeHandlerSearch(this.selectedSourcesSearchValue);
      })
    },
    handleClearSelectedSourcesButtonClick() {
      if (this.nextStepsEdited) {
        this.clearMessageTitle = this.$t("dashboardSourceChangeTitle");
        this.clearMessageDescription = this.$t("dashboardSourceChangeDescription");
        this.clearMessageQuestion = this.$t("dashboardSourceChangeQuestion");
        this.methodAfterClear = () => {
          this.$emit("clearNextData");
          this.handleClearSelectedSources();
        }
        this.cancelClear = () => {
          this.$refs.dataSourceListTree.checkedNodes[data.node.systemName] = {
            checked: true,
            partialChecked: false,
          };
        }

        this.$nextTick(() => {
          this.showClearMessage = true;
        })
      } else {
        this.handleClearSelectedSources();
      }
    },
    handleClearSelectedSources() {
      let selectedSourceTree = this.$refs.selectedDataSourcesTree;
      let allKeys = Object.keys(selectedSourceTree.expandedKeys);
      allKeys.forEach(k => selectedSourceTree.expandedKeys[k] = false);
      this.$refs.dataSourceListTree.checkedNodes = {}
      this.$emit("clearSelectedSources")
      selectedSourceTree.getData();
      selectedSourceTree.fullNodes = null;
      this.selectedSource = null;
      this.$nextTick(() => {
        this.selectedDataSourcesTreeHandlerSearch(this.selectedSourcesSearchValue);
      })
    },
    handleDataSourceListTreeLoaded() {
      const selectedSourcesAreEmptyOnBeginnig = this.$refs.selectedDataSourcesTree.nodes.length === 0;
      if (this.datasets?.length > 0) {
        for (const dataset of this.datasets) {
          const dataSources = this.$refs.dataSourceListTree.nodes.filter(n => n.systemName === dataset.datasetName);
          if (dataSources.length === 0)
            continue;
          const dataSource = {...dataSources[0], isMain: dataset.isMain };
          if (selectedSourcesAreEmptyOnBeginnig)
            this.$emit("listTreeLoaded", dataSource);
          this.$refs.dataSourceListTree.checkedNodes[dataSource.systemName] = {
            checked: true,
            partialChecked: false,
          };
        }
      }
    },
    handleMainSourceChange(value) {
      if (this.nextStepsEdited) {
        this.clearMessageTitle = this.$t("dashboardMainSourceChangeTitle");
        this.clearMessageDescription = this.$t("dashboardMainSourceChangeDescription");
        this.clearMessageQuestion = this.$t("dashboardMainSourceChangeQuestion");
        this.methodAfterClear = () => {
          this.$emit("clearNextData");
          this.mainSourceChange(value);
        }

        this.cancelClear = () => {
          this.selectedSource.isMain = !value;
        }

        this.$nextTick(() => {
          this.showClearMessage = true;
        })
      } else {
        this.mainSourceChange(value);
      }
    },
    mainSourceChange(value) {
      if (value) {
        for(const node of this.$refs.selectedDataSourcesTree.nodes) {
          let isMain = node.systemName === this.selectedSource.systemName;
          node.isMain = isMain;
          this.setDatasetMainByNode(node, isMain)
        }
      }
      else {
        for(const node of this.$refs.selectedDataSourcesTree.nodes)
          this.setDatasetMainByNode(node, false)
        this.markDefaultMainSourceIfNotMarked();
      }
      this.$emit("mainSourceChange")
    },
    markDefaultMainSourceIfNotMarked() {
      let tree = this.$refs.selectedDataSourcesTree;
      let nodes = tree.fullNodes ?? tree.nodes;
      if(nodes && nodes.length > 0 && !nodes.some(n => n.isMain)) {
        nodes.forEach((n, i) => {
          let isMain = i === 0;
          n.isMain = isMain;
          this.setDatasetMainByNode(n, isMain)
        })
      }
    },
    setDatasetMainByNode(node, isMain) {
      const datasetIndex = this.datasets.findIndex(d => d.datasetName == node.systemName);
      if (datasetIndex > -1) {
        this.datasets[datasetIndex].isMain = isMain;
      }
    },
  },
  mounted() {
    Object.keys(this.$refs).forEach(r => {
      if (r.toLowerCase().includes('tree')) {
        this.$refs[r].handlerDoubleClick = function() {
          return false;
        }
      }
    });
  }
}
</script>

<style scoped>

</style>
