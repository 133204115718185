<template>
  <ProSpaceGeoLayout>
    <template #top>
      <ProSpaceFilterPanel
        :closeTxt="$t('closeTxt')"
        :searchPlaceholder="$t('searchTxt')"
        :txtResetFilters="$t('txtResetFilters')"
        :unicKeyStorage="false"
        @apply="submitFilter"
        ref="filter-panel"
        filter-model="ActualModel/FIO"
      >
        <template #hint> {{ $t("filterBy") }}</template>
        <template #filters="{ filter }">
          <ProSpaceCustomFilterPanel
            :customTxt="$t('custom')"
            :customFilterTxt="$t('customFilterTxt')"
            :clearFilterTxt="$t('clearFilterTxt')"
            :selectAllTxt="$t('selectAllTxt')"
            :closeTxt="$t('closeTxt')"
            :applyTxt="$t('applyTxt')"
            :fromTxt="$t('fromTxt')"
            :toTxt="$t('toTxt')"
            :services="allCustomFilters"
            :unicKeyStorage="false"
            @apply="(model) => customFilterApply(filter, model)"
          />
        </template>
      </ProSpaceFilterPanel>
    </template>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :service="historyService"
        @selected-change="selectedHandler"
        :noMountedRequest="true"
        getMethod="grid"
        selectionMode="single"
        defaultSortField="editDate"
        defaultSortOrder="-1"
        :defaultFilter="defaultFilter"
      >
        <template #columns>
          <Column
            field="ActualModel/FIO"
            :header="this.$t('fioFull')"
            sortable
            headerClass="move-sort-head"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                v-if="field.data"
                :id="field.data.actualModel.Id"
                :status="getStatus(field.data.actualModel.Status)"
                :title="field.data.actualModel.FIO"
              />
            </template>
          </Column>
          <Column field="editDate" :header="$t('changeDateTime')" sortable>
            <template #body="field">
              <ProSpaceDateTimeColumn
                :date="field.data.editDate"
                :dateFormat="userinfo.dateFormat"
                :timeFormat="userinfo.timeFormat"
              />
            </template>
          </Column>
          <Column field="operationType" :header="$t('operation')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ $t(field.data.operationType) }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column field="userLogin" :header="$t('user')" sortable>
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.userLogin }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column
            field="ActualModel/DistributorName"
            :header="$t('name')"
            sortable
          >
            <template #body="field">
              <ProSpaceAction
                :text="field.data.actualModel.DistributorName"
                @click="openFromReference(field.data, 'DistributorName')"
              >
              </ProSpaceAction>
            </template>
          </Column>
        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTableHeaderCell,
  ProSpaceFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceAction,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { AnotherContactPersonService } from "../../../services/AnotherContactPersonService";
import { mapGetters } from "vuex";
const _ = require("lodash");
export default {
  components: {
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTableHeaderCell,
    ProSpaceFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceAction,
  },
  props: {
    historyService: {
      type: Object,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: true,
    },
    historyCustomServices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      anothercontactpersonService: new AnotherContactPersonService(),
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    customServices() {
      return [];
    },
    allCustomFilters() {
      return this.historyCustomServices.concat(this.customServices);
    },
  },
  methods: {
    getActualName(record) {
      return record.FIO;
    },
    getActualId(record) {
      return record.Id;
    },
    getActualStatus(record) {
      return this.getStatus(record.Status);
    },
    openFromReference(record, fieldName) {
      this.$emit("openFromReference", record, fieldName);
    },
    emitFilter() {
      this.$refs["filter-panel"].submitFilter();
    },
    selectedHandler(val) {
      this.$emit("selectedHandler", val);
    },
    submitFilter({ filter, search }) {
      this.$refs.grid.submitFilter(filter, search);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model, this.defaultFilter);
      this.$refs["filter-panel"].submitFilter();
    },
  },
};
</script>
<style lang="scss">
</style>
