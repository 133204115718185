<template>
  <ProSpaceModal
    v-model="visible"
    :title="title"
    icon="list-check"
    :width="modalWidth"
    :height="modalHeight"
    without-padding
    :restoreResize="true"
    :content-style="!modalWidth || !modalHeight ? { height: '70vh', width: isOneFilial ? '40vw' : '60vw' } : ''"
    :cancelTxt="!readOnly ? $t('cancelInf') : $t('closeTxt')"
    :applyTxt="!readOnly ? $t('selectTxt2') : null"
    :disabled-apply="disabledApplyBtn"
    :iconHeight="20"
    :iconWidth="20"
    @apply="apply"
    @cancel="$emit('close')"
  >
    <template #near-title>
      <div v-if="selectedUnits.length > 0 && needCounter" class="regular-counter" style="margin-left: 10px">
        {{ selectedUnits.length }}
      </div>
    </template>
    <ProSpaceGeoLayout :routeName="`${$route.name}DistributorHierarchySelectModal`">
      <template #center>
        <DistributorHierarchySelectTree class="padding-top-10 padding-20" ref="tree"
          :selectedUnits="selectedUnits"
          :readOnly="readOnly"
          :service="service"
          :getMethod="getMethod"
          :selectionMode="selectionMode"
          :defaultFilter="defaultFilter"
          :propLabel="propLabel"
          :checkedRows="checkedRows"
          :expandAll="expandAll"
          :searchable="!readOnly"
          :isOneFilial="isOneFilial"
          :isFilial="isFilial"
          :propParentId="propParentId"
          @changed="(units) => selectedUnits = units"
          @updateTree:checked="$emit('updateTree:checked', $event)"
          @treeLoaded="handlerLoadedTree"
          @mounted="handlerMounted"
        />
      </template>
      <template #right v-if="mountedTree && loaded && selectedUnits.length > 0 && !readOnly && !hideRightPanel">
        <ProSpaceRightPanel :header="headerRightPanel">
          <template #center>
            <ProSpacePanelBlock :header="headerPanel">
              <template #header>
                <ProSpaceAction v-if="!readOnly" :text="$t('clear')" @click="clear" />
              </template>
              <ProSpaceSimpleGrid
                :headers="panelHeader"
                :items="panelItems"
                :modalTitle="$t('distributorStructure')"
                :btnText="$t('viewMore')"
                :applyTxt="null"
                :cancelTxt="$t('closeTxt')"
              />
            </ProSpacePanelBlock>
          </template>
        </ProSpaceRightPanel>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpaceAction,
  ProSpaceSimpleGrid
} from "@prospace/prospace-components-library";
import DistributorHierarchySelectTree from "../trees/DistributorHierarchySelectTree";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
const _ = require("lodash");
export default {
  components: {
    DistributorHierarchySelectTree,
    ProSpaceModal,
    ProSpaceGeoLayout,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpaceAction,
    ProSpaceSimpleGrid
  },
  emits: ["update:modelValue", "apply", "cancel"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      default: new DistributorStructureService()
    },
    titleModal: {
      type: String,
      default: ''
    },
    getMethod: {
      type: String,
      default: "allTree"
    },
    selectionMode: {
      type: String,
      default: "checkbox",
    },
    defaultFilter: {
      type: String,
    },
    propLabel: {
      type: String,
      default: "name",
    },
    checkedRows: {
      type: Array,
      default: [],
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
    needValidate: {
      type: Boolean,
      default: false
    },
    isOneDistr: {
      type: Boolean,
      default: false
    },
    needFilterValues: {
      type: Boolean,
      default: false
    },
    hideRightPanel: {
      type: Boolean,
      default: false
    },
    isOneFilial: {
      type: Boolean,
      default: false
    },
    isFilial: {
      type: Boolean,
      default: false
    },
    needCounter: {
      type: Boolean,
      default: true
    },
    modalWidth: {
      type: [String, Number]
    },
    modalHeight: {
      type: [String, Number]
    },
    propParentId: {
      type: String,
      default: "parentId",
    },
  },
  data() {
    return {
      selectedUnits: [],
      loaded: false,
      mountedTree: false
    }
  },
  watch: {
    modelValue: {
      handler(val) {
        if(this.checkedRows && Array.isArray(this.checkedRows))
          this.selectedUnits = _.cloneDeep(this.checkedRows);
        if (!val) {
          this.mountedTree = false
          this.loaded = false
        }
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    panelHeader() {
      if (this.isOneDistr) {
        return [{name: this.$t('code'), value: 'code', f: (v) => v}, {name: this.$t('nameL'), value: 'name'}]
      }
      return [{name: this.$t('distributor'), value: 'code', f: (v) => v}, {name: this.$t('branch'), value: 'name'}]
    },
    panelItems() {
      const list =  this.$refs.tree.getNodesList()
      return this.selectedUnits.map(i => {
        const node = list[i.distributorStructureViewId] || i
        return {
          code: this.isOneDistr ? node.code : node.billToName,
          name: node.name || "",
        }
      })
    },
    disabledApplyBtn() {
      if (this.needValidate) {
        return this.selectedUnits.length === 0
      }
      return false
    },
    title() {
      return this.titleModal || this.$t('distrsAndBranches')
    },
    headerRightPanel() {
      return {
        title: this.$t('itemsSelected'),
        counter: this.selectedUnits.length,
        showCounter: true,
      }
    },
    headerPanel() {
      return {
        title: this.$t('total'),
        counter: this.selectedUnits.length,
        showCounter: true,
        isExpand: false,
      }
    }
  },
  methods: {
    handlerLoadedTree(records) {
      if (this.needFilterValues)
        this.selectedUnits = this.selectedUnits.filter(x => records.some(r => r.id === (x.distributorStructureViewId ?? x.id)));

      this.$nextTick(() => {
        this.loaded = true;
      })
    },
    handlerMounted() {
      this.mountedTree = true
    },
    apply() {
      this.$emit('apply', this.selectedUnits)
    },
    clear() {
      this.selectedUnits = []
      this.$refs.tree.clearCheckedNodes()
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.prospace-list-items-table .p-datatable-tbody > tr:not(.p-datatable-row-expansion) > td:last-child) {
  padding: 9px 5px;
}
:deep(.prospace-tree .p-tree) {
  margin-right: -17px;
  padding-right: 13px;
}
</style>
