<template>
  <!--rows-->
  <ProSpacePanelBlock
    :header="rowPanelHeader"
  >
    <template #header >
      <ProSpaceAction :text="$t('editItemTxt')" @click="handleEditRowsClick" />
      <ProSpaceWarning v-if="validation.rows.$error"/>
    </template>
    <template v-slot>
      <ProSpaceSimpleGrid
        :headers="rowSimpleGridHeader"
        :items="rowSimpleGridRows"
        :limit="null"
        >
        <template #action="field" >
          <ProSpaceInputSwitch
            v-if="modelTags"
            v-model="modelTags[field.data.name]"
            :label="$t('markAsTag')"
            flex-direction="row-reverse"
            @update:modelValue="switchHandler({$event, field})"
          >
          </ProSpaceInputSwitch>
        </template>
      </ProSpaceSimpleGrid>
    </template>
  </ProSpacePanelBlock>
  <!--column-->
  <ProSpacePanelBlock
    :header="columnPanelHeader"
  >
    <template v-slot>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('field')"
        >
          <template #content>
            <ProSpaceDropdown
              v-model="pivotTableModel.columnField"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('field')"
              :options="fieldOptions"
              :invalid="validation.columnField.$error"
              :invalid-messages="validation.columnField.$errors"
              @blur="validation.columnField.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
  </ProSpacePanelBlock>

  <!--values-->
  <ProSpacePanelBlock
    :header="valuePanelHeader"
  >
    <template #header >
      <ProSpaceAction :text="$t('editItemTxt')" @click="handleEditValuesClick" />
      <ProSpaceWarning v-if="validation.values.$error"/>
    </template>
    <template v-slot>
      <ProSpaceSimpleGrid
        :headers="rowSimpleGridHeaderValues"
        :items="rowSimpleGridRowsValues"
        :limit="null"
      >
        <template #action="field" >
          <ProSpaceAction :text="$t('addColorSelection')" @click.stop="handleAddColorSelection(field.index)"/>
        </template>
      </ProSpaceSimpleGrid>
    </template>
  </ProSpacePanelBlock>

  <!--sorting-->
  <ProSpacePanelBlock
    :header="sortingPanelHeader"
  >
    <template v-slot>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('field')"
        >
          <template #content>
            <ProSpaceDropdown
              v-model="pivotTableModel.sort.field"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('field')"
              :options="fieldOptions"
              :invalid="validation.sort.field.$error"
              :invalid-messages="validation.sort.field.$errors"
              @blur="validation.sort.field.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
          :title="$t('sortDirection')"
        >
          <template #content>
            <ProSpaceDropdown
              v-model="pivotTableModel.sort.direction"
              propValue="systemName"
              propLabel="name"
              :placeholder="$t('sortDirection')"
              :options="sortDirectionOptions"
              :invalid="validation.sort.direction.$error"
              :invalid-messages="validation.sort.direction.$errors"
              @blur="validation.sort.direction.$touch"
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
      <div class="prospace-panel-block-list__row">
        <ProSpacePanelBlockItem
          class="prospace-panel-block-list__item"
        >
          <template #header>
            <ProSpaceHLayout :gap="5">
              <div>
                {{ $t('filter') }}
              </div>
              <ProSpaceIcon
                v-hint="filterHintText"
                icon="question"
              />
            </ProSpaceHLayout>
          </template>
          <template #content>
            <ProSpaceInputChips
              v-model="pivotTableModel.filter"
              showHintItem
            />
          </template>
        </ProSpacePanelBlockItem>
      </div>
    </template>
  </ProSpacePanelBlock>
  <!--Rows selection-->
  <ProSpaceModal
    v-if="showEditRowsModal"
    v-model="showEditRowsModal"
    modalClass="modal-pick-list"
    :style="getModalStyle('rows')"
    :smallPadding="true"
    :iconWidth="20"
    :iconHeight="20"
    icon="list-check"
    :title="$t('fieldSelection')"
    :applyTxt="$t('applyTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="handleEditRowModalApply"
    @cancel="handleEditRowModalCancel"
    @resize="(e) => setModalSize(e, 'rows')"
  >
    <ProSpacePickList
      v-if="showEditRowsModal"
      v-model="selectedRows"
      :options="fieldOptions"
      propKey="systemName"
      propLabel="name"
    >
    </ProSpacePickList>
  </ProSpaceModal>
  <!--Values selection-->
  <ProSpaceModal
    v-if="showEditValuesModal"
    v-model="showEditValuesModal"
    modalClass="modal-pick-list"
    :style="getModalStyle('values')"
    :smallPadding="true"
    :iconWidth="20"
    :iconHeight="20"
    icon="list-check"
    :title="$t('fieldSelection')"
    :applyTxt="$t('applyTxt')"
    :cancelTxt="$t('cancelInf')"
    @apply="handleEditValueModalApply"
    @cancel="handleEditValueModalCancel"
    @resize="(e) => setModalSize(e,'values')"
  >
    <ProSpacePickList
      v-if="showEditValuesModal"
      v-model="selectedValues"
      :options="fieldOptions"
      propKey="systemName"
      propLabel="name"
    >
    </ProSpacePickList>
  </ProSpaceModal>
  <!--Value color selection-->
  <DashboardModalColorSelection
    v-if="editColor"
    v-model="editColor"
    :color="pivotTableModel.values[editingValueColorIndex].color"
    fieldType="field"
    @update:color="handleColorUpdate"
  />
</template>

<script>
import {
  ProSpacePanelBlock,
  ProSpaceAction,
  ProSpacePanelBlockItem,
  ProSpaceDropdown,
  ProSpaceModal,
  ProSpaceSimpleGrid,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceInputChips,
  Hint,
  ProSpaceWarning,
  ProSpaceInputSwitch,
} from '@prospace/prospace-components-library'
import ProSpacePickList from './ProSpacePickList.vue';
import DashboardModalColorSelection from '../DashboardModalColorSelection.vue'
export default {
  name: "PivotTableSettings",
  components: {
    ProSpacePanelBlock,
    ProSpaceAction,
    ProSpacePanelBlockItem,
    ProSpaceDropdown,
    ProSpaceModal,
    ProSpacePickList,
    ProSpaceSimpleGrid,
    DashboardModalColorSelection,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceInputChips,
    ProSpaceWarning,
    ProSpaceInputSwitch,
  },
  props: {
    pivotTableModel: {
      type: Object,
      required: true
    },
    createPivotValueField: {
      type: Function,
      required: true
    },
    fieldOptions: {
      type: Array,
      required: true
    },
    validation: {
      type: Object,
      required: true
    },
    filterHintText: {
      type: String,
      required: true
    }
  },
  directives: {
    hint: Hint
  },
  data() {
    return {
      showEditRowsModal: false,
      showEditValuesModal: false,
      selectedRows: this.getSelectedRows(),
      selectedValues: this.getSelectedValues(),
      editingValueColorIndex: null,
      editColor: false,
      modelTags: {},
      modalSize: {
        rows: { width: '900px', height: '536px' },
        values: { width: '900px', height: '536px' },
      },
    }
  },
  watch: {
    pivotTableModel: {
      handler: function(val) {
        this.modelTags = val.tagRows || {};
      },
      deep: true,
      immediate: true
    },

  },
  created() {
    //обратная совместимость
    if (!this.pivotTableModel.filter)
      this.pivotTableModel.filter = [];
  },
  computed: {
    rowPanelHeader() {
      return {
        title: this.$t('rows'),
        counter: this.pivotTableModel?.rows?.length
      }
    },
    columnPanelHeader() {
      return {
        title: this.$t('column'),
      }
    },
    valuePanelHeader() {
      return {
        title: this.$t('values'),
        counter: this.pivotTableModel?.values?.length
      }
    },
    sortingPanelHeader() {
      return {
        title: this.$t('sorting'),
      }
    },
    rowSimpleGridHeader() {
      return [
        {
          name: this.$t("field"),
          value: "field",
          additionalValue: "additionalValue",
          f: (item) => item,
        }
      ]
    },
    rowSimpleGridHeaderValues() {
      return [
        {
          name: this.$t("field"),
          value: "field",
          additionalValue: "additionalValue",
          f: (item) => item,
        }
      ]
    },
    rowSimpleGridRows() {
      return this.pivotTableModel.rows.map(row =>{
        const name = typeof row === 'string' ? row : row.field;
        const isTag = false;

        return {
          field: this.getRowName(name),
          additionalValue: this.$t('markAsTag'),
          action: null,
          name: name,
          isTag: isTag
        }
      })
    },
    rowSimpleGridRowsValues() {
      return this.pivotTableModel.values.map((row, index) =>({
        field: this.getValueName(row),
        additionalValue: this.$t('addColorSelection'),
        action: this.handleAddColorSelection
      }))
    },
    sortDirectionOptions() {
      return [
        {
          name: this.$t("asc"),
          systemName: "asc"
        },
        {
          name: this.$t("desc"),
          systemName: "desc"
        }
      ]
    },
  },
  methods: {
    getSelectedRows() {
      return this.pivotTableModel.rows.map(r => ({
          systemName: typeof r === 'string' ? r : r.field,
          name: this.fieldOptions.filter(o => o.systemName === r || o.systemName === r.field)[0].name
        })
      )
    },
    getSelectedValues() {
      return this.pivotTableModel.values.map(v => ({
          systemName: v.field,
          name: this.fieldOptions.filter(o => o.systemName === v.field)[0].name
        })
      )
    },
    handleEditRowsClick() {
      this.showEditRowsModal = true;
    },
    handleEditValuesClick() {
      this.showEditValuesModal = true;
    },
    getRowName(row) {
      return this.fieldOptions.filter(f => f.systemName === row || f.systemName === row.field)[0].name
    },
    getValueName(value) {
      return this.fieldOptions.filter(f => f.systemName === value.field)[0].name
    },
    handleEditRowModalApply() {
      this.pivotTableModel.rows = this.selectedRows.map(r => r.systemName)
      this.validation.rows.$touch()
    },
    handleEditRowModalCancel() {
      this.selectedRows = this.getSelectedRows();
      this.validation.rows.$touch()
    },
    handleEditValueModalApply() {
      this.pivotTableModel.values = this.selectedValues.map(r => {
        const oldValue = this.pivotTableModel.values
          .filter(value => value.field === r.systemName)[0];
        return this.createPivotValueField(r.systemName, oldValue?.color)
      })
      this.validation.values.$touch()
    },
    handleEditValueModalCancel() {
      this.selectedValues = this.getSelectedValues();
      this.validation.values.$touch()
    },
    handleAddColorSelection(index) {
      this.editingValueColorIndex = index;
      this.editColor = true;
    },
    handleColorUpdate(color) {
      this.pivotTableModel.values[this.editingValueColorIndex].color = color
    },
    switchHandler() {
      this.$emit("update:tagsRow", this.modelTags);
    },
    getModalStyle(type) {
      const { width, height } = this.modalSize[type];
      return `min-width: ${width}; width: ${width}; height: ${height}; min-height: ${height};`
    },
    setModalSize(size, type) {
      let { width, height } = size;
      this.modalSize[type] = {
        width: `${(width).toFixed()}px`,
        height: `${(height).toFixed()}px`,
      };
    }
  }
}
</script>
