<template>
  <ProSpaceModal
    v-model="visible"
    width="1020"
    :title="$t('taskLog')"
    :onlyWatchMode="true"
    :withoutPadding="true"
    :content-style="{ height: '80vh' }"
    :applyTxt="null"
    :cancelTxt="$t('cancelInf')"
  >
    <SystemLogGrid v-if="visible" :task="task" />
  </ProSpaceModal>
</template>

<script>
import { ProSpaceModal } from "@prospace/prospace-components-library";
import SystemLogGrid from "./SystemLogGrid";
import _ from "lodash";

export default {
  components: {
    ProSpaceModal,
    SystemLogGrid,
  },
  data() {
    return {
      task: null,
      visible: false,
    };
  },
  methods: {
    show(task) {
      this.task = _.cloneDeep(task);
      this.visible = true;
    },
  },
};
</script>

<style></style>
