<template>
  <ProSpaceVLayout :gap="10" isInlineH>
    <ProSpaceHLayout :gap="10">
      <ProSpaceDropdown
        v-model="triggerOptions.TriggerPeriodic"
        :label="$t('repeat')"
        :placeholder="$t('repeat')"
        :options="triggerPeriodicOptions"
        propLabel="text"
        propValue="value"
      />
    </ProSpaceHLayout>
    <ProSpaceHLayout :gap="10">
      <ProSpaceInputNumber
        v-if="dayVisible"
        v-model="triggerOptions.EveryDay"
        :label="$t('day')"
      />
      <ProSpaceInputNumber
        v-if="everyVisible"
        v-model="triggerOptions.Every"
        :label="$t('every')"
      />
      <ProSpaceDropdown
        v-if="everyDayVisible"
        v-model="everyDayModel"
        :label="$t('every')"
        :placeholder="$t('every')"
        :options="daysOptions"
        propLabel="text"
        propValue="value"
      />
      <ProSpaceInputCalendar
        v-if="timeVisible"
        v-model="triggerOptions.PeriodicTime"
        dateFormat="HH:MM:ss"
        :label="$t('time')"
        :closeTxt="$t('closeTxt')"
        :applyTxt="$t('applyTxt')"
        timeOnly
        showSeconds
      />
    </ProSpaceHLayout>
    <ProSpaceHLayout :gap="10">
      <TriggerEditorDays
        v-if="onTheDaysVisible"
        :triggerOptions="triggerOptions"
        :label="$t('onTheDays')"
      />
    </ProSpaceHLayout>
    <ProSpaceHLayout :gap="10">
      <ProSpaceInputCalendar
        v-model="startAt"
        :dateFormat="userinfo.dateFormat"
        :label="$t('startRepeat')"
        :closeTxt="$t('closeTxt')"
        :applyTxt="$t('applyTxt')"
      />
      <ProSpaceInputCalendar
        v-model="endAt"
        :dateFormat="userinfo.dateFormat"
        :label="$t('endRepeat')"
        :closeTxt="$t('closeTxt')"
        :applyTxt="$t('applyTxt')"
      />
    </ProSpaceHLayout>
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceInputNumber,
  ProSpaceInputCalendar,
} from "@prospace/prospace-components-library";
import TriggerEditorDays from "./TriggerEditorDays";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceInputNumber,
    ProSpaceInputCalendar,
    TriggerEditorDays,
  },
  props: {
    triggerOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    const onceVal = 0;
    const secondVal = 1;
    const minuteVal = 2;
    const hourVal = 3;
    const dayVal = 4;
    const weekVal = 5;
    const monthVal = 6;

    return {
      everyDayModel: this.triggerOptions.DaysOfWeek[0],

      onceVal: onceVal,
      secondVal: secondVal,
      minuteVal: minuteVal,
      hourVal: hourVal,
      dayVal: dayVal,
      weekVal: weekVal,
      monthVal: monthVal,

      triggerPeriodicOptions: [
        { text: this.$t("once"), value: onceVal },
        { text: this.$t("second"), value: secondVal },
        { text: this.$t("minute"), value: minuteVal },
        { text: this.$t("hour"), value: hourVal },
        { text: this.$t("day"), value: dayVal },
        { text: this.$t("week"), value: weekVal },
        { text: this.$t("month"), value: monthVal },
      ],
      daysOptions: [
        { text: this.$t("sun"), value: 0x40 },
        { text: this.$t("mon"), value: 0x01 },
        { text: this.$t("tue"), value: 0x02 },
        { text: this.$t("wed"), value: 0x04 },
        { text: this.$t("thu"), value: 0x08 },
        { text: this.$t("fri"), value: 0x10 },
        { text: this.$t("sat"), value: 0x20 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    startAt: {
      get() {
        return this.triggerOptions.StartAt !== null ? this.toDate(this.triggerOptions.StartAt) : new Date()
      },
      set(value) {
        this.$emit("update:startAt", value);
      }
    },
    endAt: {
      get() {
        return this.triggerOptions.EndAt !== null ? this.toDate(this.triggerOptions.EndAt) : new Date()
      },
      set(value) {
        this.$emit("update:endAt", value);
      }
    },
    dayVisible() {
      const visibleIn = [this.monthVal];
      return visibleIn.includes(this.triggerOptions.TriggerPeriodic);
    },
    everyVisible() {
      const visibleIn = [
        this.secondVal,
        this.minuteVal,
        this.hourVal,
        this.weekVal,
        this.monthVal,
      ];
      return visibleIn.includes(this.triggerOptions.TriggerPeriodic);
    },
    everyDayVisible() {
      const visibleIn = [this.dayVal];
      return visibleIn.includes(this.triggerOptions.TriggerPeriodic);
    },
    timeVisible() {
      const visibleIn = [this.dayVal, this.weekVal, this.monthVal];
      return visibleIn.includes(this.triggerOptions.TriggerPeriodic);
    },
    onTheDaysVisible() {
      const visibleIn = [this.weekVal];
      return visibleIn.includes(this.triggerOptions.TriggerPeriodic);
    },
  },
  watch: {
    everyDayModel: {
      handler(val) {
        if(val)
          this.triggerOptions.DaysOfWeek = [val];
      },
    },
    "triggerOptions.TriggerPeriodic": {
      handler(val) {
        this.triggerOptions.day = null;
        this.triggerOptions.Every = null;
        this.triggerOptions.EveryDay = null;
        this.triggerOptions.DaysOfWeek = [];
        this.triggerOptions.StartAt = null;
        this.triggerOptions.EndAt = null;
        this.triggerOptions.PeriodicTime = null;
        this.everyDayModel = null;
      },
    },
  },
  methods: {
    toDate(date) {
      return moment(date).toDate()
    },
  },
};
</script>

<style></style>
