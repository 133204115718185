<template>
  <ProSpaceModal
    v-model="localModelValue"
    class="dashboard-restrictions"
    :style="getModalStyle"
    :needHideApply="false"
    :needHideAfterDataLoad="false"
    :isDataLoading="loading"
    :title="getId ? $t('permissions') + ': ' + getName : $t('permissions')"
    icon="list-check"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('save')"
    applyIcon="check"
    @apply="handleApply"
    @cancel="closeModal"
    @resize="setModalSize"
  >
    <ProSpaceTabs v-model="activeRestrictionsTab" :tabs="restrictionsTabs">
      <template v-for="(tab, i) in restrictionsTabs" v-slot:[tab.tabName]="slotProps">
        <DashboardRestrictions
          :ref="`dashboardRestriction-${i}`"
          :restrictions-types="tab.systemName"
          :restrictions="restrictionsModel"
          @update="setRestrictionsModel"
          @deletionRequest="toggleAlert('showDeleteMessage', true, i)"
          @delete="deleteRestrictionsModel"
        />
      </template>
    </ProSpaceTabs>

    <template #footer>
      <ProSpaceHLayout justify-content="space-between">
        <ProSpaceAction :text="$t('clear')" @click="toggleAlert('showClearAlertMessage', true)" />
        <ProSpaceHLayout :gap="0" style="width: auto;">
          <ProSpaceButton type="tertiary" :disabled="loading" :label="$t('cancelInf')" @click="closeModal" />
          <ProSpaceButton type="primary" :disabled="loading" :label="$t('save')" icon="check" @click="handleApply" />
        </ProSpaceHLayout>
      </ProSpaceHLayout>
    </template>
  </ProSpaceModal>
  <ProSpaceFlashMessage
    v-if="showDeleteMessage"
    v-model="showDeleteMessage"
    type="default"
    icon="flash-warning"
    :title="$t('dashboardRestrictionDeleteAlertTitle')"
    :cancelTxt="$t('closeTxt')"
  >
    <template #default>
      {{ getDeleteMessageText }}
    </template>
    <template #footer>
      <ProSpaceHLayout :gap="5" justify-content="center">
        <ProSpaceButton
          type="tertiary"
          :label="$t('cancelTxt')"
          @click="toggleAlert('showDeleteMessage', false)"
        />
        <ProSpaceButton
          type="primary"
          icon="check"
          :label="$t('delete')"
          @click="confirmDelete"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showValidationMessage"
    v-model="showValidationMessage"
    type="default"
    icon="flash-warning"
    :title="$t('dashboardRestrictionValidationTitle')"
    :cancelTxt="$t('closeTxt')"
  >
    <template #default>
      {{ $t('dashboardRestrictionValidationText', {arg: this.getInvalidRestrictionsNames}) }}
    </template>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showClearAlertMessage"
    v-model="showClearAlertMessage"
    type="default"
    icon="flash-warning"
    :title="$t('dashboardRestrictionClearTitle')"
  >
    <template #default>
      {{ $t('dashboardRestrictionClearText') }}
    </template>
    <template #footer>
      <ProSpaceHLayout :gap="5" justify-content="center">
        <ProSpaceButton
          type="tertiary"
          :label="$t('cancelTxt')"
          @click="toggleAlert('showClearAlertMessage', false)"
        />
        <ProSpaceButton
          type="primary"
          icon="check"
          :label="$t('confirmTxt')"
          @click="clearModel(true)"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceFlashMessage>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceTabs,
  ProSpaceFlashMessage,
  ProSpaceAction,
  ProSpaceButton,
  ProSpaceHLayout,
} from "@prospace/prospace-components-library";

import DashboardRestrictions from "./DashboardRestrictions.vue";

import { mapGetters } from "vuex";
import { DashboardService } from "../../../../services/DashboardService.js";

export default {
  name: "DashboardModalRestrictions",
  components: {
    ProSpaceModal,
    ProSpaceTabs,
    ProSpaceFlashMessage,
    ProSpaceAction,
    ProSpaceButton,
    ProSpaceHLayout,

    DashboardRestrictions,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      dashboardService: new DashboardService(),

      restrictionsModel: {},
      oldRestrictionsModel: {},
      dashboardModel: null,

      activeRestrictionsTab: {
        name: this.$t("distributors"),
        isActive: true,
        systemName: "distributors",
        tabName: "tab-1"
      },

      showValidationMessage: false,
      showClearAlertMessage: false,
      showDeleteMessage: false,

      deletionConfirmation: null,
      isClearing: false,

      loading: false,

      modalSize: { width: '60%', height: '76%' },
      relevantKeys: ['distributors', 'roles', 'positions'],
    };
  },
  watch: {
    localModelValue: {
      handler(value) {
        if (!value && !this.loading) {
          this.clearModel();
        } else if (this.loading) {
          this.loading = false;
        }

        if (value) {
          if (this.getId) {
            this.loading = true;
            this.dashboardService.get(this.getId).then((response) => {
              this.dashboardModel = _.cloneDeep(response.data);
              const { restrictions } = this.dashboardModel;
              if (restrictions) {
                this.restrictionsModel = restrictions;
              } else {
                this.setDefaultRestrictionsModel();
              }
              this.oldRestrictionsModel = { ...this.restrictionsModel };
              this.loading = false;

            }).catch(() => {
              this.loading = false;
            });
          }
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    restrictionsTabs() {
      return [
        { name: this.$t("distributors"), systemName: "distributors", isActive: true, tabName: "tab-1" },
        { name: this.$t("roles"), systemName: "roles", isActive: false, tabName: "tab-2" },
        { name: this.$t("positions"), systemName: "positions", isActive: false, tabName: "tab-3" },
      ];
    },
    getId() {
      return this.dashboard?.id;
    },
    getName() {
      return this.dashboard?.name;
    },
    getModalStyle() {
      const { width, height } = this.modalSize;
      return `min-width: 945px; width: ${width}; height: ${height}; min-height: 580px;`
    },
    disabledApply() {
      return JSON.stringify(this.restrictionsModel) === JSON.stringify(this.oldRestrictionsModel);
    },
    getRestrictionsVariables() {
      const { distributors, roles, positions, isAllDistributors, isAllRoles, isAllPositions } = this.restrictionsModel;
      return {
        distributors, roles, positions,
        isAllDistributors, isAllRoles, isAllPositions
      };
    },
    getInvalidRestrictionsNames() {
      return this.relevantKeys
        .filter(a => !this.validGroup(a))
        .map(a => this.$t(`${a}RestrictionType`, 2))
        .join(', ');
    },
    getDeleteMessageText() {
      return this.$t('dashboardRestrictionDeleteAlertText',
        {
          arg: this.activeRestrictionsTab.systemName === 'distributors'
            ? this.$t(`${(this.activeRestrictionsTab.systemName)}RestrictionType`, 1)
            : this.$t(`${(this.activeRestrictionsTab.systemName)}RestrictionType`, 0)
        }
      )
    }
  },
  methods: {
    propertyNameIsAll(d) {
      return `isAll${d.charAt(0).toUpperCase() + d.slice(1)}`;
    },
    validGroup(group) {
      return this.getRestrictionsVariables[this.propertyNameIsAll(group)] ||
      this.getRestrictionsVariables[group].length > 0;
    },
    checkModelInvalid() {
      if (!this.restrictionsModel) return false;
      return Object.keys(this.restrictionsModel)
        .filter(key => this.relevantKeys.includes(key))
        .some(k => !this.validGroup(k));
    },
    handleApply() {
      this.loading = true;
      if (this.checkModelInvalid() && !this.isClearing) {
        this.toggleAlert('showValidationMessage', true);
        this.loading = false;
        return;
      }

      const saveMethod = "update";

      if (this.isClearing) {
        this.restrictionsModel = null;
        this.isClearing = false;
      }
      this.dashboardModel.restrictions = this.restrictionsModel;

      this.dashboardService[saveMethod](this.dashboardModel)
          .then(async (result) => {
            // this.dashboardModel = result.data[0];
            // this.restrictionsModel = result.data[0].restrictions;
            this.$emit('update');
            this.loading = false;
            this.localModelValue = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    setDistributorsModel(restrictions) {
      this.restrictionsModel.distributors = [...this.restrictionsModel.distributors, ...restrictions];
    },
    setRolesModel(restrictions) {
      this.restrictionsModel.roles = [...this.restrictionsModel.roles, ...restrictions];
    },
    setPositionsModel(restrictions) {
      this.restrictionsModel.positions = [...this.restrictionsModel.positions, ...restrictions];
    },
    setRestrictionsModel(e) {
      Object.keys(e).forEach(key => {
        const val = e[key];
        if (Array.isArray(val)) {
          this.restrictionsModel[key] = val;
        } else {
          this.restrictionsModel[key] = val;
        }

      });
    },
    deleteRestrictionsModel(e) {
      const key = Object.keys(e)[0];
      const val = Object.values(e)[0];

      this.restrictionsModel[key] = val;
    },
    confirmDelete() {
      this.$refs[`dashboardRestriction-${this.deletionConfirmation}`].deleteFromModel();
      this.toggleAlert('showDeleteMessage', false);
    },
    clearModel(reset = false) {
      if (reset) {
        this.isClearing = true;
        this.$nextTick(() => {
          const i = this.restrictionsTabs.findIndex(tab => tab.systemName === this.activeRestrictionsTab.systemName);
          if (i > -1 && this.$refs[`dashboardRestriction-${i}`]) {
            this.$refs[`dashboardRestriction-${i}`].loaded = false;
          }
        });
      }
      this.setDefaultRestrictionsModel(reset);
      this.toggleAlert('showClearAlertMessage', false);
    },
    closeModal() {
      this.clearModel();
      this.$emit("update:modelValue", false);
    },
    setDefaultRestrictionsModel(reset = false) {
      this.restrictionsModel = {
        distributors: [],
        roles: [],
        positions: [],
        isAllPositions: false,
        isAllDistributors: false,
        isAllRoles: false,
      }
    },
    setModalSize(size) {
      let { width, height } = size;
      this.modalSize = {
        width: `${(width).toFixed()}px`,
        height: `${(height).toFixed()}px`,
      };
    },
    toggleAlert(name, state, index) {
      if (index >= 0) {
        this.deletionConfirmation = index;
      }
      this[name] = state;
    }
  },
};
</script>

<style lang="scss">
.dashboard-restrictions {
  .prospace-modal-wrapper {
    .prospace-scrollbar {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .prospace-tabs {
    &__content {
      padding-top: 20px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

</style>
