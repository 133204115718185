import { setReadOnly, setAlive, setDisable, setNone, setCondition, resetCondition } from "./elementAccessFunctions";

const table = {};

function workCondition(element) {
  if (!element) {
    return
  }
  switch (element.stateStatus) {
    case "ALIVE":
      return setAlive(element);
    case "DISABLE":
      return setDisable(element);
    case "NONE":
      return setNone(element);
    case "READONLY":
      return setReadOnly(element);
  }
}

const accessMixin = {
  directives: {
    "access-role": {
      mounted: function (element, binding, vnode) {
        let elemName = binding.value.name;
        let me = binding.value.me;

        let pageName = me.$route.name.split("-create")[0];
        let page = table[pageName];

        if (!page) {
          return;
        }

        if (typeof page == "string") {
          page = table[page];
        }

        let elemRef = page[elemName];
        if (!elemRef) return;
        let item = JSON.parse(localStorage.getItem("userRole"));
        let role = item && item.systemName ? item.systemName : null;
        let condition = elemRef[role] ? elemRef[role] : "ALIVE";
        setCondition(element, condition);
        workCondition(element);
      },
    },
  },
  data() {
    return {
      mixinLoadingAccess: false
    }
  },
  methods: {
    clearAllCondition() {
      Object.keys(this.$refs).forEach((elem) => resetCondition(this.$refs[elem]));
    },
    resetCondition(refs = [], cond = "ALIVE") {
      refs.forEach((elem) => {
        resetCondition(this.$refs[elem], cond);
        workCondition(this.$refs[elem]);
      });
    },
    mountAccess(area) {
      this.mixinLoadingAccess = true;
      if (this.refsForCheckInMixin && this.refsForCheckInMixin.length > 0) {
        let areaName = area || this.$route.name.split("-create")[0];
        this.startMountAccess(areaName);
      } else {
        this.mixinLoadingAccess = false;
      }
    },
    async loadRoleAccess(area) {
      let areaName = area || this.$route.name.split("-create")[0];
      return await this.$store.dispatch("securityRoleAccess/load", areaName)
    },
    loadLocalRoleAccess(area) {
      let areaName = area || this.$route.name.split("-create")[0];
      this.$store
        .dispatch("securityRoleAccess/getAccess", areaName)
        .then((result) => {
          this.localAccess = [result];
        });
    },
    checkCreator(model) {
      return model && this.userinfo && (!model.id || model.createdBy === this.userinfo.code);
    },
    checkFormAccess(model) {
      return this.checkAccess("edit-option", model);
    },
    checkRestrictionsAccess(model) {
      return this.checkAccess("restrictions-option", model);
    },
    checkDeleteAccess() {
      return this.checkAccess("deleted-fastFilter");
    },
    checkAccess(elem, model) {
      return this.checkRoleAccess(elem, model, this.access);
    },
    checkLocalAccess(elem, model) {
      return this.checkRoleAccess(elem, model, this.localAccess);
    },
    checkRoleAccess(elem, model, access) {
      let isAccess = false;

      if(model && model.disabled && !elem.includes("tab-"))
        return isAccess;

      if (access) {
        let element = access[0] ? access[0][elem] : null;
        if (element) {
          let roleModel = JSON.parse(localStorage.getItem("userRole"));
          let role =
            roleModel && roleModel.systemName ? roleModel.systemName : null;
          if (
            !element[role] ||
            (element[role].includes("CREATOR") && this.checkCreator(model))
          )
            isAccess = true;
        }
      }
      return isAccess;
    },
    wrapperCheckRoles(elemsList, model) {
      let res = elemsList.filter((elem) => {
        if (this.access) {
          let element = this.access[0] ? this.access[0][elem.liteRef] : null;
          let exclude = false;
          if (element) {
            let item = JSON.parse(localStorage.getItem("userRole"));
            let role = item && item.systemName ? item.systemName : null;
            if (
              element[role] == "NONE" ||
              (element[role] == "CREATOR_OR_NONE" && !this.checkCreator(model))
            ) {
              exclude = true;
              return false;
            }
          }
          return !exclude;
        }
      });
      return res;
    },
    startMountAccess(area) {
      let checked = 0;
      const limitLoader = 10
      const interval = setInterval(() => {
        let allRefsReady = this.refsForCheckInMixin.every((elem) => Boolean(this.$refs[elem])
        );
        if (allRefsReady && checked >= limitLoader) {
          this.setAccess(area);
          clearInterval(interval);
        }
        checked++;
      }, 100);
    },
    setAccess(area) {
      this.$store.getters;
      let item = JSON.parse(localStorage.getItem("userRole"));
      let role = item && item.systemName ? item.systemName : null;
      let refs = this.$refs;
      let me = this;
      this.$store
        .dispatch("securityRoleAccess/getAccess", area)
        .then((result) => {
          // CIRCLE BY REFS
          Object.keys(me.$refs).forEach((elem) => {
            if (!this.model.disabled && result && result[elem]) {
              let elemAccesses = result[elem];
              let condition = this.getCondition(elemAccesses, role, elem);
              let element = refs[elem];
              if(element) {
                setCondition(element, condition);
                workCondition(element);
              }
            } else if(this.model.disabled) {
              const buttons = ["action-", "-button"];
              let condition = buttons.some(b => elem.includes(b)) ? "NONE" : "READONLY";
              let element = refs[elem];
              if(element) {
                setCondition(element, condition);
                workCondition(element);
              }
            }
          });
          this.mixinLoadingAccess = false;
        });
    },
    getCondition(elemAccesses, role, elem) {
      let condition = "ALIVE";

      if (elemAccesses && elemAccesses[role])
        if (
          !(elemAccesses[role].includes("CREATOR") && this.checkCreator(this.model))
        )
          condition = elemAccesses[role].includes("CREATOR")
            ? elemAccesses[role].split("_OR_")[1]
            : elemAccesses[role];

      return condition;
    },
  },
};

export default accessMixin;
