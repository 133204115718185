<template>
  <div @click="$emit('clickItem')" :class="['dashboard-filter-item', {'dashboard-filter-item--isActive': isActive}]">
    <div class="dashboard-filter-item__label">
      {{ label }}
    </div>
    <div class="dashboard-filter-item__icons">
      <div>
        <Fa
          v-if="needWarning"
          class="dashboard-filter-item__icon"
          :icon="icons.faExclamationTriangle"
          :style="{ color: '#EE6D62' }"
          size="10px"
        />
      </div>
      <div>
        <ProSpaceIcon
          v-if="isRequired"
          class="dashboard-filter-item__icon"
          :hint="$t('requiredFilter')"
          icon="message-warning"
          iconWidth="14px"
          iconHeight="14px"
          customIconColor="#FF9500"
        />
      </div>
      <div>
        <ProSpaceIconButton
          icon="circle-close"
          @click="$emit('delete')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ProSpaceIcon,
  ProSpaceIconButton,
} from "@prospace/prospace-components-library";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "DashboardFilterItem",
  components: {
    ProSpaceIcon,
    ProSpaceIconButton,
    Fa: FontAwesomeIcon,
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    needWarning: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    icons() {
      return { faExclamationTriangle };
    },
  }
}
</script>

<style scoped lang="scss">
.dashboard-filter-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--prospace-ui-row-border);
  border-radius: 3px;
  background-color: var(--prospace-ui-card);
  box-shadow: 0px 2px 10px 0px rgba(51, 51, 51, 0.07);
  cursor: pointer;

  &__label {
    font-size: 14px;
    font-weight: 500;
  }
  &__icons {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  &__icon {
      height: 14px;
      width: 14px;
      display: flex;
      align-items: center;
      margin-right: 8px;
  }

  &--isActive {
    background-color: var(--prospace-ui-row-select);
    border: 1px solid var(--prospace-ui-main);
  }
}
</style>
