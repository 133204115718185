<template>
  <ProSpaceGeoLayout :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="$t('systemTaskLog')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <SystemLogGrid ref="grid" />
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
} from "@prospace/prospace-components-library";
import SystemLogGrid from "./components/SystemLogGrid";
import _ from "lodash";

export default {
  components: {
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    SystemLogGrid,
  },
  data() {
    return {};
  },
  methods: {
    actionUpdate() {
      this.$refs.grid.update();
    },
  },
};
</script>

<style></style>
