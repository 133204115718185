<template>
  <ProSpaceGeoLayout class="dashboard-restrictions-selections" isHideBottom isHideRight isHideLeft isHideTop >
    <template #center>
      <ProSpaceGeoLayout>
        <template v-slot:top>
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputSwitch
              v-model="model[`isAll${capitalize(restrictionsTypes)}`]"
              :label="$t('allTxt')"
              @update:modelValue="applyIsAllChangedMessage"
            />
            <ProSpaceButton
              ref="addRecipient-button"
              type="secondary"
              icon="edit"
              class="margin-bottom-20"
              :label="$t('editItemTxt')"
              :disabled="model[`isAll${capitalize(restrictionsTypes)}`]"
              @click="toggleSelectModal(true)"
            />
          </ProSpaceVLayout>
        </template>
        <template v-slot:center>
          <ProSpaceMainGrid
            v-if="isRestrictionsSelected"
            ref="selectedGrid"
            type="single"
            isInline
            removeBg
            noMountedRequest
            loading
            getMethod="grid"
            :service="getRestrictionsService"
            :defaultFilter="mainGridFilter"
            defaultSortFild="name"
          >
            <template #columns>
              <Column
                :field="restrictionsTypes === 'roles' ? 'systemName' : 'name'"
                :header="$t(getFieldName)"
                :sortable="restrictionsTypes === 'distributors'"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    :id="restrictionsTypes === 'distributors' ? field.data.code : null"
                    :status="restrictionsTypes === 'distributors' ? { text: field.data.code, type: 'default' } : null"
                    :title="restrictionsTypes === 'roles' ? field.data.displayName : field.data.name"
                    noHover
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceAction
                    icon="circle-close"
                    gray
                    @click="deletionRequestHandler(field.data)"
                  />
                </template>
              </Column>
            </template>
          </ProSpaceMainGrid>
          <ProSpaceEmptyGrid
            v-else
            :icon="!getSomeIsAllSelected ? 'error-message' : 'developer'"
            :title="!getSomeIsAllSelected ? $t('dashboardRestrictionsNoDataTitle') : $t('dashboardRestrictionSelectedAll')"
            :text="getEmptyGridText"
          />
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
  <DistributorSelectModal
    v-model="showDistributorsSelectModal"
    :modalTitle="$t('selectDistributors')"
    :selectedIds="model.distributors"
    :allSelectionText="$t('isAllDistributorsSelectionNews')"
    @applyDistributors="applyDistributorSelectModal"
  />
  <DistributorHierarchySelectModal
    ref="positionsModal"
    class="dashboard-restrictions-selections__hierarchy"
    v-model="showPositionsSelectModal"
    expandAll
    hideRightPanel
    hideTags
    needFilterValues
    modalWidth="525"
    modalHeight="575"
    :titleModal="$t('selectionPositions')"
    :service="positionsService"
    getMethod="allTree"
    selectionMode="checkbox"
    :checkedRows="selectedItems"
    @updateTree:checked="getTreeChecked"
    @apply="applyPositionsSelectModal"
  />
  <RolesSelectModal
    v-model="showRolesSelectModal"
    :service="rolesService"
    :default-filter="defaultFilters.disabled"
    :selectedItems="selectedItems"
    @apply="applyRolesSelectModal"
  />
</template>

<script>
import {
  ProSpaceGeoLayout,
  ProSpaceVLayout,
  ProSpaceMainGrid,
  ProSpaceEmptyGrid,
  ProSpaceInputSwitch,
  ProSpaceButton,
  ProSpaceFlashMessage,
  ProSpaceAction,
  ProSpaceTableHeaderCell,
  Filters,
} from "@prospace/prospace-components-library";

import DistributorSelectModal from "@modals/DistributorSelectModal";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal";
import RolesSelectModal from "@modals/RolesSelectModal.vue";
import Column from "primevue/column";

import { DistributorService } from "@masterdata/services/DistributorService.js";
import { RoleService } from "../../../../../../../Services/Notification/ProSpace.Notification.Frontend/services/RoleService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";

export default {
  name: "DashboardRestrictions",
  components: {
    ProSpaceGeoLayout,
    ProSpaceVLayout,
    ProSpaceMainGrid,
    ProSpaceEmptyGrid,
    ProSpaceInputSwitch,
    ProSpaceButton,
    ProSpaceFlashMessage,
    ProSpaceAction,
    ProSpaceTableHeaderCell,
    Column,

    DistributorSelectModal,
    DistributorHierarchySelectModal,
    RolesSelectModal,
  },
  props: {
    restrictionsTypes: {
      type: String,
      default: 'distributors'
    },
    restrictions: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      distributorsService: new DistributorService(),
      rolesService: new RoleService(),
      positionsService: new DistributorOrgService(),

      defaultFilters: Filters.getDefaultsFilters(),
      mainGridFilter: null,

      model: {
        distributors: [],
        isAllDistributors: false,
        roles: [],
        isAllRoles: false,
        positions: [],
        isAllPositions: false
      },
      selectedItems: null,
      deletionRequestObj: null,
      checkedPositions: null,
      allPositionsList: null,

      showDistributorsSelectModal: false,
      showPositionsSelectModal: false,
      showRolesSelectModal: false,
      needCancelIsAll: true,
      loaded: false,
    };
  },
  watch: {
    restrictions: {
      handler: function(val, oldVal) {
        if (!this.loaded && Object.keys(val).length > 0) {
          this.getData();
          this.loaded = true;
        }
      },
      deep: true,
      immediate: true
    },
    restrictionsTypes: {
      handler: function(val) {
        this.setFilter(val);
        this.getData();
      },
    },
  },
  computed: {
    distributorsFilter() {
      return { id: { or: this.model.distributors }, ...this.defaultFilters.disabled };
    },
    rolesFilter() {
      return { id: { or: this.model.roles }, ...this.defaultFilters.disabled };
    },
    positionsFilter() {
      return { id: { or: this.model.positions } };
    },
    getSimpleGridFilter() {
      return this[`${this.restrictionsTypes}Filter`];
    },
    getRestrictionsService() {
      return this[`${this.restrictionsTypes}Service`]
    },
    isRestrictionsSelected() {
      return  (this.model[this.restrictionsTypes] && this.model[this.restrictionsTypes].length > 0);
    },
    getFieldName() {
      return this.restrictionsTypes.split('').slice(0, -1).join('').toLowerCase();
    },
    getSomeIsAllSelected() {
      return (this.restrictionsTypes === 'distributors' && this.model.isAllDistributors) ||
        (this.restrictionsTypes === 'roles' && this.model.isAllRoles) ||
        (this.restrictionsTypes === 'positions' && this.model.isAllPositions);
    },
    getEmptyGridText() {
      if (this.getSomeIsAllSelected) {
        return this.$t('dashboardRestrictionsSelectedAllText', { arg: this.$t(this.restrictionsTypes, 2) })
      } else {
        return this.$t('dashboardRestrictionsNoDataText',
          { arg: this.$t(`${this.restrictionsTypes}RestrictionType`,
              this.restrictionsTypes === 'distributors' ? 2 : 1) })
      }
    }
  },
  methods: {
    setFilter(type) {
      this.mainGridFilter = { id: { or: this.model[type] }, ...this.defaultFilters.disabled };
    },
    getData() {
      this.model = _.cloneDeep(this.restrictions);
      const { distributors, roles, positions } = this.model;
      if (distributors?.length > 0 || roles?.length > 0 || positions?.length > 0) {
        if (!this.mainGridFilter) {
          this.mainGridFilter = this.getSimpleGridFilter;
        }

        this.$nextTick(() => {
          this.updateSelectedGrid(false, true);
        });
      } else {
        this.$nextTick(() => {
          this.updateSelectedGrid(true, true);
        });
      }
    },
    toggleSelectModal(state) {
      this[`show${this.capitalize(this.restrictionsTypes)}SelectModal`] = state;
      this.$nextTick(() => {
        if (this.model[this.restrictionsTypes]?.length > 0) {
          this.selectedItems = this.getSelectedItems();
        } else {
          this.selectedItems = [];
        }
      });
    },
    updateSelectedGrid(isAll, clear = false) {
      if (!this.$refs.selectedGrid) return;

      this.$refs.selectedGrid.records = [];
      if (!isAll || !clear) this.$refs.selectedGrid.submitDefaultFilter();
    },
    applyIsAllChangedMessage(e) {
      this.$nextTick(() => {
        this.model[this.restrictionsTypes] = [];
        this.model[`isAll${this.capitalize(this.restrictionsTypes)}`] = e;
        this.$emit('update', {
          [this.restrictionsTypes]: this.model[this.restrictionsTypes],
          [`isAll${this.capitalize(this.restrictionsTypes)}`]: e
        });
      });
    },
    deletionRequestHandler(item) {
      this.deletionRequestObj = item;
      this.$emit('deletionRequest', item);
    },
    deleteFromModel() {
      const item = this.deletionRequestObj;
      this.$refs.selectedGrid.records =
        this.$refs.selectedGrid.records.filter((r) => r.id !== item.id);

      this.model[this.restrictionsTypes] = this.model[this.restrictionsTypes].filter(
        (r) => r !== item.id
      );
      this.$emit('delete', { [this.restrictionsTypes]: this.model[this.restrictionsTypes] });
    },
    async applyDistributorSelectModal({items, requestModel}, isAll) {
      this.model.isAllDistributors = isAll;
      if (!isAll) {
        this.model.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorsService.getByMultiple(requestModel);
          this.model.distributors = distributors.data.records.map(x => x.id);
        } else if (requestModel && !requestModel.filter) {
          this.model.distributors = requestModel.records &&
          requestModel.records.every(r => r)
            ? requestModel.records.map(r => r.id)
            : [];
        } else {
          this.model.distributors = items;
        }
      } else {
        this.model.distributors = [];
      }
      this.setFilter('distributors');
      this.$nextTick(() => {
        this.updateSelectedGrid(isAll);
      });
      this.$emit('update', { distributors: this.model.distributors, isAllDistributors: isAll });
      this.toggleSelectModal(false);
    },
    refreshViewTree() {
      this.$nextTick(() => {
        if(this.$refs.positionsModal)
          this.$refs.positionsModal.refresh();
      });
    },
    applyPositionsSelectModal() {
      const isAll = this.checkedPositions?.length > 0
        ? this.checkedPositions.every((pos, i, arr) => {
            return (pos.checked in this.allPositionsList) &&
              Object.keys(this.allPositionsList).length === arr.length;
          })
        : false;
      if (isAll) {
        this.model.isAllPositions = true;
        this.model.positions = [];
      } else {
        this.model.isAllPositions = false;
        this.model.positions = this.checkedPositions?.map(c => c.checked);
        this.selectedItems = [];
      }
      this.setFilter('positions');
      this.$nextTick(() => {
        this.updateSelectedGrid(isAll);
      });
      this.$emit('update', { positions: this.model.positions, isAllPositions: this.model.isAllPositions });
      this.toggleSelectModal(false);
    },
    applyRolesSelectModal(roles) {
      if (!roles) return;
      const { values, isAll } = roles;
      if (!values) return;
      this.model.roles = !isAll ? values?.map(r => r.id) : [];
      this.model.isAllRoles = isAll;
      this.setFilter('roles');
      this.$nextTick(() => {
        this.updateSelectedGrid(false);
      });
      this.$emit('update', { roles: this.model.roles, isAllRoles: isAll });
      this.toggleSelectModal(false);
    },
    getSelectedItems() {
      return this.$refs.selectedGrid?.records;
    },
    getTreeChecked({ checked, list }) {
      this.checkedPositions = checked;
      this.allPositionsList = list;
    },
    capitalize: (text) => text[0].toUpperCase() + text.toLowerCase().slice(1)
  },
};
</script>
<style lang="scss">
.dashboard-restrictions-selections {
  &__hierarchy {
    .prospace-tree {
      .prospace-tag {
        display: none;
      }
    }
  }
  .main-grid {
    border: 1px solid var(--prospace-ui-border-color);
    border-radius: 8px;
    overflow: hidden;
    .p-datatable-table {
      .p-datatable-thead {
        height: 32px;
        border: none;
        box-shadow: none;
        background-color: var(--prospace-ui-bg) !important;
        & > tr {
          & > th {
            padding-left: 0;
            padding-top: 10px;
            background-color: transparent;
          }
        }
      }
      .p-datatable-tbody {
        & > tr {
          &:not(.p-datatable-emptymessage) {
            height: 40px !important;
            margin: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
            width: calc(100% - 4px) !important;
            box-shadow: none;
          }
          &:not(.p-highlight) {
            border: 1px solid var(--prospace-ui-border-color);
          }
        }
        .prospace-action {
          &:hover {
            .prospace-icon {
              background-color: var(--prospace-ui-main) !important;
            }
          }
        }
      }
      .p-sortable-column {
        background-color: transparent;
        &:not(.p-highlight) {
          &:hover {
            background-color: transparent;
          }
        }
      }

    }
    .prospace-table-header-cell {
      &__status-id {
        .prospace-tag {
          &:first-child {
            display: none;
          }
        }
      }
      &__title {
        &--inline {
          font-size: 12px;
          line-height: 36px;
        }
      }
    }
  }

  .prospace-scrollbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .prospace-empty-grid {
    &__text {
      opacity: 1;
      color: var(--prospace-text-gray);
      &--margin {
        margin-top: 10px;
      }
    }
  }
}
</style>
