<template>
  <ProSpaceGeoLayoutOld
    class="dashboard-grid-layout"
    isHideBottom isHideTop
    :isHideCenter="isHideCenter"
    :class="{'prospace-geo-layout--ishideCenter' : isHideCenter}"
  >
    <template #left>
      <ProSpaceLeftPanel
        :panelName="sourceHeader.title"
        :class="['dashboard-grid-layout__panel', 'dashboard-grid-layout__panel--mainSource', {'dashboard-grid-layout__panel--hideLeftTitle': !sourceHeader.title}]"
        :searchable="sourceHeader.search"
        :searchPlaceholder="$t('searchTxt')"
        @search="searchLeftHandler">
        <template #center>
          <slot name="left"></slot>
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #center>
      <ProSpaceLeftPanel
        :panelName="selectedFiltersHeader.title"
        class="dashboard-grid-layout__panel dashboard-grid-layout__panel--selectedFilters"
        :searchable="selectedFiltersHeader.search"
        :searchPlaceholder="$t('searchTxt')"
        @search="searchCenterHandler"
        >
        <template #center>
          <slot name="center"></slot>
        </template>
        <template #top-center>
          <ProSpaceTag
            v-if="selectedFiltersHeader.counter"
            :text="selectedFiltersHeader.counter > 0 ? selectedFiltersHeader.counter.toString() : ''"
            class="dashboard-grid-layout__counter"
            big-text
          />
          <ProSpaceAction
            v-if="selectedFiltersHeader.clearButtonVisible"
            class="dashboard-grid-layout__action"
            :text="$t('clear')"
            :disabled="false"
            gray
            @click="selectedFiltersHeader.clearButtonHandler"
            />
        </template>
      </ProSpaceLeftPanel>
    </template>
    <template #right>
      <ProSpacePanelBlock
        ref="right"
        :class="['dashboard-grid-layout__right', { 'dashboard-grid-layout__right--scrollable': scrollableRight }]"
        :header="filterSettingsHeader"
      >
        <template #header >
          <ProSpaceAction v-if="filterSettingsHeader.action" :text="filterSettingsHeader.action" @click="$emit('clickAction')" />
        </template>
        <template v-slot>
          <slot name="right"></slot>
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceGeoLayoutOld>
</template>

<script>
import {
  // ProSpaceGeoLayoutOld,
  ProSpacePanelBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLeftPanel,
  ProSpaceAction,
  ProSpaceTag,
} from "@prospace/prospace-components-library";
import ProSpaceGeoLayoutOld from "./GeoLayoutOld";
export default {
  name: "DashboardModalLayout",
  components: {
    ProSpaceGeoLayoutOld,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpacePanelBlock,
    ProSpaceLeftPanel,
    ProSpaceAction,
    ProSpaceTag,
  },
  emits: ['searchLeft', 'searchCenter'],
  props: {
    panels: {
      type: Array,
      default: () => [],
    },
    isHideCenter: {
      type: Boolean,
      default: false
    },
    scrollableRight: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    sourceHeader() {
      return this.panels[0];
    },
    selectedFiltersHeader() {
      return this.panels[1];
    },
    filterSettingsHeader() {
      return this.panels[2];
    },
  },
  methods: {
    searchLeftHandler(e) {
      this.$emit('searchLeft', e);
    },
    searchCenterHandler(e) {
      this.$emit('searchCenter', e);
    }
  },
  mounted() {
    const panelBody = this.$refs.right;
    if (panelBody && this.scrollableRight) {
      panelBody
        .$el
        .querySelector('.prospace-panel-block__body')
        .classList
        .add('prospace-scrollbar');
    }
  },
}
</script>

<style lang="scss">
.dashboard-grid-layout {
  height: 100%;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr !important;

  .prospace-geo-layout__center, .prospace-geo-layout__left, .prospace-geo-layout__right {
    padding-left: 0;
    padding-right: 0;
  }

  &.prospace-geo-layout {
    &--ishideCenter {
      grid-template-columns: 1fr 1fr !important;
      grid-template-areas: "left right" !important;
      grid-template-rows: auto auto !important;
      column-gap: 20px !important;
      .prospace-left-panel,
      .prospace-panel-block {
        width: 100% !important;
      }
    }
  }

  .prospace-left-panel {
    &__top-section {
      border-bottom: none;
      &:first-child {
        padding-top: 12px;
        padding-bottom: 2px;
        height: 33px !important;
      }
    }
  }

  &__panel {
    height: 100%;
    background-color: var(--prospace-ui-gray-card-bg) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none !important;

    .prospace-left-panel__top-section--have-panel {
      justify-content: flex-start !important;
      gap: 5px !important;
    }

    .prospace-left-panel__top--name {
      font-size: 13px;
    }
    &--mainSource {
      border-top-right-radius: 0;
      border-right: none !important;
    }
    &--hideLeftTitle {
      border-right: 1px solid var(--prospace-ui-border-color) !important;
      border-top-right-radius: 5px;
      .prospace-geo-layout__top {
        display: none;
      }
    }
    &--selectedFilters {
      background-color: var(--prospace-ui-tab-main-active);
      border-top-left-radius: 0;
    }
    &--mainSource, &--selectedFilters {
      .prospace-left-panel__top-section--search {
        &:empty {
          display: none;
        }
      }
    }
    .prospace-left-panel__top_burger-button {
      display: none;
    }
  }
  &__action {
    margin-left: auto;
    .prospace-action-text {
      color: var(--prospace-ui-menu-icon);
    }
  }
  &__right {
    padding-left: 10px;
    &--scrollable {
      height: 100%;
      overflow: hidden;
      & > .prospace-panel-block {
        &-header {
          position: sticky;
          margin-bottom: 20px;
        }
        &__body {
          height: calc(100% - 44px);
          overflow: scroll;
          padding: 0 10px!important;
          margin-bottom: -5px;
          &:empty {
            display: none;
          }
        }
      }
    }
    .prospace-panel-block {
      &__body {
        padding: 10px 0 0 0;
      }
      &-list__row {
        margin-bottom: 20px;
        border-bottom: none;
        .prospace-panel-block-item__content_text {
          font-size: 12px;
        }
      }
      &-list__item {
        &--withoutTitle {
          .prospace-panel-block-item__content {
            margin-top: 0;
          }
        }
      }
    }
    .prospace-input-switch__wrapper label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.dashboard-source-select {
    .p-tree {
      .p-treenode-children {
        padding-left: 23px;
        .p-checkbox {
          display: none;
        }
      }
      .p-tree-container {
        .p-treenode {
          .p-treenode-content {
            padding-right: 0;
            .p-treenode-label {
              padding-right: 5px;
            }
          }
        }
      }
    }
    .prospace-tree {
      &:not(.prospace-tree-nested) {
        .prospace-label-with-counter {
          width: calc(100% - 15px);
        }
      }
    }
    .prospace-geo-layout__right {
      overflow: hidden;
    }
  }

  .p-tree {
    .p-tree-wrapper {
      overflow-x: hidden;
      .p-tree-container {
        overflow-x: hidden;
        .p-treenode {
          .p-treenode-content {
            margin: 0 10px;
          }
          .p-treenode-children {
            margin-right: 10px;
            & > .p-treenode {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .prospace-tree {
    .prospace-empty-grid {
      padding-left: 10px;
      padding-right: 10px;
      .prospace-empty-grid__title--margin {
        margin-top: 15px;
      }
      .prospace-empty-grid__text {
        margin-top: 5px;
        color: var(--prospace-text-gray);
        opacity: 1;
      }
    }
  }
}

</style>
