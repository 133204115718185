<template>
  <ProSpaceHLayout class="dashboard-filter-modal-body__columns" :gap="20">
    <ProSpaceVLayout :gap="20" :class="{'column': isTwoColumns}">
      <slot :filterList="filterColumns.left"/>
    </ProSpaceVLayout>
    <ProSpaceVLayout v-if="isTwoColumns" class="column" :gap="20">
      <slot :filterList="filterColumns.right"/>
    </ProSpaceVLayout>
  </ProSpaceHLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceHLayout,
} from "@prospace/prospace-components-library";

export default {
  name: "DashboardModalFilterList",
  components: {
    ProSpaceVLayout,
    ProSpaceHLayout,
  },
  props: {
    filterColumns: {
      type: Object,
      required: true
    }
  },
  computed: {
    isTwoColumns() {
      return this.filterColumns.right?.length > 0;
    }
  },
}
</script>

<style lang="scss" scoped>
.column {
  width: calc(50% - 10px);
}
</style>