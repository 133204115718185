<template>
  <div class="dashboard-panel-restrictions">
    <ProSpacePanelBlock :header="headerOverview">
      <template v-slot:header>
        <ProSpaceAction v-if="checkRestrictionsAccess(dashboard)" :text="$t('editItemTxt')" @click="onClickEdit" />
      </template>
    </ProSpacePanelBlock>
    <ProSpaceVLayout :gap="10">
      <ProSpacePanelBlock v-for="block in restrictionsBlocks" :header="getBlockHeader(block)">
        <template v-slot >
          <ProSpaceSimpleGrid
            v-show="loaded && this.restrictions[block.name]?.length > 0"
            :ref="`simpleGrid-${block.name}`"
            :getMethod="block.method"
            :service="block.service"
            :defaultFilter="block.filter"
            :headers="block.fields"
            :limit="5"
            :deletable="false"
            :btnText="$t('viewMore')"
            :modalTitle="$t(block.name)"
            :cancelTxt="$t('closeTxt')"
            :applyTxt="null"
            :defaultModal="block.defaultModal"
            @openModal="$emit('openModalViewMore', { name: block.name, filter: block.filter })"
          />
          <ProSpaceAction
            v-show="loaded && restrictions[`isAll${this.capitalize(block.name)}`]"
            :text="block.action"
            @click="onClickAll(block.name)"
          />
        </template>
      </ProSpacePanelBlock>
    </ProSpaceVLayout>
  </div>
</template>

<script>
import {
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceVLayout,
} from "@prospace/prospace-components-library";

import { DistributorService } from "@masterdata/services/DistributorService.js";
import { RoleService } from "../../../../../../../Services/Notification/ProSpace.Notification.Frontend/services/RoleService";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";


export default {
  name: "DashboardPanelRestrictions",
  components: {
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceVLayout,
  },
  props: {
    dashboard: {
      type: Object,
      default: null
    },
    checkRestrictionsAccess: {
      type: Function
    }
  },
  data() {
    return {
      // services
      distributorsService: new DistributorService(),
      rolesService: new RoleService(),
      positionsService: new DistributorOrgService(),

      // restrictions ids: distributors, positions, roles
      restrictions: {
        distributors: [],
        positions: [],
        roles: [],
        isAllRoles: false,
        isAllDistributors: false,
        isAllPositions: false,
      },
      loaded: false,
    }
  },
  watch: {
    dashboard: {
      handler: async function(val) {
        await this.getRestrictions();
        const types = ['distributors', 'roles', 'positions'];
        for (const type in types) {
          await this.setServices(types[type]);
        }
        this.loaded = true;
        this.$nextTick(() => {
          this.getData();
        });
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    headerOverview() {
      return {
        title: this.$t("permissions"),
        isExpand: false,
      };
    },
    restrictionsBlocks() {
      return [
        {
          name: "distributors",
          header: {
            title: this.$t("distributors"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("code"),
              value: "code",
              f: (item) => item,
            },
            {
              name: this.$t("distributor"),
              value: "name",
              f: (item) => item,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allDistributors"),
          defaultModal: false,
        },
        {
          name: "roles",
          header: {
            title: this.$t("roles"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("role"),
              value: "displayName",
              f: (item) => item,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allRoles"),
          defaultModal: true,
        },
        {
          name: "positions",
          header: {
            title: this.$t("positions"),
            isExpand: true,
          },
          fields: [
            {
              name: this.$t("position"),
              value: "name",
              f: (item) => item,
            },
          ],
          method: null,
          service: null,
          filter: null,
          action: this.$t("allPositions"),
          defaultModal: false,
        }
      ]
    },
  },
  methods: {
    getData() {
      this.restrictionsBlocks.forEach(block => {
        const { name } = block;
        if (name && !this.restrictions[`isAll${this.capitalize(name)}`]) {
          const grid = this.$refs[`simpleGrid-${name}`];
          grid[0].getServerData()
        }
      })
    },
    onClickEdit() {
      this.$emit('edit:restrictions', this.dashboard);
    },
    onClickAll(name) {
      this.$emit('show:modal', name)
    },
    async getRestrictions() {
      const { restrictions } = this.dashboard;
      if (restrictions) {
        this.restrictions = restrictions;
      }
    },
    setServices(name) {
      const type = this.restrictionsBlocks.find(block => block.name === name);
      if (type) {
        type.service = this[`${name}Service`];
        type.filter = { id: { or: this.restrictions[name] } };
        type.method = 'grid'
      }
    },
    capitalize: (text) => text[0].toUpperCase() + text.toLowerCase().slice(1),
    getBlockHeader(block) {
      if (this.restrictions[block.name]?.length > 0) {
        return block.header;
      } else {
        block.header.isExpand = false;
        return block.header;
      }
    }
  },
}
</script>

<style lang="scss">
.dashboard-panel-restrictions {
  .prospace-panel-block-header {
    margin-bottom: 0;
    & + .prospace-panel-block__body {
      margin-top: 10px;
    }
  }
  .p-datatable {
    .prospace-list-items-table {
      .p-datatable-tbody {
        &  > tr {
          height: 34px;
        }
      }
    }
  }
  .prospace-panel-block {
    &:last-child {
      padding-bottom: 10px;
    }
  }
}
</style>
