<template>
  <ProSpaceFlashMessage
    v-model="model"
    type="default"
    icon="flash-warning"
    :title="$t('deletingPosition')"
    @cancel="handlerCancel"
  >
    <template #default>
      <span>
        {{ $t("questionDeletingPosition") + " " }}
        <span class="text-color-accent">{{ node.name }}</span
        >?
      </span>
    </template>
    <template #footer>
      <ProSpaceHLayout :gap="5" justify-content="center">
        <ProSpaceButton
          type="tertiary"
          :label="$t('cancelInf')"
          @click="handlerCancel"
        />
        <ProSpaceButton
          type="primary"
          :label="$t('delete')"
          @click="handlerApply"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceFlashMessage>
</template>

<script>
import {
    ProSpaceFlashMessage,
    ProSpaceHLayout,
    ProSpaceButton
} from "@prospace/prospace-components-library";
export default {
    components: {
        ProSpaceFlashMessage,
        ProSpaceHLayout,
        ProSpaceButton
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        node: {
            type: Object,
            required: true
        }
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    },
    methods: {
        handlerCancel() {
            this.$emit('update:node', null)
            this.$emit('update:modelValue', false)
            this.$emit('cancel')
        },
        handlerApply() {
            this.$emit('update:modelValue', false)
            this.$emit('apply')
        }
    }
}
</script>