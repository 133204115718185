<template>
  <ProSpaceGeoLayout class="workflows" :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="layoutName">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxt')"
            @click="showCreateModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="workflowService.constructor.name + 'grid'"
            @apply="submitFilter"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            ref="filterPanel"
            :filter-model="['name', 'description']"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceFilterContainsChips
                v-model="filter.name"
                :placeholder-value="$t('name')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.status"
                :service="statusService"
                getMethod="get"
                optionLabel="text"
                optionValue="status"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('status')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="workflowService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="workflowService"
            :noMountedRequest="true"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="name"
                :header="$t('workflowName')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    style="cursor: pointer"
                    @click-on-title="toItem(field.data)"
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.name"
                    :description="`${field.data.description || ''}`"
                  />
                </template>
              </Column>
              <Column field="version" :header="$t('version')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.version }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column
                v-if="dotsOptions.length > 0"
                field="actions"
                class="column-end column-end-30"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  />
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <WorkflowPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="workflowService"
        :secondService="workflowItemService"
        :options="dotsOptions"
        @delete-item="deleteItem"
        @edit="editItem"
        @history-item="showHistory"
      />
      <ProSpaceGridModalForm
        v-model="showRoleModal"
        ref="workflow_items"
        width="410"
        :id="masterId"
        :key="masterId"
        :service="workflowItemService"
        getMethod="grid"
        colName="stepNo"
        colDesc="name"
        header="Steps"
        :onlyWatchMode="true"
        :applyTxt="$t('applyTxt')"
        :cancelTxt="$t('cancelInf')"
        :searchTxt="$t('searchTxt')"
      >
      </ProSpaceGridModalForm>
      <ProSpaceModal
        :key="`validate-modal-${showModal}`"
        v-model="showModal"
        width="410"
        :icon="isEdit ? 'edit' : 'plus'"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
        :validate-local="$t"
        :validate-model="modalModel"
        :validate-config="{
          name: { required },
          description: { required },
        }"
      >
        <template #title>
          {{ isEdit ? $t("editItemTxt") : "" }}
          {{ isCreate ? $t("createItemTxt") : "" }} {{ $t("workflowLower") }}
        </template>
        <template #default="{ v }">
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              :label="$t('name')"
              v-model="modalModel.name"
              :placeholder="$t('workflowName')"
              :invalid="v.name.$error"
              :invalid-messages="v.name.$errors"
              @blur="v.name.$touch"
            ></ProSpaceInputText>
            <ProSpaceInputText
              :label="$t('description')"
              v-model="modalModel.description"
              :placeholder="$t('workflowDescription')"
              :invalid="v.description.$error"
              :invalid-messages="v.description.$errors"
              @blur="v.description.$touch"
            ></ProSpaceInputText>
          </ProSpaceVLayout>
        </template>
      </ProSpaceModal>
      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="WorkflowSettingApiModel"
        @close="closeHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteWorkflow', { name: deletingModel.name })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceInputText,
  ProSpaceGridModalForm,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ProSpaceModal,
  event_bus,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceRightPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceFilterPanel,
  ProSpaceEmptyGrid,
  ProSpaceVLayout,
  ProSpaceDots,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import WorkflowPanel from "./components/WorkflowPanel.vue";
import { WorkflowService } from "../../services/WorkflowService.js";
import { WorkflowItemService } from "../../services/WorkflowItemService.js";
import { StatusService } from "../../services/StatusService.js";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import _ from "lodash";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ProSpaceHistoryModal,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceInputText,
    ProSpaceGridModalForm,
    ProSpaceMainGrid,
    ProSpaceModal,
    Column,
    ProSpaceEmptyGrid,
    WorkflowPanel,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceRightPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceFilterPanel,
    ProSpaceVLayout,
    ProSpaceDots,
    ProSpaceDefaultTextColumn,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      v$: useVuelidate(), grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    };
  },
  validations() {
    return {
      modalModel: {
        name: { required },
        description: { required },
      },
    };
  },
  data() {
    return {
      showHistoryModal: false,
      showModal: false,
      showRoleModal: false,

      emitter: event_bus,
      panelModel: {},
      panelModels: [],
      // tabCountField: "roles",

      // Service
      workflowService: new WorkflowService(),
      workflowItemService: new WorkflowItemService(),
      statusService: new StatusService(),
      // securityUserRole: new SecurityUserRole(),

      // Modal
      modalModel: {
        name: null,
        description: null,
      },
      isEdit: false,
      isCreate: false,

      //InlinePanel
      masterId: null,

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },
      showDeleteModal: false,
      deletingModel: null,
    };
  },
  methods: {
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "active":
          type = "success";
          break;
        case "draft":
          type = "info";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: status[0].toUpperCase() + status.toLowerCase().slice(1),
        type,
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    toItem(data) {
      const url = `/workflow/workflowsetting/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    actionUpdate() {
      this.$refs.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    emitFilter() {
      this.filterPanel.submitFilter();
      this.selectedMultipleClear();
    },
    showCreateModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        name: null,
        description: null,
        status: "DRAFT",
      };
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "edit";
      }
      this.workflowService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.workflowService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "checkboxes",
          service: new StatusService(),
          getMethod: "get",
          model: "status",
          label: this.$t("status"),
          optionValue: "status",
          optionLabel: "text",
          selected: {},
        },
        {
          type: "int",
          model: "version",
          label: this.$t("version"),
          optionValue: "version",
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        { label: this.$t("active"), field: "status", value: "ACTIVE" },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    layoutName() {
      return this.$t("workflow");
    },
    isDeletedTab() {
      return this.modelFastFilter.label === this.$t("deleted");
    },
  },
};
</script>
