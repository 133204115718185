<template>
    <ProSpaceHLayout class="home-dashboard-button" :gap="10">
        <ProSpaceIcon class="home-dashboard-button__icon" :icon="icon" type="secondary" />
        <div class="text-color-main text-size-13 text-bold text-ellipsis" v-hint>
            {{ label }}
        </div>
    </ProSpaceHLayout>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceIcon,
  Hint,
} from "@prospace/prospace-components-library";
export default {
    name: "HomeDashboardButton",
    components: {
        ProSpaceHLayout,
        ProSpaceIcon,
    },
    directives: {
        hint: Hint
    },
    props: {
        label: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
.home-dashboard-button {
    padding: 20px;
    background: var(--prospace-ui-gray-card-bg);
    border-radius: 5px;
    cursor: pointer;
    &__icon {
        min-width: 20px;
    }
}
</style>