<template>

  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
      :title="elementName"
      :text="$t('elementType')"
    />
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #content>
        <ProSpaceDropdown
          ref="typeDropdown"
          v-model="localElementType"
          :options="elementTypeDropdownOptions"
          :placeholder="$t('elementType')"
          :invalid="validation.type.$error"
          :invalid-messages="validation.type.$errors"
          propValue="systemName"
          propLabel="name"
          @change="handleTypeChanged"
          @blur="validation.type.$touch"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item prospace-panel-block-list__item--withoutTitle"
      :text="$t('value')"
    />
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #content>
        <ProSpaceDropdown
          v-if="localElementType === 'field'"
          ref="valueDropdown"
          v-model="localElementValue"
          propValue="systemName"
          propLabel="name"
          :options="valueOptions"
          :placeholder="$t('value')"
          :invalid="validation.value.$error"
          :invalid-messages="validation.value.$errors"
          @blur="validation.value.$touch"
        />
        <ProSpaceInputText
          v-if="localElementType === 'label'"
          v-model="localElementValue"
          :placeholder="$t('value')"
          :invalid="validation.value.$error"
          :invalid-messages="validation.value.$errors"
          @blur="validation.value.$touch"
        />
        <ProSpaceInputText
          v-if="localElementType === 'none'"
          v-model="localElementValue"
          :disabled="true"
          :placeholder="$t('value')"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row" v-if="showElementLink">
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item prospace-panel-block-list__item--withoutTitle"
      :text="$t('link')"
    />
    <ProSpacePanelBlockItem
      class="prospace-panel-block-list__item"
    >
      <template #content>
        <ProSpaceInputText
          v-model="localElementLink"
          :disabled="localElementType === 'none'"
          :placeholder="$t('link')"
        />
      </template>
    </ProSpacePanelBlockItem>
  </div>
  <div class="prospace-panel-block-list__row prospace-panel-block-list__row--withoutMarginAction">
    <ProSpaceAction
      v-if="needColor"
      :text="$t('addColorSelection')"
      @click="handleAddColorSelection"
    />
  </div>

  <DashboardModalColorSelection
    v-if="editingColor"
    v-model="editColor"
    :color="editingColor"
    :textOnly="isColorTextOnly"
    :fieldType="localElementType"
    @update:color="updateColor"
  />
</template>

<script>
import {
  ProSpaceDropdown,
  ProSpacePanelBlockItem,
  ProSpaceAction,
  ProSpaceInputText
} from "@prospace/prospace-components-library";

import DashboardModalColorSelection from "./DashboardModalColorSelection.vue";
export default {
  name: "DashboardLayoutElementSettings",
  components: {
    ProSpacePanelBlockItem,
    ProSpaceDropdown,
    ProSpaceAction,
    ProSpaceInputText,
    DashboardModalColorSelection
  },
  props: {
    elementName: {
      type: String,
      required: true
    },
    elementType: {
      type: String,
      default: null
    },
    elementValue: {
      type: String,
      default: null
    },
    elementLink: {
      type: String,
      default: null
    },
    color: {
      type: Object,
      default: null
    },
    valueOptions: {
      type: Array,
      required: true
    },
    needColor: {
      type: Boolean,
      default: true
    },
    isColorTextOnly: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object,
      required: true
    },
    createDefaultLayoutColorSettings: {
      type: Function,
      required: true,
    },
    showElementLink: {
      type: Boolean
    }
  },
  data() {
    return {
      editColor: false,
      showModal: false
    }
  },
  computed: {
    elementTypeDropdownOptions() {
      return [
        {
          systemName: "field",
          name: this.$t("field"),
        },
        {
          systemName: "label",
          name: this.$t("lettering"),
        },
        {
          systemName: "none",
          name: this.$t("none"),
        },
      ];
    },
    localElementType: {
      get() {
        return this.elementType
      },
      set(newVal) {
        this.$emit("update:elementType", newVal)
      }
    },
    localElementValue: {
      get() {
        return this.elementValue
      },
      set(newVal) {
        this.$emit("update:elementValue", newVal)
      }
    },
    localElementLink: {
      get() {
        return this.elementLink
      },
      set(newVal) {
        this.$emit("update:elementLink", newVal)
      }
    },
    editingColor: {
      get() {
        if (this.editColor) return this.color;
        else return null;
      },
      set(newVal) {
        this.$emit("update:color", newVal)
      }
    }
  },
  watch: {
    valueOptions: {
      handler(val) {
        if(this.$refs.valueDropdown)
          this.$refs.valueDropdown.localOptions = val
      }
    }
  },
  methods: {
    handleAddColorSelection() {
      this.editColor = true;
    },
    updateColor(color) {
      this.editingColor = color
    },
    handleTypeChanged(type) {
      this.localElementValue = null;
      if (type.systemName === 'field' && this.$refs.valueDropdown)
        this.$refs.valueDropdown.localOptions = this.valueOptions;

      this.editingColor = this.createDefaultLayoutColorSettings();

      if (this.showElementLink && type.systemName === 'none') {
        this.localElementLink = "";
      }
    },
  }
}
</script>
