<template>
  <ProSpaceHLayout v-if="showTaskInfo" :gap="20" justify-content="flex-start">
    <ProSpaceTag v-if="task.status"
      :text="task.status"
      :type="getStatus(task.status)"
    />
    <ProSpaceTextBlock
      v-if="task.lastExecution"
      :top="$t('lastExecution')"
      :bottom="task.lastExecution"
    />
    <ProSpaceTextBlock
      :top="$t('createdAtTxt')"
      :bottom="getFormatDate(task.createdAt)"
    />
    <ProSpaceTextBlock
      :top="$t('createdByTxt')"
      :bottom="task.createdBy"
    />
  </ProSpaceHLayout>
  <ProSpaceHLayout v-else-if="showTriggerInfo" :gap="10" justify-content="flex-start">
    <ProSpaceTag
      v-if="trigger.status"
      :text="trigger.status"
      :type="getStatus(trigger.status)"
    />
    <ProSpaceTextBlock
      v-if="trigger.lastExecution"
      :top="$t('lastStart')"
      :bottom="trigger.lastExecution"
    />
    <ProSpaceTextBlock
      v-if="trigger.nextExecution"
      :top="$t('nextStart')"
      :bottom="trigger.nextExecution"
    />
  </ProSpaceHLayout>
</template>

<script>
import {
  ProSpaceHLayout,
  ProSpaceTextBlock,
  ProSpaceTag
} from "@prospace/prospace-components-library";
import { mapGetters } from "vuex";
export default {
  emits: ["update:days"],
  components: {
    ProSpaceHLayout,
    ProSpaceTextBlock,
    ProSpaceTag
  },
  props: {
    task: {
      type: Object
    },
    trigger: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getFormatDate: "userinfoStore/getFormatDate"
    }),
    showTaskInfo() {
      return this.task && !this.trigger;
    },
    showTriggerInfo() {
      return !!this.trigger;
    }
  },
  methods: {
    getStatus() {
      return 'default'
    }
  }
};
</script>

<style>
</style>
