<template>
  <ProSpaceVLayout :gap="10" is-inline-h>
    <ProSpacePanelBlock :header="headerClear" v-if="typeof selectedCount === 'string'">
      <template #header>
        <ProSpaceAction :text="$t('clear')" @click="selectedActionReset" />
      </template>
    </ProSpacePanelBlock>
    <ProSpacePanelBlock v-if="selectedClude?.items?.length > 0" :header="headerClude">
      <template #header>
        <ProSpaceAction :text="$t('clear')" @click="clickHandlerClear" />
      </template>
      <ProSpaceSimpleGrid
        :headers="items"
        :items="selectedClude.items"
        :btnText="$t('viewMore')"
        :defaultModal="false"
        @open-modal="modelModal = true"
      />
      <ProSpaceModal
        v-model="modelModal"
        icon="parameters"
        :title="
          $t(
            `${selectedClude.isAll ? 'listExcluded' : 'listIncluded'}`
          )
        "
        :contentStyle="{
          width: '50vw',
          height: '418px',
        }"
        applyTxt=""
        :cancelTxt="$t('closeTxt')"
      >
        <ProSpaceSimpleGrid
          :headers="items"
          :items="selectedClude.items"
          :btnText="$t('viewMore')"
          :limit="null"
        />
      </ProSpaceModal>
    </ProSpacePanelBlock>
  </ProSpaceVLayout>
</template>

<script>
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import {
  ProSpacePanelBlock,
  ProSpaceAction,
  ProSpaceSimpleGrid,
  ProSpaceModal,
  ProSpaceVLayout
} from "@prospace/prospace-components-library";
export default {
  components: {
    ProSpacePanelBlock,
    ProSpaceAction,
    ProSpaceSimpleGrid,
    ProSpaceModal,
    ProSpaceVLayout
  },
  name: "PanelBlockSelectedItems",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modelModal: false
    }
  },
  setup() {
    const { selectedClude, selectedActionClear, selectedActionReset, selectedCount } = useMultiSelectRightPanel()
    return {
      selectedClude, selectedActionClear, selectedActionReset, selectedCount
    }
  },
  computed: {
    headerClude() {
      return {
        title: this.$t(`${this.selectedClude.isAll ? "itemsExcluded" : "itemsIncluded"}`),
        counter: this.selectedClude.items.length,
        showCounter: true,
      };
    },
    headerClear() {
      return {
          title: this.$t("itemsSelectAll"),
          isExpand: false,
      };
    }
  },
  methods: {
    clickHandlerClear() {
      this.selectedActionClear();
      this.$emit('clear')
    }
  }
};
</script>

<style></style>
