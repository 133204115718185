<template>
  <ProSpaceVLayout :gap="10">
    <ProSpaceGeoLayout class="geo-row" style="margin-left: 10px;">
      <template v-slot:left>
        <ProSpaceTextBlock
          :top="$t('identification')"
          :bottom="$t('identificationInformation')"
          :gap="10"
          type="third"
          break-word
        />
      </template>
      <template v-slot:center>
        <ProSpaceVLayout :gap="20">
          <ProSpaceDropdown
            v-model="task.context.JobType"
            :label="$t('taskType')"
            :placeholder="$t('taskType')"
            :service="taskTypeService"
            filter
            getMethod="get"
          />
          <ProSpaceInputText
            v-model="task.description"
            :label="$t('description')"
            resizeOnlyVertical
            isTextarea
          />
        </ProSpaceVLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceVLayout>
</template>

<script>
import {
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceInputSwitch,
  ProSpaceDropdown,
  ProSpaceInputText
} from "@prospace/prospace-components-library";
import { TaskTypeService } from "../../../../services/TaskTypeService";

export default {
  components: {
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceInputSwitch,
    ProSpaceDropdown,
    ProSpaceInputText
  },
  props: {
    task: {
      type: Object,
      required: true,
      default: {}
    },
    isCreate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      taskTypeService: new TaskTypeService(),
    }
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.flex-field {
  display: flex;
  align-items: center;
  gap: 3px;
}
</style>
