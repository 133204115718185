<template>
  <ProSpaceModal
    :key="`validate-modal-${show}`"
    class="menuitems-modal-add"
    v-model="show"
    :contentStyle="{ width: '410px', height: '450px' }"
    :icon="mode === 'create' ? 'plus' : 'edit'"
    :title="`${mode === 'create' ? $t('newTxt') : $t('editItemTxt')} ${$t(
      'menuItemL'
    )}`"
    :hide-footer="loading"
    @update:modelValue="checkEvent"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :validate-local="$t"
    :validate-model="model"
    :validate-config="{
      title: { required },
    }"
  >
    <template #default="{ v }">
      <ProSpaceTabs
        :tabs="tabs"
        ref="tabs"
        v-loading="loading"
        @select-tab="selectStep"
      >
        <template #tab-1>
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              placeholder="name"
              :label="$t('title')"
              v-model="model.title"
              :invalid="v.title.$error"
              :invalid-messages="v.title.$errors"
              @blur="v.title.$touch"
            />
            <ProSpaceInputText
              :placeholder="$t('path')"
              :label="$t('path')"
              v-model="model.path"
            />
            <ProSpaceDropdown
              :label="$t('parent')"
              :placeholder="$t('parent')"
              :service="menuItemsService"
              getMethod="getPaths"
              :argMethod="model"
              v-model="model.parentId"
            />
            <ProSpaceDropdown
              :label="$t('icon')"
              :service="menuItemsService"
              getMethod="getIcons"
              v-model="model.icon"
            >
              <template #value="slotProps">
                <ProSpaceHLayout :gap="5">
                  <ProSpaceIcon
                    :icon="slotProps.value"
                  />
                  {{ slotProps.value }}
                </ProSpaceHLayout>
              </template>
              <template #option="slotProps">
                <ProSpaceHLayout :gap="5">
                  <ProSpaceIcon
                    :icon="slotProps.option.label"
                  />
                  <span class="text-size-12">{{ slotProps.option.label }}</span>
                </ProSpaceHLayout>
              </template>
            </ProSpaceDropdown>
            <ProSpaceInputNumber
              :label="$t('order')"
              integeronly
              :min="0"
              v-model="model.order"
            />
          </ProSpaceVLayout>
        </template>
        <template #tab-2>
          <ProSpaceVLayout :gap="20">
            <ProSpaceInputText
              placeholder="русский"
              label="Russian"
              v-model="model.language.RU"
            />
            <ProSpaceInputText
              placeholder="english"
              label="English"
              v-model="model.language.EN"
            />
            <ProSpaceInputText
              placeholder="français"
              label="France"
              v-model="model.language.FR"
            />
          </ProSpaceVLayout>
        </template>
      </ProSpaceTabs>
    </template>
    <template #footer>
      <ProSpaceHLayout justify-content="flex-end" :gap="5" v-if="!loading">
        <ProSpaceButton
          type="tertiary"
          :label="$t('cancelInf')"
          @click="closeModal"
        />
        <ProSpaceButton
          type="primary"
          :label="step === 0 ? $t('next') : $t('save')"
          @click="onCLickSave"
          :disabled="v$.model.$invalid && step === 1"
        />
      </ProSpaceHLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceMessage,
  ProSpaceTabs,
  ProSpaceInputText,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceButton,
  ProSpaceDropdown,
  ProSpaceIcon,
  Loading,
  ProSpaceInputNumber,
} from "@prospace/prospace-components-library";
import { MenuItemsService } from "../../../services/MenuItemsService.js";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import _ from "lodash";
export default {
  name: "MenuItemsModal",
  components: {
    ProSpaceModal,
    ProSpaceMessage,
    ProSpaceTabs,
    ProSpaceInputText,
    ProSpaceHLayout,
    ProSpaceButton,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceIcon,
    ProSpaceInputNumber,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  directives: {
    loading: Loading,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    tabs() {
      return [
        { name: this.$t("overview"), isActive: true },
        { name: this.$t("localization"), isActive: false },
      ];
    },
  },
  validations() {
    return {
      model: {
        title: { required },
      },
    };
  },
  data() {
    return {
      model: null,
      mode: "create",
      show: false,
      step: 0,
      loading: false,
      menuItemsService: new MenuItemsService(),
      paths: null,
    };
  },
  methods: {
    initModal(model) {
      this.showModal();
      if (model) {
        this.model = _.cloneDeep(model);
        this.model.language = JSON.parse(this.model.language);
        this.mode = "edit";
      } else {
        this.mode = "create";
        this.model = {
          path: null,
          title: null,
          order: 0,
          language: {
            RU: "",
            EN: "",
            FR: "",
          },
          parentId: null,
          icon: "dashboard",
        };
      }
      this.getPaths();
    },
    selectStep({ index }) {
      this.step = index;
    },
    initTabs() {
      this.step = 0;
      this.changeTab();
    },
    onCLickSave() {
      this.step = this.getStep();
      if (this.step === 0) {
        this.step++;
        this.changeTab();
      } else if (this.step === 1) {
        this.sendRequest();
      }
    },
    changeTab() {
      this.$refs.tabs.setTabs(this.step);
    },
    getStep() {
      return this.$refs.tabs.componentTabs.findIndex((i) => i.isActive);
    },
    sendRequest() {
      this.loading = true;
      this.model.language = JSON.stringify(this.model.language);
      const methods = {
        create: "createItem",
        edit: "editItem",
      };
      this.menuItemsService[methods[this.mode]]([this.model]).finally(() => {
        this.loading = false;
        this.closeModal();
        this.$emit("get-grid");
      });
    },
    showModal() {
      this.show = true;
    },
    closeModal() {
      this.initTabs();
      this.show = false;
      this.$emit("close");
    },
    async getPaths() {
      this.loading = true;

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scope>
.menuitems-modal-add {
  z-index: 1000 !important;
  &__inner {
    width: 410px !important;
    height: 500px !important;
    border: var(--prospace-ui-border);
    .prospace-tabs__header__tabs .prospace-scroll__inner {
      border-bottom: var(--prospace-ui-border);
    }
  }
}
</style>
