import { Transformer } from "../../../../Transformer/index.js";
import Api from "../../../../Api/DashboardService/DashboardDatasetApi";

export class DashboardDatasetService {
  constructor() {
    this.transformer = new Transformer();
    this.Api = new Api();
    this.datasetName = null;
    this.integrationTable = null;
  }
  async getIntegrationSourceColumns() {
    return await this.transformer.getGrid(this.Api, "getIntegrationSourceColumns", this.integrationTable);
  }
  async getDatasetModel() {
    const response = await this.transformer.setForm(this.Api, "getDatasetModel");
    const parsed = JSON.parse(response)

    const result = [];
    for (const dataset of parsed.datasetApiModels) {
      const parent = {
        systemName: dataset.systemName,
        name: this.getLocalizedName(dataset.localization),
        localization: dataset.localization,
        fieldNames: dataset.fields.map(f => this.getLocalizedName(f.localization)),
        children: dataset.fields.map(f => ({
          name: this.getLocalizedName(f.localization),
          ...f
        }))
      }
      result.push(parent);
    }

    return { data: result };
  }
  async getDatasetFields(name) {
    const datasets = await this.getDatasetModel();
    const dataset = datasets.data.find(x => x.systemName === name);
    return { data: dataset.children };
  }
  async getDatasetData(dataInfo) {
    return await this.transformer.getForm(this.Api, "getDatasetData", null, dataInfo);
  }
  async getDatasetDataFilter(filter) {
    let dataInfo = [{ datasetName: this.datasetName, filter: filter }];
    let result = await this.transformer.getForm(this.Api, "getDatasetData", null, dataInfo);
    let data = result[0].data;
    return { data: { records: data, total: data.length } };
  }
  getLocalizedName(localization) {
    return localization[this.getLocale()]
  }
  getLocale() {
    return localStorage.getItem('locale') ?? "ru";
  }
}
