<template>
  <ProSpaceModal
    v-model="visible"
    without-padding
    :title="title"
    :icon="!readOnly ? 'list-check' : 'list-check'"
    :restoreResize="true"
    :content-style="{ height: '420px', width: '480px' }"
    :cancelTxt="!readOnly ? $t('cancelInf') : $t('closeTxt')"
    :applyTxt="!readOnly ? $t('selectTxt2') : null"
    :disabled-apply="disabledApplyBtn"
    :iconHeight="20"
    :iconWidth="20"
    @apply="apply"
  >
    <ProSpaceGeoLayout>
      <template #center>
        <ProSpaceGeoLayout>
          <template #top>
            <ProSpaceFilterPanel
              ref="filter-panel"
              filter-model="displayName"
              :closeTxt="$t('closeTxt')"
              :searchPlaceholder="$t('searchTxt')"
              :txtResetFilters="$t('txtResetFilters')"
              :unicKeyStorage="service?.constructor.name + 'grid'"
              :blocked-filters="needBlock"
              @apply="submitFilter"
              @block="selectedMultipleBlockFilterHandler"
            />
          </template>
          <template #center>
            <ProSpaceMainGrid
              ref="grid"
              getMethod="grid"
              :service="service"
              :selectedItems="selectedItems"
              :noMountedRequest="true"
              :default-filter="defaultFilter"
              :selectionMode="!readOnly ? 'multiple' : 'single'"
              :needAllRecords="true"
              @selected-change="selectedHandler"
            >
              <template #columns>
                <Column field="displayName" :header="$t('role')" sortable>
                  <template #body="field">
                    <ProSpaceTableHeaderCell
                      :title="field.data.displayName"
                    />
                  </template>
                </Column>
              </template>
              <template #empty>
                <ProSpaceEmptyGrid
                  icon="error-message"
                  :title="$t('noDataTitle')"
                  :text="$t('noDataTxt')"
                />
              </template>
            </ProSpaceMainGrid>
          </template>
        </ProSpaceGeoLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceMainGrid,
  ProSpaceFilterPanel,
  ProSpaceEmptyGrid,
  ProSpaceTableHeaderCell,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"

export default {
  name: "RolesSelectModal",
  components: {
    ProSpaceModal,
    ProSpaceGeoLayout,
    ProSpaceMainGrid,
    ProSpaceFilterPanel,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceTableHeaderCell,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    service: {
      type: Object,
      default: null
    },
    needValidate: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    titleModal: {
      type: String,
      default: ''
    },
    defaultFilter: {
      type: Object,
      default: () => {}
    },
    selectedItems: {
      type: Array,
      default: null
    }
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedMultipleReset
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
      selectedMultipleBlockFilterHandler,
      needBlock, selectedMultipleReset
    }
  },
  data() {
    return {
      // Grid Panel
      selectedValues: [],
      selectedAllValues: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    title() {
      return this.titleModal || this.$t('roles')
    },
    disabledApplyBtn() {
      if (this.needValidate) {
        return this.selectedValues.length === 0
      }
      return false
    },
  },
  methods: {
    apply() {
      this.$emit('apply', { values: this.selectedValues, isAll: this.selectedAllValues });
    },
    selectedHandler(val) {
      if (this.readOnly) return;
      if (val.type === 'head' && val.hasSelect) {
        this.selectedAllValues = true;
      } else {
        this.selectedAllValues = false;
      }
      this.selectedMultipleGridHandler(val)
      this.selectedValues = this.grid.getSelectedModel();
    },
    submitFilter({ filter, search }) {
      if (!this.service) return;
      this.grid.submitFilter(filter, search);
    },
  }
}
</script>

<style scoped>

</style>
