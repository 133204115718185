<template>
  <ProSpaceHLayout
    ref="scrollbar"
    :gap="10"
    :class="['dashboard-plate-iterator', 'prospace-scrollbar', { 'dashboard-plate-iterator--hscrollable': data?.length > 3 }]"
  >
    <div v-for="(item, index) in data" :key="`${index}-${item.title}-${item.text}`">
      <DashboardPlateValue
        :title="item.title"
        :text="item.text"
        :color="getColorByType(item)"
        :fullWidth="false"
      />
    </div>
  </ProSpaceHLayout>
</template>

<script>
import DashboardPlateValue from "./DashboardPlateValue.vue";
import { ProSpaceHLayout } from "@prospace/prospace-components-library";
import { getStyleColor, checkValue } from "@dashboard/utils/dashboardUtils.js";
export default {
  name: "DashboardPlateIterator",
  components: {
    DashboardPlateValue,
    ProSpaceHLayout,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      scrollContainer: null,
    };
  },
  methods: {
    getStyleColor,
    checkValue,
    getColorByType(item) {
        if (!this.color) {
            return null;
        }

        let resultColor = null;

        if (this.color.type === 'default') {
            resultColor = this.color
        }
        if (this.color.type === 'lights') {
            const value = item["title"];
            if (Number.isNaN(Number(value))) {
              resultColor = this.color
            } else {
              const { range } = this.color;
              if (value < range.left) {
                  resultColor = this.color.before
              } else if (value > range.left && value < range.right) {
                  resultColor = this.color.center
              } else if (value > range.right) {
                  resultColor = this.color.after
              } else {
                  resultColor = this.color.after
              }
            }
        }
        return resultColor
    },
    hScrollHandler(evt) {
      evt.preventDefault();
      this.scrollContainer.scrollLeft += evt.deltaY;
    }
  },
  mounted() {
    if (this.data?.length > 3) {
      this.scrollContainer = this.$refs.scrollbar.$el;
      this.scrollContainer?.addEventListener("wheel", this.hScrollHandler);
    }
  },
  beforeUnmount() {
    this.scrollContainer?.removeEventListener("wheel", this.hScrollHandler);
  }
};
</script>

<style lang="scss">
.dashboard-plate-iterator {
  //padding: 6.5px 10px;
  border-radius: 5px;
  text-align: center;
  & > div {
    flex: 1 0 auto;
  }
  &__title {
    font-size: 14px;
    line-height: 17.5px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  &__text {
    font-size: 10px;
    line-height: 12px;
    color: var(--prospace-text-gray);
  }
  &--hscrollable {
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 60px;
    & > div {
      max-width: 33.33%;
      border-radius: 5px;
    }
  }
}
</style>
