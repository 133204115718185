<template>
  <ProSpaceGeoLayout id="dashboard-body" class="dashboard">
    <template #top>
      <ProSpaceTopPanel
        header=""
        back
        boxShadow
        @back="backHandler"
      >
        <template #center>
          <ProSpaceDropdown
            ref="dahboardList"
            v-model="dashboardBroker.dashboardView"
            propValue="id"
            propLabel="name"
            argMethod="$filter=(disabled eq false)&$orderby=createdAt desc"
            getMethod="grid"
            class="dashboard__selector"
            :service="dashboardBroker.dashboardService"
            :placeholder="$t('dashboard')"
            @change="changeDashboard"
          />
        </template>
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="updateHandler"
          />
          <ProSpaceDots
            style="margin-left: 10px"
            :options="wrapperCheckRoles(dotsHeaderOptions, dashboardBroker.dashboard)"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceVLayout
        ref="printTarget"
        class="dashboard-layout"
        :gap="10"
        v-if="!isShowModalFilter"
        v-loading="loading"
      >
        <DashboardContainer v-bind="dashboardBroker.filterPanel" v-if="dashboardBroker.filterPanel" />
        <DashboardRootContainer
          v-bind="dashboardBroker.rootContainer"
          v-if="dashboardBroker.rootContainer"
          class="prospace-scrollbar" style="overflow: auto;height: 100%;"
          @ref-sent="receiveRef"
        />
      </ProSpaceVLayout>
      <DashboardModalFilter
        v-if="isShowModalFilter"
        :visible="isShowModalFilter"
        :dashboard="dashboard"
        @apply="filterApplyHandle"
        @close="filterCloseHandle"
      />
      <DashboardModalForm
        v-if="dashboardBroker.dashboard"
        v-model="showEditModal"
        :id="dashboardBroker.dashboard.id"
        :dashboardName="dashboardBroker.dashboard.name"
        :afterSaveHandler="resetDashboard"
      />
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceBlock,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceIconButton,
  ProSpaceDots,
  ProSpaceDropdown,
  Loading
} from "@prospace/prospace-components-library";

import { useStatistic, useConvertHTMLToExcel } from "@composes";
import { mapGetters } from "vuex";
import { ref } from "vue";
import { useHtml2Canvas } from "vue3-html2canvas";
import printJS from 'print-js'

import DashboardChartItem from "@dashboard/views/Dashboards/components/DashboardChartItem";
import DashboardPlateIterator from "@dashboard/views/Dashboards/components/DashboardPlateIterator";
import DashboardPlateValue from "@dashboard/views/Dashboards/components/DashboardPlateValue";
import DashboardRootContainer from "@dashboard/views/Dashboards/components/DashboardRootContainer";
import DashboardContainer from "@dashboard/views/Dashboards/components/DashboardContainer";
import DashboardModalFilter from "@dashboard/views/Dashboards/components/DashboardModalFilter";
import DashboardModalForm from "./components/DashboardModalForm";
import Chart from "primevue/chart";

import DashboardBroker from "@dashboard/brokers/DashboardBroker.js";
export default {
  name: "Dashboard",
  mixins: [accessMixin],
  components: {
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceBlock,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceIconButton,
    ProSpaceDots,
    ProSpaceDropdown,
    DashboardChartItem,
    DashboardPlateIterator,
    DashboardPlateValue,
    DashboardRootContainer,
    DashboardContainer,
    DashboardModalFilter,
    DashboardModalForm,
    Chart,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const html2canvas = useHtml2Canvas();
    const printTarget = ref(null);

    const loadingPivotExport = ref(false);
    const { tableToExcel } = useConvertHTMLToExcel();
    const receivedRef = ref(null);
    const isPivot = ref(false);
    const receiveRef = ({ ref, component }) => {
      receivedRef.value = ref;
      isPivot.value = component === 'DashboardPivot';
    };

    const getFormattedDate = () => {
      const date = new Date();
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return date.toLocaleString('ru-RU', options).replace(/[\s,:]/g, '-').replace(/\//g, '-');
    };
    const downloadExcel = () => {
      if (receivedRef.value) {
        tableToExcel(receivedRef.value, `pivot_table-${getFormattedDate()}-list-1.xlsx`).then(blob => {
          loadingPivotExport.value = true;
          const fileName = `pivot_table-${getFormattedDate()}.xlsx`;
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url); // Освобождаем URL
          loadingPivotExport.value = false;
        });
      }
    };

    return {
      setStatistic, distActions,
      html2canvas, printTarget,
      downloadExcel, loadingPivotExport,
      receiveRef, receivedRef, isPivot
    }
  },
  directives: {
    loading: Loading,
  },
  data() {
    return {
      showModalFilter: false,
      showEditModal: false,
      dashboardBroker: new DashboardBroker(),
      masterId: null,
      loading: true
    };
  },
  async created() {
    await this.init()
  },
  mounted() {
    this.dashboardBroker.onMounted();
    this.loadRoleAccess();
  },
  unmounted() {
    this.dashboardBroker.onUnmounted();
  },
  methods: {
    async init(id = null) {
      this.masterId = id ?? this.$route.params.id;
      await this.dashboardBroker.init(this.masterId);
      this.dashboardBroker.setUserinfo(this.userinfo);

      const filterName = btoa("dashbord-filter_" + this.masterId + this.dashboardBroker.dashboard.lastUpdated);
      const filter = JSON.parse(localStorage.getItem(filterName));
      this.dashboardBroker.setFilterModel(filter);
      if (this.dashboard.isPrefilterRequired && filter == null) {
        this.showModalFilter = true;
      } else {
        await this.dashboardBroker.update();
      }
      this.loading = false;
    },
    backHandler() {
      this.$router.push({
        name: "dashboards",
      });
    },
    async updateHandler() {
      await this.dashboardBroker.update();
    },
    async filterApplyHandle(filter) {
      this.showModalFilter = false;
      this.dashboardBroker.setFilterModel(filter);
      await this.dashboardBroker.update();
    },
    filterCloseHandle() {
      if (this.dashboardBroker.filterModel == null) this.backHandler();
    },
    changeDashboard(model) {
      if (!model) return;
      const url = `/dashboard/dashboard/${model.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push({
        name: 'dashboard',
        params: { id: model.id }
      });
      this.resetDashboard(model, false);
    },
    handleHeaderDots(action) {
      if (action === "edit") this.showModalForm();
      if (action === "print") this.printDashboard();
      if (action === "export") this.downloadExcel();
    },
    showModalForm() {
      this.showEditModal = true;
    },
    resetDashboard(model = null, resetDahboardDropdown = true) {
      this.dashboardBroker.reset();

      if (resetDahboardDropdown)
        this.$refs.dahboardList.getData();

      this.$nextTick(async () => {
        await this.init(model.id);
      })
    },
    async printDashboard() {
      this.printTarget.$el.style.height = "auto"
      let loadingMessage = this.$t("loading") + "...";
      this.html2canvas(this.printTarget.$el, { }).then(function (canvas) {
        printJS({
          printable: canvas,
          type: 'html',
          showModal: true,
          documentTitle: "",
          style: 'body { margin: 0; } canvas { max-width: 100%; }',
          maxWidth: null,
          modalMessage: loadingMessage
        });
      });
      this.printTarget.$el.style.height = ""
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      currentTheme: "userinfoStore/getTheme",
    }),
    isShowModalFilter() {
      return this.showModalFilter;
    },
    dashboard() {
      return this.dashboardBroker.dashboard;
    },
    dotsHeaderOptions() {
      let resOptions = [];
      const pivotOption = [{
        name: "export",
        text: this.$t("export_xlsx"),
        icon: "upload",
        liteRef: "export-xlsx-option",
      }];
      const options = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          liteRef: "edit-option",
        },
        {
          name: "print",
          text: this.$t("printTxt"),
          icon: "print-file",
          liteRef: "print-option",
        },
      ];
      if (this.isPivot) {
        resOptions = [...options, ...pivotOption]
      } else {
        resOptions = options;
      }
      return resOptions;
    },
  },
  watch: {
    currentTheme: {
      async handler(val) {
        this.loading = true;
        await this.dashboardBroker.update();
        this.loading = false;
      },
    },
  }
};
</script>

<style lang="scss">
.dashboard {
  background-color: var(--prospace-dashboard-ui-bg);
  &-layout {
    padding: 10px;
  }
  &-block {
    padding: 10px;
    box-shadow: 0px 2px 10px 0px #33333312;
    width: 100%;
    border-radius: 5px;
  }
  &-h {
    &-full {
      height: 100%;
    }
  }
  &-bg {
    &-white {
      background-color: var(--prospace-dashboard-ui-card-white);
    }
    &-gray {
      background-color: var(--prospace-dashboard-ui-card-grey);
      box-shadow: none;
    }
  }
  &-content {
    flex: 1;
  }
  &__selector {
    width: auto !important;
    .prospace-dropdown__select {
      border: none;
      background-color: var(--prospace-ui-bg);
      .p-dropdown-label {
        padding-left: 0;
        font-size: 18px;
        font-weight: 500;
        color: var(--prospace-text-main);
      }
      .p-dropdown-trigger {
        margin-left: 10px;
        &-icon {
          width: 20px;
          height: 20px;
        }
      }
      &.p-focus {
        border: none;
        box-shadow: none;
        .p-dropdown-trigger-icon {
          background-color: var(--prospace-ui-main);
        }
      }
    }
  }
}
#loader {
  .pi-spinner {
    font-size: 20px;
  }
}
</style>
