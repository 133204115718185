<template>
  <ProSpaceModal
    :key="`user-profile-modal-${localModelValue}`"
    v-model="localModelValue"
    :class="{ 'userprofile-modal--reset-password': showChangePassword }"
    :title="$t('userProfile')"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :icon="icon"
    :width="600"
    class="userprofile-modal"
    with-grey-background
    small-padding
    :validate-local="$t"
    :validate-model="password"
    :validate-config="{
      newPassword: {
        length: {
          message: $t('notValidPasswordLength'),
          fn: validatePasswordLength,
        },
        alph: {
          message: $t('notValidPasswordAlpth'),
          fn: validatePasswordAlpth,
        },
        alphUpper: {
          message: $t('notValidPasswordAlpthUpper'),
          fn: validatePasswordAlpthUpper,
        },
        numbers: {
          message: $t('validatePasswordNumbers'),
          fn: validatePasswordNumbers,
        },
        symbols: {
          message: $t('validatePasswordSymbols'),
          fn: validatePasswordSymbols,
        },
        userId: {
          message: $t('validatePasswordUserId'),
          fn: validatePasswordUserId,
        },
        userLogin: {
          message: $t('validatePasswordUserLogin'),
          fn: validatePasswordUserLogin,
        },
        userEmail: {
          message: $t('validatePasswordUserEmail'),
          fn: validatePasswordUserEmail,
        },
        userFirstName: {
          message: $t('validatePasswordUserFirstName'),
          fn: validatePasswordUserFirstName,
        },
        userLastName: {
          message: $t('validatePasswordUserLastName'),
          fn: validatePasswordUserLastName,
        },
        userSecondName: {
          message: $t('validatePasswordUserSecondName'),
          fn: validatePasswordUserSecondName,
        },
        space: {
          message: $t('validatePasswordSpace'),
          fn: validatePasswordSpace,
        },
        mdp: {
          message: $t('validatePasswordMDP'),
          fn: validatePasswordMDP,
        },
        english: {
          message: $t('validatePasswordEnglish'),
          fn: validatePasswordEnglish,
        },
      },
    }"
    @apply="save"
    :needHideApply="false"
  >
    <template v-slot:default="{ v }">
      <ProSpaceVLayout :gap="10">
        <div class="userprofile-modal__block prospace-scrollbar" style="min-height: 144px; overflow: auto">
          <ProSpaceSidebarFooter
            :user="user"
            :current-locale="currentLocale"
            :locale-options="localeOptions"
            theme-txt=""
            language-txt=""
            @changeLocale="changeLocale"
            @logout="logout"
            @clear-storage="handlerClearStorage"
            :logout-txt="$t('sideBarLogoutTxt')"
            :storage-txt="$t('sideBarStorageTxt')"
            :is-row="true"
          >
            <template #roledropdown>
              <ProSpaceDropdown
                v-model="selectRoleModel"
                :options="user.roles"
                propLabel="displayName"
                propValue="id"
                :label="$t('role')"
                :placeholder="$t('role')"
                class="dropdown__select-role"
              />
            </template>
            <ProSpaceVLayout :gap="5">
              <ProSpaceHLayout :gap="5" style="height: 18px">
                <ProSpaceTag
                  type="gray-light"
                  :text="user.id"
                  bordered
                  style="height: 18px"
                />
                <ProSpaceTag
                  v-if="user.orgLinkType"
                  type="blue"
                  :text="$t(user.orgLinkType)"
                  bordered
                  style="height: 18px"
                />
                <!-- <ProSpaceTag
                  v-if="
                    user.orgLinkType === 'Distributor' &&
                    checkLocalAccess('action-distrinfo')
                  "
                  type="secondary"
                  icon="info-outline"
                  typeIcon="secondary"
                  :text="$t('distributorProfile')"
                  bordered
                  style="height: 18px"
                  @click="goToUserDistr"
                /> -->
              </ProSpaceHLayout>
              <div class="text-bold text-color-main text-size-14">
                {{ user.name }}
              </div>
            </ProSpaceVLayout>
          </ProSpaceSidebarFooter>
        </div>
        <ProSpaceGeoLayout class="userprofile-modal__block">
          <template #center>
            <ProSpaceVLayout>
              <ProSpaceHLayout
                class="userprofile-modal__block-section"
              >
                <ProSpaceVLayout v-if="getPosition()">
                  <ProSpaceTextBlock
                    :top="$t('position')"
                    :bottom="getPosition()"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </ProSpaceVLayout>
                <ProSpaceInputLayout :label="$t('branches')" v-if="distrStructure.length">
                  <ProSpaceAction :text="$t('open')" @click="handlerClickFilial" />
                  <DistributorHierarchySelectModal
                    v-model="modalDistrStructure"
                    :service="distributorStructureService"
                    get-method="getViewTree"
                    :titleModal="$t('branches')"
                    :checked-rows="distrStructure"
                    readOnly
                    expandAll
                    searchable
                  />
                </ProSpaceInputLayout>
              </ProSpaceHLayout>
              <ProSpaceVLayout
                v-if="showChangePassword"
                class="userprofile-modal__block-section"
              >
                <ProSpaceTextBlock
                  :top="$t('password')"
                  :gap="10"
                  type="third"
                  break-word
                />
                <ProSpaceHLayout
                  :gap="10"
                  :align-items="isPasswordEdit ? 'flex-start' : 'flex-end'"
                  style="margin-top: 10px"
                >
                  <ProSpaceInputText
                    style="width: 50%"
                    :isPassword="true"
                    v-model="password.oldPassword"
                    :label="$t('currentPassword')"
                  ></ProSpaceInputText>
                  <ProSpaceAction
                    v-if="isPasswordEdit === false"
                    :text="$t('change')"
                    class="userprofile-modal__block--changepassword"
                    @click="isPasswordEdit = true"
                  ></ProSpaceAction>
                  <ProSpaceInputText
                    style="width: 50%"
                    :isPassword="true"
                    v-if="isPasswordEdit === true"
                    :label="$t('newPassword')"
                    v-model="password.newPassword"
                    :invalid="v.newPassword.$error"
                    :invalid-messages="v.newPassword.$errors"
                    @blur="v.newPassword.$touch"
                  ></ProSpaceInputText>
                </ProSpaceHLayout>
              </ProSpaceVLayout>
              <ProSpaceVLayout class="userprofile-modal__block-section">
                <ProSpaceTextBlock
                  :top="$t('dateTimeFormat')"
                  :gap="10"
                  type="third"
                  break-word
                />
                <ProSpaceHLayout :gap="10" style="margin-top: 10px">
                  <ProSpaceDropdown
                    :label="$t('dateFormat')"
                    v-model="datetimeFormats.dateFormat"
                    getMethod="getDateFormats"
                    :service="dateHelperService"
                  ></ProSpaceDropdown>
                  <ProSpaceDropdown
                    :label="$t('timeZone')"
                    v-model="datetimeFormats.timeZoneId"
                    getMethod="getTimeZone"
                    :service="dateHelperService"
                  ></ProSpaceDropdown>
                  <ProSpaceDropdown
                    :label="$t('timeFormat')"
                    v-model="datetimeFormats.timeFormat"
                    getMethod="getTimeFormats"
                    :service="dateHelperService"
                  ></ProSpaceDropdown>
                </ProSpaceHLayout>
              </ProSpaceVLayout>
            </ProSpaceVLayout>
          </template>
        </ProSpaceGeoLayout>
      </ProSpaceVLayout>
    </template>
  </ProSpaceModal>
</template>

<script>
import accessMixin from "../../../Common/utils/elementAccess/accessMixin";
import {
  ProSpaceAction,
  ProSpaceDropdown,
  ProSpaceHLayout,
  ProSpaceTextBlock,
  ProSpaceInputText,
  ProSpaceModal,
  ProSpaceSidebarFooter,
  ProSpaceTag,
  ProSpaceVLayout,
  ProSpaceGeoLayout,
  ProSpaceInputLayout
} from "@prospace/prospace-components-library";
import { UserService } from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/UserService";
import { DateHelperService } from "../../../../Services/SecurityAdmin/ProSpace.SecurityAdmin.Frontend/services/DateHelperService";
import { hashPassword } from "../../../Common/utils/passwordHelper";
import { mapGetters } from "vuex";
import DistributorHierarchySelectModal from "@modals/DistributorHierarchySelectModal.vue";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
export default {
  mixins: [accessMixin],
  name: "UserProfileModal",
  components: {
    ProSpaceModal,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTextBlock,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceDropdown,
    ProSpaceSidebarFooter,
    ProSpaceTag,
    ProSpaceGeoLayout,
    ProSpaceInputLayout,
    DistributorHierarchySelectModal
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    confirmTimezone: {
      type: [Boolean, Object],
      default: false
    }
  },
  watch: {
    modelValue(val) {
      if (!val) {
        this.password.oldPassword = "";
        this.password.newPassword = "";
        this.isPasswordEdit = false;
      } else if (this.user) {
        this.datetimeFormats.dateFormat = this.user.dateFormat;
        this.datetimeFormats.timeFormat = this.user.timeFormat;
        this.datetimeFormats.timeZoneId = this.user.timeZoneId;
        this.getDistrStructure()
      }
      this.$emit("update:modelValue", val);
    },
    async confirmTimezone(val) {
      if (val && typeof val !== 'object') {
        this.isTimezoneEdit = true;
        await this.save();
        this.logout();
      } else if (typeof val === 'object') {
        this.isTimezoneEdit = false;
        this.datetimeFormats.timeZoneId = this.user.timeZoneId;
      }
    }
  },
  data() {
    return {
      model: null,
      userService: new UserService(),
      dateHelperService: new DateHelperService(),
      modalDistrStructure: false,
      distributorStructureService: new DistributorStructureService(),
      distrStructure: [],
      user: {},
      lightTheme: "light",
      darkTheme: "dark",
      currentThemeName: null,
      currentLocale: "",
      expandedLang: false,
      localeOptions: [],
      localAccess: null,
      password: {
        oldPassword: "",
        newPassword: "",
      },
      isPasswordEdit: false,
      selectRoleModel: JSON.parse(localStorage.getItem("userRole"))
        ? JSON.parse(localStorage.getItem("userRole")).id
        : null,
      datetimeFormats: {
        dateFormat: "DD.MM.YYYY",
        timeFormat: "hh:mm:ss",
        timeZoneId: "Greenwich Standard Time",
      },
      isTimezoneEdit: false,
    };
  },
  created() {
    const localeTheme = localStorage.getItem("theme");
    if (localeTheme) {
      this.currentThemeName = localeTheme;
    } else {
      this.currentThemeName = this.lightTheme;
    }
    //document.documentElement.setAttribute("data-theme", this.currentThemeName);
  },
  mounted() {
    this.loadLocalRoleAccess("userprofilemodal");
  },
  methods: {
    handlerClickFilial() {
      this.modalDistrStructure = true
    },
    async getDistrStructure() {
      const user = (await this.userService.get(this.user.id)).data
      this.distrStructure = user.distributorStructures
      this.distributorStructureService.setNodeViewIds(this.distrStructure.map(x => x.distributorStructureViewId));
    },
    goToUserDistr() {
      this.$emit("update:modelValue", false);
      this.$router.push(
        `/masterdata/distributor/${this.user.orgLinkDistributorId}`
      );
    },
    validatePasswordLength(password) {
      if (this.isPasswordEdit) {
        return password.length >= 12;
      }
      return true;
    },
    validatePasswordAlpth(password) {
      if (this.isPasswordEdit) {
        let re = new RegExp("(?=.*[a-z])");
        return !(password.match(re) === null);
      }
      return true;
    },
    validatePasswordAlpthUpper(password) {
      if (this.isPasswordEdit) {
        let re = new RegExp("(?=.*[A-Z])");
        return !(password.match(re) === null);
      }
      return true;
    },
    validatePasswordNumbers(password) {
      if (this.isPasswordEdit) {
        let re = new RegExp("(?=.*\\d)");
        return !(password.match(re) === null);
      }
      return true;
    },
    validatePasswordSymbols(password) {
      if (this.isPasswordEdit) {
        let re = new RegExp("(?=.*[!*@#$%^&+=])");
        return !(password.match(re) === null);
      }
      return true;
    },
    validatePasswordUserId(password) {
      if (this.isPasswordEdit) {
        return !password.includes(this.user.id);
      }
      return true;
    },
    validatePasswordUserLogin(password) {
      if (this.isPasswordEdit && this.user.login) {
        return !password.toLowerCase().includes(this.user.login.toLowerCase());
      }
      return true;
    },
    validatePasswordUserEmail(password) {
      if (this.isPasswordEdit && this.user.email) {
        return !password.toLowerCase().includes(this.user.email.toLowerCase());
      }
      return true;
    },
    validatePasswordUserFirstName(password) {
      if (this.isPasswordEdit && this.user.firstName) {
        return !password
          .toLowerCase()
          .includes(this.user.firstName.toLowerCase());
      }
      return true;
    },
    validatePasswordUserLastName(password) {
      if (this.isPasswordEdit && this.user.lastName) {
        return !password
          .toLowerCase()
          .includes(this.user.lastName.toLowerCase());
      }
      return true;
    },
    validatePasswordUserSecondName(password) {
      if (this.isPasswordEdit && this.user.secondName) {
        return !password
          .toLowerCase()
          .includes(this.user.secondName.toLowerCase());
      }
      return true;
    },
    validatePasswordSpace(password) {
      if (this.isPasswordEdit) {
        return !password.includes(" ");
      }
      return true;
    },
    validatePasswordMDP(password) {
      if (this.isPasswordEdit) {
        return !password.toLowerCase().includes("mdp");
      }
      return true;
    },
    validatePasswordEnglish(password) {
      if (this.isPasswordEdit) {
        let re = new RegExp(/^.*(^[A-Za-z\s'&\d!*@#$%^&+=]*$).*$/);
        return !(password.match(re) === null);
      }
      return true;
    },
    getPosition() {
      return (
        this.user.orgLinkDistributorOrgName || this.user.orgLinkMarsOrgName
      );
    },
    async save() {
      let passwordResponse = null;
      if (this.isPasswordEdit) {
        passwordResponse = await this.changePassword();
        this.password.oldPassword = null;
        this.password.newPassword = null;
        this.isPasswordEdit = false;
      }
      if (passwordResponse && passwordResponse.data === false) return;
      let role = this.user.roles.find(
        (role) => role.id == this.selectRoleModel
      );
      let isRoleChanged =
        role.id != JSON.parse(localStorage.getItem("userRole")).id;
      localStorage.setItem("userRole", JSON.stringify(role));
      localStorage.setItem("dateFormat", this.datetimeFormats.dateFormat);

      let oldFormats = JSON.stringify({
        dateFormat: this.user.dateFormat,
        timeFormat: this.user.timeFormat,
        timeZoneId: this.user.timeZoneId,
      });
      let newFormats = JSON.stringify(this.datetimeFormats);
      if ((this.datetimeFormats.timeZoneId !== this.user.timeZoneId) && !this.isTimezoneEdit) {
        this.$emit("update:timezone");
        return;
      }
      if (newFormats !== oldFormats) {
        this.userService
          .updateDateTimeFormats(this.datetimeFormats)
          .then((res) => {
            if (res) {
              this.user.dateFormat = this.datetimeFormats.dateFormat;
              this.user.timeFormat = this.datetimeFormats.timeFormat;
              this.user.timeZoneId = this.datetimeFormats.timeZoneId;
              this.$store.dispatch("userinfoStore/setUserinfo", this.user);
            }
            this.changeLocationOrReload(isRoleChanged);
          });
      } else {
        this.changeLocationOrReload(isRoleChanged);
      }
    },
    changeLocationOrReload(isRoleChanged) {
      if (isRoleChanged) {
            window.location.href = "/information/home";
          } else {
            window.location.reload();
          }
    },
    async changePassword() {
      var response = await this.userService.changePassword(
        hashPassword(this.password.oldPassword),
        hashPassword(this.password.newPassword)
      );
      return response;
    },
    changeTheme() {
      this.currentThemeName =
        this.currentThemeName === this.lightTheme
          ? this.darkTheme
          : this.lightTheme;
      document.documentElement.setAttribute(
        "data-theme",
        this.currentThemeName
      );
      localStorage.setItem("theme", this.currentThemeName);
    },
    changeLocale(locale) {
      this.$emit("changeLocale", locale);
    },
    logout() {
      this.$emit("logout");
    },
    handlerClearStorage() {
      this.$emit('clear-storage')
    }
  },
  computed: {
    ...mapGetters({
      access: "securityRoleAccess/access",
    }),
    icon() {
      return "user";
    },
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    langArrowIcon() {
      return this.expandedLang ? "arrow-up" : "arrow-down";
    },
    showChangePassword() {
      return this.user.providerName === "Cookies";
    },
  },
};
</script>
<style lang="scss">
.short-form-field {
  .form-field-inner {
    flex-direction: column;
  }
  .prospace-input-layout__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
<style lang="scss">
.userprofile-modal {
  .p-dialog-content {
    overflow: auto !important;
  }

  .prospace-modal__icon {
    .icon-user {
      width: 16px !important;
      height: 16px !important;
    }
  }

  &__block {
    &:not(.prospace-geo-layout) {
      padding: 20px;
    }
    &-section {
      width: 100%;
      padding: 20px;
      &:not(:last-child) {
        border-bottom: 1px dashed var(--prospace-ui-border-color);
      }
    }
    .prospace-input-layout.prospace-dropdown.dropdown__select-role {
      height: 50px;
      .prospace-input-layout__label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
      }
      .p-dropdown.p-component {
        width: 240px;
        margin-right: 10px;
      }
    }

    .prospace-switch-theme {
      gap: 5px;
    }

    .prospace-switch-theme--dark.is-active {
      background: #fff !important;

      .prospace-switch-theme__icon {
        background-color: #cfd6e2 !important;
      }
    }
    .prospace-switch-theme--dark {
      background: inherit !important;

      .prospace-switch-theme__icon {
        background-color: #5b7692;
      }
    }

    .prospace-switch-theme--light {
      .prospace-switch-theme__icon {
        background-color: #b0bec5;
      }
    }

    .prospace-switch-theme--light.is-active {
      background-color: #2c3e50;

      .prospace-switch-theme__icon {
        background-color: #526b83 !important;
      }
    }
    &--changepassword {
      height: 30px;
    }
  }
}
</style>
<style lang="scss" scoped>
.userprofile-modal {
  z-index: 1000 !important;

  &__inner {
    width: 572px !important;
    height: 337px !important;
    .side-equal-block {
      width: calc(100% - 20px);
      padding-top: 10px;
    }
    .form-field {
      margin: 0;
    }
  }
  &--reset-password {
    .userprofile-modal__inner {
      height: 460px !important;
    }
  }
  &__block {
    width: 100%;
    background-color: var(--prospace-ui-card);
    box-shadow: 0 2px 10px rgba(51, 51, 51, 0.07);
    border-radius: 5px;
  }
}
</style>
