<template>
  <ProSpaceGeoLayout :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="this.$t('dashboardIntegrationMatchings')">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            @click="actionUpdate"
          />
          <ProSpaceDots
            style="margin-left: 10px"
            :options="dotsHeaderOptions"
            :isSquare="true"
            @action="(action) => handleHeaderDots(action)"
          />
          <ProSpaceButton
            type="primary"
            icon="plus"
            :label="this.$t('dashboardIntegrationMatchingCreateNew')"
            @click="
              $router.push({
                path: `/dashboardservice/DashboardIntegrationMatching`,
                query: {
                  block: this.selectedNode ? this.selectedNode.code : null,
                },
              })
            "
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="
              dashboardIntegrationMatchingService.constructor.name + 'grid'
            "
            @apply="submitFilter"
            ref="filterPanel"
            :filter-model="[
              'integrationSource',
              'integrationField',
              'integrationValue',
              'systemValue',
              'displayValue'
            ]"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
                selectedMultipleClear()
              }
            "
            :ignore-clear-keys="['block']"
          >
            <template #hint>
              {{ this.$t("filterBy") }}
            </template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.integrationSource"
                :service="baseTypeService"
                getMethod="grid"
                optionValue="code"
                optionLabel="name"
                :default-filter="{
                  systemDirectoryCode: 'IntegrationSource',
                  disabled: false
                }"
                :placeholderValue="$t('dashboardIntegrationMatchingIntegrationSource')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.integrationField"
                :placeholder-value="$t('dashboardIntegrationMatchingIntegrationField')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.systemValue"
                :placeholder-value="$t('dashboardIntegrationMatchingSystemValue')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />
              <ProSpaceFilterContainsChips
                v-model="filter.displayValue"
                :placeholder-value="$t('dashboardIntegrationMatchingDisplayValue')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                @apply="emitFilter"
              />

              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="
                  dashboardIntegrationMatchingService.constructor.name + 'grid'
                "
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            :service="dashboardIntegrationMatchingService"
            :noMountedRequest="true"
            defaultColumnWidths="200"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column
                field="integrationValue"
                :header="this.$t('dashboardIntegrationMatchingIntegrationValue')"
                sortable
                headerClass="move-sort-head"
              >
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :title="field.data.integrationValue"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>

              <Column
                field="integrationSource"
                :header="this.$t('dashboardIntegrationMatchingIntegrationSource')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.integrationSource }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="integrationField"
                :header="this.$t('dashboardIntegrationMatchingIntegrationField')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ $t(field.data.integrationField) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="systemValue"
                :header="this.$t('dashboardIntegrationMatchingSystemValue')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ $t(field.data.systemValue) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="displayValue"
                :header="this.$t('dashboardIntegrationMatchingDisplayValue')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ $t(field.data.displayValue) }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>

              <Column
                field="createdAt"
                :header="this.$t('createdAtTxt')"
                sortable
              >
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>

              <Column
                field="actions"
                class="column-end column-end-30"
              >
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>

      <ProSpaceHistoryModal
        ref="history"
        v-model="showHistoryModal"
        modelName="dashboardIntegrationMatchingApiModel"
        @close="closeHistory"
      />
    </template>
    <template #right>
      <DashboardIntegrationMatchingPanel
        v-if="hasSelect"
        ref="right-panel"
        :service="dashboardIntegrationMatchingService"
        :options="dotsOptions"
        @clear="selectedMultipleClear"
        @edit="editItem"
        @delete="deleteItem"
        @history="showHistory"
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteDashboardIntegrationMatching', { name:  deletingModel.integrationValue })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceFilterPanel,
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceMainGrid,
  ProSpaceDots,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceTableHeaderCell,
  ProSpaceDateColumn,
  ProSpaceDateTimeColumn,
  ProSpaceDefaultTextColumn,
  ProSpaceEmptyGrid,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpaceVLayout,
  ProSpaceFilterContainsChips,
  ProSpaceFilterDatesRange,
  ProSpaceDropdown,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceInputSwitch,
  ProSpaceInputText,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin.js";
import { DashboardIntegrationMatchingService } from "../../services/DashboardIntegrationMatchingService.js";
import { DashboardDatasetService } from "../../services/DashboardDatasetService.js";

import Column from "primevue/column";
import DashboardIntegrationMatchingPanel from "./components/DashboardIntegrationMatchingPanel";
import ProSpaceHistoryModal from "../../../../../Frontends/ProSpace/components/modals/ProSpaceHistoryModal";
const _ = require("lodash");
import { mapGetters } from "vuex";
import { ref } from "vue";
import { useMultiSelectGrid } from "@composes/multiselect";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceFilterPanel,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceMainGrid,
    ProSpaceDots,
    ProSpaceModal,
    Column,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    ProSpaceDateColumn,
    ProSpaceDateTimeColumn,
    ProSpaceDefaultTextColumn,
    ProSpaceEmptyGrid,
    DashboardIntegrationMatchingPanel,
    ProSpaceHistoryModal,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpaceVLayout,
    ProSpaceFilterContainsChips,
    ProSpaceFilterDatesRange,
    ProSpaceDropdown,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceInputSwitch,
    ProSpaceInputText,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null);
    const filterPanel = ref(null);
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect
    } = useMultiSelectGrid(grid, filterPanel);

    return {
      filterPanel,
      grid,
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    };
  },
  data() {
    return {
      showImportModal: false,
      showHistoryModal: false,

      gridDefaultFilter: {},

      // Service
      dashboardIntegrationMatchingService: new DashboardIntegrationMatchingService(),
      dashboardDatasetService: new DashboardDatasetService(),
      baseTypeService: new BaseTypeService(),

      //InlinePanel
      masterId: null,
      isEdit: false,
      isCreate: false,
      modalModel: {},

      // Multi filter
      modelFastFilter: {
        label: this.$t("allTxt"),
        field: "disabled",
        value: false,
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    customServices() {
      const filters = [
        {
          type: "chips",
          model: "integrationValue",
          label: this.$t("dashboardIntegrationMatchingIntegrationValue"),
          selected: {},
        }
      ];
      filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          },
          {
            type: "chips",
            model: "updatedBy",
            label: this.$t("updatedByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range-time",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );
      return filters;
    },
    dotsOptions() {
      const res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        }
      ];

      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }

      return res;
    },
    dotsHeaderOptions() {
      return [
        {
          name: "reset",
          text: this.$t("update"),
          icon: "update",
        },
      ];
    },
    fastFilters() {
      return [
        { label: this.$t("allTxt"), field: "disabled", value: false },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    }
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    checkAccess(elem, model) {
      return this.checkRoleAccess(elem, model, this.access);
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.name;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    toItem(data) {
      if (!data || data.disabled) return;
      this.$router.push(`/dashboardservice/dashboardIntegrationMatching/${data.id}`);
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    resetMatching() {
      this.loadingHandler(true);
      this.dashboardIntegrationMatchingService
        .resetMatching()
        .then(() => this.loadingHandler(false))
        .catch(() => this.loadingHandler(false));
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val);
    },
    editItem(model) {
      if (!model) return;
      this.toItem(model);
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "update";
      }
      this.dashboardIntegrationMatchingService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete() {
      this.dashboardIntegrationMatchingService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.selectedMultipleClear();
        this.deletingModel = null;
      });
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
    handleHeaderDots(action) {
      if (action === "reset") {
        this.resetMatching();
      } 
    },
    nodeSelect(value) {
      if (this.selectedNode && this.selectedNode.key === value.key) return;
      var filter = value.id === 0 ? { and: [] } : { and: [value.code] };
      this.filterPanel.filter["block"] = filter;
      this.filterPanel.submitFilter();
      this.selectedNode = _.cloneDeep(value);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/main.scss";
.basetype-tree {
  .radio-tabs {
    margin-top: 1px;
    .radio-tab:nth-child(2) {
      display: none !important;
    }
    &-body {
      margin: 10px;
    }
  }

  &__left-panel {
    border: 1px solid var(--libBorder);
  }
}
.prospace-multiselect {
  &-panel {
    .p-multiselect-items-wrapper {
      min-height: 120px;
    }
  }
}
</style>
