<template>
  <ProSpaceGeoLayout class="menuitem" v-if="model">
    <template #top>
      <ProSpaceTopPanel
        :header="model.title"
        boxShadow
        back
        notification
        @back="backHandler"
      >
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceIconButton
            style="margin-left: 10px"
            icon="upload"
            iconWidth="24px"
            iconHeight="24px"
            containerSize="24px"
            @click="actionUpload"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout is-template :template-center="true">
        <template #center>
          <ProSpaceTabs
            :tabs="radionBtns"
            :loading="loading"
            :withoutMarginBottom="true"
            @select-tab="handlerSelectTab"
          >
            <ProSpaceAction
              icon="check"
              :text="$t('save')"
              @click="saveMenuitem"
              :disabled="v$.model.$invalid"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('mainInformation')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="20">
                      <ProSpaceInputText
                        :placeholder="$t('title')"
                        :label="$t('title')"
                        v-model="model.title"
                        :invalid="v$.model.title.$error"
                        :invalid-messages="v$.model.title.$errors"
                        @blur="v$.model.title.$touch"
                      />
                      <ProSpaceInputText
                        :placeholder="$t('path')"
                        :label="$t('path')"
                        v-model="model.path"
                      />
                      <ProSpaceDropdown
                        :label="$t('parent')"
                        :service="menuItemsService"
                        getMethod="getPaths"
                        :argMethod="model"
                        v-model="model.parentId"
                      />
                      <ProSpaceDropdown
                        :label="$t('icon')"
                        :service="menuItemsService"
                        getMethod="getIcons"
                        v-model="model.icon"
                      >
                        <template #value="slotProps">
                          <ProSpaceHLayout :gap="5">
                            <ProSpaceIcon
                              :icon="slotProps.value"
                              iconWidth="14px"
                              iconHeight="14px"
                            />
                            {{ slotProps.value }}
                          </ProSpaceHLayout>
                        </template>
                        <template #option="slotProps">
                          <ProSpaceHLayout :gap="5">
                            <ProSpaceIcon
                              :icon="slotProps.option.value"
                              iconWidth="14px"
                              iconHeight="14px"
                            />
                            <span class="text-size-12">{{
                              slotProps.option.label
                            }}</span>
                          </ProSpaceHLayout>
                        </template>
                      </ProSpaceDropdown>
                      <ProSpaceInputNumber
                        :label="$t('order')"
                        v-model="model.order"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template v-if="v$.model.title.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('localization')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="20">
                      <ProSpaceInputText
                        placeholder="русский"
                        label="Russian"
                        v-model="model.language.RU"
                      />
                      <ProSpaceInputText
                        placeholder="english"
                        label="English"
                        v-model="model.language.EN"
                      />
                      <ProSpaceInputText
                        placeholder="français"
                        label="France"
                        v-model="model.language.FR"
                      />
                    </ProSpaceVLayout>
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceTabs,
  ProSpaceVLayout,
  ProSpaceHLayout,
  ProSpaceTextBlock,
  ProSpaceInputText,
  ProSpaceDropdown,
  ProSpaceIcon,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceIconButton,
  ProSpaceAction,
  ProSpaceInputNumber,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { MenuItemsService } from "../../services/MenuItemsService.js";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  name: "MenuItem",
  components: {
    ProSpaceTabs,
    ProSpaceVLayout,
    ProSpaceHLayout,
    ProSpaceTextBlock,
    ProSpaceInputText,
    ProSpaceDropdown,
    ProSpaceIcon,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceIconButton,
    ProSpaceAction,
    ProSpaceInputNumber,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  validations() {
    return {
      model: {
        title: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  data() {
    return {
      menuItemsService: new MenuItemsService(),
      masterId: null,
      model: null,
      langs: null,
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    async getData() {
      this.loading = true;
      this.masterId = this.$route.params.id;
      const res = await this.menuItemsService.getItem(this.masterId);
      this.model = res.data[0];
      this.model.language = JSON.parse(this.model.language);
      this.loading = false;
    },
    saveMenuitem() {
      this.loading = true;
      this.model.language = JSON.stringify(this.model.language);
      this.menuItemsService.editItem([this.model]).finally(() => {
        this.getData();
        this.loading = false;
      });
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    radionBtns() {
      return [{ name: this.$t("overview"), value: "Overview", systemName: "overview" }];
    },
    radioBtnSelected() {
      return { name: this.$t("overview"), value: "Overview" };
    },
  },
};
</script>
