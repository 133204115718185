<template>
  <ProSpaceModal
    v-model="localModelValue"
    :style="getModalStyle"
    :withoutPadding="true"
    :needHideApply="false"
    :needHideAfterDataLoad="false"
    :isDataLoading="loading"
    :title="id ? dashboardName : $t('newDashboardTxt')"
    :icon="id ? 'pen' : 'plus'"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="getApplyText"
    applyIcon="check"
    :disabledCancel="loading"
    :disabledApply="isNextButtonDisabled || loading"
    @apply="getHandleApply"
    @cancel="closeModal"
    @resize="setModalSize"
  >
    <ProSpaceGeoLayout isHideBottom isHideRight isHideLeft isHideTop>
      <template #center>
        <ProSpaceTabs v-model="modelTab" :tabs="tabs" class="dashboard-modal-form__tabs">
              <template v-slot:tab-1>
                <ProSpaceVLayout :gap="20">
                  <ProSpaceInputText
                    v-model="model.name"
                    :label="$t('dashboardName')"
                    :placeholder="$t('dashboardName')"
                    :invalid="v$.model.name.$error"
                    :invalid-messages="v$.model.name.$errors"
                    @blur="v$.model.name.$touch"
                    style="width: calc(50% - 5px)"
                  />
                  <ProSpaceDropdown
                    v-model="model.section"
                    :label="$t('dashboardSection')"
                    :placeholder="$t('dashboardSection')"
                    :invalid="v$.model.section.$error"
                    :invalid-messages="v$.model.section.$errors"
                    @blur="v$.model.section.$touch"
                    :service="baseTypeService"
                    getMethod="grid"
                    propValue="code"
                    propLabel="name"
                    argMethod="$filter=systemDirectoryCode eq 'DashboardSection' and disabled eq false"
                    @change="
                      (item) =>
                        (model.section =
                          item && item.code ? item.code : null)
                    "
                    style="width: calc(50% - 5px)"
                  />
                  <ProSpaceInputText
                    class="dashboard-modal-form__textarea"
                    isTextarea
                    v-model="model.description"
                    :rows="11"
                    :label="$t('dashboardDescription')"
                    :placeholder="$t('dashboardDescription')"
                    :invalid="v$.model.description.$error"
                    :invalid-messages="v$.model.description.$errors"
                    @blur="v$.model.description.$touch"
                  />
                </ProSpaceVLayout>
              </template>
              <template v-slot:tab-2>
                <DashboardSourceSelect
                  :getTagInfo="getTagInfo"
                  :datasets="datasets"
                  :selectedSourcesService="selectedSourcesService"
                  :counter="selectedDataSources?.length"
                  :nextStepsEdited="isEditedStepsAfterSource"
                  @handlerCheckUncheck="handlerCheckUncheck"
                  @mainSourceChange="handlerMainSourceChange"
                  @clearSelectedSources="handleClearSelectedSourcesButtonClick"
                  @listTreeLoaded="handleDataSourceListTreeLoaded"
                  @clearNextData="clearDataAfterSource"
                />
              </template>
              <template v-slot:tab-3>
                <DashboardFilterSettings
                  :filters="model.filters"
                  :selectedSourcesService="selectedSourcesService"
                  :getTagInfo="getTagInfo"
                  :validateWhenSelect="afterValidation"
                  @update:selectedFilters="setSelectedFilters"
                />
              </template>
              <template v-slot:tab-4>
                <DashboardLayoutSettings
                  ref="layoutSettingsForm"
                  :layoutSettings="layoutSettings"
                  :validateWhenSelect="afterValidation"
                  :selectedSourcesService="selectedSourcesService"
                  :createDefaultLayoutColorSettings="createDefaultLayoutColorSettings"
                  :createLayoutSettingsRootContainerChild="createLayoutSettingsRootContainerChild"
                  :createGraphLayout="createGraphLayout"
                  :createPivotValueField="createPivotValueField"
                  @update:model="setLayoutSettings"
                />
              </template>
            </ProSpaceTabs>
      </template>
    </ProSpaceGeoLayout>
    <ProSpaceFlashMessage
      v-if="showValidationMessage"
      v-model="showValidationMessage"
      type="default"
      icon="flash-warning"
      :title="$t('dashboardConstructorValidationTitle')"
      :cancelTxt="$t('cancelInf')"
    >
      {{ $t('dashboardConstructorValidationDescription') }}
    </ProSpaceFlashMessage>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceFile,
  ProSpaceGeoLayout,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceAction,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpaceTabs,
  ProSpaceInputText,
  ProSpaceInputNumber,
  ProSpaceInputCalendar,
  ProSpaceCheckbox,
  ProSpaceEmptyGrid,
  ProSpacePanelBlock,
  ProSpaceRightPanel,
  ProSpaceTag,
  ProSpaceInputLayout,
  ProSpaceLeftPanel,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceTagsColumn,
  ProSpaceInputSwitch,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { required, maxLength, helpers, minValue, maxValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { BaseTypeService } from "../../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService.js";
import { DashboardService } from "../../../services/DashboardService.js";
import { DashboardDatasetService } from "../../../services/DashboardDatasetService.js";

import DashboardFilterSettings from "./DashboardFilterSettings.vue";
import DashboardLayoutSettings from "./DashboardLayoutSettings.vue"
import DashboardSourceSelect from "./DashboardSourceSelect.vue";
import DashboardBaseInfoForm from "./DashboardBaseInfoForm.vue";
import moment from "moment";
import _ from "lodash";

export default {
  name: "DashboardModalForm",
  components: {
    DashboardBaseInfoForm,
    DashboardSourceSelect,
    ProSpaceModal,
    ProSpaceFile,
    ProSpaceGeoLayout,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceAction,
    ProSpaceIcon,
    ProSpaceDropdown,
    ProSpaceTabs,
    ProSpaceInputText,
    ProSpaceInputNumber,
    ProSpaceInputCalendar,
    ProSpaceCheckbox,
    ProSpaceEmptyGrid,
    ProSpacePanelBlock,
    ProSpaceRightPanel,
    ProSpaceTag,
    ProSpaceInputLayout,
    ProSpaceLeftPanel,
    ProSpaceTree,
    ProSpacePanelBlockItem,
    ProSpaceTagsColumn,
    ProSpaceInputSwitch,
    ProSpaceFlashMessage,
    DashboardFilterSettings,
    DashboardLayoutSettings
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    dashboardName: {
      type: String,
      default: null
    },
    afterSaveHandler: {
      type: Function,
      default: () => null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      moment,
      model: null,
      layoutSettings: null,
      datasets: [],
      selectedDataSources: [],
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      baseTypeService: new BaseTypeService(),
      datasetService: new DashboardDatasetService(),
      dashboardService: new DashboardService(),
      selectedSourcesService: {
        getSelectedSources: this.getSelectedSources,
        getMainSource: this.getMainSource,
        getMainSourceFilters: this.getMainSourceFilters,
        getNotMainSources: this.getNotMainSources
      },
      loading: false,
      showValidationMessage: false,
      afterValidation: false,
      step: 0,
      modalSize: { width: '60%', height: '76%' },
    };
  },
  created() {
    this.model = this.emptyModel();
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", { maxLength: 255 }),
            maxLength(255)
          ),
        },
        section: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        },
        description: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required),
        }
      },
    };
  },
  methods: {
    emptyModel() {
      return {
        name: "",
        section: null,
        description: "",
        filters: [],
        datasets: []
      };
    },
    handleApply() {
      this.loading = true;
      if (this.checkModelInvalid()) {
        this.showValidationMessage = true;
        this.$refs.layoutSettingsForm.$refs.leftTree.getData();
        this.$refs.layoutSettingsForm.expandAllNodes(true);
        this.loading = false;
        return;
      }

      const saveMethod = this.id ? "update" : "create";

      this.model.datasets = this.datasets;
      if (this.model.layouts && this.model.layouts[0])
        this.model.layouts[0].layoutSettings = JSON.stringify(this.layoutSettings)
      else
        this.model.layouts = [{ layoutSettings: JSON.stringify(this.layoutSettings) }];

      this.dashboardService[saveMethod](this.model)
          .then(async (result) => {
            this.model.id = result.data[0].id;

            if (this.afterSaveHandler) this.afterSaveHandler(result.data[0]);
            this.loading = false;
            this.localModelValue = false;
            const filterName = btoa("dashbord-filter_" + this.model.id + this.model.lastUpdated);
            localStorage.removeItem(filterName);
          })
          .catch(() => {
            this.loading = false;
          });
    },
    checkModelInvalid() {
      let validBasicTab = this.isBasicTabIncomplete;
      let validSourceTab = this.isSourceTabIncomplete;
      let validFilterTab = !this.checkFilterSettings();
      let validLayoutTab = !this.checkLayoutSettings();

      this.afterValidation = true;

      return validBasicTab
        || validSourceTab
        || validFilterTab
        || validLayoutTab
    },
    checkFilterSettings() {
      let valid = true;

      this.model.filters.forEach(f => {
        let correct = !!f.type;
        if (correct && (f.type === 'select' || f.type === 'multiselect' || f.type === 'checkbox'))
          correct = !!(f.sourceDatasetName && f.sourceDatasetField);

        f.needWarning = !correct;

        valid = valid && correct;
      })

      return valid;
    },
    checkLayoutSettings() {
      return this.checkFilterPanel(this.layoutSettings.filterPanel)
        & this.checkContainer(this.layoutSettings.rootContainer, true);
    },
    checkFilterPanel(panel) {
      let valid = true;
      let checkPanelElement = (field) => {
        let correct = !!field.type;
        if (correct)
          correct = field.type !== 'none' ? !!field.value : true;
        return correct
      }

      valid = checkPanelElement(panel.header)
        && checkPanelElement(panel.label)
        && checkPanelElement(panel.value);

      if (valid && (panel.header.type === 'field' || panel.label.type === 'field' || panel.value.type === 'field'))
        valid = !!panel.source;

      panel.needWarning = !valid

      return valid;
    },
    checkContainer(container, isRoot = false) {
      let valid = true;

      valid = isRoot || (container.value && container.value != 5e-324);
      container.needWarning = !valid;

      if (Array.isArray(container.children)) {
        container.children.forEach(elem => {
          if (elem.element === "container")
            valid = this.checkContainer(elem) && valid;
          else
            valid = this.checkElement(elem) && valid;
        })
      }

      return valid;
    },
    checkElement(elem) {
      let valid = true;
      let checkGraph = (el) => {
        let correct = !!el.xAxis;

        if (correct)
          correct = el.datasets.every(d => d.graphType && d.valueField)

        return correct;
      }

      let checkPanel = (el) => {
        let valid = true;
        let checkPanelElement = (field) => {
          let correct = !!field.type;
          if (correct)
            correct = field.type !== 'none' ? !!field.value : true;
          return correct
        }

        valid = checkPanelElement(el.label) && checkPanelElement(el.value);
        if (valid && elem.element === "text")
          valid = checkPanelElement(el.header);
        return valid;
      }

      let checkIterpanel = (el) => {
        return !!(el.label && el.value);
      }

      const checkPivot = (pivot) => {
        return pivot.columnField
          && pivot.rows?.length > 0
          && pivot.rows.every(row => row)
          && pivot.values?.length > 0
          && pivot.values.every(val => val.field)
          && pivot.sort?.field
          && pivot.sort?.direction && (pivot.sort.direction === 'asc' || pivot.sort.direction === 'desc')
      }

      if (elem.element === "graph")
        valid = checkGraph(elem);
      else if (elem.element === "text" || elem.element === "panel")
        valid = checkPanel(elem);
      else if (elem.element === "iterpanel")
        valid = checkIterpanel(elem);
      else if (elem.element === "pivot")
        valid = checkPivot(elem);

      elem.needWarning = !valid;

      return valid;
    },
    closeModal() {
      this.clearModel();
      this.$emit("update:modelValue", false);
    },
    clearModel() {
      this.afterValidation = false;
      this.model = this.emptyModel();
      this.v$.$reset();
      this.datasets = [];
      this.selectedDataSources = [];
      this.layoutSettings = this.createDefaultLayoutSettings();
    },
    getTagInfo(node, isMain) {
      if (node.children) {
        if (isMain && node.isMain)
          return {type: "blue", text: this.$t("basicSource")}

        return {type: "light-blue", text: this.$t("source")}
      }
      else return {type: "teal", text: this.$t("field")}
    },
    handlerCheckUncheck({ checkedNodes, node }) {
      if (checkedNodes[node.systemName]) {
        this.selectedDataSources.push({..._.cloneDeep(node), isMain: false});
        this.datasets.push({
          datasetName: node.systemName,
          isMain: node.isMain,
          localization: JSON.stringify(node.localization)
        })

      }
      else {
        const index = this.selectedDataSources.findIndex(n => n.systemName == node.systemName);
        this.selectedDataSources.splice(index, 1);

        this.datasets = this.datasets.filter(d => d.datasetName !== node.systemName)
      }
    },
    handlerMainSourceChange() {
      const assign = (d) => Object.assign(this.selectedDataSources.find(sd => d.datasetName === sd.systemName), d);
      this.selectedDataSources = this.datasets.map(assign);
    },
    async getSelectedSources() {
      return { data: this.selectedDataSources }
    },
    async getMainSource() {
      return { data: this.selectedDataSources.filter(s => s.isMain) }
    },
    async getMainSourceFilters() {
      let mainSource = _.cloneDeep(this.selectedDataSources.filter(s => s.isMain));
      mainSource.forEach(s => {
        s.children = s.children.filter(x => x.isFilter)
      });
      return { data: mainSource }
    },
    getNotMainSources() {
      return this.selectedDataSources.filter(s => !s.isMain)
    },
    handleClearSelectedSourcesButtonClick() {
      this.selectedDataSources = [];
      this.datasets = [];
    },
    handleDataSourceListTreeLoaded(dataSource) {
      this.selectedDataSources.push(_.cloneDeep(dataSource));
    },
    updateStep() {
      if (this.step < 3) {
        this.step += 1;
        this.modelTab = this.tabs[this.step];
      }
    },
    clearModelFilters() {
      this.model.filters = [];
    },
    clearLayoutSettings() {
      this.layoutSettings = this.createDefaultLayoutSettings();
    },
    clearDataAfterSource() {
      this.clearModelFilters();
      this.clearLayoutSettings();
    },
    setSelectedFilters(filters) {
      this.model.filters = _.cloneDeep(filters);
    },
    createDefaultLayoutSettings() {
      return {
        filterPanel: {
          source: null,
          header: {
            type: 'none',
            value: null,
            color: this.createDefaultLayoutColorSettings()
          },
          label: {
            type: 'none',
            value: null,
            color: this.createDefaultLayoutColorSettings()
          },
          value: {
            type: 'none',
            value: null,
            color: this.createDefaultLayoutColorSettings()
          },
        },
        rootContainer: {
          element: "root-container",
          type: "vertical",
          children: [],
          name: "root-container",
          level: 0,
          systemName: "key_0:1",
          key: "key_0:1",
        }
      }
    },
    createDefaultLayoutColorSettings(type = 'default') {
      return {
        type: type,
        range: {
          left: 80,
          right: 95
        },
        background: "--prospace-dashboard-background-grey",
        text: "--prospace-dashboard-text-black",
        before: {
          background: "--prospace-dashboard-background-orange",
          text: "--prospace-dashboard-text-white"
        },
        center: {
          background: "--prospace-dashboard-background-orange",
          text: "--prospace-dashboard-text-white"
        },
        after: {
          background: "--prospace-dashboard-background-green",
          text: "--prospace-dashboard-text-white"
        }
      }
    },
    createLayoutSettingsRootContainerChild(type) {
      if (type === 'container') {
        return {
          element: "container",
          type: "vertical",
          value: 400,
          children: []
        }
      }
      if (type === 'graph') {
        return {
          element: "graph",
          xAxis: null,
          showLegend: false,
          datasets: [
            this.createGraphLayout(),
          ],
          filter: []
        }
      }
      if (type === 'text') {
        return {
          element: "text",
          header: {
            type: 'none',
            value: null,
            color: this.createDefaultLayoutColorSettings()
          },
          label: {
            type: 'none',
            value: null,
            link: null,
            color: this.createDefaultLayoutColorSettings()
          },
          value: {
            type: 'none',
            value: null,
            color: this.createDefaultLayoutColorSettings()
          },
          filter: []
        }
      }
      if (type === 'panel') {
        return {
          element: "panel",
          label: {
            type: 'none',
            value: null,
          },
          value: {
            type: 'none',
            value: null,
          },
          color: this.createDefaultLayoutColorSettings(),
          filter: []
        }
      }
      if (type === 'iterpanel') {
        return {
          element: "iterpanel",
          value: null,
          label: null,
          color: this.createDefaultLayoutColorSettings(),
          filter: []
        }
      }
      if (type === 'pivot') {
        return {
          element: "pivot",
          columnField: null,
          rows: [],
          tagRows: {},
          values: [],
          sort: {
            field: null,
            direction: "asc"
          },
          filter: []
        }
      }
    },
    createPivotValueField(field = null, color = null) {
      return {
        field: field,
        color: color ?? this.createDefaultLayoutColorSettings("lights")
      }
    },
    createGraphLayout() {
      return {
        graphType: "bar",
        valueField: null,
        color: {
          type: "default",
          background: "--prospace-dashboard-graph-light-blue"
        }
      }
    },
    setLayoutSettings(settings) {
      this.layoutSettings = settings;
    },
    setModalSize(size) {
      let { width, height } = size;
      this.modalSize = {
        width: `${(width).toFixed()}px`,
        height: `${(height).toFixed()}px`,
      };
    }
  },
  watch: {
    localModelValue: {
      handler(value) {
        if (!value && !this.loading) {
          this.clearModel();
          this.modelTab = this.tabs[0];
        } else if (this.loading) {
          this.loading = false;
        }

        if (value) {
          this.modelTab = this.tabs[0];
          if (this.id) {
            this.loading = true;
            this.dashboardService.get(this.id).then((response) => {
              this.datasetService.getDatasetModel().then((datasetModels) => {
                this.model = response.data;
                if (this.model.datasets) {
                  this.datasets = _.cloneDeep(this.model.datasets);
                  this.selectedDataSources = datasetModels.data
                    .filter(x => this.model.datasets.some(d => d.datasetName === x.systemName))
                    .map(x => { return { isMain: this.model.datasets.find(d => d.datasetName === x.systemName).isMain, ...x} });
                }

                if (this.model.filters) {
                  this.model.filters.forEach(f => {
                    const localization = JSON.parse(f.localization)
                    f.filterLocalizationName = this.datasetService.getLocalizedName(localization)
                  })
                }

                if (this.model.layouts && this.model.layouts[0])
                  this.layoutSettings = JSON.parse(this.model.layouts[0].layoutSettings)
                else
                  this.layoutSettings = this.createDefaultLayoutSettings()

                this.loading = false;
              }).catch(() => {
                this.loading = false;
              });
            }).catch(() => {
              this.loading = false;
            });
          }
          else
            this.layoutSettings = this.createDefaultLayoutSettings()
        }
      },
    },
    modelTab: {
      handler: function(tab, oldTab) {
        if (tab.systemName !== oldTab.systemName) {
          this.step = this.tabs.findIndex(t => t.systemName === tab.systemName);
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    icon() {
      return { faFileUpload };
    },
    isBasicTabIncomplete() {
      return this.v$.model.$invalid;
    },
    isSourceTabIncomplete() {
      return !(this.selectedDataSources && this.selectedDataSources.length > 0 && this.selectedDataSources.some(x => x.isMain))
    },
    isNextButtonDisabled() {
      return (this.modelTab.systemName === "basicInformation" && this.isBasicTabIncomplete)
        || (this.modelTab.systemName === "dataSourceSelection" && this.isSourceTabIncomplete)
    },
    isEditedStepsAfterSource() {
      return JSON.stringify(this.layoutSettings) !== JSON.stringify(this.createDefaultLayoutSettings())
        || (this.model.filters && this.model.filters.length > 0);
    },
    tabs() {
      if (!this.model) return [];
      const tabs = [
        {
          name: this.$t("basicInformation"),
          systemName: "basicInformation",
          isActive: this.modelTab.systemName === "basicInformation",
        },
        {
          name: this.$t("dataSourceSelection"),
          systemName: "dataSourceSelection",
          isActive: this.modelTab.systemName === "dataSourceSelection",
          isDisabled: this.isBasicTabIncomplete,
        },
        {
          name: this.$t("filterSettings"),
          systemName: "filterSettings",
          isActive: this.modelTab.systemName === "filterSettings",
          isDisabled: this.isBasicTabIncomplete || this.isSourceTabIncomplete,
        },
        {
          name: this.$t("placement"),
          systemName: "placement",
          isActive: this.modelTab.systemName === "placement",
          isDisabled: this.isBasicTabIncomplete || this.isSourceTabIncomplete,
        },
      ];
      if (!tabs.some((i) => i.isActive)) {
        tabs[0].isActive = true;
        this.modelTab = tabs[0];
      }
      return tabs;
    },
    getApplyText() {
      if (this.step !== (this.tabs.length - 1)) {
        return this.$t("next");
      } else {
        return this.id ? this.$t('save') : this.$t('createDashbordTxt')
      }
    },
    getHandleApply() {
      return this.step !== (this.tabs.length - 1) ? this.updateStep : this.handleApply;
    },
    isLastStep() {
      return this.step !== (this.tabs.length - 1);
    },
    getModalStyle() {
      const { width, height } = this.modalSize;
      return `min-width: 945px; width: ${width}; height: ${height}; min-height: 580px;`
    }
  },
};
</script>

<style lang="scss">
.dashboard-modal-form {
  &__tabs {
    & > .prospace-geo-layout__top {
      padding-left: 40px;
      padding-right: 40px;
    }
    .prospace-tabs__content {
      height: 100%;
      padding: 20px 40px 0 40px;
    }
  }
  &__textarea {
    .prospace-input-layout__errors {
      margin-top: -1px;
    }
  }
}
</style>
