<template>
  <ProSpaceRightPanel v-if="!!record" :header="header" no-top-border class="history-panel">
    <template v-if="!!record" v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="headerChanges">
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList
                :list="content"
                :itemsPerPage="itemsPerPage"
                :searched="true"
                :searchPlaceholder="$t('searchTxt')"
                type="row"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="headerFields">
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList
                :list="contentActual"
                :itemsPerPage="itemsPerPage"
                :searched="true"
                :searchPlaceholder="$t('searchTxt')"
                type="row"
              />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
      <ProSpaceModal
        v-model="modal"
        :title="modalTitle"
        icon="parameters"
        :applyTxt="false"
        :cancelTxt="$t('closeTxt')"
        :contentStyle="{
          minHeight: '300px',
          height: '300px'
        }"
      >
        <template v-slot:default>
          <pre class="parameters-view"
            v-if="modalType === 'json'"
          >{{ params }}</pre>
          <ProSpaceVLayout
            v-else-if="modalType === 'checkbox' && params.length > 0"
            is-inline-h
            :gap="15"
          >
            <ProSpaceHLayout
              v-for="item in params"
              is-inline-h
              :gap="10"
            >
              <ProSpaceSimpleCheckbox
                v-model="item.checked"
                disabled
              />
              {{item.text}}
            </ProSpaceHLayout>
          </ProSpaceVLayout>
          <ProSpaceEmptyGrid
            v-else-if="params.length === 0"
            icon="error-message"
            :title="modalEmptyTitle"
            :text="modalEmptyText"
          />
        </template>
      </ProSpaceModal>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceSimpleCheckbox,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceEmptyGrid
  // ProSpacePanelBlockItem,
} from "@prospace/prospace-components-library";
import { mapGetters } from "vuex";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import {
  useStatistic
} from "@composes"
export default {
  name: "HistoryPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceModal,
    ProSpaceGeoLayout,
    ProSpaceSimpleCheckbox,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceEmptyGrid
    // ProSpacePanelBlockItem,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    historyService: {
      type: Object,
      required: true,
    },
    getActualName: {
      type: Function,
      required: true,
    },
    getActualId: {
      type: Function,
      required: true,
    },
    getActualStatus: {
      type: Function,
      required: true,
    },
    fields: {
      type: Object,
      default: null
    },
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return {
      setStatistic, distActions
    }
  },
  data() {
    return {
      tabs: [
        { s: "tab-1", name: this.$t("changes"), systemName: "changes", isActive: true },
        { s: "tab-2", name: this.$t("fields"), systemName: "fields" },
      ],
      headerChanges: {
        title: "",
        isExpand: false,
      },
      headerFields: {
        title: "",
        isExpand: false,
      },
      modal: false,
      params: null,
      modalType: 'json',
      modalTitle: '',
      modalEmptyTitle: '',
      modalEmptyText: ''
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTimeSeconds: "userinfoStore/getFormatDateTimeSeconds",
      getFormatDate: "userinfoStore/getFormatDate"
    }),
    actualModel() {
      if (this.record && this.record.actualModel)
        return this.record.actualModel;
      return null;
    },
    previosValues() {
      if (this.record && this.record.previousValues)
        return this.record.previousValues;
      return null;
    },
    isReference() {
      let isPreviousReference = false;
      if (this.previosValues) {
        const keys = Object.keys(this.previosValues);
        if (keys.length === 1) {
          const s = keys[0].split("|");
          const rf = this.historyService.modelInfo.referenceFrom.find(
            (v) => v.modelName === s[0]
          );
          if (rf) isPreviousReference = true;
        }
      }
      return (
        this.record &&
        Array.isArray(this.record.groupIds) &&
        this.record.groupIds.length === 1 &&
        isPreviousReference
      );
    },
    itemsPerPage() {
      if (this.actualModel) return Object.keys(this.actualModel).length;
      return 1;
    },
    header() {
      if (this.actualModel) {
        return {
          title: this.getActualName(this.actualModel),
          id: this.getActualId(this.actualModel),
          status: this.getActualStatus(this.actualModel),
          options: [],
          showCounter: false,
        };
      }
      return {};
    },
    content() {
      if (this.previosValues) {
        let result = [];
        for (const key in this.previosValues) {
          if (this.historyService.modelInfo.systemProperties.includes(key))
            continue;
          let value = this.previosValues[key];
          const field = this.fields ? this.fields[key] : null;

          if (field && field.hidden) {
            continue;
          }

          const setValuePm = () => {
            value = value ? this.getFormatDateTimeSeconds(value) : null;
            value ? value += ' ' + getAMorPM(value, this.userinfo.timeFormat) : null;
          }

          if (field) {
            switch(field.type) {
              case 'date':
                value = value ? this.getFormatDate(value) : null;
                break;
              case 'datetime':
                value = value ? this.getFormatDateTimeSeconds(value) : null;
                break;
              case 'pm':
                setValuePm()
                break;
              default:
                value = this.formatStringValue(value)
            }
          } else if (this.isDate(key)) {
            setValuePm()
          } else if (this.isStr(key)) {
            value = this.formatStringValue(value);
          }

          let item = {
            title: key,
            text: value,
          };
          let modelName = this.getModelName(this.historyService.modelInfo.modelName);
          let rf = this.referenceFieldConfs[key];

          if (rf) {
            if (rf.isKey) continue;
            modelName = this.getModelName(rf.modelName);
            item.title = rf.originalName;
          }

          item.title = this.$t(modelName + item.title);
          item.action = undefined;
          if (this.isReference) {
            item.action = () => {
              this.$emit("showReferenceChanges", key, this.record.groupIds[0]);
            };
          } else if (this.isStr(key) && this.isJSON(value)) {
            item.action = () => {
              this.showModal(key, value);
            };
            item.underline = true;
          }

          if (field && field.title) {
            item.title = field.title
          }

          if (field && field.value) {
            item.text = typeof field.value === 'function' ? field.value(value) : field.value;
          }

          if (field && field.type === 'tab') {
            item.action = () => {
              this.$emit("showReferenceChanges", field.modelName || key, this.record.groupIds[0]);
            };
            item.underline = true;
          } else if (field && field.type === 'action' && field.fnAction) {
            item.action = () => field.fnAction(this.actualModel)
            item.underline = false;
          }

          result.push([item]);
        }
        return result;
      }
      return [];
    },
    contentActual() {
      if (this.actualModel) {
        let result = [];
        for (const key in this.actualModel) {
          if (this.historyService.modelInfo.systemProperties.includes(key)) {
            continue;
          }

          let value = this.actualModel[key];
          const field = this.fields ? this.fields[key] : null;

          if (field && field.hidden) {
            continue;
          }

          const setValuePm = () => {
            value = value ? this.getFormatDateTimeSeconds(value) : null;
            value ? value += ' ' + getAMorPM(value, this.userinfo.timeFormat) : null;
          }

          if (field) {
            switch(field.type) {
              case 'date':
                value = value ? this.getFormatDate(value) : null;
                break;
              case 'datetime':
                value = value ? this.getFormatDateTimeSeconds(value) : null;
                break;
              case 'pm':
                setValuePm()
                break;
              default:
                value = this.formatStringValue(value)
            }
          } else if (this.isDate(key)) {
            setValuePm()
          } else if (this.isStr(key)) {
            value = this.formatStringValue(value);
          }

          let item = {
            title: key,
            text: value,
          };
          let modelName = this.getModelName(this.historyService.modelInfo.modelName);
          let rf = this.referenceFieldConfs[key];

          if (rf) {
            if (rf.isKey) continue;
            modelName = this.getModelName(rf.modelName);
            item.title = rf.originalName;
          }

          item.title = this.$t(modelName + item.title);
          item.action = undefined;
          if (this.isStr(key) && this.isJSON(value)) {
            item.action = () => {
              this.showModal(key, value);
            };
            item.underline = true;
          }

          if (field && field.title) {
            item.title = field.title
          }

          if (field && field.value) {
            item.text = typeof field.value === 'function' ? field.value(value) : field.value;
          }

          if (field && field.type === 'tab') {
            item.action = () => {
              this.$emit("showReferenceChanges", field.modelName || key, this.record.groupIds[0]);
            };
            item.underline = true;
          } else if (field && field.type === 'tab-actual' && field.filterField && field.filterValueField) {
            item.action = () => {
              this.$emit("showReferenceChangesActual", field.modelName, field.filterField, this.actualModel[field.filterValueField]);
            };
            item.underline = true;
          } else if (field && field.type === 'action' && field.fnAction) {
            item.action = () => field.fnAction(this.actualModel)
            item.underline = false;
          }

          result.push([item]);
        }
        return result;
      }
      return [];
    },
    referenceFieldConfs() {
      return this.historyService.referenceFieldConfs();
    },
    fieldProperties() {
      return this.historyService.modelInfo?.properties;
    },
  },
  mounted() {},
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    getModelName(modelName) {
      modelName = modelName.replace('ApiModel','');
      modelName = modelName.replace(/^.{1}/g, modelName[0].toLowerCase());
      return modelName;
    },
    isDate(fieldName) {
      const prop = this.fieldProperties[fieldName];
      return prop === "DateTime" || prop === "DateTimeOffset";
    },
    isStr(fieldName) {
      const prop = this.fieldProperties[fieldName];
      return prop === "String";
    },
    isJSON(value) {
      try {
        const maybeJSON = JSON.parse(value);
        return maybeJSON && typeof maybeJSON === "object";
      } catch {
        return false;
      }
    },
    showModal(key, value) {
      this.modal = true;
      const field = this.fields ? this.fields[key] : null
      this.modalType = 'json'
      if (field && field.type === 'modal') {
        this.modalType = field.modalType
        this.modalTitle = field.modalTitle ?? this.$t('parameters')
        this.modalEmptyTitle = field.modalEmptyTitle ?? this.$t('noDataTitle')
        this.modalEmptyText = field.modalEmptyText ?? this.$t('noDataTxt')
      }

      if (this.modalType === 'json') {
        this.params = JSON.stringify(JSON.parse(value), null, 2);
      } else if (this.modalType === 'checkbox') {
        this.params = JSON.parse(value)
      } else if (this.modalType === 'file') {

      }
      
    },
    formatStringValue(value) {
      if (!value) {
        return;
      }
      return this.removeWhitespaceSymbols(this.removeHtmlTags(value));
    },
    removeHtmlTags(str) {
      if (typeof str === "string") return str.replace(/(<([^>]+)>)/gi, " ");
      return str;
    },
    removeWhitespaceSymbols(str) {
      if (typeof str === "string") return str.replaceAll("&nbsp;", " ").replaceAll("&emsp;", " ");
      return str;
    },
  },
};
</script>

<style lang="scss">
.history-panel {
  .prospace-panel-block__body {
    padding: 0 3px;
  }
  .prospace-panel-block-list__row {
    padding: 0 7px;
  }
  .prospace-right-panel__center {
    & > div, .prospace-tabs, .prospace-tabs__content, .prospace-panel-block, .prospace-panel-block__body, .prospace-panel-block-list {
      height: 100%;
    }
    .prospace-panel-block-list__rows {
      height: calc(100% - 40px);
    }
  }
  .prospace-right-panel--padding {
    padding: 0 10px;
  }
  .prospace-right-panel__bottom {
    display: none;
  }
}
</style>
