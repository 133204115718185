<template>
  <ProSpaceGeoLayout :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="$t('backgroundTasks')">
        <template #right>
          <ProSpaceIconButton
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
            icon="update"
            @click="actionUpdate"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :searchPlaceholder="$t('searchTxt')"
            :closeTxt="$t('closeTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            :unicKeyStorage="userTaskService.constructor.name + 'grid'"
            @apply="submitFilter"
            ref="filterPanel"
            filter-model="taskName"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customFilters"
                :unicKeyStorage="userTaskService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            style="height: calc(100vh - 150px)"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="userTaskService"
            :noMountedRequest="true"
            @selected-change="selectedHandler"
          >
            <template #columns>
              <Column field="taskName" :header="$t('taskName')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.taskName"
                    :description="`${field.data.description || ''}`"
                    :no-hover="true"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="createdBy" :header="$t('createdByTxt')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.createdBy }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="execution" :header="$t('execution')" sortable>
                <template #body="field">
                  <ProSpaceDefaultTextColumn>
                    {{ field.data.execution }}
                  </ProSpaceDefaultTextColumn>
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    :sub-options="subDotsOptions"
                    @action="
                      (action) =>
                        handleDots(
                          [...dotsOptions, ...subDotsOptions],
                          action,
                          field.data
                        )
                    "
                  />
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="error-message"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
          <TaskResult
            ref="result"
            :checkAccess="checkAccess"
            @action="showParameter"
          />
          <TaskParameters ref="parameter" />
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <UserTaskPanel v-if="hasSelect"
        ref="right-panel"
        :service="userTaskService"
        :options="[...dotsOptions, ...subDotsOptions]"
        :checkAccess="checkAccess"
        @action="
          (action, task) =>
            handleDots([...dotsOptions, ...subDotsOptions], action, task)
        "
      />
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="TaskQueueItemApiModel"
    @close="closeHistory"
  />
</template>
<script>
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import {
  ProSpaceGeoLayout,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceTableHeaderCell,
  ProSpaceMainGrid,
  ProSpaceCustomFilterPanel,
  ProSpaceFilterPanel,
  ProSpaceIconButton,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceTopPanel,
  ProSpaceDots,
  event_bus,
  ProSpaceEmptyGrid,
  ProSpaceModal,
  ProSpacePanelBlockItem,
  ProSpaceLabel,
  ProSpaceDivider,
  ProSpaceAction,
  ProSpaceDefaultTextColumn,
  ProSpaceFilterContainsChips,
  ProSpaceDateTimeColumn
} from "@prospace/prospace-components-library";
import { UserTaskService } from "../../services/UserTaskService.js";
import Column from "primevue/column";
import UserTaskPanel from "./components/UserTaskPanel";
import TaskResult from "../components/TaskResult";
import TaskParameters from "../components/TaskParameters";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import { TaskStatusService } from "../../services/TaskStatusService.js";
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
const _ = require("lodash");
import { mapGetters } from "vuex";
export default {
  mixins: [accessMixin],
  components: {
    ProSpaceGeoLayout,
    ProSpaceEmptyGrid,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTableHeaderCell,
    Column,
    ProSpaceMainGrid,
    ProSpaceCustomFilterPanel,
    ProSpaceFilterPanel,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpaceButton,
    ProSpaceTopPanel,
    ProSpaceDots,
    UserTaskPanel,
    TaskResult,
    TaskParameters,
    ProSpaceModal,
    ProSpacePanelBlockItem,
    ProSpaceLabel,
    ProSpaceDivider,
    ProSpaceAction,
    ProSpaceHistoryModal,
    ProSpaceDefaultTextColumn,
    ProSpaceFilterContainsChips,
    ProSpaceDateTimeColumn
  },
  data() {
    return {
      showHistoryModal: false,
      userTaskService: new UserTaskService(),
      taskStatusService: new TaskStatusService(),
      masterId: null,

      emitter: event_bus,
    };
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
     const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel, selectedMultipleGridHandler,
      hasSelect, selectedMultipleClear,
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
    }),
    dotsOptions() {
      return [
        {
          name: "result",
          text: this.$t("taskResult"),
          icon: "edit",
          method: this.showResult,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
    },
    subDotsOptions() {
      return [
        {
          name: "again",
          text: this.$t("runAgain"),
          icon: "history",
          method: this.runTaskAgain,
        },
      ];
    },
    modelFastFilter() {
      return {
        label: this.$t("allTxt"),
      };
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
        },
        { label: this.$t("completeS"), field: "status", value: "Complete" },
        { label: this.$t("error"), field: "status", value: "Error" },
        { label: this.$t("newTxt"), field: "status", value: "New" },
      ];
    },
    customFilters() {
      let filters = [
        {
          type: "range",
          model: "execution",
          label: this.$t("execution"),
          selected: {},
          min: 0,
          max: 9999999999,
        },
        {
          type: "dates-range",
          model: "startedAt",
          label: this.$t("startedAt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      ]

      if(this.checkAccess("metainfo-by-block"))
        filters.push(
          {
            type: "chips",
            model: "createdBy",
            label: this.$t("createdByTxt"),
            selected: {},
          }
        );

      filters.push(
        {
          type: "dates-range-time",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        }
      );

      return filters;
    },
  },
  mounted() {
    this.loadRoleAccess();
    if (this.$route.query && this.$route.query.openResult) {
      this.userTaskService
        .grid(`$filter=id eq ${this.$route.query.openResult}`)
        .then((res) => {
          if (res && res.data && Array.isArray(res.data.records)) {
            this.showResult(res.data.records[0]);
          }
        })
    }
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.taskName;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    handleDots(dotsOptions, action, item) {
      if (action != "parameter") {
        var option = dotsOptions.find((o) => o.name === action);
        option.method(item);
      } else {
        this.showParameter(item);
      }
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.filterPanel.submitFilter();
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    showModalForm(name, task) {
      this.$refs[name].show(task);
    },
    getStatus(status) {
      if (!status) return;
      let type = "default";
      switch (status.toLowerCase()) {
        case "complete":
          type = "success";
          break;
        case "process":
          type = "warning";
          break;
        case "error":
          type = "danger";
          break;
        default:
          type = "default";
      }
      return {
        text: this.taskStatusService.search(status),
        type,
      };
    },
    showResult(task) {
      this.showModalForm("result", task);
    },
    showParameter(task) {
      this.showModalForm("parameter", task);
    },
    runTaskAgain(task) {
      this.userTaskService.runAgain(task).then(() => {
        this.actionUpdate();
      });
    },
  },
};
</script>
<style lang="scss"></style>
