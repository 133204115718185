<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @edit="$emit('edit', distributor)"
    @delete="$emit('delete', distributor)"
    @show-history="$emit('history', distributor)"
  >
    <template v-if="selectedCount === 1" v-slot:center>
      <ProSpaceTabs :tabs="tabs" @select-tab="handlerSelectTab">
        <template v-slot:tab-1>
          <ProSpacePanelBlock :header="{}" withPaddingTop>
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList :itemsPerPage="5" :list="content" />
            </template>
          </ProSpacePanelBlock>
        </template>
        <template v-slot:tab-2>
          <ProSpacePanelBlock :header="{}" withPaddingTop>
            <template v-slot:header> </template>
            <template v-slot>
              <ProSpacePanelBlockList :itemsPerPage="10" :list="contentRequisites" />
            </template>
          </ProSpacePanelBlock>
        </template>
      </ProSpaceTabs>
    </template>
    <template v-if="selectedCount === 1" v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-if="isMultipleView()" v-slot:center>
      <ProSpaceVLayout :gap="30">
        <PanelBlockSelectedItems :items="[
            { name: $t('code'), value: 'code', f: (v) => v },
            { name: $t('distributor'), value: 'name', f: (v) => v },
          ]"
          @clear="$emit('clear-selected')"
        />
      </ProSpaceVLayout>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import { DistributorService } from "../../../services/DistributorService";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
import PanelBlockSelectedItems from "@multiselect/PanelBlockSelectedItems"
export default {
  name: "DistributorPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    PanelBlockSelectedItems
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterAccessDots: {
      type: Function,
    },
    keyField: {
      type: String,
      default: "id",
    },
    checkAccess: {
      type: Function,
    }
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    const { selectedData, selectedCount, isMultipleView } = useMultiSelectRightPanel()
    return {
      setStatistic, distActions,
      selectedData, selectedCount, isMultipleView
    }
  },
  data() {
    return {
      distributor: null,
      distributorService: new DistributorService(),
      loading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    tabs() {
      return [
          { name: this.$t("mainInformation"), isActive: true, systemName: "mainInformation" },
          { name: this.$t("requisites"), systemName: "requisites" }
      ];
    },
    header() {
      if (this.distributor) {
        return {
          title: this.distributor.name,
          id: this.distributor.code,
          status: { text: this.$t("distributor"), type: "blue" },
          options: this.getAccessDots(this.options),
          showCounter: false,
        };
      }
      return {
        title: this.$t("itemsSelected"),
        counter: this.selectedCount,
        showCounter: true,
      };
    },
    content() {
      if (this.distributor) {
        return [
          [
            {
              title: this.$t("distributorLegalAddress"),
              text: this.distributor.legalAddress,
            },
          ],
          [
            {
              title: this.$t("distributorMailAddress"),
              text: this.distributor.mailAddress,
            },
          ],
          [
            {
              title: this.$t("distributorPhoneNumber"),
              text: this.distributor.phoneNumber,
            },
          ],
          [
            {
              title: this.$t("distributorFaxNumber"),
              text: this.distributor.faxNumber,
            },
          ],
          [
            {
              title: this.$t("email"),
              text: this.distributor.emailAddress,
            },
          ],
        ];
      }
      return [];
    },
    contentRequisites() {
      if (this.distributor) {
        return [
          [
            {
              title: this.$t("nameOfBank"),
              text: this.distributor.bankName,
            },
          ],
          [
            {
              title: this.$t("bankPaymentAccount"),
              text: this.distributor.bankPaymentAccount,
            },
          ],
          [
            {
              title: this.$t("bankCorrespondentAccount"),
              text: this.distributor.bankCorrespondentAccount,
            },
          ],
          [
            {
              title: this.$t("bankBIC"),
              text: this.distributor.bankBIC,
            },
          ],
          [
            {
              title: this.$t("bankINN"),
              text: this.distributor.bankINN,
            },
          ],
          [
            {
              title: this.$t("bankKPP"),
              text: this.distributor.bankKPP,
            },
          ],
          [
            {
              title: this.$t("bankOGRN"),
              text: this.distributor.bankOGRN,
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.distributor) {
        if(this.checkAccess && this.checkAccess("metainfo-by-block"))
          metaInfo.push([
            {
              title: this.$t("createdByTxt"),
              text: this.distributor.createdBy,
            },
            {
              title: this.$t("updatedByTxt"),
              text: this.distributor.updatedBy,
            },
          ]);

        metaInfo.push([
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.distributor.createdAt) + ' ' + getAMorPM(this.distributor.createdAt, this.userinfo.timeFormat),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.distributor.lastUpdated) + ' ' + getAMorPM(this.distributor.lastUpdated, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    handlerSelectTab({ selectedTab }) {
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    getAccessDots(options) {
      return this.filterAccessDots ? this.filterAccessDots(options, this.distributor) : options;
    },
    update() {
      this.hasError = false;
      try {
        this.getDistributor();
      } catch (e) {
        this.hasError = true;
      }
    },
    getDistributor() {
      if (this.selectedCount === 1) {
        this.loading = true;
        let method = this.keyField === "code" ? "getByCode" : "get"
        this.distributorService[method](this.keyField === "code" ? this.selectedData.code : this.selectedData.id).then((res) => {
          this.distributor = res.data;
          this.loading = false;
        });
      }
      this.distributor = null;
    },
  },
  watch: {
    selectedData() {
      this.update();
    },
    selectedCount() {
      this.update();
    }
  },
};
</script>

<style scoped></style>
