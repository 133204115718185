<template>
  <ProSpaceModal
    v-model="localModelValue"
    :width="420"
    :height="240"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('add')"
    :disabledApply="false"
    :disabledCancel="isSaving"
    :needHideApply="false"
    :title="$t('newElementTxt')"
    icon="plus"
    style="margin-top: 26vh;"
    @apply="addNewContainer"
    @cancel="closeModal"
  >
    <ProSpaceGeoLayout isHideBottom isHideRight isHideLeft isHideTop>
      <template #center>
        <ProSpaceVLayout :gap="5">
          <ProSpaceLabel :text="$t('elementType')" bold />
          <ProSpaceDropdown
            ref="filterTypeDropDown"
            v-model="selectedContainer"
            :options="getContainerTypesOptions"
            propValue="value"
            propLabel="label"
            :placeholder="$t('elementType')"
          />
        </ProSpaceVLayout>
      </template>
    </ProSpaceGeoLayout>
  </ProSpaceModal>
</template>

<script>
import {
  ProSpaceModal,
  ProSpaceGeoLayout,
  ProSpaceVLayout,
  ProSpaceLabel,
  ProSpaceDropdown,
} from "@prospace/prospace-components-library";
export default {
  name: "DashboardModalNewContainer",
  components: {
    ProSpaceModal,
    ProSpaceGeoLayout,
    ProSpaceVLayout,
    ProSpaceLabel,
    ProSpaceDropdown,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSaving: false,
      selectedContainer: null,
    };
  },
  watch: {
    modelValue(val) {
      if (val)   
        this.selectedContainer = this.getContainerTypesOptions[0].value;
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    getContainerTypesOptions() {
      return [
        { label: this.$t('containerElementType'), value: 'container' },
        { label: this.$t('chartElementType'), value: 'graph' },
        { label: this.$t('textValueElementType'), value: 'text' },
        { label: this.$t('digitValueElementType'), value: 'panel' },
        { label: this.$t('iterPanelElementType'), value: 'iterpanel' },
        { label: this.$t('pivotElementType'), value: 'pivot' },
        // { label: this.$t('tableElementType'), value: 'table' },
      ]
    },
    getEmmitedContainer() {
      return this.getContainerTypesOptions.find(el => el.value === this.selectedContainer);
    }
  },
  methods: {
    closeModal() {
      this.selectedContainer = this.getContainerTypesOptions[0].value;
      this.$emit("update:modelValue", false);
    },
    addNewContainer() {
      this.$emit("create:container", this.getEmmitedContainer);
    }
  },
  mounted() {
    // this.selectedContainer = this.getContainerTypesOptions[0].value;
  }
}
</script>

<style scoped>

</style>
