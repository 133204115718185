<template>
  <DashboardModalLayout
    class="dashboard-filter-settings"
    :panels="panels">
    <template #left>
      <ProSpaceTree
        ref="mainDataSourceTree"
        searchName="name"
        getNodeMethod="getMainSourceFilters"
        propKey="systemName"
        propLabel="name"
        selectionMode="checkbox"
        :errorMessage="$t('noData')"
        :searchFilterModel="['name', 'systemName']"
        :service="selectedSourcesService"
        :noMountedSelectNode="true"
        :fnVisibleCheckbox="(node) => !node.children"
        @check="handlerCheckUnCheck"
        @uncheck="handlerCheckUnCheck"
        @loaded="handleMainDataSourceTreeLoaded"
      >
        <template #prefix="{ node }">
          <ProSpaceTag v-bind="getTagInfo(node, true)" />
        </template>
      </ProSpaceTree>
    </template>
    <template #center>
      <ProSpaceVLayout v-if="selectedFilters && selectedFilters.length > 0" :gap="2" style="padding: 4px;">
        <DashboardFilterItem
          v-for="(filter, index) in selectedFilters"
          :key="`${filter.systemName}-${index}`"
          :label="filter.filterLocalizationName"
          :is-required="filter.isRequired"
          :is-active="filter.name === selectedFilter?.name"
          :needWarning="filter.needWarning"
          @clickItem="() => handleFilterClick(filter)"
          @delete="() => handleDeleteFilter(filter, index)"
        />
      </ProSpaceVLayout>
      <ProSpaceVLayout v-else :gap="2" style="padding: 4px;">
        <ProSpaceEmptyGrid icon="error-message" :title="$t('noData')" :text="$t('noFiltersText')" />
      </ProSpaceVLayout>
    </template>
    <template #right>
      <template v-if="selectedFilter">
        <div class="dashboard-grid-layout__right prospace-panel-block-list">
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
              :title="$t('filterName')"
              :text="selectedFilter.filterLocalizationName"
            />
          </div>
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
              :title="$t('filterType')"
            >
              <template #content>
                <ProSpaceDropdown
                  ref="filterTypeDropDown"
                  v-model="selectedFilter.type"
                  :options="selectedFilterTypes"
                  propValue="code"
                  propLabel="name"
                  :invalid="v$.selectedFilter.type.$error"
                  :invalid-messages="v$.selectedFilter.type.$errors"
                  @blur="v$.selectedFilter.type.$touch"
                  @change="handleFilterTypeChange"
                />
              </template>
            </ProSpacePanelBlockItem>
          </div>
          <template
            v-if="selectedFilter.type === 'select'
              || selectedFilter.type === 'multiselect'
              || selectedFilter.type === 'checkbox'
            ">
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('source')"
              >
                <template #content>
                  <ProSpaceDropdown
                    ref="sourceDatasetNameDropdown"
                    v-model="selectedFilter.sourceDatasetName"
                    propValue="systemName"
                    propLabel="name"
                    :placeholder="$t('source')"
                    :options="notMainSources"
                    :invalid="v$.selectedFilter.sourceDatasetName.$error"
                    :invalid-messages="v$.selectedFilter.sourceDatasetName.$errors"
                    @blur="v$.selectedFilter.sourceDatasetName.$touch"
                    @change="handleSourceDatasetNameChange"
                  />
                </template>
              </ProSpacePanelBlockItem>
            </div>
            <div class="prospace-panel-block-list__row">
              <ProSpacePanelBlockItem
                class="prospace-panel-block-list__item"
                :title="$t('field')"
              >
                <template #content>
                  <ProSpaceDropdown
                    ref="sourceDatasetField"
                    v-model="selectedFilter.sourceDatasetField"
                    propValue="systemName"
                    propLabel="name"
                    :placeholder="$t('field')"
                    :invalid="v$.selectedFilter.sourceDatasetField.$error"
                    :invalid-messages="v$.selectedFilter.sourceDatasetField.$errors"
                    :options="sourceDatasetFieldOptions"
                    @blur="v$.selectedFilter.sourceDatasetField.$touch"
                  />
                </template>
              </ProSpacePanelBlockItem>
            </div>
          </template>
          <div class="prospace-panel-block-list__row">
            <ProSpacePanelBlockItem
              class="prospace-panel-block-list__item"
            >
              <template #content>
                <ProSpaceHLayout>
                  <ProSpaceInputSwitch
                    v-model="selectedFilter.isRequired"
                    :label="$t('requiredFilter')"
                  />
                  <ProSpaceIcon
                    v-hint="$t('requiredFilterShowsRequiredFilterFillingInSelectDashboardParameterModalWindow')"
                    icon="question"
                  />
                </ProSpaceHLayout>

              </template>
            </ProSpacePanelBlockItem>
          </div>
        </div>
      </template>
    </template>
  </DashboardModalLayout>
</template>

<script>
import DashboardModalLayout from "./DashboardModalLayout.vue";
import { DashboardDatasetService } from "../../../services/DashboardDatasetService";
import {
  ProSpaceIconButton,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceIcon,
  ProSpaceDropdown,
  ProSpaceTag,
  ProSpaceTree,
  ProSpacePanelBlockItem,
  ProSpaceInputSwitch,
  ProSpaceEmptyGrid,
  Hint,
} from "@prospace/prospace-components-library";
import DashboardFilterItem from "./DashboardFilterItem.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import _ from "lodash";
export default {
  name: "DashboardFilterSettings",
  components: {
    DashboardFilterItem,
    DashboardModalLayout,
    ProSpaceTree,
    ProSpaceTag,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceIconButton,
    ProSpaceIcon,
    ProSpacePanelBlockItem,
    ProSpaceDropdown,
    ProSpaceInputSwitch,
    ProSpaceEmptyGrid
  },
  directives: {
    hint: Hint
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    selectedSourcesService: {
      type: Object,
      required: true,
    },
    getTagInfo: {
      type: Function,
      required: true
    },
    validateWhenSelect: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  watch: {
    selectedFilter: {
      handler(val) {
        if (val)
          this.$emit('update:selectedFilters', this.selectedFilters);
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      selectedFilter: null,
      selectedFilterTypes: null,
      notMainSources: null,
      mainSources: null,
      sourceDatasetFieldOptions: null,
      datasetService: new DashboardDatasetService(),
      selectedFilters: [],
    }
  },
  validations() {
    const checkCorrectSource = (value) => {
        let type = this.selectedFilter.type;

        if (type === "select" || type === "checkbox" || type === "multiselect")
          return !!value;

        return true;
      }

    return {
      selectedFilter: {
        type: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), required)
        },
        sourceDatasetName: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), checkCorrectSource)
        },
        sourceDatasetField: {
          required: helpers.withMessage(this.$t("requiredFieldErrorMessage"), checkCorrectSource)
        },
      }
    };
  },
  methods: {
    handlerCheckUnCheck({ checkedNodes, node }) {
      if (this.mainSources[0].children.length > Object.keys(checkedNodes).length) {
        const index = this.selectedFilters.findIndex(filter => filter.systemName === node.systemName);
        const createFilterObj = (node) => {
          return {
            name: node.systemName,
            systemName: node.systemName,
            filterLocalizationName: this.datasetService.getLocalizedName(node.localization),
            localization: JSON.stringify(node.localization),
            type: this.getFilterTypesByDataType(node.type)[0].code,
            dataType: node.type,
            sourceDatasetName: null,
            sourceDatasetField: null,
            sourceField: node.sourceField,
            isRequired: false
          };
        };
        if (checkedNodes[node.systemName] || checkedNodes[this.mainSources[0].systemName]) {
          if (node.children) {
            node.children.map(child => {
              this.selectedFilters = [...this.selectedFilters, createFilterObj(child)];
            })
          } else if (!node.children || checkedNodes[this.mainSources[0].systemName] || checkedNodes[node.systemName]) {
            this.selectedFilters = [...this.selectedFilters, createFilterObj(node)];
          }
        }
        else {
          if (Object.keys(checkedNodes).length === 0) {
            this.selectedFilters.length = 0;
          } else {
            this.selectedFilters = this.deletingFilterFunc(index);
          }
        }
      }
      this.$emit('update:selectedFilters', this.selectedFilters);
    },
    deletingFilterFunc(ind) {
      return this.selectedFilters.filter(el => el.systemName !== this.selectedFilters[ind].systemName);
    },
    handleDeleteFilter(filter, index) {
      this.selectedFilters = this.deletingFilterFunc(index);
      delete this.$refs.mainDataSourceTree.checkedNodes[filter.systemName];
      this.$refs.mainDataSourceTree.checkParent(filter);
      if (this.selectedFilter && this.selectedFilter.systemName == filter.systemName)
        this.selectedFilter = null;
      this.$emit('update:selectedFilters', this.selectedFilters);
    },
    clearFilters() {
      if (this.selectedFilters.length > 0) {
        this.selectedFilters = [];
        this.$refs.mainDataSourceTree.checkedNodes = {};
        this.selectedFilter = null;
        this.$emit('update:selectedFilters', this.selectedFilters);
      }
    },
    handleFilterClick(filter) {
      this.v$.$reset();
      this.selectedFilter = filter;
      this.selectedFilterTypes = this.getSelectedFilterTypes();
      //костыль, так как при обновлении значения пропа :options выпадающие значения в дропдауне не меняются
      if(this.$refs.filterTypeDropDown)
        this.$refs.filterTypeDropDown.localOptions = this.selectedFilterTypes;

      this.setSourceDatasetDropdownOptions();
      this.setSourceDatasetFieldOptions();

      if (this.validateWhenSelect)
        this.v$.selectedFilter.$touch();
      else
        this.v$.$reset();
    },
    getFilterTypesByDataType(dataType) {
      return this.dataTypeFilters[dataType]
        .map(type => ({
          code: type,
          name: this.$t(type + 'TypeDashboardFilter')
        }))
    },
    getSelectedFilterTypes() {
      return this.getFilterTypesByDataType(this.selectedFilter.dataType);
    },
    handleFilterTypeChange(value) {
      if (!value) return;
      if (!(value.code === 'select' || value.code === 'multiselect' || value.code === 'checkbox')) {
        this.selectedFilter.sourceDatasetName = null;
        this.selectedFilter.sourceDatasetField = null;
        this.v$.$reset();
      }
      this.setSourceDatasetDropdownOptions();
    },
    handleSourceDatasetNameChange(value) {
      if (!value) return;
      this.setSourceDatasetFieldOptions();
    },
    handleMainDataSourceTreeLoaded() {
      const tree = this.$refs.mainDataSourceTree;
      const sourceTreeNodes = tree?.nodes;
      const checkedNodes = tree?.checkedNodes;
      tree.expandAll();

      if (this.filters?.length === 0 || sourceTreeNodes?.length === 0)
        return;

      for (const filter of this.filters) {
        const nodeChildren = sourceTreeNodes[0].children.filter(c => c.systemName === filter.name)

        if(nodeChildren.length === 0)
          continue;

        const nodeChild = nodeChildren[0];
        filter.systemName = nodeChild.systemName;
        checkedNodes[filter.systemName] = {
          checked: true,
          partialChecked: false,
        };
      }
    },
    setCheckedParent() {
      const tree = this.$refs.mainDataSourceTree;
      const checked = tree?.checkedNodes;
      const source = this.mainSources[0];
      const mainName = source?.systemName;
      const selectedCount = this.selectedFilters.length;
      const childrenCount = source?.children.length;

      if (selectedCount === childrenCount) {
        if (!checked[mainName]) {
          checked[mainName] = {
            ...source,
            checked: true,
            partialChecked: false
          };
        }
      } else if (selectedCount < childrenCount && selectedCount > 0) {
        if (!checked[mainName]) {
          checked[mainName] = {
            ...source,
            partialChecked: true,
            checked: false
          };
        }
      }
    },
    async setMainDataSource() {
      const mainSources = await this.selectedSourcesService.getMainSource();
      this.mainSources = mainSources.data;
      if (this.mainSources?.length > 0) {
        this.setCheckedParent();
      }
    },
    setSourceDatasetDropdownOptions() {
      const filterType = this.selectedFilter.type;
      if (filterType === 'select' || filterType === 'multiselect' || filterType === 'checkbox') {
        this.notMainSources = this.selectedSourcesService.getNotMainSources();
        if (this.$refs.sourceDatasetNameDropdown)
          this.$refs.sourceDatasetNameDropdown.localOptions = this.notMainSources;
      }
    },
    setSourceDatasetFieldOptions() {
      const filterType = this.selectedFilter.type;
      if ((filterType === 'select'
          || filterType === 'multiselect'
          || filterType === 'checkbox')
          && this.notMainSources) {
        this.notMainSources.filter(s => this.selectedFilter.sourceDatasetName === s.systemName)
          .forEach(source => {
            this.sourceDatasetFieldOptions = source.children.filter(c => c.type === this.selectedFilter.dataType);
            if (this.$refs.sourceDatasetField)
              this.$refs.sourceDatasetField.localOptions = this.sourceDatasetFieldOptions;
          })
      }
    }
  },
  computed: {
    panels() {
      return [
        {
          title: this.$t("basicSource"),
          search: false
        },
        {
          title: this.$t("dashboardSelectedFilters"),
          counter: this.selectedFilters?.length,
          search: false,
          clearButtonVisible: true,
          clearButtonHandler: this.clearFilters
        },
        {
          title: this.$t("filterSettings"),
        }
      ]
    },
    dataTypeFilters() {
      return {
        'String': ['chips', 'checkbox', 'select', 'multiselect'],
        'Int32': ['number', 'range', 'select', 'multiselect'],
        'Int64': ['number', 'range', 'select', 'multiselect'],
        'Decimal': ['number', 'range', 'select', 'multiselect'],
        'Boolean': ['yesno'],
        'DateTime': [
          'dateselect',
          'daterangeselect',
          'datetimeselect',
          'datetimerangeselect',
          'select',
          'multiselect'
        ],
        'DateTimeOffset': [
          'dateselect',
          'daterangeselect',
          'datetimeselect',
          'datetimerangeselect',
          'select',
          'multiselect'
        ]
      }
    }
  },
  mounted() {
    this.$refs['mainDataSourceTree'].handlerDoubleClick = function() {
      return false;
    };
    this.$refs['mainDataSourceTree'].onNodeExpand = function() {
      return false;
    };
    this.setMainDataSource();
    this.selectedFilters = _.cloneDeep(this.filters.map(x => { return { systemName: x.name, ...x }}));
  }
}
</script>

<style lang="scss">
.dashboard-filter-settings {
  .p-tree .p-tree-container {
    padding-left: 10px;
    padding-right: 10px;
    .p-treenode {
      &:not(.p-treenode-leaf) {
        & > {
          .p-treenode-content {
            margin-left: 0;
            padding-left: 5px;
            padding-right: 2px;
            margin-right: 0;
            .p-tree-toggler {
              display: none;
            }
            & + .p-treenode-children {
              margin-right: 0;
              padding-left: 5px;
            }
          }
        }
      }
      &.p-treenode-leaf {
        margin-right: 0 !important;
        margin-left: -5px;
        & > {
          .p-treenode-content {
            margin-left: 0;
            padding-left: 0;
            margin-right: 0;
            padding-right: 0;
            .p-tree-toggler {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .prospace-empty-grid {
    &__icon {
      width: 230px !important;
      height: 130px !important;
    }
    &__title {
      font-weight: 500;
      &--margin {
        margin-top: 15px;
      }
    }
    &__text {
      margin-top: 5px;
      color: var(--prospace-text-gray);
      opacity: 1;
    }
  }
}

</style>
