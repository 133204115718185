<template>
  <ProSpaceGeoLayout>
    <template #center>
      <ProSpaceMainGrid
        ref="grid"
        :id="userId"
        :masterId="userId"
        :keyId="userId"
        :service="userUseCaseService"
        @selected-change="selectedHandler"
        getMethod="grid"
        selectionMode="single"
      >
        <template #columns>
          <Column
            field="useCaseTitle"
            :header="$t('title')"
            sortable
            style="cursor: pointer"
          >
            <template #body="field">
              <ProSpaceTableHeaderCell
                v-if="field.data"
                :id="field.data.id"
                :title="field.data.useCaseTitle"
                style="cursor: pointer"
              />
            </template>
          </Column>
          <Column
            field="useCaseDescription"
            :header="$t('description')"
            sortable
          >
            <template #body="field">
              <ProSpaceDefaultTextColumn>
                {{ field.data.useCaseDescription }}
              </ProSpaceDefaultTextColumn>
            </template>
          </Column>
          <Column :header="$t('activityPeriod')" sortable>
            <template #body="field">
              <ProSpaceDatesColumn
                :start-date="field.data.useCaseStartDate"
                :end-date="field.data.useCaseEndDate"
                :format="userinfo.dateFormat"
              />
            </template>
          </Column>
        </template>
      </ProSpaceMainGrid>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTableHeaderCell,
  ProSpaceInputText,
  ProSpaceGeoLayout,
  ProSpaceDatesColumn,
  event_bus,
  ModalForm,
  ProSpaceMainGrid,
  ProSpaceDefaultTextColumn,
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import { ClaimService } from "../../../services/ClaimService.js";
import { UserUseCaseService } from "../../../services/UserUseCaseService.js";
import { UseCaseClaimService } from "../../../services/UseCaseClaimService.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ProSpaceTableHeaderCell,
    ProSpaceInputText,
    ProSpaceDatesColumn,
    ModalForm,
    ProSpaceMainGrid,
    Column,
    ProSpaceGeoLayout,
    ProSpaceDefaultTextColumn,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // Grid Panel
      selectedModel: {},
      spKey: 0,
      spHide: false,
      emitter: event_bus,

      // Service
      claimService: new ClaimService(),
      userUseCaseService: new UserUseCaseService(),
      useCaseClaimService: new UseCaseClaimService(),

      // Common
      layoutName: "UseCases",

      //InlinePanel
      masterId: null,

      // Modal
      modalModel: {
        title: "",
        description: "",
        createdAt: "",
        lastUpdated: "",
      },
      isEdit: false,
      isCreate: false,
    };
  },
  methods: {
    viewMoreClick() {
      this.showModalForm("view claim");
    },
    selectedHandler(val) {
      this.$emit("selected-change", val.data.id);
    },
    submitFilter() {
      this.$refs.grid.getRecords(true);
    },
    spToggle() {
      this.spHide = !this.spHide;
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    disabledModalRule() {
      return this.modalModel.title ? this.modalModel.title.length == 0 : false;
    },
  },
};
</script>
