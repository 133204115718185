<template>
  <ProSpaceGeoLayout class="taskassignment" v-if="model" :routeName="$route.name">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
        <template #right> </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
        :routeName="$route.name"
      >
        <template #center>
          <ProSpaceTabs
            v-model="modelTab"
            without-margin-bottom
            :tabs="overviewTabs"
            :loading="loading"
            :refLoading="
              mixinLoadingAccess ||
              statusMixinLoadingAccess ||
              editFieldMixinLoadingAccess
            "
            @select-tab="handlerSelectTab"
          >
            <ProSpaceHLayout :gap="10">
              <ProSpaceAction
                v-if="
                  !model.status ||
                  model.status === 'creation' ||
                  ['taskDraft', 'taskModer'].includes(model.status)
                "
                ref="action-saveDraft"
                :disabled="disabledSave"
                :text="$t('saveDraft')"
                @click="setModelAndSave(false)"
              />
              <ProSpaceAction
                v-if="model && model.status && model.status === 'taskPublished'"
                ref="action-savePublish"
                :disabled="disabledPublished"
                :text="$t('save')"
                @click="setModelAndSave(true)"
              />
              <ProSpaceAction
                v-if="masterId"
                ref="action-createTemplate"
                :text="$t('taskAssignmentCreateTemplate')"
                @click="createTemplate()"
              />
              <ProSpaceAction
                v-if="model.status === 'taskDraft'"
                ref="action-publish"
                :disabled="disabledPublished"
                icon="check"
                :text="$t('publish')"
                @click="confirmPublish()"
              />
            </ProSpaceHLayout>
            <template v-slot:tab-1>
              <ProSpaceHLayout
                isInlineH
                alignItems="flex-start"
              >
                <div class="taskassignment__expired-block" v-if="model.isExpired">
                  <ProSpaceIcon icon="due" :hint="$t('overdueHint')" />
                  {{ $t('taskAssignmentTaskExpired') }}
                </div>
                <ProSpaceHLayout
                  ref="moderation-ProSpaceGeoLayout"
                  v-show="model.status === 'taskModer'"
                  align-items="flex-start"
                  justifyContent="flex-end"
                  class="margin-top-10"
                  isInlineH
                  :gap="5"
                >
                  <ProSpaceButton
                    ref="rejectPublish-button"
                    type="tertiary"
                    icon="ban"
                    :label="$t('reject')"
                    @click="
                      showReasonModal(
                        rejectStep,
                        'reject',
                        $t('rejectPublish')
                      )
                    "
                  />
                  <ProSpaceButton
                    ref="acceptPublish-button"
                    :disabled="disabledPublished"
                    type="primary"
                    icon="check"
                    :label="$t('acceptPublish')"
                    @click="moderationConfirm()"
                  />
                </ProSpaceHLayout>
                <ProSpaceHLayout
                  ref="accept-ProSpaceGeoLayout"
                  v-show="model.status === 'taskPublished'
                    && model.canApproveTask"
                  align-items="flex-start"
                  justifyContent="flex-end"
                  class="margin-top-10"
                  isInlineH
                  :gap="5"
                >
                  <ProSpaceButton
                    ref="performTask-button"
                    type="primary"
                    icon="check"
                    :label="$t('accept')"
                    @click="
                      showReasonModal(
                        nextStep,
                        null,
                        $t('performTask'),
                        true
                      )
                    "
                  />
                </ProSpaceHLayout>
                <ProSpaceHLayout
                  ref="acceptComplete-ProSpaceGeoLayout"
                  v-show="model.status === 'taskApproving'"
                  align-items="flex-start"
                  justifyContent="flex-end"
                  class="margin-top-20"
                  isInlineH
                  :gap="5"
                >
                  <ProSpaceButton
                    ref="rejectTask-button"
                    type="blue"
                    icon="ban"
                    :label="$t('reject')"
                    @click="
                      showReasonModal(
                        rejectStepWithFlash,
                        'reject',
                        $t('rejectTask')
                      )
                    "
                  />
                  <ProSpaceButton
                    ref="performTaskComplete-button"
                    type="primary"
                    icon="check"
                    :label="$t('accept')"
                    @click="
                      showReasonModal(
                        nextStep,
                        null,
                        $t('performTask'),
                        true
                      )
                    "
                  />
                </ProSpaceHLayout>
              </ProSpaceHLayout>
              <ProSpaceVLayout :gap="20">
                <ProSpaceGeoLayout class="geo-row" v-if="!masterId">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTemplateTemplateNameInfo')"
                      :bottom="
                        $t('taskAssignmentTemplateTemplateNameInfoAdditional')
                      "
                      :gap="10"
                      break-word
                      type="third"
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceDropdown
                        ref="taskAssignmentTemplateId-ProSpaceDropdown"
                        v-model="model.taskAssignmentTemplateId"
                        :label="$t('taskAssignmentTemplateTemplateName')"
                        :placeholder="$t('taskAssignmentTemplateTemplateName')"
                        :invalid="v$.model.templateId.$error"
                        :invalid-messages="v$.model.templateId.$errors"
                        @blur="v$.model.templateId.$touch"
                        :service="taskAssignmentTemplateService"
                        getMethod="grid"
                        propValue="id"
                        propLabel="templateName"
                        :argMethod="getTemplateFilter()"
                        @change="onTemplateChange"
                        style="width: calc(50% - 5px)"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.templateId.$error" v-slot:right>
                    <ProSpaceVLayout justify-content="center">
                      <ProSpaceIcon icon="warning" colored />
                    </ProSpaceVLayout>
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTypeInfo')"
                      :bottom="$t('taskAssignmentTypeInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceDropdown
                        v-model="model.type"
                        ref="type-ProSpaceDropdown"
                        :label="$t('taskAssignmentType')"
                        :placeholder="$t('taskAssignmentType')"
                        :invalid="v$.model.type.$error"
                        :invalid-messages="v$.model.type.$errors"
                        @blur="v$.model.type.$touch"
                        :service="baseTypeService"
                        getMethod="grid"
                        propValue="code"
                        propLabel="name"
                        argMethod="$filter=(systemDirectoryCode eq 'Task type' and disabled eq false)&$orderby=name asc"
                        @change="(item) => onTypeChange(item)"
                        style="width: calc(50% - 5px)"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.type.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTaskInfo')"
                      :bottom="$t('taskAssignmentTaskInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="20"
                    >
                      <ProSpaceInputText
                        v-model="model.name"
                        ref="name-ProSpaceInputText"
                        :label="$t('taskAssignmentName')"
                        :placeholder="$t('taskAssignmentName')"
                        :invalid="v$.model.name.$error"
                        :invalid-messages="v$.model.name.$errors"
                        @blur="v$.model.name.$touch"
                      />
                      <ProSpaceExtendedTextArea
                        v-model="model.description"
                        ref="description-ProSpaceExtendedTextArea"
                        :label="$t('taskAssignmentDescription')"
                        :placeholder="$t('taskAssignmentDescription')"
                        :invalid="v$.model.description.$error"
                        :invalid-messages="v$.model.description.$errors"
                        :t="$t"
                        :locale="$i18n.locale"
                        @blur="v$.model.description.$touch"
                      >
                      </ProSpaceExtendedTextArea>
                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="isPositionsRestricted-ProSpaceInputSwitch"
                          v-model="model.isPositionsRestricted"
                          :label="$t('taskAssignmentSendByPositions')"
                          :readonly="!checkEditFieldAccess('isPositionsRestricted-ProSpaceInputSwitch') || model.confirmationByAuthorizedPerson"
                          @update:modelValue="handleIsSendByPositionsUpdate"
                        />
                        <ProSpaceIcon
                          v-hint="$t('taskAssignmentSendByPositionsHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="isDistributorStructureRestricted-ProSpaceInputSwitch"
                          v-model="model.isDistributorStructureRestricted"
                          :label="$t('taskAssignmentSendByDistributorStructures')"
                          :readonly="!checkEditFieldAccess('isDistributorStructureRestricted-ProSpaceInputSwitch') || model.confirmationByAuthorizedPerson"
                          @update:modelValue="handleIsDistributorStructureRestrictedUpdate"
                        />
                        <ProSpaceIcon
                          v-hint="$t('taskAssignmentSendByDistributorStructuresHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="confirmationByAuthorizedPerson-ProSpaceInputSwitch"
                          v-model="model.confirmationByAuthorizedPerson"
                          :label="$t('taskAssignmentConfirmationByAuthorizedPerson')"
                          :readonly="!checkEditFieldAccess('confirmationByAuthorizedPerson-ProSpaceInputSwitch')
                            || (model.isDistributorStructureRestricted || model.isPositionsRestricted)
                          "
                          @update:modelValue="handleConfirmationByAuthorizedPersonUpdate"
                        />
                        <ProSpaceIcon
                          v-hint="$t('taskAssignmentConfirmationByAuthorizedPersonHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        justify-content="flex-start"
                        align-items="center"
                        :gap="5"
                      >
                        <ProSpaceInputSwitch
                          ref="marsValidation-ProSpaceInputSwitch"
                          v-model="model.marsValidation"
                          :label="$t('taskAssignmentMarsValidation')"
                        />
                        <ProSpaceIcon
                          v-hint="$t('taskAssignmentMarsValidationHint')"
                          icon="question"
                        />
                      </ProSpaceHLayout>

                      <ProSpaceAction
                        v-if="model.newsItemId"
                        ref="action-linkToNews"
                        icon="link-icon"
                        :text="this.$t('taskAssignmentLinkToNews')"
                        @click="linkToNews"
                      />
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="
                      v$.model.name.$error ||
                      v$.model.description.$error ||
                      v$.model.marsValidation.$error ||
                      v$.model.confirmationByAuthorizedPerson.$error
                    "
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentDateInfo')"
                      :bottom="$t('taskAssignmentDateInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceVLayout :gap="10">
                      <ProSpaceHLayout
                        justify-content="space-between"
                        align-items="flex-start"
                        :gap="10"
                      >
                        <ProSpaceInputCalendar
                          ref="startAt-ProSpaceInputCalendar"
                          v-model="model.startAt"
                          :minDate="new Date()"
                          :disabled="!dueDateAvailable"
                          :label="$t('taskAssignmentStartAt')"
                          :placeholder="$t('taskAssignmentStartAt')"
                          :invalid="v$.model.startAt.$error"
                          :invalid-messages="v$.model.startAt.$errors"
                          @blur="v$.model.startAt.$touch"
                        />
                        <ProSpaceInputCalendar
                          ref="endAt-ProSpaceInputCalendar"
                          v-model="model.endAt"
                          :minDate="new Date()"
                          :disabled="!dueDateAvailable"
                          :label="$t('taskAssignmentEndAt')"
                          :placeholder="$t('taskAssignmentEndAt')"
                          :invalid="v$.model.endAt.$error"
                          :invalid-messages="v$.model.endAt.$errors"
                          @blur="v$.model.endAt.$touch"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout
                        v-if="
                          model.needRepeat &&
                          !(
                            [
                              'taskWaitPublish',
                              'taskPublished',
                              'taskApproving',
                              'taskComplete',
                              'taskCanceled'
                            ].includes(this.model.status) ||
                            !checkAccess('tab-repeatTask-ProSpaceTabs', model)
                          )
                        "
                      >
                        <span class="text-color-accent">{{
                          $t("needRepeatTxt")
                        }}</span>
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </template>
                  <template
                    v-if="v$.model.startAt.$error || v$.model.endAt.$error"
                    v-slot:right
                  >
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout class="geo-row">
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentTagsInfo')"
                      :bottom="$t('taskAssignmentTagsInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceInputChips
                        v-model="tags"
                        ref="tags-ProSpaceInputChips"
                        placeholder="+"
                        :label="$t('taskAssignmentTags')"
                        :invalid="v$.model.tags.$error"
                        :invalid-messages="v$.model.tags.$errors"
                        @blur="v$.model.tags.$touch"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.tags.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
                <ProSpaceGeoLayout
                  class="geo-row"
                  :class="{
                    'geo-row--no-border': this.model.status !== 'taskModer',
                  }"
                >
                  <template v-slot:left>
                    <ProSpaceTextBlock
                      :top="$t('taskAssignmentAttachmentsInfo')"
                      :bottom="$t('taskAssignmentAttachmentsInfoAdditional')"
                      :gap="10"
                      type="third"
                      break-word
                    />
                  </template>
                  <template v-slot:center>
                    <ProSpaceHLayout
                      justify-content="space-between"
                      align-items="flex-start"
                      :gap="10"
                    >
                      <ProSpaceFile
                        v-model="attachments"
                        ref="attachments-ProSpaceFile"
                        accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
                        :service="fileService"
                        :fileLimit="1000"
                        :maxFileSize="209715200"
                        :txtBtnUpload="$t('uploadFile')"
                        :txtEmptyFiles="$t('uploadFileDragAndDropText')"
                        :txtErrorStatus="$t('uploadStatusError')"
                        :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
                        :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
                        :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
                      />
                    </ProSpaceHLayout>
                  </template>
                  <template v-if="v$.model.attachments.$error" v-slot:right>
                    <ProSpaceWarning />
                  </template>
                </ProSpaceGeoLayout>
              </ProSpaceVLayout>
            </template>
            <template v-slot:tab-2>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('repeatTask')"
                    :bottom="$t('repeatTaskTxt')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceVLayout :gap="20">
                    <ProSpaceInputSwitch
                      :disabled="needRepeatDisabled || model.disabled"
                      :label="$t('repeatTask')"
                      v-model="model.needRepeat"
                      :noBorder="true"
                      @change="repeatTaskChange()"
                    />
                    <ProSpaceVLayout
                      v-if="model.needRepeat"
                      :gap="20"
                      isInlineH
                      style="width: 370px"
                    >
                      <ProSpaceHLayout :gap="10">
                        <ProSpaceDropdown
                          :readonly="model.lock || model.disabled"
                          v-model="repeatConfig.Repeat"
                          style="width: calc(50% - 5px)"
                          :label="$t('repeat')"
                          :placeholder="$t('repeat')"
                          :options="triggerPeriodicOptions"
                          propLabel="text"
                          propValue="value"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout :gap="10">
                        <ProSpaceInputNumber
                          :readonly="model.lock || model.disabled"
                          v-if="dayVisible"
                          v-model="repeatConfig.MonthDay"
                          :label="$t('day')"
                        />
                        <ProSpaceInputNumber
                          v-if="everyVisible"
                          :readonly="model.lock || model.disabled"
                          v-model="repeatConfig.RepeatCount"
                          :label="this.checkLabelLocalization"
                        />
                        <ProSpaceDropdown
                          v-if="everyDayVisible"
                          :readonly="model.lock || model.disabled"
                          v-model="everyDayModel"
                          :label="$t('everyDay')"
                          :placeholder="$t('every')"
                          :options="daysOptions"
                          propLabel="text"
                          propValue="value"
                        />
                        <ProSpaceInputNumber
                          :readonly="model.lock || model.disabled"
                          v-model="repeatConfig.Duration"
                          :label="$t('duration')"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout :gap="10" v-if="onTheDaysVisible">
                        <RepeatConfigDays
                          :disabled="model.lock || model.disabled"
                          :repeatConfig="repeatConfig"
                          :label="$t('onTheDays')"
                        />
                      </ProSpaceHLayout>
                      <ProSpaceHLayout :gap="10">
                        <ProSpaceInputCalendar
                          :disabled="model.lock || model.disabled"
                          v-model="repeatConfig.StartDate"
                          :minDate="new Date()"
                          :label="$t('startRepeat')"
                          :closeTxt="$t('closeTxt')"
                          :applyTxt="$t('applyTxt')"
                        />
                        <ProSpaceInputCalendar
                          :disabled="model.lock || model.disabled"
                          v-model="repeatConfig.EndDate"
                          :minDate="new Date()"
                          :label="$t('endRepeat')"
                          :closeTxt="$t('closeTxt')"
                          :applyTxt="$t('applyTxt')"
                        />
                      </ProSpaceHLayout>
                    </ProSpaceVLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
            <template #tab-3 v-if="isFilial">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                expandAll
                searchable
                readOnly
                :service="distributorStructureService"
                :titleTxt="$t('branches')"
                :descriptionTxt="$t('restrictingAccessBranchesTask')"
              />
            </template>
            <template #tab-3 v-else-if="model.isDistributorStructureRestricted">
              <DistributorHierarchySelectForm
                ref="distributorStructures-DistributorHierarchySelectForm"
                v-model="model.distributorStructures"
                viewMethod="getTaskAssignmentViewTree"
                expandAll
                searchable
                :service="distributorStructureService"
                :afterEdit="afterDistributorStructureEdit"
                :titleTxt="$t('branches')"
                :descriptionTxt="$t('restrictingAccessBranchesTask')"
                :hint="$t('restrictingAccessHintAll')"
                @apply="applyDistributorStructure"
              />
            </template>
            <template v-slot:tab-3 v-else>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('distributors')"
                    :bottom="$t('taskAssignmentAllHint')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceVLayout :gap="20">
                        <ProSpaceInputSwitch
                          v-if="isEditAllDistrAvailable"
                          ref="isAllDistributors-ProSpaceInputSwitch"
                          v-model="model.isAllDistributors"
                          :label="$t('allTxt')"
                          @update:modelValue="isAllDistributorsChanged"
                        />
                        <ProSpaceButton
                          v-if="!model.lock"
                          ref="addRecipient-button"
                          type="secondary"
                          icon="edit"
                          :label="this.$t('editItemTxt')"
                          class="margin-bottom-30"
                          :disabled="model.isAllDistributors"
                          @click="showTaskAssignmentDistributor"
                        />
                      </ProSpaceVLayout>
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        v-if="
                          model.distributorIds &&
                          model.distributorIds.length > 0
                        "
                        ref="distributorGrid"
                        getMethod="grid"
                        :headers="distributorGridHeaders"
                        :service="distributorService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          !model.lock &&
                          checkAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          ) &&
                          checkStatusAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        :deleteMethod="null"
                        :defaultFilter="distributorFilter"
                        deletingFieldName="name"
                        :afterConfirmText="$t('questionDeletingList')"
                        :defaultModal="false"
                        :confirmDelete="true"
                        confirmIcon="flash-success"
                        :confirmTitle="$t('deletingContactUser')"
                        :confirmText="$t('questionDeleting')"
                        @delete="deleteFromDistributors"
                        @openModal="showEditDistributorsModalReadOnly"
                      />
                      <DistributorSelectModal
                        v-model="readDistributorModal"
                        :modalTitle="$t('listDistributors')"
                        :selectedIds="model.distributorIds"
                        :needAllQuestion="false"
                        :readOnly="true"
                        :selectionMode="'single'"
                        :isAll="model.isAllDistributors"
                        :defaultFilter="distributorFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <DistributorSelectModal
                v-model="showDistributorSelectModal"
                needAllQuestion
                :modalTitle="$t('selectDistributors')"
                :selectedIds="model.distributorIds"
                :selectionMode="editType === 'series' ? 'single' : 'multiple'"
                :allSelectionText="$t('isAllDistributorsSelectionTask')"
                @applyDistributors="applyDistributorSelectModal"
              />
              <ProSpaceFlashMessage
                v-if="isAllChangedMessage"
                v-model="isAllChangedMessage"
                type="default"
                icon="flash-success"
                :title="$t('isAllDistributorsTitle')"
                :applyTxt="$t('accept')"
                :cancelTxt="$t('cancelInf')"
                @apply="applyIsAllChangedMessage"
                @update:modelValue="cancelIsAllChangedMessage"
              >
                {{ $t("isAllDistributorsTaskText") }}
              </ProSpaceFlashMessage>
            </template>
            <template v-slot:tab-4>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('positions')"
                    :bottom="$t('taskPositionsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:center>
                      <PositionSelectTree
                        ref="positions-PositionSelectTree"
                        expandAll
                        :positionService="positionOptions.positionService"
                        :positionGetMethod="positionOptions.positionGetMethod"
                        :positionSelectionMode="positionOptions.positionSelectionMode"
                        :positionFilter="positionOptions.positionFilter"
                        :propLabel="positionOptions.propLabel"
                        :checkedPositions="positions"
                        @changed="
                          (newPositions) => {
                            positions = [...newPositions];
                          }
                        "
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
            </template>
            <template v-slot:tab-5>
              <ProSpaceGeoLayout class="geo-row">
                <template v-slot:left>
                  <ProSpaceTextBlock
                    :top="$t('taskAssignmentRecipientsMars')"
                    :bottom="$t('taskAssignmentRecipientsInfo')"
                    :gap="10"
                    type="third"
                    break-word
                  />
                </template>
                <template v-slot:center>
                  <ProSpaceGeoLayout>
                    <template v-slot:top>
                      <ProSpaceButton
                        v-if="!model.lock"
                        ref="addRecipient-button"
                        type="secondary"
                        icon="edit"
                        :label="this.$t('editItemTxt')"
                        class="margin-bottom-30"
                        @click="showOrgStructureSelect"
                      />
                    </template>
                    <template v-slot:center>
                      <ProSpaceSimpleGrid
                        ref="recipientGrid"
                        getMethod="simple"
                        :headers="recipientGridHeaders"
                        :service="recipientService"
                        :limit="50"
                        :btnText="$t('viewMore')"
                        :deletable="
                          !model.lock &&
                          checkAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          ) &&
                          checkStatusAccess(
                            'action-delete-ProSpaceSimpleGrid',
                            model
                          )
                        "
                        deletingFieldName="email"
                        :afterConfirmText="$t('questionDeletingList')"
                        @delete="deleteFromFilter"
                      />
                    </template>
                  </ProSpaceGeoLayout>
                </template>
              </ProSpaceGeoLayout>
              <OrgStructureSelectModal
                ref="filterModal"
                v-model="showOrgStructureSelectModal"
                node-method="onlyMars"
                @close="closeOrgStructureSelectModal"
              />
            </template>
          </ProSpaceTabs>
        </template>
        <template #right v-if="model.steps && model.steps.length > 0">
          <ProSpaceRightPanel no-border :header="statusHeader">
            <template #center>
              <ProSpaceTimeLineCondition
                :fileService="fileService"
                :elems="model.steps"
                :commentTile="$t('comment')"
                :title="$t('comments')"
                :icon="model.needRepeat ? 'repeat' : ''"
                :titleAction="$t('addComment')"
                :dateTimeFormatter="formatTimelineDate"
                @add="showReasonModal(addComment, 'info', $t('addComment'), false, true)"
              >
              </ProSpaceTimeLineCondition>
            </template>
          </ProSpaceRightPanel>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
  <ProSpaceModal
    v-model="reasonModalShow"
    :key="`reason-modal-${reasonModalShow}`"
    :title="reasonTitle"
    icon="list-check"
    @apply="saveStep"
    :width="530"
    :cancelTxt="$t('cancelInf')"
    :applyTxt="$t('applyTxt')"
    :validate-local="$t"
    :validate-model="modalModel"
    :validate-config="{
      comment: {
        required,
      },
    }"
  >
    <template #default="{ v }">
      <ProSpaceVLayout :gap="20">
        <ProSpaceInputText
          :label="$t('comment')"
          v-model="modalModel.comment"
          :placeholder="$t('comment')"
          :invalid="v.comment.$error"
          :invalid-messages="v.comment.$errors"
          @blur="v.comment.$touch"
        />
        <ProSpaceFile
          v-if="needStepFile"
          v-model="stepAttachments"
          accept=".doc, .docx, .docm, .rtf, .txt, .csv, .xml, .pdf, .png, .gif, .jpg, .jpeg, .ppt, .pptx, .potx, .potm, .pptm, .ppsx, .ppsm, .xls, .xlsx, .xlsm, .xlsb, .zip, .7z, .rar, .mp4, .avi, .mkv, .wmv"
          :service="fileService"
          :fileLimit="1000"
          :maxFileSize="209715200"
          :txtBtnUpload="$t('uploadFile')"
          :txtEmptyFiles="$t('uploadFileDragAndDropText')"
          :txtErrorStatus="$t('uploadStatusError')"
          :invalidFileLimitMessage="$t('invalidFileLimitMessage')"
          :invalidFileSizeMessage="$t('invalidFileSizeMessage')"
          :invalidFileTypeMessage="$t('invalidFileTypeMessage')"
        />
      </ProSpaceVLayout>
    </template>
  </ProSpaceModal>
  <ProSpaceFlashMessage
    v-if="isNonExistsUsers"
    v-model="isNonExistsUsers"
    type="error"
    icon="flash-error"
    :title="$t('nonExistsUsersTitle')"
    :applyTxt="null"
    :cancelTxt="null"
  >
    {{ $t('nonExistsUsersText') }}
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="isNonExistsAuthorizedPersons"
    v-model="isNonExistsAuthorizedPersons"
    type="error"
    icon="flash-error"
    :title="$t('nonExistsAuthorizedPersonsTitle')"
    :applyTxt="null"
    :cancelTxt="$t('closeTxt')"
  >
    {{ nonExistsAuthorizedPersonsTextP1 }}
      <ProSpaceAction
        :text="nonExistsAuthorizedPersonsTextAction"
        @click="() => showNonExistsAuthorizedPersonsDistributors = true"
      />
    {{ $t('nonExistsDistributorsAuthorizedPersonsTextP2') }}
  </ProSpaceFlashMessage>
  <DistributorSelectModal
    v-model="showNonExistsAuthorizedPersonsDistributors"
    selectionMode="single"
    readOnly
    :modalTitle="$t('listDistributors')"
    :defaultFilter="nonExistsAuthorizedPersonsDefaultFilter"
  />
  <ProSpaceFlashMessage
    v-if="showConfirmModel"
    v-model="showConfirmModel"
    :type="confirmType"
    :icon="confirmIcon"
    :title="confirmTitle"
    :applyTxt="confirmApplyTxt"
    :cancelTxt="confirmCancelTxt"
    @apply="applyConfirm"
  >
    <div v-html="confirmText"></div>
  </ProSpaceFlashMessage>
  <ProSpaceFlashMessage
    v-if="showDistrWarning"
    v-model="showDistrWarning"
    type="warning"
    icon="flash-warning"
    :title="$t('distrCountWarning')"
  >
    {{ $t("distrCountWarningTxt") }}
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceInputChips,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceButton,
  ProSpaceMainGrid,
  ProSpaceSimpleGrid,
  ProSpaceInputSwitch,
  ProSpaceExtendedTextArea,
  ProSpaceDatePeriod,
  ProSpaceWarning,
  ProSpaceTag,
  ProSpaceModal,
  ProSpaceFlashMessage,
  ProSpaceTimeLineCondition,
  Hint,
} from "@prospace/prospace-components-library";
import PositionSelectTree from "@trees/PositionSelectTree";
import { TaskAssignmentService } from "../../services/TaskAssignmentService.js";
import { TaskAssignmentAttachmentsService } from "../../services/TaskAssignmentAttachmentsService.js";
import { TaskAssignmentRecipientService } from "../../services/TaskAssignmentRecipientService";
import { BaseTypeService } from "../../../../BaseType/BaseTypeService.Frontend/services/BaseTypeService";
import { TaskAssignmentTemplateService } from "../../services/TaskAssignmentTemplateService.js";
import { DistributorService } from "@masterdata/services/DistributorService.js";
import { DistributorOrgService } from "@masterdata/services/DistributorOrgService";
import { DistributorStructureService } from "@masterdata/services/DistributorStructureService.js";
import { FileService } from "../../../../../Frontends/ProSpace/services/FileService.js";
import OrgStructureSelectModal from "@modals/OrgStructureSelectModal";
import DistributorSelectModal from "@modals/DistributorSelectModal";
import RepeatConfigDays from "./components/RepeatConfigDays.vue";
import Column from "primevue/column";
import { mapGetters } from "vuex";
import editFieldMixin from "../../../../../Frontends/Common/utils/elementAccess/editFieldMixin";
import accessMixin from "../../../../../Frontends/Common/utils/elementAccess/accessMixin";
import statusAccessMixin from "../../../../../Frontends/Common/utils/elementAccess/statusAccessMixin";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import DistributorHierarchySelectForm from '@forms/DistributorHierarchySelectForm.vue'
import {
  useStatistic
} from "@composes"
export default {
  mixins: [editFieldMixin, accessMixin, statusAccessMixin],
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceInputChips,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceButton,
    OrgStructureSelectModal,
    ProSpaceMainGrid,
    ProSpaceSimpleGrid,
    Column,
    RepeatConfigDays,
    ProSpaceInputSwitch,
    ProSpaceExtendedTextArea,
    ProSpaceDatePeriod,
    ProSpaceWarning,
    ProSpaceTag,
    ProSpaceModal,
    ProSpaceFlashMessage,
    ProSpaceTimeLineCondition,
    DistributorSelectModal,
    DistributorHierarchySelectForm,
    PositionSelectTree
  },
  directives: {
    hint: Hint,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  data() {
    return {
      moment,
      // Tabs
      modelTab: {
        name: this.$t("parameters"),
        isActive: true,
        systemName: "parameters",
      },
      // Common
      model: null,
      startModel: null,
      loading: false,
      attachmentsToCreate: [],
      attachments: [],
      stepAttachments: [],
      tags: [],
      positions: [],
      // Service
      taskAssignmentService: new TaskAssignmentService(),
      taskAssignmentAttachmentsService: new TaskAssignmentAttachmentsService(),
      recipientService: new TaskAssignmentRecipientService(),
      baseTypeService: new BaseTypeService(),
      taskAssignmentTemplateService: new TaskAssignmentTemplateService(),
      distributorService: new DistributorService(),
      distributorStructureService: new DistributorStructureService(),
      fileService: new FileService(),

      getMethod: "getFull",
      masterId: null,

      editFieldModel: {},

      buffredStartAt: null,
      buffredEndAt: null,

      //Recipients
      showOrgStructureSelectModal: false,
      showDistributorSelectModal: false,
      readDistributorModal: false,
      showNonExistsAuthorizedPersonsDistributors: false,
      isAllChangedMessage: false,
      needCancelIsAll: true,
      afterDistributorStructureEdit: false,
      nodeFilter: {
        add: {},
        remove: {},
      },
      gridFilter: {
        add: {},
        remove: {},
      },
      oDataStringFilter: "",
      selectedTreeNodes: {},
      recipientGridHeaders: [
        { name: this.$t("type"), value: "recordTypeLocale" },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
        {
          name: this.$t("email"),
          value: "email",
          f: (item) => item,
        },
      ],
      distributorGridHeaders: [
        {
          name: this.$t("code"),
          value: "code",
          f: (item) => item,
        },
        {
          name: this.$t("name"),
          value: "name",
          f: (item) => item,
        },
      ],

      // to switch between view data and persist
      persistRecipients: true,

      repeatConfig: {
        StartDate: new Date(),
        EndDate: new Date(),
        Repeat: "Days",
        WeekDays: null,
        Duration: 1,
        RepeatCount: 1,
        MonthDay: 1,
      },

      onceVal: "Once",
      dayVal: "Days",
      weekVal: "Weeks",
      monthVal: "Months",
      everyDayModel: 1,
      triggerPeriodicOptions: [
        // { text: this.$t("once"), value: "Once" },
        { text: this.$t("daily"), value: "Days" },
        { text: this.$t("weekly"), value: "Weeks" },
        { text: this.$t("monthly"), value: "Months" },
      ],
      daysOptions: [
        { text: this.$t("sun"), value: 0 },
        { text: this.$t("mon"), value: 1 },
        { text: this.$t("tue"), value: 2 },
        { text: this.$t("wed"), value: 3 },
        { text: this.$t("thu"), value: 4 },
        { text: this.$t("fri"), value: 5 },
        { text: this.$t("sat"), value: 6 },
      ],
      isCreate: false,
      isUploadError: false,

      needStepFile: false,
      reasonTitle: "",
      reasonCallback: null,
      reasonModalShow: false,
      modalModel: {
        comment: "",
        stepType: "",
        attachments: []
      },

      isNonExistsAuthorizedPersons: false,
      isNonExistsUsers: false,
      nonExistsAuthorizedPersonsTextP1: "",
      nonExistsAuthorizedPersonsTextAction: "",
      nonExistsAuthorizedPersonsDefaultFilter: {},

      showConfirmModel: false,
      confirmIcon: "",
      confirmType: "",
      confirmTitle: "",
      confirmText: "",
      confirmApplyTxt: "",
      confirmCancelTxt: "",
      applyConfirm: () => {},
      showDistrWarning: false,
      editType: "single",
    };
  },
  validations() {
    return {
      model: {
        name: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '100'}),
            maxLength(100)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        description: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        type: {
          maxLength: helpers.withMessage(
            this.$t("maxLengthErrorMessage", {maxLength: '255'}),
            maxLength(255)
          ),
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        templateId: {},
        marsValidation: {},
        confirmationByAuthorizedPerson: {},
        startAt: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          mustBeLessThanEnd: helpers.withMessage(
            this.$t("dateMustBeLessThanEnd"),
            this.checkStartDate
          ),
          moderationMustBeBeforeDates: helpers.withMessage(
            this.$t("correctTaskDates"),
            this.checkModerationOrDraftAndNotOutOfDeadline
          )
        },
        endAt: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
          mustBeGreaterThanStart: helpers.withMessage(
            this.$t("dateMustBeGreaterThanStart"),
            this.checkEndDate
          ),
          moderationMustBeBeforeDates: helpers.withMessage(
            this.$t("correctTaskDates"),
            this.checkModerationOrDraftAndNotOutOfDeadline
          )
        },
        repeatConfig: {},
        tags: {},
        attachments: {},
      },
    };
  },
  mounted() {
    this.loadRoleAccess();
    this.masterId = this.$route.params.id;
    if (this.$route.query && this.$route.query.editType)
      this.editType = this.$route.query.editType;

    this.getData().then((data) => {
      this.setAllAccess();
    });
    this.recipientService.filter = this.recipientGridFilter;
    if (this.masterId) {
      this.$store.dispatch("notifications/updateReadedRecord", {
        title: "Tasks",
        id: Number(this.masterId),
      });
    }
  },
  methods: {
    formatTimelineDate(date) {
      return this.getFormatDateTime(date);
    },
    handlerSelectTab({ selectedTab }) {
      this.setAllAccess()
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: selectedTab.systemName
      })
    },
    setAllAccess(clear = false) {
      this.$nextTick(() => {
        if (this.model.lock) {
          this.setReadOnly();
        } else {
          if (clear) this.clearAllCondition();

          if (this.modelTab.systemName === "parameters")
            this.resetCondition([
              "confirmationByAuthorizedPerson-ProSpaceInputSwitch",
              "isPositionsRestricted-ProSpaceInputSwitch",
              "isDistributorStructureRestricted-ProSpaceInputSwitch",
            ]);

          this.mountAccess();
          this.mountEditFieldAccess();
          this.mountStatusAccess();
        }
      });
    },
    moderationConfirm() {
      this.confirmType = "default";
      this.confirmIcon = "flash-success";
      this.confirmTitle = this.$t("publishTask");
      this.confirmText = this.$t("publishTaskTxt");
      this.confirmApplyTxt = this.$t("publish");
      this.confirmCancelTxt = this.$t("reject");
      this.applyConfirm = () => {
        this.setModelAndSave(true, false).then((res) => {
          if (res && res.success) {
            this.modalModel.stepType = "accept";
            this.nextStep().then(() => {
              this.afterSave();
            });
          } else this.loading = false;
        });
      };
      this.showConfirmModel = true;
    },
    confirmPublish() {
      let needModeration = !this.model.taskAssignmentTemplateId;
      this.confirmType = "default";
      this.confirmIcon = needModeration ? "flash-warning" : "flash-success";
      this.confirmTitle = needModeration
        ? this.$t("needModeration")
        : this.$t("publishTask");
      this.confirmText = needModeration
        ? this.$t("needModerationTxt")
        : this.$t("publishTaskTxt");
      this.confirmApplyTxt = needModeration
        ? this.$t("send")
        : this.$t("publish");
      this.confirmCancelTxt = this.$t("reject");
      this.applyConfirm = () => {
        this.setModelAndSave(true, false).then((res) => {
          if (res && res.success) {
            let next = () => {
              this.nextStep().then(() => {
                this.afterSave();
              });
            };
            if (needModeration) {
              this.modalModel.stepType = "reject";
              this.rejectStep().then((rjres) => {
                if (rjres && rjres.success) {
                  if (rjres.data.length > 0)
                    this.model.currentStepNo = rjres.data[0].stepNo;
                  this.modalModel.stepType = "accept-info";
                  next();
                } else this.loading = false;
              });
            } else {
              this.modalModel.stepType = "accept";
              next();
            }
          } else this.loading = false;
        });
      };
      this.showConfirmModel = true;
    },
    showReasonModal(callback, stepType, title, needStepFile = false, declineCallbackByStatus = false) {
      if (!stepType) {
        if (this.model.marsValidation) {
          stepType = this.model.status === "taskPublished" ? "accept" : "done";
        } else stepType = "done";
      }
      this.modalModel.stepType = stepType;
      this.modalModel.comment = "";
      this.reasonCallback = async () => {
        await callback();
        this.reasonCallback = null;
        this.afterSave();
      };
      if (!declineCallbackByStatus && this.model.status === "taskApproving" && stepType !== "reject") {
        this.reasonCallback();
        return;
      }
      this.needStepFile = needStepFile;
      this.reasonTitle = title;
      this.reasonModalShow = true;
    },
    saveStep() {
      this.mixinLoadingAccess = true
      let fileInfos = this.stepAttachments
        .filter((f) => !f.id)
        .map((f) => {
          return {
            fileName: f.name,
            fileVisibleFlag: 0,
          };
        });
      if (fileInfos.length > 0) {
        this.fileService.createFilesRoot(fileInfos).then((res) => {
          this.modalModel.attachments = res.data.data.map((f, i) => {
            this.stepAttachments[i].gfid = f.gfid;
            return {
              fileName: f.fileName,
              gfid: f.gfid,
              fileLength: this.stepAttachments[i].size,
            };
          });
          this.upload(res.data.data, this.stepAttachments, null, this.uploadStepError).then(() => {
              if(!this.isUploadError) {
                this.stepItem.attachments = this.stepItem.attachments.map(x => Object.assign(x, { statusFlag: 0 }))
                this.reasonCallback();
                this.stepAttachments = [];
              } else {
                this.mixinLoadingAccess = false
                this.reasonModalShow = true;
              }

              this.isUploadError = false;
            })
            .catch(() => {
              this.mixinLoadingAccess = false
              this.isUploadError = false;
              this.reasonModalShow = true;
            })
          }
        );
      } else {
        this.reasonCallback()
      }
    },
    rejectStepWithFlash() {
      return this.rejectStep().then((res) => {
        if (res && res.success) {
          this.confirmType = "warning";
          this.confirmIcon = "flash-warning";
          this.confirmTitle = this.$t("rejectTaskFlashTitle");
          this.confirmText = this.$t("rejectTaskFlashTxt");
          this.confirmApplyTxt = "";
          this.confirmCancelTxt = "";
          this.showConfirmModel = true;
        }
        return res;
      });
    },
    nextStep() {
      return this.taskAssignmentService
        .nextStep(this.stepItem)
        .then((result) => {
          this.modalModel = {
            comment: "",
            stepType: "",
            attachments: [],
          };
          return result;
        });
    },
    rejectStep() {
      let stepItem = this.stepItem;
      stepItem.afterPublish = !["taskModer", "taskDraft"].includes(
        this.model.status
      );
      return this.taskAssignmentService.rejectStep(stepItem).then((result) => {
        this.modalModel = {
          comment: "",
          stepType: "",
          attachments: [],
        };
        return result;
      });
    },
    addComment() {
      let stepItem = this.stepItem;
      stepItem.stepTypeMessage = "";
      stepItem.afterPublish = !["taskModer", "taskDraft"].includes(
        this.model.status
      );
      return this.taskAssignmentService.addComment(stepItem).then((result) => {
        this.modalModel = {
          comment: "",
          stepType: "",
          attachments: [],
        };
        return result;
      });
    },
    showOrgStructureSelect() {
      this.updateSelectedTreeNodes();
      this.$refs.filterModal.nodeFilter = _.cloneDeep(this.nodeFilter);
      this.$refs.filterModal.gridFilter = _.cloneDeep(this.gridFilter);
      this.$refs.filterModal.selectedTreeNodes = _.cloneDeep(
        this.selectedTreeNodes
      );
      this.$nextTick(() => {
        this.showOrgStructureSelectModal = true;
      });
    },
    showTaskAssignmentDistributor() {
      this.showDistributorSelectModal = true;
    },
    showEditDistributorsModalReadOnly() {
      this.readDistributorModal = true;
    },
    isAllDistributorsChanged(val) {
      if (!val) this.updateDistributorGrid(val);
      else this.showIsAllChangedMessage();
    },
    showIsAllChangedMessage() {
      this.isAllChangedMessage = true;
    },
    applyIsAllChangedMessage() {
      this.needCancelIsAll = false;
      this.model.distributorIds = [];
      this.updateDistributorGrid(true);
    },
    cancelIsAllChangedMessage(val) {
      if (!(val || this.needCancelIsAll)) {
        this.needCancelIsAll = true;
        return;
      }

      this.model.isAllDistributors = false;
    },
    closeOrgStructureSelectModal() {
      // let distrIds = this.getDistributorIds(
      //   this.$refs.filterModal.nodeFilter.add
      // ).concat(this.getDistributorIds(this.$refs.filterModal.gridFilter.add));
      // if (distrIds.length > 1) {
      //   this.showDistrWarning = true;
      //   return;
      // }

      this.nodeFilter = _.cloneDeep(this.$refs.filterModal.nodeFilter);
      this.gridFilter = _.cloneDeep(this.$refs.filterModal.gridFilter);
      this.selectedTreeNodes = _.cloneDeep(
        this.$refs.filterModal.$refs.tree.checkedNodes
      );
      this.oDataStringFilter = this.convertFilterToOdata();
      Object.values(this.nodeFilter).forEach((o) => delete o.children);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.$refs.recipientGrid.records = [];
      this.feelNeedFilter();
      if (this.model.needFilter) this.$refs.recipientGrid.getData();
      this.model.updateRecipients = true;
      this.showOrgStructureSelectModal = false;
    },
    getDistributorIds(filter) {
      let ids = [];
      if (!filter) return ids;
      return Object.values(filter)
        .map((f) => f.distributorId)
        .filter((id) => id && id !== 0);
    },
    async applyDistributorSelectModal({items, requestModel}, isAll) {
      this.model.isAllDistributors = isAll;
      if (!isAll) {
        this.model.distributorRequestModel = requestModel;
        if (requestModel && requestModel.filter) {
          let distributors = await this.distributorService.getByMultiple(requestModel);
          this.model.distributorIds = distributors.data.records.map(x => x.id);
        } else {
          this.model.distributorIds = items;
        }
      } else {
        this.model.distributorIds = [];
      }
      this.model.updateDistributors = JSON.stringify(this.model.distributorIds) !== this.distributorIds;
      this.$nextTick(() => {
        this.updateDistributorGrid(isAll);
      });
    },
    updateDistributorGrid(isAll) {
      if(!this.$refs.distributorGrid)
        return;

      this.$refs.distributorGrid.records = [];
      if(!isAll)
        this.$refs.distributorGrid.getData();
    },
    deleteFromFilter(item) {
      this.$refs.recipientGrid.records =
        this.$refs.recipientGrid.records.filter(
          (r) => !(r.id === item.id && r.recordType === item.recordType)
        );
      if (this.$refs.recipientGrid.records.length === 0) {
        this.nodeFilter = {
          add: {},
          remove: {},
        };
        this.gridFilter = {
          add: {},
          remove: {},
        };
      } else {
        let key = `${item.id}|${item.recordType}`;
        if (Object.keys(this.gridFilter.add).includes(key)) {
          delete this.gridFilter.add[key];
        } else {
          this.gridFilter.remove[key] = item;
        }
      }
      this.oDataStringFilter = this.convertFilterToOdata();
      this.model.oDataFilter = this.oDataStringFilter;
      this.persistRecipients = false;
      this.recipientService.fromView = true;
      this.recipientService.filter = this.recipientGridFilter;
      this.feelNeedFilter();
    },
    deleteFromDistributors(item) {
      this.$refs.distributorGrid.records =
        this.$refs.distributorGrid.records.filter((r) => r.id !== item.id);

      this.model.distributorIds = this.model.distributorIds.filter(
        (r) => r !== item.id
      );
      this.model.updateDistributors =
        JSON.stringify(this.model.distributorIds) !== this.distributorIds;
    },
    updateSelectedTreeNodes() {
      if (!this.$refs.recipientGrid) {
        return;
      }
      if (
        Object.keys(this.nodeFilter.add).length === 0 &&
        Object.keys(this.gridFilter.add).length === 0
      ) {
        if (this.selectedTreeNodes) {
          delete this.selectedTreeNodes["all"];
        }
        return;
      }
      const fullRecords = this.$refs.recipientGrid.records;
      const listTreeNodes = {
        all: {
          checked: false,
          partialChecked: true,
        },
      };

      fullRecords.forEach((r) => {
        const keys = r.fullPath.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      Object.values(this.nodeFilter.add).forEach((n) => {
        const keys = n.path.split("|");
        keys.forEach((key, index, arr) => {
          if (!listTreeNodes[key]) {
            listTreeNodes[key] = {
              checked: false,
              partialChecked: true,
              parentKey: index > 0 ? arr[--index] : "all",
            };
          }
        });
      });
      // check=true all nodeFilter.add and children
      const checkChildren = (parentKey) => {
        const children = Object.entries(listTreeNodes)
          .filter(([k, v]) => v.parentKey === parentKey)
          .map(([k]) => k);
        if (children.length === 0) {
          return;
        }
        children.forEach((k) => {
          checkChildren(k);
          listTreeNodes[k] = {
            checked: true,
            partialChecked: false,
            parentKey: parentKey,
          };
        });
        return;
      };
      Object.entries(this.nodeFilter.add).forEach(([key, value]) => {
        listTreeNodes[key] = {
          checked: true,
          partialChecked: false,
          parentKey: value.parentId,
        };
        checkChildren(key);
      });

      // partialChecked=true if parent has removed child
      const removesParentKeys = [];
      Object.values(this.gridFilter.remove).forEach((val) => {
        const arr = val.fullPath.split("|");
        const parentKey = arr[arr.length - 2];
        if (!removesParentKeys.includes(parentKey)) {
          removesParentKeys.push(parentKey);
        }
      });
      const uncheckParent = (childKey) => {
        const child = listTreeNodes[childKey];
        if (!child) {
          return;
        }
        if (child.checked) {
          child.checked = false;
          child.partialChecked = true;
        }
        const parent = listTreeNodes[child.parentKey];
        if (!parent) {
          return;
        }
        if (parent.checked) {
          parent.checked = false;
          parent.partialChecked = true;
          uncheckParent(parent.parentKey);
          return;
        }
        return;
      };
      removesParentKeys.forEach(uncheckParent);

      this.selectedTreeNodes = listTreeNodes;
    },
    feelNeedFilter() {
      this.model.needFilter =
        Object.keys(this.nodeFilter.add).length > 0 ||
        Object.keys(this.nodeFilter.remove).length > 0 ||
        Object.keys(this.gridFilter.add).length > 0 ||
        Object.keys(this.gridFilter.remove).length > 0;
      this.recipientService.needFilter = this.model.needFilter;
    },
    convertFilterToOdata() {
      // ( ((orgId in included nodes) && (orgId not in excluded nodes)) || (userid in included users) ) && (userId not in excluded users)
      const nodeAdd = Object.values(this.nodeFilter.add);
      const usersAdd = Object.values(this.gridFilter.add);
      const nodeRemove = Object.values(this.nodeFilter.remove);
      const usersRemove = Object.values(this.gridFilter.remove);
      let nodeAddStrings = "";
      let usersAddStrings = usersAdd
        .map((e) => `(recordTypeId eq '${e.recordType + "-" + e.id}')`)
        .join(" or ");
      let usersRemoveStrings = usersRemove
        .map((e) => `(recordTypeId ne '${e.recordType + "-" + e.id}')`)
        .join(" and ");
      let getIdFilter = (n, o) => {
        if (n.type === "RealDistributor")
          return `and distributorId ${o} ${n.id}`;
        if ([9223372036854775000, -9223372036854775000].includes(n.id))
          return `and orgId ${o} null`;
        return `and orgId ${o} ${n.id}`;
      };
      let nodeRemoveStrings = nodeRemove
        .map((e) => `(type eq '${e.type}' ${getIdFilter(e, "ne")})`)
        .join(" and ");
      if (!nodeAdd.find((e) => e.key === "all"))
        nodeAddStrings = nodeAdd
          .map((e) => `(type eq '${e.realType}' ${getIdFilter(e, "eq")})`)
          .join(" or ");

      let filter = "";
      if (nodeAddStrings || nodeRemoveStrings)
        filter =
          "(" +
          [nodeAddStrings, nodeRemoveStrings]
            .filter((e) => e)
            .map((e) => `(${e})`)
            .join(" and ") +
          ")";

      if (usersAddStrings) {
        if (filter) filter = `${filter} or `;
        filter = `${filter} (${usersAddStrings})`;
      }

      if (usersRemoveStrings) {
        if (filter) filter = `(${filter}) and `;
        filter = `${filter} (${usersRemoveStrings})`;
      }
      if (filter) filter = "$filter=" + filter;
      return filter;
    },
    gridLoaded() {},
    async getData() {
      if (this.masterId === undefined) {
        this.model = {
          type:
            this.$route.query &&
            this.$route.query.code &&
            this.$route.query.code != "all"
              ? this.$route.query.code
              : null,
          name: "",
          description: "",
          isPositionsRestricted: false,
          marsValidation: true,
          confirmationByAuthorizedPerson: false,
          startAt: null,
          endAt: null,
          tags: [],
          attachments: [],
          needRepeat: false,
          currentStepNo: 0,
          distributorIds: [],
          distributorRequestModel: {
            filter: "",
            records: []
          },
          createdBy: this.userinfo.code,
          newsItemId: null,
          status: "creation",
          isDistributorStructureRestricted: false,
          distributorStructures: [],
          positions: []
        };
        this.isCreate = true;
        return;
      }
      this.isCreate = false;
      const result = await this.taskAssignmentService[this.getMethod](
        this.masterId,
        JSON.parse(localStorage.getItem("userRole")).systemName
      );
      this.model = result.data;
      this.positions = [...this.model.positions];

      if (this.model.status === "taskModer" || this.model.status === "taskDraft")
        this.v$.model.$touch();

      this.distributorStructureService.setTaskAssignmentId(this.model.id);
      this.distributorStructureService.setNodeViewIds(this.model.distributorStructures.map(x => x.distributorStructureViewId));
      // this.startModel = _.cloneDeep(this.model);
      if (this.model) {
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "Tasks",
          id: "taskPublishStaticId",
          realId: Number(this.model.id),
          type: this.model.type,
          groupUpdate: true,
        });
        this.$store.dispatch("notifications/updateReadedRecord", {
          title: "Tasks",
          id: "taskNewStaticId",
          realId: Number(this.model.id),
          type: this.model.type,
          groupUpdate: true,
        });

        this.distributorIds = JSON.stringify(this.model.distributorIds);
        this.distributorStructures = JSON.stringify(this.model.distributorStructures);
        this.positionsJson = JSON.stringify(this.model.positions);

        this.model.steps = this.model.steps.map((s) => {
          s.stepTypeMessage = this.$t(s.stepTypeMessage);
          return s;
        });
        this.model.needFilter = false;
        this.tags = this.model.tags
          ? this.model.tags.split("#").filter((t) => !!t)
          : [];
        try {
          let filter = JSON.parse(this.model.filter);
          this.nodeFilter = filter.nodeFilter;
          this.gridFilter = filter.gridFilter;
          this.feelNeedFilter();
        } catch {}
        try {
          if (this.model.editFieldsSetting) {
            this.editFieldModel = JSON.parse(this.model.editFieldsSetting);
          }
        } catch {}
        try {
          this.selectedTreeNodes = JSON.parse(this.model.nodeSelection);
        } catch {}
        try {
          if (this.model.repeatConfig && this.model.repeatConfig.length > 0) {
            this.repeatConfig = JSON.parse(this.model.repeatConfig);
            if (this.everyDayVisible)
              this.everyDayModel = this.repeatConfig.WeekDays[0];
          }
        } catch {}
        if (this.model.attachments)
          this.attachments = this.model.attachments.map((f) => {
            let r = new File([], f.fileName);
            r.gfid = f.gfid;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
      }
    },
    fillRepeatConfig() {
      if (this.everyDayVisible)
        this.repeatConfig.WeekDays = [this.everyDayModel];

      this.repeatConfig.StartDate = this.removeTime(
        this.repeatConfig.StartDate
      );
      this.repeatConfig.EndDate = this.removeTime(this.repeatConfig.EndDate);
      let rc = JSON.stringify(this.repeatConfig);
      if (rc !== this.model.repeatConfig) {
        this.model.repeatConfig = rc;
        this.model.updateRepeat = true;
      }
    },
    removeTime(date) {
      if (typeof date === "string") date = new Date(date);
      var d = date.getDate();
      var m = date.getMonth() + 1;
      var y = date.getFullYear();
      return (
        "" +
        y +
        "-" +
        (m <= 9 ? "0" + m : m) +
        "-" +
        (d <= 9 ? "0" + d : d) +
        "T00:00:00.000Z"
      );
    },
    async setModelAndSave(needCheckUsers, loadModel = true) {
      this.fillRepeatConfig();
      this.startDateUpdate();
      this.endDateUpdate();
      this.loading = true;

      if (needCheckUsers) {
        if(this.model.confirmationByAuthorizedPerson) {
          this.positions.forEach(x => {
            x["taskAssignmentId"] = this.model.id;
            x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
          });
          this.model.positions = this.positions;

          let authorizedPersonsValidationRes = await this.taskAssignmentService.validateAuthorizedPersonsExists(this.model);
          let nonExistsAPDistributors = authorizedPersonsValidationRes.data;

          if (nonExistsAPDistributors && nonExistsAPDistributors.length > 0) {
            if(nonExistsAPDistributors.length > 1) {
              this.nonExistsAuthorizedPersonsTextP1 = this.$t('nonExistsDistributorsAuthorizedPersonsTextP1');
              this.nonExistsAuthorizedPersonsTextAction = this.$t('nonExistsDistributorsAuthorizedPersonsTextAction');
            } else {
              this.nonExistsAuthorizedPersonsTextP1 = this.$t('nonExistsDistributorAuthorizedPersonsTextP1');
              this.nonExistsAuthorizedPersonsTextAction = nonExistsAPDistributors[0].name;
            }

            this.nonExistsAuthorizedPersonsDefaultFilter = { id: { or: nonExistsAPDistributors.map(x => x.id) } }

            this.$nextTick(() => {
              this.isNonExistsAuthorizedPersons = true;
            })

            this.loading = false;
            return Promise.resolve(false);
          } else if (authorizedPersonsValidationRes.errors) {
            this.loading = false;
            return Promise.resolve(false);
          }
        } else if (this.model.isPositionsRestricted && this.model.isDistributorStructureRestricted) {
          this.positions.forEach(x => {
            x["taskAssignmentId"] = this.model.id;
            x["leaf"] = !this.positions.some(p => p.parentId === x.distributorOrgId);
          });
          this.model.positions = this.positions;

          var usersValidationRes = await this.taskAssignmentService.validateUsersExists(this.model);

          if (!usersValidationRes || usersValidationRes.data === false) {
            this.isNonExistsUsers = true;
            this.loading = false;
            return Promise.resolve(false);
          } else if (!usersValidationRes || usersValidationRes.errors) {
            this.loading = false;
            return Promise.resolve(false);
          }
        }
      }

      return this.setModel().then((response) => {
        let newFiles = this.setNewAttachments(response.data.data);
        if (Array.isArray(newFiles) && newFiles.length > 0) {
          this.model.updateAttachments = true;
        }
        this.model.updatePositions = this.positionsJson !== JSON.stringify(this.positions);
        return this.save(newFiles, loadModel);
      });
    },
    setModel() {
      this.model.tags = this.tags.join("#");
      this.model.editFieldsSetting = JSON.stringify(this.editFieldModel);
      this.model.filter = JSON.stringify({
        nodeFilter: this.nodeFilter,
        gridFilter: this.gridFilter,
      });
      this.model.nodeSelection = JSON.stringify(this.selectedTreeNodes);
      this.model.distributorIds = !this.model.isAllDistributors
        ? this.model.distributorIds
        : [];

      if (this.model.isPositionsRestricted) {
        this.positions.forEach((x) => {
          x["taskAssignmentId"] = this.model.id;
          x["leaf"] = !this.positions.some(
            (p) => p.parentId === x.distributorOrgId
          );
        });
        this.model.positions = this.positions;
      } else {
        this.model.positions = [];
      }

      this.attachmentsToCreate = this.attachments.filter((f) => !f.gfid);
      const actualFiles = this.attachments
        .filter((f) => f.gfid)
        .map((f) => f.gfid);
      if (this.model.attachments)
        this.model.attachments = this.model.attachments.filter((f) =>
          actualFiles.includes(f.gfid)
        );
      if (this.attachmentsToCreate.length > 0) {
        let fileInfos = this.attachmentsToCreate
          .filter((f) => !f.id)
          .map((f) => {
            return {
              fileName: f.name,
              fileVisibleFlag: 0,
            };
          });
        return this.fileService.createFilesRoot(fileInfos);
      }
      return new Promise((resolve, reject) => {
        resolve({ data: { data: [] } });
      });
    },
    setNewAttachments(attachments) {
      let newFiles = attachments.map((f, i) => {
        return {
          fileName: f.fileName,
          gfid: f.gfid,
          fileLength: this.attachmentsToCreate[i].size,
        };
      });
      if (!this.model.attachments) {
        this.model.attachments = [];
      }
      this.model.attachments = this.model.attachments.concat(newFiles);
      return newFiles;
    },
    startDateUpdate() {
      if (this.model.startAt !== null) {
        let momentDate = this.moment(this.model.startAt);
        if (momentDate.hours() == 0 && momentDate.minutes() == 0 && momentDate.seconds() == 0) {
          this.model.startAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let startMomentDate = momentDate.startOf('day');
        this.model.startAt = startMomentDate.format("YYYY-MM-DDTHH:mm:ss");
        if (
          this.overviewTabs.find((x) => x.systemName == "parameters").isActive
        )
          this.$refs["startAt-ProSpaceInputCalendar"].value = startMomentDate.toDate();
      }
    },
    endDateUpdate() {
      if (this.model.endAt !== null) {
        let momentDate = this.moment(this.model.endAt);
        if (momentDate.hours() == 23 && momentDate.minutes() == 59 && momentDate.seconds() == 59) {
          this.model.endAt = momentDate.format("YYYY-MM-DDTHH:mm:ss");
          return;
        }

        let endMomentDate = momentDate.endOf('day');
        this.model.endAt = endMomentDate.format("YYYY-MM-DDTHH:mm:ss");
        if (
          this.overviewTabs.find((x) => x.systemName == "parameters").isActive
        )
          this.$refs["endAt-ProSpaceInputCalendar"].value = endMomentDate.toDate();
      }
    },
    save(newFiles, loadModel) {
      const isCreate = !this.masterId;
      let saveMethod = isCreate ? "create" : "update";
      if (saveMethod === "update" && this.editType === "series")
        saveMethod = "updateSeries";

      if (!this.model.isDistributorStructureRestricted) {
        this.model.distributorStructures = [];
      }

      if (!this.model.isPositionsRestricted) {
        this.model.positions = [];
        this.positions = []
      }

      return this.taskAssignmentService[saveMethod](this.model)
        .then((result) => {
          this.model.id = result.data[0].id;
          if (newFiles.length > 0) {
            this.upload(newFiles, this.attachmentsToCreate, this.uploadSuccess, this.uploadError).then(() => {
                if (loadModel) this.afterSave(result, isCreate);
              })
              .catch(() => {
                if (loadModel) this.afterSave(result, isCreate);
              });
          } else if (loadModel) {
            this.afterSave(result, isCreate);
          }
          return result;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    afterSave(result, isCreate) {
      if (isCreate) {
        this.masterId = result.data[0].id;
      }
      this.getData().then(() => {
        this.setAllAccess();
      });
      this.persistRecipients = true;
      this.recipientService.fromView = false;
      this.recipientService.filter = this.recipientGridFilter;
      this.loading = false;
    },
    async upload(newFiles, toCreate, successCallback, errorCallback) {
      await this.fileService.uploadChunks(newFiles, toCreate, successCallback, errorCallback);
      this.attachmentsToCreate = [];
    },
    async uploadSuccess(gfids) {
      await this.taskAssignmentAttachmentsService.acceptFiles(gfids, this.model.id);
    },
    async uploadError(gfids) {
      await this.taskAssignmentAttachmentsService.errorFiles(gfids, this.model.id);
    },
    async uploadStepError(gfids) {
      this.isUploadError = true;
      this.stepAttachments = this.stepAttachments
        .filter(f => gfids.includes(f.gfid))
        .map(x => Object.assign(x, { statusFlag: 255 }))
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
    onTemplateChange(item) {
      if (item !== undefined) {
        try {
          if (item.editFieldsSetting) {
            this.editFieldModel = JSON.parse(item.editFieldsSetting);
            this.setAllAccess(true);
          }
          this.model.taskAssignmentTemplateId = item.id;
          this.model.type = item.type;
          this.model.name = item.name;
          this.model.description = item.description;
          this.model.isPositionsRestricted = item.isPositionsRestricted;
          this.model.isDistributorStructureRestricted = item.isDistributorStructureRestricted;
          this.model.marsValidation = item.marsValidation;
          this.model.confirmationByAuthorizedPerson = item.confirmationByAuthorizedPerson;
          this.model.startAt = this.model.startAt;
          this.model.endAt = this.model.endAt;
          this.model.editFieldsSetting = item.editFieldsSetting;
          this.positions = [...item.positions];
          this.tags = item.tags ? item.tags.split("#").filter((t) => !!t) : [];
          this.attachments = item.attachments.map((f) => {
            let r = new File([], f.fileName);
            r.gfid = f.gfid;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
          this.model.attachments = item.attachments.map((f) => {
            let r = {};
            r.gfid = f.gfid;
            r.fileName = f.fileName;
            r.fileLength = f.fileLength;
            r.statusFlag = f.statusFlag;
            return r;
          });
        } catch {}
      }
    },
    createTemplate() {
      this.$router.push({
        path: `/taskservice/taskassignmenttemplate`,
        query: {
          taskId: this.masterId,
        },
      });
    },
    linkToNews() {
      this.$router.push({
        path: `/newsservice/newsitem/${this.model.newsItemId}`,
      });
    },
    setReadOnly() {
      let toNone = this.refsForCheckInMixin.filter(
        (x) =>
          x.includes("button") ||
          x.includes("ProSpaceGeoLayout") ||
          (x.includes("action") && x != "action-createTemplate")
      );
      let toReadOnly = this.refsForCheckInMixin.filter(
        (x) =>
          !x.includes("button") &&
          !x.includes("action") &&
          !x.includes("ProSpaceGeoLayout")
      );
      this.resetCondition(toNone, "NONE");
      this.resetCondition(toReadOnly, "READONLY");
    },
    repeatTaskChange() {
      if (this.model) {
        if (this.model.needRepeat) {
          this.buffredStartAt = this.model.startAt;
          this.buffredEndAt = this.model.endAt;
          this.model.startAt = new Date();
          this.model.endAt = new Date();
        } else {
          this.model.startAt = this.buffredStartAt || new Date();
          this.model.endAt = this.buffredEndAt || new Date();
          this.model.groupId = null;
        }
      }
    },
    getTemplateFilter() {
      return this.model.type !== null
        ? "$filter=(type eq '" +
            this.model.type +
            "' and disabled eq false)&$orderby=templateName asc"
        : "$filter=(disabled eq false)&$orderby=templateName asc";
    },
    onTypeChange(item) {
      this.model.type = item?.code;
      this.$nextTick(() => {
        !this.masterId && this.$refs["taskAssignmentTemplateId-ProSpaceDropdown"].getData();
      });
    },
    checkStartDate(value) {
      return this.moment(value).format('YYYYMMDD') <= this.moment(this.model.endAt).format('YYYYMMDD')
    },
    checkEndDate(value) {
      return this.moment(value).format('YYYYMMDD') >= this.moment(this.model.startAt).format('YYYYMMDD')
    },
    checkModerationOrDraftAndNotOutOfDeadline(value) {
      if (this.model.status !== "taskModer" && this.model.status !== "taskDraft") {
        return true;
      }

      const currentMoment = this.moment().format('YYYYMMDD');
      return this.moment(this.model.endAt).format('YYYYMMDD') >= currentMoment;
    },
    handleConfirmationByAuthorizedPersonUpdate(value) {
      if (value) {
        this.model.isPositionsRestricted = false;
        this.model.isDistributorStructureRestricted = false;
      }
    },
    applyDistributorStructure() {
      this.model.updateDistributorStructures = JSON.stringify(this.model.distributorStructures) !== this.distributorStructures;
      this.afterDistributorStructureEdit = true;
    },
    handleIsSendByPositionsUpdate(value) {
      if (!value) {
        this.positions = [];
        this.model.positions = [];
      } else {
        this.model.confirmationByAuthorizedPerson = false;
      }
    },
    handleIsDistributorStructureRestrictedUpdate(value) {
      if (!value) {
        this.model.distributorStructures = [];
      } else {
        this.model.confirmationByAuthorizedPerson = false;
      }
    }
  },
  watch: {
    lastChangedNotification: {
      handler(val) {
        if (
          val &&
          val.messageData &&
          val.messageData.Title === "Background tasks" &&
          val.messageData.Result &&
          val.messageData.Result.Type === "TaskPublished" &&
          Array.isArray(val.messageData.Result.Ids) &&
          val.messageData.Result.Ids.includes(this.model.id)
        ) {
          this.afterSave();
        }
      },
    },
  },
  computed: {
    statusAccessConfig() {
      let baseConfig = {
        "Distributor user": "READONLY",
        "Distributor manager": "READONLY",
        "Mars user": "READONLY",
        "Mars manager": "READONLY",
        "Content manager": {
          creation: "CREATOR_OR_READONLY",
          taskDraft: "CREATOR_OR_READONLY",
          taskModer: "CREATOR_OR_READONLY",
          other: "READONLY",
        }
      }
      let statusAccessConfig = Object.assign(_.cloneDeep(baseConfig), {
        "Support administrator": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "READONLY",
        },
        "Technical Support": {
          other: "ALIVE",
        },
        "Content manager plus": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "READONLY",
        },
      });
      let marsValidationAccessConfig = Object.assign(_.cloneDeep(baseConfig), {
        "Support administrator": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          other: "READONLY",
        },
        "Technical Support": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          other: "READONLY",
        },
        "Content manager plus": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          other: "READONLY",
        },
      });
      return {
        "taskAssignmentTemplateId-ProSpaceDropdown": statusAccessConfig,
        "type-ProSpaceDropdown": statusAccessConfig,
        "name-ProSpaceInputText": statusAccessConfig,
        "description-ProSpaceExtendedTextArea": statusAccessConfig,
        "marsValidation-ProSpaceInputSwitch": marsValidationAccessConfig,
        "confirmationByAuthorizedPerson-ProSpaceInputSwitch": marsValidationAccessConfig,
        "dueDate-ProSpaceInputCalendar": statusAccessConfig,
        "tags-ProSpaceInputChips": statusAccessConfig,
        "attachments-ProSpaceFile": statusAccessConfig,
        "addRecipient-button": this.recipientGridStatusConfig,
        "isAllDistributors-ProSpaceInputSwitch": statusAccessConfig,
        "action-delete-ProSpaceSimpleGrid": this.recipientGridStatusConfig,
        "position-PositionSelectTree": statusAccessConfig,
        "isPositionsRestricted-ProSpaceInputSwitch": marsValidationAccessConfig,
        "isDistributorStructureRestricted-ProSpaceInputSwitch": marsValidationAccessConfig,
      };
    },
    recipientGridStatusConfig() {
      let config = {
        "Distributor user": "NONE",
        "Distributor manager": "NONE",
        "Mars user": "NONE",
        "Mars manager": "NONE",
        "Content manager": {
          creation: "CREATOR_OR_NONE",
          taskDraft: "CREATOR_OR_NONE",
          taskModer: "CREATOR_OR_NONE",
          other: "NONE",
        },
        "Support administrator": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "NONE",
        },
        "Technical Support": {
          other: "ALIVE",
        },
        "Content manager plus": {
          creation: "ALIVE",
          taskDraft: "ALIVE",
          taskModer: "ALIVE",
          taskPublished: "ALIVE",
          other: "NONE",
        },
      };

      // disable distr edit for task series after publication
      let afterPublish = !["taskModer", "taskDraft"].includes(
        this.model.status
      );
      if (afterPublish === true && this.model.needRepeat === true) {
        delete config["Support administrator"].taskPublished;
        delete config["Content manager plus"].taskPublished;
        config["Technical Support"].taskPublished = "NONE";
      }
      return config;
    },
    disabledSave() {
      return this.v$.model.$invalid; // || _.isEqual(this.model, this.startModel);
    },
    disabledPublished() {
      let distributorsNotChosen = true;
      if(this.model.isDistributorStructureRestricted)
        distributorsNotChosen = this.model.distributorStructures.length === 0;
      else
        distributorsNotChosen = !this.model.isAllDistributors && this.model.distributorIds.length === 0;
      const positionsNotChosen = this.model.isPositionsRestricted && !(this.positions && this.positions.length > 0);

      const currentMoment = this.moment().format('YYYYMMDD');
      const outOfModerationOrTaskDeadline = (this.model.status === "taskModer" || this.model.status === "taskDraft")
          && this.moment(this.model.endAt).format("YYYYMMDD") < currentMoment;

      return this.v$.model.$invalid || distributorsNotChosen || positionsNotChosen || outOfModerationOrTaskDeadline;
    },
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      access: "securityRoleAccess/access",
      lastChangedNotification: "notifications/lastChanged",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
    }),
    refsForCheckInMixin() {
      let refs = [];

      if (this.modelTab.systemName === "parameters")
        refs.push(
          "type-ProSpaceDropdown",
          "name-ProSpaceInputText",
          "description-ProSpaceExtendedTextArea",
          "marsValidation-ProSpaceInputSwitch",
          "confirmationByAuthorizedPerson-ProSpaceInputSwitch",
          "tags-ProSpaceInputChips",
          "attachments-ProSpaceFile",
          "rejectPublish-button",
          "acceptPublish-button",
          "rejectTask-button",
          "performTask-button",
          "accept-ProSpaceGeoLayout",
          "performTaskComplete-button",
          "moderation-ProSpaceGeoLayout",
          "acceptComplete-ProSpaceGeoLayout",
          "isPositionsRestricted-ProSpaceInputSwitch",
          "isDistributorStructureRestricted-ProSpaceInputSwitch",
        );

      if (this.masterId) refs.push("action-createTemplate");

      if (!this.masterId && this.modelTab.systemName === "parameters")
        refs.push("taskAssignmentTemplateId-ProSpaceDropdown");

      if (this.modelTab.systemName === "notifications")
        refs.push("addRecipient-button");

      if (
        !this.model ||
        !this.model.status ||
        ["taskDraft", "taskModer"].includes(this.model.status)
      )
        refs.push("action-saveDraft");

      if (
        this.model &&
        this.model.status &&
        this.model.status === "taskPublished"
      )
        refs.push("action-savePublish");

      if (this.model && this.model.status === "taskDraft")
        refs.push("action-publish");

      if (this.modelTab.systemName === "distributors") {
        if(this.model && this.model.isDistributorStructureRestricted)
          refs.push("distributorStructures-DistributorHierarchySelectForm");
        else {
          if (this.isEditAllDistrAvailable)
            refs.push("isAllDistributors-ProSpaceInputSwitch");
          if (!this.model.lock)
            refs.push("addRecipient-button");
        }
      }

      if (this.modelTab.systemName === "positions")
          refs.push("positions-PositionSelectTree");

      return refs;
    },
    recipientGridFilter() {
      if (this.persistRecipients)
        return `$filter=TaskAssignmentId eq ${Number(
          this.masterId
        )} and disabled eq false`;
      return this.oDataStringFilter;
    },
    distributorFilter() {
      return { id: { or: this.model.distributorIds } };
    },
    checkLabelLocalization() {
      if (this.onTheDaysVisible) return this.$t("everyWeek");
      if (this.dayVisible) return this.$t("everyMonth");
      return this.$t("every");
    },
    dayVisible() {
      const visibleIn = [this.monthVal];
      return visibleIn.includes(this.repeatConfig.Repeat);
    },
    everyVisible() {
      const visibleIn = [this.weekVal, this.monthVal];
      return visibleIn.includes(this.repeatConfig.Repeat);
    },
    everyDayVisible() {
      const visibleIn = [this.dayVal];
      return visibleIn.includes(this.repeatConfig.Repeat);
    },
    onTheDaysVisible() {
      const visibleIn = [this.weekVal];
      return visibleIn.includes(this.repeatConfig.Repeat);
    },
    layoutName() {
      return this.masterId
        ? this.model.name
        : this.$t("newTxtF") + " " + this.$t("taskAssignment");
    },
    overviewTabs() {
      let isHiddenRepeat = [
        "taskWaitPublish",
        // "taskPublished",
        "taskApproving",
        "taskComplete",
        'taskCanceled'
      ].includes(this.model.status);

      return [
        {
          name: this.$t("parameters"),
          systemName: "parameters",
          fields: "Overview",
          isActive: this.modelTab.systemName === "parameters",
        },
        {
          name: this.$t("repeatTask"),
          systemName: "repeatTask",
          fields: "Repeat",
          isDisabled: this.model.groupId && this.editType !== "series",
          isHidden:
          isHiddenRepeat ||
            !this.checkAccess("tab-repeatTask-ProSpaceTabs", this.model) ||
            (this.model.status === 'taskPublished' && !this.model.needRepeat),
          isActive: this.modelTab.systemName === "repeatTask",
        },
        {
          name: this.isFilial || this.model.isDistributorStructureRestricted ? this.$t('branches') : this.$t("distributors"),
          systemName: "distributors",
          fields: "Distributors",
          isDisabled: this.isCreate,
          isHidden: !this.isFilial && !this.checkAccess("tab-distributors-ProSpaceTabs", this.model),
          isActive: this.modelTab.systemName === "distributors",
        },
        {
          name: this.$t("positions"),
          systemName: "positions",
          isHidden: !this.model.isPositionsRestricted,
          isActive: this.modelTab.systemName === "positions",
        },
        {
          name: this.$t("taskAssignmentNotificationsMars"),
          systemName: "notifications",
          fields: "Recipients",
          isDisabled: this.isCreate,
          isHidden: !this.checkAccess("tab-notifications-ProSpaceTabs", this.model),
          isActive: this.modelTab.systemName === "notifications",
        },
      ];
    },
    stepItem() {
      return {
        workflowEntityId: this.model.workflowEntityId,
        stepNo: this.model.currentStepNo,
        status: this.model.status,
        comment: this.modalModel.comment || "",
        stepType: this.modalModel.stepType || "",
        stepTypeMessage: this.model.status ? this.model.status + "Step" : "",
        attachments: this.modalModel.attachments,
        taskAssignmentId: this.model.id,
        securityUserId: this.userinfo.id,
        securityUserFirstName: this.userinfo.firstName,
        securityUserLastName: this.userinfo.lastName,
        securityUserType: this.userinfo.orgLinkType,
        confirmDate: new Date(),
      };
    },
    statusHeader() {
      return {
        title: this.model.name,
        status: this.taskAssignmentService.getStatusByValue(this.model.status),
        icon: this.model.needRepeat ? "repeat" : "",
        iconColored: true,
      };
    },
    needRepeatDisabled() {
      return this.model.lock || this.editType === "series";
    },
    dueDateAvailable() {
      return !this.model.lock
        && this.editType !== 'series'
        && this.checkAccess('dueDate-ProSpaceInputCalendar', this.model)
        && this.checkStatusAccess('dueDate-ProSpaceInputCalendar', this.model);
    },
    isEditAllDistrAvailable() {
      return ["taskModer", "taskDraft"].includes(this.model.status);
    },
    isFilial() {
      return this.model.isDistributorStructureRestricted && !this.checkAccess("isFilial-Expression", this.model);
    },
    positionOptions() {
      let options = {
        positionService: null,
        positionGetMethod: null,
        positionSelectionMode: null,
        positionFilter: null,
        propLabel: null,
      };

      if(this.checkAccess("position-PositionSelectTree", this.model)
        && this.checkStatusAccess("position-PositionSelectTree", this.model)) {
        options.positionService = new DistributorOrgService();
        options.positionGetMethod = "allTree";
        options.positionSelectionMode = "checkbox";
        options.propLabel = "name";
      } else {
        let positionService = new TaskAssignmentService();
        positionService.taskAssignmentId = this.model.id;
        options.positionService = positionService;
        options.positionGetMethod = "getPositions";
        options.positionSelectionMode = "single";
        options.propLabel = "distributorOrgName";
        options.positionFilter = {
          disabled: { and: [false] },
          taskAssignmentId: this.model.id,
        };
      }

      return options;
    },
  },
};
</script>
<style lang="scss">
.taskassignment {
  &__expired-block {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    background-color: var(--prospace-ui-message-attention-red-bg);
    border-radius: 5px;
    padding: 5px 10px 5px 5px;
    height: 30px;
    color: var(--prospace-text-gray);
    margin-top: 10px;
    font-size: 13px;
    white-space: nowrap;
    .prospace-icon {
      background-color: var(--prospace-ui-red);
    }
  }
}
</style>
