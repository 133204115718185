<template>
  <ProSpaceGeoLayout class="competencetask" v-if="model">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler">
        <template #right>
          <ProSpaceAction
            :disabled="v$.model.$invalid"
            icon="double-check"
            :text="$t('saveAll')"
            @click="saveAll"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout
        :is-templated="true"
        :template-center="true"
        :template-right="true"
      >
        <template #center>
          <ProSpaceTabs :tabs="overviewTabs" :loading="loading">
            <ProSpaceAction
              :disabled="v$.model.$invalid"
              icon="check"
              :text="$t('save')"
              @click="savePart(saveFields.Overview)"
            />
            <template v-slot:tab-1>
              <ProSpaceVLayout :gap="20">  </ProSpaceVLayout>
            </template>
          </ProSpaceTabs>
        </template>
        <template #right>
          <ProSpaceRightPanel no-border>
            <template #center>
              <ProSpacePanelBlock :header="ProSpacePanelBlockHeader">
                <template v-slot>
                  <ProSpacePanelBlockList
                    :list="ProSpacePanelBlockListComputed"
                  />
                </template>
              </ProSpacePanelBlock>
            </template>
          </ProSpaceRightPanel>
        </template>
      </ProSpaceGeoLayout>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceTopPanel,
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceRadioButtonSecondary,
  ProSpaceGeoLayout,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpaceTextBlock,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceDropdown,
  ProSpaceFile,
  ProSpaceInputCalendar,
  ProSpaceInputNumber,
  ProSpaceSearchfield,
  ProSpaceMultiSelect,
  ProSpaceCustomFilterPanel,
  ProSpaceIcon,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { CompetenceTaskService } from "../../services/CompetenceTaskService.js";

import { required, maxLength, helpers, minValue, maxValue } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  components: {
    ProSpaceTopPanel,
    ProSpaceGeoLayout,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceRadioButtonSecondary,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpaceTextBlock,
	  ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceDropdown,
    ProSpaceFile,
    ProSpaceInputCalendar,
    ProSpaceInputNumber,
    ProSpaceSearchfield,
    ProSpaceMultiSelect,
    ProSpaceCustomFilterPanel,
    ProSpaceIcon,
    ProSpaceWarning,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    this.tabs = [{ label: "Overview", value: "Overview" }];
    return {
      // Tabs
      modelTab: this.tabs[0], // Первое предустановленное значение

      // Common
      layoutName: "",
      model: null,
      loading: false,

      // Service
      competenceTaskService: new CompetenceTaskService(),


      getMethod: "get",
      masterId: null,

      // SaveFields
      saveFields: {
        Overview: ["competenceId",
"name",
"status",
"assignmentTo",
"startAt",
"endAt",
"priority",
],
      },
    };
  },
  validations() {
    return {
      model: {
  "competenceId": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required)
  },
  "name": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required),
    "maxLength": helpers.withMessage(this.$t('maxLengthErrorMessage'), maxLength(255))
  },
  "status": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required),
    "maxLength": helpers.withMessage(this.$t('maxLengthErrorMessage'), maxLength(50))
  },
  "assignmentTo": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required),
    "maxLength": helpers.withMessage(this.$t('maxLengthErrorMessage'), maxLength(50))
  },
  "startAt": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required)
  },
  "endAt": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required)
  },
  "priority": {
    "required": helpers.withMessage(this.$t('requiredFieldErrorMessage'), required)
  }
}
,
    };
  },
  mounted() {
    this.masterId = this.$route.params.id;
    this.getData();
  },
  methods: {
    gridLoaded() {},
    async getData() {
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.competenceTaskService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.layoutName = `${this.model.name}`;
    },
    saveAll() {
      this.loading = true;
      this.competenceTaskService.set().then(() => {
        this.getData();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true
      this.competenceTaskService.setPart(fields).then(() => {
        this.getData();
        this.loading = false;
      });
    },
    submitFilter(nameRef = "grid") {
      if (!this.$refs[nameRef]) return;
      this.$refs[nameRef].getRecords(true);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ProSpacePanelBlockListComputed() {
      return [
        [
          {
            title: this.$t("createdByTxt"),
            text: this.model.createdBy,
          },
        ],
        [
          {
            title: this.$t("createdAtTxt"),
            text: new Date(this.model.createdAt),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: new Date(this.model.lastUpdated),
          },
        ],
      ];
    },
    overviewTabs() {
      return [{ name: this.$t("overview") }];
    },
    ProSpacePanelBlockHeader(){ 
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      }
    },
  },
};
</script>
<style lang="scss"></style>
