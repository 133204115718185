<template>
  <ProSpaceRightPanel
    :header="header"
    :loading="loading"
    :has-error="hasError"
    @clear="clearPanel"
  >
    <template v-slot:center>
      <ProSpacePanelBlock :header="headerOverview">
        <template v-slot>
          <ProSpacePanelBlockList :list="content" :btnText="$t('viewMore')" :itemsPerPage="6"/>
        </template>
      </ProSpacePanelBlock>
    </template>
    <template v-slot:bottom>
      <ProSpacePanelBlock :header="headerBottom">
        <template v-slot>
          <ProSpacePanelBlockList :list="contentBottom" />
        </template>
      </ProSpacePanelBlock>
    </template>
  </ProSpaceRightPanel>
</template>

<script>
import {
  ProSpaceRightPanel,
  ProSpaceTabs,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceAction,
} from "@prospace/prospace-components-library";
import { getAMorPM } from "@prospace/prospace-components-library/src/lib-assets/mixins/date";
import { BBDProductService } from "../../../services/BBDProductService";
import { mapGetters } from "vuex";
import {
  useMultiSelectRightPanel,
} from "@composes/multiselect"
export default {
  name: "BBDProductPanel",
  components: {
    ProSpaceRightPanel,
    ProSpaceTabs,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceAction,
  },
  data() {
    return {
      bbdProduct: null,
      bbdProductService: new BBDProductService(),
      loading: false,
      hasError: false,
    };
  },
   setup() {
    const { selectedData } = useMultiSelectRightPanel()
    return {
      selectedData
    }
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    headerOverview() {
      return {
        title: this.$t("reserveBaseInfo"),
        isExpand: false,
      };
    },
    headerBottom() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    header() {
      if (this.bbdProduct) {
        return {
          title: this.bbdProduct.description,
          id: this.bbdProduct.grd,
          showCounter: false,
        };
      }
    },
    content() {
      if (this.bbdProduct) {
        return [
          [
            {
              title: this.$t("bbdProductDescription"),
              text: this.bbdProduct.description,
            },
          ],
          [
            {
              title: this.$t("bbdProductBBD"),
              text: this.getFormatDate(this.bbdProduct.bbd),
            },
          ],
          [
            {
              title: this.$t("bbdProductDiscount"),
              text: this.bbdProduct.discount + '%',
            },
          ],
          [
            {
              title: this.$t("bbdProductShipFrom"),
              text: this.bbdProduct.shipFrom,
            },
          ],
          [
            {
              title: this.$t("bbdProductAvailableQuantity"),
              text: this.bbdProduct.availableQuantity,
            },
          ],
          [
            {
              title: this.$t("bbdProductAnimotechLink"),
              text: "https://mars.animotech.ru/products",
              link: "https://mars.animotech.ru/products",
              headerOverflow: false
            },
          ],
        ];
      }
      return [];
    },
    contentBottom() {
      let metaInfo = [];
      if (this.bbdProduct) {
        metaInfo.push([
          {
            title: this.$t("createdByTxt"),
            text: this.bbdProduct.createdBy,
          },
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.bbdProduct.createdAt) + ' ' + getAMorPM(this.bbdProduct.createdAt, this.userinfo.timeFormat),
          },
        ]);
      }
      return metaInfo;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.hasError = false;
      try {
        this.getBBDProduct();
      } catch (e) {
        this.hasError = true;
      }
    },
    getBBDProduct() {
      if (this.selectedData) {
        this.loading = true;
        this.bbdProductService.get(this.selectedData.id).then((res) => {
          this.bbdProduct = res.data;
          this.loading = false;
        });
      }
      this.bbdProduct = null;
    },
    clearPanel() {
      this.$emit("clear");
    }
  },
  watch: {
    selectedData: {
      deep: true,
      handler() {
        this.update();  
      }
    },
  },
};
</script>

<style scoped></style>
