<template>
  <ProSpaceGeoLayout class="roles" :routeName="$route.name" isParent>
    <template #top>
      <ProSpaceTopPanel :header="layoutName">
        <template #right>
          <ProSpaceIconButton
            icon="update"
            @click="actionUpdate"
            iconWidth="16px"
            iconHeight="16px"
            containerSize="20px"
          />
          <ProSpaceButton
            style="margin-left: 10px"
            type="primary"
            icon="plus"
            :label="$t('newTxtF')"
            @click="showChangeModal"
          />
        </template>
      </ProSpaceTopPanel>
    </template>
    <template #center>
      <ProSpaceGeoLayout>
        <template #top>
          <ProSpaceFilterPanel
            :closeTxt="$t('closeTxt')"
            :searchPlaceholder="$t('searchTxt')"
            :txtResetFilters="$t('txtResetFilters')"
            :modelFastFilter="modelFastFilter"
            :fastFilters="fastFilters"
            @apply="submitFilter"
            :unicKeyStorage="roleService.constructor.name + 'grid'"
            @update:modelFastFilter="
              (val) => {
                modelFastFilter = val;
              }
            "
            ref="filterPanel"
            :filter-model="['displayName', 'systemName']"
          >
            <template #hint> {{ $t("filterBy") }}</template>
            <template #filters="{ filter }">
              <ProSpaceMultiSelect
                :localization="$t"
                v-model="filter.status"
                :service="statusService"
                getMethod="getRole"
                optionLabel="text"
                optionValue="status"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :placeholderValue="$t('status')"
                :applyTxt="$t('applyTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :searchPlaceholder="$t('searchTxt')"
                @apply="emitFilter"
              />
              <ProSpaceCustomFilterPanel
                :customTxt="$t('custom')"
                :customFilterTxt="$t('customFilterTxt')"
                :clearFilterTxt="$t('clearFilterTxt')"
                :selectAllTxt="$t('selectAllTxt')"
                :closeTxt="$t('closeTxt')"
                :applyTxt="$t('applyTxt')"
                :fromTxt="$t('fromTxt')"
                :toTxt="$t('toTxt')"
                :services="customServices"
                :unicKeyStorage="roleService.constructor.name + 'grid'"
                @apply="(model) => customFilterApply(filter, model)"
              />
            </template>
          </ProSpaceFilterPanel>
        </template>
        <template #center>
          <ProSpaceMainGrid
            ref="grid"
            getMethod="grid"
            defaultSortField="createdAt"
            :defaultSortOrder="-1"
            :service="roleService"
            :noMountedRequest="true"
            selectionMode="single"
            @selected-change="selectedHandler"
            @db-click="toItem"
          >
            <template #columns>
              <Column field="displayName" :header="$t('name')" sortable>
                <template #body="field">
                  <ProSpaceTableHeaderCell
                    v-if="field.data"
                    :id="field.data.id"
                    :status="getStatus(field.data.status)"
                    :title="field.data.displayName ? field.data.displayName : field.data.systemName"
                    @click-on-title="toItem(field.data)"
                  />
                </template>
              </Column>
              <Column field="createdAt" sortable :header="$t('createdDate')">
                <template #body="field">
                  <ProSpaceDateTimeColumn
                    :date="field.data.createdAt"
                    :dateFormat="userinfo.dateFormat"
                    :timeFormat="userinfo.timeFormat"
                  />
                </template>
              </Column>
              <Column field="actions" class="column-end column-end-30">
                <template #body="field">
                  <ProSpaceDots
                    :options="dotsOptions"
                    @action="(action) => handleDots(action, field.data)"
                  ></ProSpaceDots>
                </template>
              </Column>
            </template>
            <template #empty>
              <ProSpaceEmptyGrid
                icon="empty-grid"
                :title="$t('noDataTitle')"
                :text="$t('noDataTxt')"
              />
            </template>
          </ProSpaceMainGrid>
        </template>
      </ProSpaceGeoLayout>
    </template>
    <template #right>
      <RolePanel
        ref="right-panel"
        v-if="hasSelect"
        :service="roleService"
        :options="dotsOptions"
        @edit="editItem"
        @clear="selectedMultipleClear"
        @delete-item="deleteItem"
        @history-item="showHistory"
      />
      <ProSpaceModal
        v-model="showModal"
        width="410"
        :icon="isEdit ? 'edit' : 'plus'"
        :disabled-apply="disabledModalRule"
        @apply="changeItem"
        :cancelTxt="$t('cancelInf')"
        :applyTxt="$t('applyTxt')"
      >
        <template #title>
          {{ isEdit ? $t("editItemTxt") : "" }}
          {{ isCreate ? $t("createItemTxt") : "" }} {{ $t("roleLower") }}
          {{ detailName }}
        </template>
        <ProSpaceInputText
          :label="$t('name')"
          v-model="modalModel.systemName"
          :placeholder="$t('name')"
        ></ProSpaceInputText>
      </ProSpaceModal>
    </template>
  </ProSpaceGeoLayout>
  <ModalImport
    v-model="showImportModal"
    @loading="loadingHandler"
    accept=".csv, .xlsx"
  />
  <ProSpaceHistoryModal
    ref="history"
    v-model="showHistoryModal"
    modelName="SecurityRoleApiModel"
    @close="closeHistory"
  />
  <ProSpaceFlashMessage
    v-if="showDeleteModal"
    v-model="showDeleteModal"
    type="default"
    icon="flash-warning"
    :title="$t('deletingContactUser')"
    :applyTxt="$t('confirmTxt')"
    :cancelTxt="$t('reject')"
    @apply="applyDelete"
  >
    <ProSpaceVLayout :gap="10">
      <div
        class="text-color-gray text-bold"
        style="padding: 0 5px"
        v-html="$t('deleteRole', { name:  deletingModel.systemName })"
      ></div>
    </ProSpaceVLayout>
  </ProSpaceFlashMessage>
</template>
<script>
import {
  ProSpaceCustomFilterPanel,
  ProSpaceMultiSelect,
  ProSpaceFilterPanel,
  ProSpaceInputText,
  ProSpaceTableHeaderCell,
  ProSpaceModal,
  event_bus,
  ProSpaceDots,
  ProSpaceMainGrid,
  ProSpaceGeoLayout,
  ProSpaceTopPanel,
  ProSpaceRightPanel,
  ProSpaceIconButton,
  ProSpaceButton,
  ProSpaceEmptyGrid,
  ProSpaceHLayout,
  ProSpaceIcon,
  ProSpaceDateTimeColumn,
  ProSpaceFlashMessage
} from "@prospace/prospace-components-library";
import Column from "primevue/column";
import RolePanel from "./components/RolePanel.vue";
import { RoleService } from "../../services/RoleService.js";
import { StatusService } from "../../services/StatusService.js";
import { UseCasesService } from "../../services/UseCasesService.js";
import ProSpaceHistoryModal from "@modals/ProSpaceHistoryModal.vue";
import ModalImport from "./components/ModalImport";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  useStatistic
} from "@composes"
import {
  useMultiSelectGrid,
} from "@composes/multiselect";
import { ref } from "vue"
export default {
  components: {
    ModalImport,
    ProSpaceCustomFilterPanel,
    ProSpaceMultiSelect,
    ProSpaceFilterPanel,
    ProSpaceInputText,
    ProSpaceModal,
    ProSpaceDots,
    ProSpaceMainGrid,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceGeoLayout,
    ProSpaceTopPanel,
    ProSpaceRightPanel,
    ProSpaceIconButton,
    ProSpaceButton,
    ProSpaceTableHeaderCell,
    RolePanel,
    ProSpaceHLayout,
    ProSpaceIcon,
    ProSpaceHistoryModal,
    ProSpaceDateTimeColumn,
    ProSpaceFlashMessage
  },
  setup() {
    const grid = ref(null)
    const filterPanel = ref(null)
    const { setStatistic, distActions } = useStatistic()
    const {
      selectedMultipleGridHandler,
      selectedMultipleClear,
      selectedData,
      hasSelect,
    } = useMultiSelectGrid(grid, filterPanel)
    return {
      grid, filterPanel,
      setStatistic, distActions,
      selectedMultipleGridHandler, selectedData,
      hasSelect, selectedMultipleClear,
    }
  },
  data() {
    return {
      showHistoryModal: false,
      showModal: false,
      showImportModal: false,

      emitter: event_bus,

      // Service
      roleService: new RoleService(),
      statusService: new StatusService(),
      useCasesService: new UseCasesService(),

      // Modal
      modalModel: {
        systemName: "",
      },
      isEdit: false,
      isCreate: false,

      // Filter Panel Model
      modelFastFilter: {
        label: this.$t("allTxt"),
        value: false,
        field: "disabled",
      },

      deletingModel: null,
      showDeleteModal: false,
    };
  },
  methods: {
    closeHistory() {
      this.showHistoryModal = false;
    },
    showHistory(record) {
      this.$refs.history.tabs[0].masterId = record.id;
      this.$refs.history.masterName = record.systemName;
      this.$nextTick(() => {
        this.showHistoryModal = true;
      });
    },
    actionUpdate() {
      this.grid.getRecords(true);
      if (this.$refs["right-panel"]) {
        this.$refs["right-panel"].update();
      }
    },
    async actionExport(format) {
      this.loadingHandler(true);
      await this.roleService.export(this.grid.getRecords(false, true), format)
      this.loadingHandler(false)
    },
    actionImport() {
      this.showImportModal = true;
    },
    loadingHandler(val) {
      this.grid.loading = val;
    },
    toItem(data) {
      const url = `/securityadmin/role/${data.id}`
      this.setStatistic({
        actionTemp: this.distActions.form,
        requestUrl: url
      })
      this.$router.push(url);
    },
    selectedHandler(val) {
      this.selectedMultipleGridHandler(val)
    },
    submitFilter({ filter, search }) {
      this.grid.submitFilter(filter, search);
    },
    emitFilter() {
      this.filterPanel.submitFilter();
    },
    customFilterApply(filter, model) {
      filter = Object.assign(filter, model);
      this.emitFilter();
    },
    showEditModal(model) {
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = model;
      this.showModal = true;
    },
    changeItem() {
      let method;
      if (this.isCreate) {
        method = "create";
      }
      if (this.isEdit) {
        method = "edit";
      }
      this.roleService[method](this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    deleteItem(model) {
      if (!model) return;
      this.deletingModel = model;
      this.showDeleteModal = true;
    },
    applyDelete(){
      this.roleService.delete(this.deletingModel).then(() => {
        this.actionUpdate();
        this.grid.selectedModel = this.grid.selectedModel.filter(i => i.id !== this.deletingModel.id)
        this.deletingModel = null;
      });
    },
    editItem(model) {
      if (!model) return;
      this.isEdit = true;
      this.isCreate = false;
      this.modalModel = _.cloneDeep(model);
      this.showModal = true;
    },
    showChangeModal() {
      this.isEdit = false;
      this.isCreate = true;
      this.modalModel = {
        systemName: "",
      };
      this.showModal = true;
    },
    getStatus(status) {
      return {
        type: status
          ? status.toLowerCase() === "active"
            ? "success"
            : "danger"
          : "no-data",
        text: status
          ? status[0].toUpperCase() + status.toLowerCase().slice(1)
          : "No data",
      };
    },
    handleDots(action, item) {
      var option = this.dotsOptions.find((o) => o.name === action);
      option.method(item);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
    }),
    dotsOptions() {
      let res = [
        {
          name: "edit",
          text: this.$t("editItemTxt"),
          icon: "edit",
          method: this.editItem,
        },
        {
          name: "delete",
          text: this.$t("delete"),
          icon: "delete",
          method: this.deleteItem,
        },
        {
          name: "showHistory",
          text: this.$t("history"),
          icon: "history",
          method: this.showHistory,
        },
      ];
      if (this.modelFastFilter.label === this.$t("deleted")) {
        return res.filter(
          (option) => option.name !== "edit" && option.name !== "delete"
        );
      }
      return res;
    },
    fastFilters() {
      return [
        {
          label: this.$t("allTxt"),
          value: false,
          field: "disabled",
        },
        { label: this.$t("active"), field: "status", value: "Active" },
        { label: this.$t("deleted"), field: "disabled", value: true },
      ];
    },
    disabledModalRule() {
      return this.modalModel.systemName.length == 0;
    },
    layoutName() {
      return this.$t("roles");
    },
    isDeletedTab() {
      return this.modelFastFilter.label === this.$t("deleted");
    },
    customServices() {
      return [
        {
          type: "number",
          model: "id",
          label: this.$t("id"),
          selected: {},
        },
        {
          type: "chips",
          model: "createdBy",
          label: this.$t("createdByTxt"),
          selected: {},
        },
        {
          type: "chips",
          model: "updatedBy",
          label: this.$t("updatedByTxt"),
          selected: {},
        },
        {
          type: "dates-range",
          model: "createdAt",
          label: this.$t("createDate"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
        {
          type: "dates-range",
          model: "lastUpdated",
          label: this.$t("lastUpdatedTxt"),
          optionValue: "date",
          selected: {},
          dateFormat: this.userinfo.dateFormat,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.roles {
  .prospace-table-header-cell__description {
    display: none;
  }
}
</style>
