<template>
  <ProSpaceGeoLayout class="notification">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler" />
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="model"
        :tabs="tabs"
        v-model="modelTab"
        without-margin-bottom
        @select="handlerSelectTab"
      >
        <ProSpaceAction
          icon="double-check"
          :text="$t('saveAll')"
          @click="saveAll"
          :disabled="v$.model.$invalid"
        />
        <template #Overview>
          <ProSpaceGeoLayout is-template :template-center="true">
            <template #center>
              <ProSpaceTabs
                :tabs="overviewTabs"
                :loading="loading"
                :withoutMarginBottom="true"
                @select-tab="handlerSelectTab"
              >
                <ProSpaceAction
                  icon="check"
                  :text="$t('save')"
                  @click="savePart(saveFields.Overview)"
                  :disabled="v$.model.$invalid"
                />
                <template v-slot:tab-1>
                  <ProSpaceVLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('mainInformation')"
                          :bottom="$t('notificationAbout')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceInputSwitch
                            :label="$t('active')"
                            v-model="model.isActive"
                            :noBorder="true"
                          />
                          <ProSpaceInputText
                            :label="$t('name')"
                            v-model="model.name"
                            :placeholder="$t('name')"
                            :invalid="v$.model.name.$error"
                            :invalid-messages="v$.model.name.$errors"
                            @blur="v$.model.name.$touch"
                          />
                          <ProSpaceInputText
                            :label="$t('description')"
                            v-model="model.description"
                            :placeholder="$t('description')"
                            :is-textarea="true"
                            :auto-resize="false"
                          />
                        </ProSpaceVLayout>
                      </template>
                      <template v-if="v$.model.name.$error" v-slot:right>
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('activityPeriodF')"
                          :bottom="$t('notificationPeriod')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceDatePeriod
                          :label="$t('activityPeriodF')"
                          :model="model"
                          startDate="startDate"
                          endDate="endDate"
                          :dateFormat="userinfo.dateFormat"
                          :validStart="v$.model.startDate"
                          :validEnd="v$.model.endDate"
                        />
                      </template>
                      <template v-if="v$.model.startDate.$error || v$.model.endDate.$error" v-slot:right>
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('events')"
                          :bottom="$t('notificationEvent')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceCombobox
                          :label="$t('event')"
                          v-model="eventsModel"
                          optionLabel="name"
                          optionValue="id"
                          getMethod="getEvents"
                          :service="notificationService"
                          display="chip"
                          argMethod="$filter=disabled eq false"
                        />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('notificationType')"
                          :bottom="
                            $t('notificationsType')
                          "
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceSelectButton
                          style="pointer-events: none"
                          :label="$t('notificationType')"
                          :items="notificationTargets"
                          v-model="model.notifyTarget"
                        />
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceTabs>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #Recipients>
          <ProSpaceGeoLayout is-template :template-center="true">
            <template #center>
              <ProSpaceTabs :tabs="recipientsTabs" :loading="loading" @select-tab="handlerSelectTab">
                <ProSpaceAction
                  icon="check"
                  :text="$t('save')"
                  @click="savePart(saveFields.Recipients)"
                />
                <template v-slot:tab-1>
                  <ProSpaceVLayout :gap="10">
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('ruleOptions')"
                          :bottom="$t('notificationRuleOptionsInformation')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="30">
                          <ProSpaceCheckbox
                            :label="$t('searchIn')"
                            v-model="recipientsCheckbox"
                            :change-obj="model"
                          />
                          <ProSpaceInputChips
                            :label="$t('value')"
                            v-model="filterParameters"
                          />
                        </ProSpaceVLayout>
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('recipients')"
                          :bottom="$t('notificationRecipientsInformation')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <RecipientTree
                          ref="recipientTree"
                          :recipientFilter="recipientFilter"
                          :recipientTreeService="recipientTreeService"
                          @updated="updateRecipintFilter"
                        />
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceTabs>
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
</template>
<script>
import {
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceDatePeriod,
  ProSpaceRadioButtonSecondary,
  ProSpaceButton,
  ProSpaceInputSwitch,
  ModalForm,
  ProSpaceTextBlock,
  ProSpaceMainGrid,
  ProSpaceTopPanel,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceGeoLayout,
  ProSpaceTabs,
  ProSpaceCombobox,
  ProSpaceCheckbox,
  ProSpaceInputChips,
  ProSpaceSelectButton,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { NotificationService } from "../../services/NotificationService.js";
import { RecipientTreeService } from "../../services/RecipientTreeService.js";
import NotificationUsers from "./NotificationUsers.vue";
import RecipientTree from "./RecipientTree.vue";
import Column from "primevue/column";
import { mapGetters } from "vuex";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTabs,
    NotificationUsers,
    RecipientTree,
    ProSpaceInputText,
    ProSpaceAction,
    ProSpaceDatePeriod,
    ProSpaceRadioButtonSecondary,
    ProSpaceButton,
    ProSpaceInputSwitch,
    ModalForm,
    ProSpaceTextBlock,
    ProSpaceMainGrid,
    ProSpaceTopPanel,
    Column,
    ProSpaceGeoLayout,
    ProSpaceCombobox,
    ProSpaceCheckbox,
    ProSpaceInputChips,
    ProSpaceSelectButton,
    ProSpaceWarning,
  },
  data() {
    return {
      // Tabs
      modelTab: null,

      // Common
      layoutName: "",
      loayoutNameProp: "name",
      model: null,
      loading: false,
      eventsModel: null,
      filterParameters: null,
      recipientFilter: null,
      recipientTabPanel: { name: "To" },

      // Service
      notificationService: new NotificationService(),
      getMethod: "get",
      masterId: null,
      recipientTreeService: new RecipientTreeService(),

      // Modal
      modalModel: { name: "", description: "", startDate: "", endDate: "" },
      isEdit: false,
      isCreate: false,

      // recipients
      recipientsCheckbox: null,

      // SaveFields
      saveFields: {
        Overview: [
          "name",
          "description",
          "startDate",
          "endDate",
          "events",
          "notifyTarget",
          "isActive",
        ],
        Recipients: [
          "attachmentRequired",
          "filterBody",
          "filterSubject",
          "filterParameters",
          "recipientFilter",
        ],
      },

      //help data
      notificationTargets: [
        { value: "Mail", label: this.$t("email"), icon: "message" },
        // { value: "SMS", label: "SMS", icon: "sms" },
        // { value: "Telegram", label: "Telegram", icon: "telegram" }
      ],

      recipientNode: null,
      customValue: null,
    };
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  validations() {
    return {
      model: {
        name: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        startDate: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
        endDate: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  mounted() {
    this.modelTab = this.tabs[0];
    this.getData();
  },
  methods: {
    handlerSelectTab(value) {
      const tab = value.selectedTab || value
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    updateRecipintFilter(data) {
      this.recipientFilter = data;
    },
    setFields() {
      let ids = this.eventsModel.map((e) => e.id);
      this.model.events = this.model.events.filter((e) =>
        ids.includes(e.notifyEventId)
      );

      ids = this.model.events.map((e) => e.notifyEventId);
      let toAdd = this.eventsModel
        .filter((e) => !ids.includes(e.id))
        .map((e) => {
          return {
            notifyEventId: e.id,
          };
        });
      this.model.events = this.model.events.concat(toAdd);

      this.model.filterParameters = JSON.stringify(this.filterParameters);
      this.model.recipientFilter = JSON.stringify(this.recipientFilter);
    },
    removeEvent(event) {
      this.model.events = this.model.events.filter((e) => e.id !== event.id);
    },
    async getData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }

      const result = await this.notificationService[this.getMethod](
        this.masterId
      );
      this.model = result.data;
      this.eventsModel = this.model.events.map((e) => {
        return {
          id: e.notifyEventId,
          name: e.notifyEventName,
        };
      });
      this.recipientsCheckbox = [
        { label: this.$t("title"), field: "filterSubject" },
        { label: this.$t("body"), field: "filterBody" },
        { label: this.$t("hasAttach"), field: "attachmentRequired" },
      ];
      try {
        this.filterParameters = JSON.parse(this.model.filterParameters);
      } catch (e) {
        this.filterParameters = [];
      }

      try {
        this.recipientFilter = JSON.parse(this.model.recipientFilter);
      } catch {}
      if (!this.recipientFilter)
        this.recipientFilter = this.recipientTreeService.emptyData;
      this.recipientTreeService.set(this.recipientFilter);

      this.layoutName = this.model[this.loayoutNameProp];
    },
    saveAll() {
      this.loading = true;
      this.setFields();
      this.notificationService.set().then(() => {
        this.getData();
        if (this.$refs.recipientTree)
          this.$refs.recipientTree.refresh();
        this.loading = false;
      });
    },
    savePart(fields) {
      this.loading = true;
      this.setFields();
      this.notificationService.setPart(fields).then(() => {
        this.getData();
        if (this.$refs.recipientTree)
          this.$refs.recipientTree.refresh();
        this.loading = false;
      });
    },
    showModalForm(name) {
      this.$refs[name].show();
    },
    createItem() {
      this.notificationService.create(this.modalModel);
    },
    deleteRecord(id) {
      // this.model.claims = this.model.claims.filter((claim) => claim.id !== id);
    },
    sendGridModal(model) {
      console.log(model);
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDate: "userinfoStore/getFormatDate",
    }),
    tabs() {
      return [
        { label: this.$t("overview"), value: "Overview", systemName: "overview" },
        { label: this.$t("recipients"), value: "Recipients", systemName: "recipients" },
      ];
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), systemName: "overview" }];
    },
    recipientsTabs() {
      return [{ name: this.$t("recipients"), systemName: "recipients"}];
    },
    disabledModalRule() {
      return (
        this.modalModel.name.length == 0 ||
        this.modalModel.description.length == 0
      );
    },
  },
};
</script>
