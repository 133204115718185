import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/dashboardservice/dashboardintegrationmatching/";

export default class DashboardIntegrationMatchingApi {
  constructor() {
    this.defaultModel = {
    };
  }
  async getDashboardIntegrationMatchings(params) {
    return await request.post(`${rootRoute}getDashboardIntegrationMatchings`, {
      filter: params,
      parameters: {},
    });
  }
  async getDashboardIntegrationMatching(id) {
    return await request.post(`${rootRoute}getDashboardIntegrationMatchings`, {
      filter: `$filter=Id eq ${id}`,
      parameters: {},
    });
  }
  async createDashboardIntegrationMatching(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}createDashboardIntegrationMatching`, { data });
  }
  async deleteDashboardIntegrationMatching(model) {
    const data = [model];
    return await request.post(`${rootRoute}deleteDashboardIntegrationMatching`, { data });
  }
  async editDashboardIntegrationMatching(model) {
    const data = [_.merge(_.cloneDeep(this.defaultModel), model)];
    return await request.post(`${rootRoute}updateDashboardIntegrationMatching`, { data });
  }
  async resetMatching() {
    return await request.post(`${rootRoute}resetMatching`);
  }
  async export(filter, role, format) {
    return await request.post(`${rootRoute}exportDashboardIntegrationMatching`, {
      filter: filter,
      role: role,
      format: format
    });
  }
}
