import ExcelJS from 'exceljs';

const useConvertHTMLToExcel = () => {
  const COLOR_WHITE = "rgb(255, 255, 255)";
  const BORDER_COLOR = "rgb(224, 231, 244)";
  const BORDER_STYLE = "thin";
  const EXCLUDED_BG_COLOR = "rgba(0, 0, 0, 0)";
  const DEFAULT_BG_COLOR = COLOR_WHITE;
  const TEXT_COLOR = "rgb(55, 71, 79)";
  const TEXT_COLOR_INVERT = COLOR_WHITE;
  const HEAD_FONT_SIZE = 12;

  const tableToExcel = async (tableElement, tabName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(tabName);
    const rows = tableElement.querySelectorAll('tr');

    rows.forEach((row, rowIndex) => {
      let rowData = [];
      const cells = row.querySelectorAll('th, td');
      let totalColspan = 0;

      cells.forEach((cell) => {
        const cellContent = escapeValue(cell.innerText.trim());
        const colspan = cell.getAttribute('colspan') ? parseInt(cell.getAttribute('colspan')) : 1;

        if (rowIndex === 0) {
          rowData.push(cellContent);
          totalColspan += colspan;
        } else {
          rowData.push(cellContent);
        }

        // add empty cells for shift
        if (rowIndex === 0 && colspan > 1) {
          for (let i = 1; i < colspan; i++) {
            rowData.push("");
          }
        }
      });

      // add row to list
      const excelRow = worksheet.addRow(rowData);

      // merging cells for 1nth row
      if (rowIndex === 0 && totalColspan > 1) {
        let startCol = 1;
        cells.forEach((cell) => {
          const colspan = cell.getAttribute('colspan') ? parseInt(cell.getAttribute('colspan')) : 1;
          if (colspan > 1) {
            worksheet.mergeCells(rowIndex + 1, startCol, rowIndex + 1, startCol + colspan - 1);
          }
          startCol += colspan; // Shift start column by number of merged columns
        });
      }

      const isValidCells = rowIndex === 0 && totalColspan > 1;
      const cellsToStyled = isValidCells ? excelRow._cells : cells;

      // apply styles
      applyStylesToRow(cellsToStyled, rowIndex + 1, worksheet, rowData, rows, isValidCells);
    });

    // create buffer
    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  };
  
  const escapeValue = (value) => {
      if (value === null || value === undefined || value === "") {
          return "";
      }
      else if (isNumber(value)){
        return Number(value.replace(/[^0-9-.]/g, ''));
      }

      return value;
  }

  const isNumber = (n) => {
    if (/[a-zA-Zа-яА-Я]/g.test(n))
      return false;
    let val = n.replace(/[^0-9-.]/g, '');
    return Number(val) == val;
  }

  const applyStylesToRow = (cells, rowIndex, worksheet, rowData, rows, isValidCells) => {
    cells.forEach((cell, cellIndex) => {
      const excelCell = isValidCells ? cell : worksheet.getCell(rowIndex, cellIndex + 1);
      const borderColor = rgbToHex(BORDER_COLOR);
      const scope = !isValidCells ? cell.getAttribute('scope') : null;
      const isRowScope = !isValidCells ? scope === 'row' : false;
      const isColGroupScope = !isValidCells ? scope === 'colgroup' : true;
      const isColScope = !isValidCells ? scope === 'col' : false;
      const isHeader = isRowScope || isColGroupScope || isColScope;

      applyStyles(cell, excelCell, borderColor, isHeader, isColScope, isColGroupScope);
    });
  };

  const applyStyles = (htmlCell, excelCell, borderColor, isHeader, isColScope, isColGroupScope) => {
    // isColGroupScope -> first row & htmlCell === excelCell
    const computedStyles = !isColGroupScope ? window.getComputedStyle(htmlCell) : null;
    const defaultBgColor = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: rgbToHex(DEFAULT_BG_COLOR) }
    };
    const textColor = rgbToHex(TEXT_COLOR);
    const textColorInvert = rgbToHex(TEXT_COLOR_INVERT);

    // set border
    excelCell.border = {
      top: { style: BORDER_STYLE, color: { argb: borderColor } },
      left: { style: BORDER_STYLE, color: { argb: borderColor } },
      bottom: { style: BORDER_STYLE, color: { argb: borderColor } },
      right: { style: BORDER_STYLE, color: { argb: borderColor } },
    };

    // set text align
    if (computedStyles?.textAlign) {
      excelCell.alignment = { vertical: 'middle', horizontal: computedStyles.textAlign };
    } else {
      excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
    }

    // set text color
    excelCell.font = {};
    if (computedStyles?.backgroundColor === DEFAULT_BG_COLOR || computedStyles?.backgroundColor === EXCLUDED_BG_COLOR || isColScope || isColGroupScope) {
      excelCell.font = { ...excelCell.font, color: { argb: textColor } };
    } else {
      excelCell.font = { ...excelCell.font, color: { argb: textColorInvert } };
    }

    // set text size
    if (computedStyles?.fontSize && !isColGroupScope) {
      excelCell.font = { ...excelCell.font, size: +computedStyles.fontSize.split('px')[0] };
    } else {
      excelCell.font = { ...excelCell.font, size: HEAD_FONT_SIZE };
    }

    // set text weight
    if (computedStyles?.fontWeight === '500' || isHeader) {
      excelCell.font = { ...excelCell.font, bold: true }
    }

    // set background color
    if (computedStyles?.backgroundColor && computedStyles?.backgroundColor === EXCLUDED_BG_COLOR || isColGroupScope) {
      excelCell.fill = defaultBgColor;
    } else {
      excelCell.fill = {
        ...defaultBgColor,
        fgColor: { argb: rgbToHex(computedStyles.backgroundColor) }
      };
    }
  };

  const rgbToHex = (rgb) => {
    const result = rgb.match(/\d+/g);
    return result ? result.reduce((hex, val) => hex + ('0' + parseInt(val).toString(16)).slice(-2), '') : null;
  };

  return { tableToExcel };
};

export default useConvertHTMLToExcel;

