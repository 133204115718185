import request from "../../Frontends/Common/utils/request/requestMain";
const _ = require("lodash");
const rootRoute = "/dashboardservice/dashboarddataset/";

export default class DashboardDatasetApi {
  constructor() {
  }
  async getIntegrationSourceColumns(integrationTable) {
    if (integrationTable)
      return await request.post(`${rootRoute}getintegrationsourcecolumns`, {
        tableName: integrationTable,
        parameters: {},
      });
    else
      return Promise.apply([])
  }
  async getDatasetModel() {
    return await request.post(`${rootRoute}getDatasetModel`);
  }
  async getDatasetData(data) {
    return await request.post(`${rootRoute}getDatasetData`, { data });
  }
}
