<template>
  <ProSpaceGeoLayout class="usecase">
    <template #top>
      <ProSpaceTopPanel :header="layoutName" back @back="backHandler" />
    </template>
    <template #center>
      <ProSpaceRadioButtonSecondary
        v-if="model"
        :tabs="tabs"
        v-model="modelTab"
        @select="handlerSelectTab"
      >
        <ProSpaceAction
          icon="double-check"
          :text="$t('saveAll')"
          :disabled="loading || claimsLoading || v$.model.$invalid"
          @click="saveAll"
        />
        <template #Overview>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="true"
            :key="geoKey"
          >
            <template #center>
              <ProSpaceTabs
                :tabs="overviewTabs"
                :loading="loading"
                :withoutMarginBottom="true"
                @select-tab="handlerSelectTab"
              >
                <ProSpaceAction
                  icon="check"
                  :text="$t('save')"
                  @click="updateOverview"
                  :disabled="v$.model.$invalid"
                />
                <template v-slot:tab-1>
                  <ProSpaceVLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('mainInformation')"
                          :bottom="$t('useCaseInformation')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceVLayout :gap="20">
                          <ProSpaceInputText
                            :label="$t('title')"
                            v-model="model.title"
                            :placeholder="$t('usecaseTitle')"
                            :invalid="v$.model.title.$error"
                            :invalid-messages="v$.model.title.$errors"
                            @blur="v$.model.title.$touch"
                          />
                          <ProSpaceInputText
                            :label="$t('description')"
                            v-model="model.description"
                            :isTextarea="true"
                            :placeholder="$t('usecaseDescription')"
                          />
                        </ProSpaceVLayout>
                      </template>
                      <template
                        v-if="
                          v$.model.title.$error
                        "
                        v-slot:right
                      >
                        <ProSpaceWarning />
                      </template>
                    </ProSpaceGeoLayout>
                    <ProSpaceGeoLayout class="geo-row">
                      <template v-slot:left>
                        <ProSpaceTextBlock
                          :top="$t('activityPeriod')"
                          :bottom="$t('usecaseActivityPeriodInformation')"
                          :gap="10"
                          type="third"
                          break-word
                        />
                      </template>
                      <template v-slot:center>
                        <ProSpaceDatePeriod
                          :label="$t('activityPeriodF')"
                          :model="model"
                          startDate="startDate"
                          endDate="endDate"
                          :dateFormat="userinfo.dateFormat"
                        />
                      </template>
                    </ProSpaceGeoLayout>
                  </ProSpaceVLayout>
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel no-border>
                <template v-slot:center>
                  <ProSpacePanelBlock :header="overviewPanelHeader">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="overviewPanelContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <tempalte #bottom>
                  <ProSpacePanelBlock :header="overviewPanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="overviewPanelBottom" />
                    </template>
                  </ProSpacePanelBlock>
                </tempalte>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
        <template #Claims>
          <ProSpaceGeoLayout
            is-template
            :template-center="true"
            :template-right="true"
          >
            <template #center>
              <ProSpaceTabs :tabs="claimsTabs" :loading="loading" @select-tab="handlerSelectTab">
                <ProSpaceHLayout :is-inline-w="true" :gap="10">
                  <ProSpaceAddInputButton
                    ref="btn-add-claim"
                    searchValue="value"
                    getMethod="grid"
                    colName="value"
                    colDesc="serviceName"
                    filterField="securityClaimId"
                    second-get-method="grid"
                    second-create-method="create"
                    second-delete-method="delete"
                    :id="masterId"
                    :service="claimService"
                    :defaultFilter="{ disabled: { and: [false] } }"
                    :second-service="useCaseClaimService"
                    :addTxt="$t('add')"
                    :secondData="useCaseSecondData"
                    :cancelTxt="$t('cancelInf')"
                    :applyTxt="$t('applyTxt')"
                    :disabled="claimsLoading"
                    @apply="reloadRecords"
                  />
                  <ProSpaceAction
                    icon="check"
                    :text="$t('save')"
                    @click="savePart(saveFields.Claims)"
                    :disabled="loading || claimsLoading"
                  />
                </ProSpaceHLayout>
                <template v-slot:tab-1>
                  <ProSpaceMainGrid
                    v-if="masterId"
                    :key="masterId"
                    :id="masterId"
                    class="in-block-grid"
                    ref="grid-claims"
                    :fnSetRecords="setClaimsRecords"
                    :isInline="true"
                    :service="useCaseClaimService"
                    @loaded="gridLoaded"
                    getMethod="grid"
                    selection-mode="single"
                    style="height: calc(100vh - 180px)"
                  >
                    <template #columns>
                      <Column
                        field="securityClaimServiceName"
                        :header="$t('title')"
                      >
                        <template #body="field">
                          <ProSpaceDefaultTextColumn>
                            {{ field.data.securityClaimServiceName }}
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                      <Column field="securityClaimValue" :header="$t('value')">
                        <template #body="field">
                          <ProSpaceDefaultTextColumn>
                            {{ field.data.securityClaimValue }}
                          </ProSpaceDefaultTextColumn>
                        </template>
                      </Column>
                      <Column
                        field="actions"
                        style="display: flex; justify-content: flex-end"
                      >
                        <template #body="field">
                          <ProSpaceIconButton
                            icon="circle-close"
                            @click="deleteRecord(field.data)"
                          />
                        </template>
                      </Column>
                    </template>
                    <template #empty>
                      <ProSpaceEmptyGrid
                        icon="error-message"
                        :title="$t('noDataTitle')"
                        :text="$t('noDataTxt')"
                      />
                    </template>
                  </ProSpaceMainGrid>
                </template>
              </ProSpaceTabs>
            </template>
            <template #right>
              <ProSpaceRightPanel no-border>
                <template v-slot:center>
                  <ProSpacePanelBlock :header="overviewPanelHeader">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="overviewPanelContent" />
                    </template>
                  </ProSpacePanelBlock>
                </template>
                <tempalte #bottom>
                  <ProSpacePanelBlock :header="overviewPanelMeta">
                    <template v-slot>
                      <ProSpacePanelBlockList :list="overviewPanelBottom" />
                    </template>
                  </ProSpacePanelBlock>
                </tempalte>
              </ProSpaceRightPanel>
            </template>
          </ProSpaceGeoLayout>
        </template>
      </ProSpaceRadioButtonSecondary>
    </template>
  </ProSpaceGeoLayout>
</template>

<script>
import {
  ProSpaceInputText,
  ProSpaceAction,
  ProSpaceAddInputButton,
  ProSpaceDatePeriod,
  ProSpaceRadioButtonSecondary,
  ProSpaceIconButton,
  ProSpaceGeoLayout,
  ProSpaceTextBlock,
  ProSpaceMainGrid,
  ProSpaceTopPanel,
  ProSpaceHLayout,
  ProSpaceVLayout,
  ProSpaceTabs,
  ProSpaceRightPanel,
  ProSpacePanelBlock,
  ProSpacePanelBlockList,
  ProSpaceEmptyGrid,
  ProSpaceDefaultTextColumn,
  ProSpaceWarning,
} from "@prospace/prospace-components-library";
import { RoleService } from "../../services/RoleService.js";
import { UseCasesService } from "../../services/UseCasesService.js";
import { UseCaseClaimService } from "../../services/UseCaseClaimService.js";
import { ClaimService } from "../../services/ClaimService.js";
import Column from "primevue/column";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  required,
  maxLength,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  useStatistic
} from "@composes"
export default {
  components: {
    ProSpaceAction,
    ProSpaceInputText,
    ProSpaceAddInputButton,
    ProSpaceDatePeriod,
    ProSpaceRadioButtonSecondary,
    ProSpaceIconButton,
    ProSpaceGeoLayout,
    ProSpaceTextBlock,
    ProSpaceMainGrid,
    ProSpaceTopPanel,
    Column,
    ProSpaceEmptyGrid,
    ProSpaceHLayout,
    ProSpaceVLayout,
    ProSpaceTabs,
    ProSpaceRightPanel,
    ProSpacePanelBlock,
    ProSpacePanelBlockList,
    ProSpaceDefaultTextColumn,
    ProSpaceWarning,
  },
  setup() {
    const { setStatistic, distActions } = useStatistic()
    return { v$: useVuelidate(), setStatistic, distActions };
  },
  validations() {
    return {
      model: {
        title: {
          required: helpers.withMessage(
            this.$t("requiredFieldErrorMessage"),
            required
          ),
        },
      },
    };
  },
  data() {
    return {
      // Tabs

      modelTab: null,
      // Common
      layoutName: "",
      loayoutNameProp: "title",
      model: null,
      loading: false,
      claimsLoading: false,

      // Service
      roleService: new RoleService(),
      useCasesService: new UseCasesService(),
      useCaseClaimService: new UseCaseClaimService(),
      claimService: new ClaimService(),
      getMethod: "get",
      masterId: null,
      recordCounted: null,

      // Modal
      modalModel: { name: "", description: "", startDate: "", endDate: "" },
      isEdit: false,
      isCreate: false,

      // SaveFields
      saveFields: {
        Overview: ["title"],
        Claims: ["claims"],
      },

      //Panel
      geoKey: 0,
      useCaseSecondRecords: [],
    };
  },
  computed: {
    ...mapGetters({
      userinfo: "userinfoStore/getUserinfo",
      getFormatDateTime: "userinfoStore/getFormatDateTime"
    }),
    useCaseSecondData() {
      return this.useCaseSecondRecords;
    },
    tabs() {
      return [
        { label: this.$t("overview"), value: "Overview", systemName: "overview" },
        { label: this.$t("claims"), value: "Claims", systemName: "claims" },
      ];
    },
    overviewPanelHeader() {
      return {
        title: this.$t("useCase"),
      };
    },
    overviewPanelMeta() {
      return {
        title: this.$t("metaInfTxt"),
        isExpand: true,
      };
    },
    claimsTabs() {
      return [{ name: this.$t("claims"), counter: this.recordCounted, systemName: "claims" }];
    },
    overviewTabs() {
      return [{ name: this.$t("overview"), counter: this.recordCounted, systemName: "overview" }];
    },
    overviewPanelContent() {
      return [
        [
          {
            title: this.$t("name"),
            text: this.model.title,
          },
        ],
        [
          {
            title: this.$t("description"),
            text: this.model.description,
          },
        ],
      ];
    },
    overviewPanelBottom() {
      return [
        [
          {
            title: this.$t("createdByTxt"),
            text: this.model.createdBy,
          },
        ],
        [
          {
            title: this.$t("createdAtTxt"),
            text: this.getFormatDateTime(this.model.createdAt),
          },
          {
            title: this.$t("lastUpdatedTxt"),
            text: this.getFormatDateTime(this.model.lastUpdated),
          },
        ],
      ];
    },
    disabledModalRule() {
      return (
        this.modalModel.name.length == 0 ||
        this.modalModel.description.length == 0
      );
    },
  },
  mounted() {
    this.modelTab = this.tabs[0];
    this.loadData();
  },
  methods: {
    handlerSelectTab(value) {
      const tab = value.selectedTab || value
      this.setStatistic({
        actionTemp: this.distActions.tab,
        actionStr: tab.systemName
      })
    },
    savePart(fields) {
      this.loading = true;
      this.useCasesService.setPart(fields).then(() => {
        this.loadData();
        this.geoKey++;
        this.loading = false;
      });
    },
    updateOverview() {
      this.loading = true;
      this.model.startDate = moment(this.model.startDate).format("YYYY-MM-DD");
      this.model.endDate = moment(this.model.endDate).format("YYYY-MM-DD");
      this.useCasesService.edit(this.model).then((res) => {
        this.loadData();
        this.geoKey++;
        this.loading = false;
      });
    },
    gridLoaded() {
      this.useCaseSecondRecords = this.$refs["grid-claims"].records;
      this.recordCounted = this.$refs["grid-claims"].records.length;
      this.claimsLoading = false;
    },
    async loadData() {
      this.masterId = this.$route.params.id;
      if (this.masterId === undefined) {
        // redirect to 404
        return;
      }
      const result = await this.useCasesService[this.getMethod](this.masterId);
      this.model = result.data;
      this.useCaseClaimService.setItem(this.model);
      this.layoutName = this.model[this.loayoutNameProp];
    },
    saveAll() {
      this.loading = true;
      this.useCasesService.set().then(() => {
        this.loadData();
        this.geoKey++;
        this.loading = false;
      });
    },
    submitFilter() {
      this.$refs["grid-claims"].getRecords(true);
    },
    createItem() {
      this.useCasesService.create(this.modalModel).then(() => {
        this.actionUpdate();
      });
    },
    reloadRecords() {
      this.claimsLoading = true;
      this.$refs["grid-claims"].getRecords();
    },
    setClaimsRecords(response) {
      return response.data.records;
    },
    deleteRecord(model) {
      this.claimsLoading = true;
      const grid = this.$refs["grid-claims"];
      grid.setLoading(true);
      this.useCaseClaimService.delete([model]).finally(() => {
        grid.getRecords();
        this.claimsLoading = false;
      });
    },
    backHandler() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.radio-tabs-body {
  .radio-tabs-body__slot-wrapper > .side-maxi-block {
    overflow: hidden;
  }
  .main-grid.in-block-grid {
    .p-datatable.p-datatable-scrollable {
      background: none;
      height: 100%;
      .p-datatable-table {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .p-datatable-thead {
          margin-left: 5px;
          border-radius: 5px;
          box-shadow: none;
          tr {
            box-shadow: none;
          }
        }
        .p-datatable-tbody {
          tr {
            box-shadow: none;
            position: relative;
            box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.07);
          }
        }
      }
    }
  }
}
</style>
